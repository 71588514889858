import moment from 'moment';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../../api/api';
import { AccessTokenBearer, SignalMessage } from '../../api/models/Global';
import { RootState } from '../store';
import { hideProgress } from '../ui/ui.actions';
import { CHANGE_CALENDAR, CHANGE_SIGNAL_MESSAGES } from './company.types';

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const loadCompanyCalendar = (
    message: AccessTokenBearer,
    companyID?: string
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CALENDAR,
        payload: {
            inProgress: true,
        }
    });

    const apiResponse = await api.Company.getCompanyCalendar({
        accessToken: message.accessToken,
        companyID: companyID,
        dateFrom: moment().toJSON(),
        dateTo: moment().add(5, "weeks").toJSON(),
        dateBatchNumber: 0,
    });
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_CALENDAR,
        payload: {
            inProgress: false,
            calendar: apiResponse.result,
        }
    });
};
export const loadResourceCalendar = (
    message: AccessTokenBearer,
    resourceID?: string
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CALENDAR,
        payload: {
            inProgress: true,
            calendar: undefined,
        }
    });
    const apiResponse = await api.Company.getResourceCalendar({
        accessToken: message.accessToken,
        resourceID: resourceID,
        dateFrom: moment().toJSON(),
        dateTo: moment().add(5, "weeks").toJSON(),
        dateBatchNumber: 0,
    });
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_CALENDAR,
        payload: {
            inProgress: false,
            calendar: apiResponse.result,
        }
    });
};
export const setSignalMessages = (
    signalMessages?: SignalMessage[],
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_SIGNAL_MESSAGES,
        payload: {
            signalMessages: signalMessages,
        }
    });
};