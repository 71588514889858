import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';

export const BackArrowHeader = (props: {
    title?: string,
    className?: string,
    dark?:boolean,
}) => {
    const history = useHistory();
    function handleClick() {
        history.goBack();
    }
    return (
        <>
            <Row className={"mt-2 align-items-center " + props.className} >
                <Col className="col-auto">
                    <IconButton onClick={handleClick} size="small" className="p-0" >
                        {props.dark && <img src="/img/icons/icon_back_dark.svg" alt="back" />}
                        {!props.dark && <img src="/img/icons/icon_back.svg" alt="back" />}
                    </IconButton>
                </Col>
                <Col>
                    <Typography variant="subtitle2" align="center" className="w-100 ml-n3">
                        {props.title}
                    </Typography >
                </Col>
            </Row>
        </>
    )
}
