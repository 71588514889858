import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { build } from '../../build';
import { RootState } from '../../store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 10,
        },
    }),
);
export const VersionInfo = () => {
    const { mobileClient, mobileVersion } = useSelector((state: RootState) => state.user);

    const classes = useStyles();
    return <Typography variant="caption" className={classes.root}>
        v.{build.commit()} [{mobileClient}, {mobileVersion}]
    </Typography>;
};