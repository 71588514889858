import { Action } from 'redux';
import { ConfirmedVisit, CreateVisitMessage } from '../../api/models/Visit';

export const CHANGE_VISIT = '[INVITE] CHANGE_VISIT';
export const CHANGE_VISIT_RESPONSE = '[INVITE] CHANGE_VISIT_RESPONSE';
export const CHANGE_ALL_VISITS = '[INVITE] CHANGE_ALL_VISITS';
export type ChangeVisitPayload = {
    createVisitMessage?: CreateVisitMessage;
    createVisitResponse?: CreateVisitResponse;
    inProgress: boolean;
    currentVisit?: ConfirmedVisit;
}
interface ChangeVisitResponseAction extends Action {
    type: typeof CHANGE_VISIT_RESPONSE,
    payload: {
        createVisitResponse?: CreateVisitResponse;
    }
}
interface ChangeVisitAction extends Action {
    type: typeof CHANGE_VISIT,
    payload: ChangeVisitPayload,
}
interface ChangeAllVisitsAction extends Action {
    type: typeof CHANGE_ALL_VISITS,
    payload: {
        inProgress: boolean;
        allVisits?: ConfirmedVisit[];
    }
}
export type CreateVisitResponse = {
    shouldRedirectToVisitConfirmed?: boolean,
    shouldRedirectToPayment?: boolean,
    visitID?: string,
    paymentLink?: string,
    paymentQRCodeUrl?: string,
    validationMessage?: string,
};
export type VisitActionTypes =
    ChangeVisitAction
    | ChangeAllVisitsAction
    | ChangeVisitResponseAction
    ;
