import { Chip, CircularProgress, LinearProgress, Slider } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { CalendarSlot, MyCompany } from "../../api/models/Company";
import { RequestVisitCreateModel } from "../../api/models/EditVisit";
import { loadCompanyCalendar, loadResourceCalendar } from "../../store/company/company.actions";
import { CreateVisit } from "../../store/edit/edit.actions";
import { RootState } from '../../store/store';
import { refreshCurrentUser } from "../../store/user/user.actions";
import { updateVisit } from "../../store/visit/visit.actions";
import { CalendarResourcePicker } from '../company/CalendarResourcePicker';
import { CalculateWidthOfFilteredEntries, CalendarView } from '../company/CalendarView';
import { CalendarVisitList } from '../company/CalendarVisitList';
import { EditVistDialog } from "../company/EditVistDialog";
import { TabsSwitch } from "../ui/TabsSwitch";
import { SignalListener } from '../users/SignalListener';
import { BackArrowHeader } from "./BackArrowHeader";
import { L10N } from "./L10N";
import { Routes } from './NavMenu';

export const CalendarPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const urlParams = useParams<{ tab: "company" | "resource", mode: "calendar" | "list" }>();

    const { user } = useSelector((state: RootState) => state.user);
    const { myCompanies, calendar, inProgress } = useSelector((state: RootState) => state.company);
    const [selectedResourceDimension, setSelectedResourceDimension] = useState<string | undefined>(undefined);
    const [selectedMode, setSelectedMode] = useState(0);
    const [addDialogVisible, setAddDialogVisible] = useState(false);
    const [scale, setScale] = useState(0.8);

    useEffect(() => {
        if (!urlParams.mode || urlParams.mode === "calendar") {
            setSelectedMode(0);
        } else {
            setSelectedMode(1);
        }
    }, [urlParams]);

    function onModeChanged(value: number) {
        if (value === 0) {
            history.push(Routes.calendarPage(urlParams.tab, "calendar"));
        } else {
            history.push(Routes.calendarPage(urlParams.tab, "list"));
        }
    }
    useMemo(() => {
        if (!user) return;
        if (!urlParams) return;
        if (urlParams.tab === "company") {
            dispatch(loadCompanyCalendar(user, user.companyID));
        } else if (urlParams.tab === "resource") {
            dispatch(loadResourceCalendar(user, user.resourceID));
        } else {
            throw new Error("not implemented");
        }
    }, [dispatch, user, urlParams]);

    async function reloadServerData() {
        if (!user) return;
        if (!urlParams) return;
        await dispatch(refreshCurrentUser(user));
        if (urlParams.tab === "company") {
            await dispatch(loadCompanyCalendar(user, user.companyID));
        } else if (urlParams.tab === "resource") {
            await dispatch(loadResourceCalendar(user, user.resourceID));
        } else {
            throw new Error("not implemented");
        }
    }
    async function ChangeCurrentCompany(m: MyCompany) {
        if (!user) return;
        setSelectedResourceDimension(undefined);
        await dispatch(loadCompanyCalendar(user, m.companyID));
    }
    async function handleClickAdd() {
        if (!calendar?.selectedCompanyDimension) return;
        if (!user?.accessToken) return;

        await dispatch(CreateVisit({
            accessToken: user?.accessToken,
            companyID: calendar?.selectedCompanyDimension,
        }));

        setAddDialogVisible(true);
    }
    async function handleClickAddConcrete(message: RequestVisitCreateModel) {
        if (!calendar?.selectedCompanyDimension) return;
        if (!user?.accessToken) return;

        await dispatch(CreateVisit({
            ...message,
            accessToken: user?.accessToken,
            companyID: calendar?.selectedCompanyDimension,
        }));

        setAddDialogVisible(true);
    }
    async function handleDeleteClick(slot: CalendarSlot) {
        if (!user) return;
        if (!slot.visitID) return;
        await dispatch(updateVisit({
            accessToken: user.accessToken,
            shouldDelete: true,
            visitID: slot.visitID,
        }));
        await reloadServerData();
    }
    function ToggleSelectResource(resourceID: string | undefined) {
        if (selectedResourceDimension === resourceID) {
            setSelectedResourceDimension(undefined);
        } else {
            setSelectedResourceDimension(resourceID);
        }
    }
    const widthOfFilteredEntries = CalculateWidthOfFilteredEntries(calendar, selectedResourceDimension);

    const authorized = (user?.companyAccessGranted ?? false);
    return (
        <>
            {!inProgress && !authorized && <Redirect to={Routes.loginAndReturn(Routes.calendarPage(urlParams.tab, urlParams.mode))} />}
            <Container style={{ maxWidth: 'none' }}>
                <BackArrowHeader title={L10N({ key: "Your company calendar..." })} />
                <SignalListener onClose={reloadServerData} />
                {addDialogVisible &&
                    <EditVistDialog
                        onReloadRequired={reloadServerData}
                        open={addDialogVisible}
                        onClose={() => setAddDialogVisible(false)}
                    />
                }
                {inProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="mb-5 mt-3">
                    <Col className="p-0" />
                    <Col className="col-auto">
                        <TabsSwitch selectedTab={selectedMode} onTabChanged={onModeChanged}
                            tabs={[
                                urlParams.tab === "company"
                                    ? L10N({ key: "Company Calendar" })
                                    : L10N({ key: "My Calendar" }),
                                L10N({ key: "appointments" })
                            ]}
                        />
                    </Col>
                    <Col className="p-0" />
                </Row>
                {myCompanies?.companies?.map && myCompanies?.companies?.length > 1 && <>
                    <Row className="my-3">
                        <Col sm={12}>
                            {myCompanies?.companies?.map((m, i) => <React.Fragment key={m.companyID}>
                                <Chip
                                    label={m.displayName}
                                    onClick={() => ChangeCurrentCompany(m)}
                                    variant="outlined"
                                    color={m.companyID === calendar?.selectedCompanyDimension ? "primary" : undefined}
                                    className="m-1" />
                            </React.Fragment>)}
                        </Col>
                    </Row>
                </>}
                <div className="p-3 mb-3 bg-white"
                    style={{
                        width: widthOfFilteredEntries.fullWidth,
                    }}>
                    <CalendarResourcePicker toggleSelectResource={ToggleSelectResource} selectedResourceDimension={selectedResourceDimension} />

                </div>
                <Row>
                    <Col sm={12} md={"auto"} >
                        <Fab onClick={reloadServerData}
                            className="" size="small"
                            disabled={inProgress} >
                            {inProgress && <CircularProgress />}
                            {!inProgress && <RefreshIcon />}
                        </Fab>
                        <Fab color="primary" size="small"
                            onClick={() => handleClickAdd()}
                            disabled={inProgress}
                            className="m-1">
                            <AddIcon />
                        </Fab>
                    </Col>
                    <Col className="col-auto" >
                        <Fab className="" size="small"
                            onClick={() => {
                                let newScale = scale - 0.5;
                                if (newScale < 0.5) newScale = 0.5;
                                setScale(newScale)
                            }}
                        >
                            <ZoomOutIcon />
                        </Fab>
                    </Col>
                    <Col className="col-auto align-self-center px-0" >
                        <Slider value={scale}
                            onChange={(event, value) => setScale(value as number)} min={0.5} max={2} step={0.05}
                            style={{ width: "150px", }}
                        />
                    </Col>
                    <Col className="col-auto" >
                        <Fab className="" size="small"
                            onClick={() => {
                                let newScale = scale + 0.5;
                                if (newScale > 2) newScale = 2;
                                setScale(newScale)
                            }}
                        >
                            <ZoomInIcon />
                        </Fab>
                    </Col>
                </Row>
                {selectedMode === 0 && <>
                    <CalendarView handleDeleteClick={handleDeleteClick}
                        onReloadRequired={reloadServerData}
                        onAdd={handleClickAddConcrete}
                        selectedResourceDimension={selectedResourceDimension}
                        scale={scale}
                    />
                </>}
                {selectedMode === 1 && <>
                    <CalendarVisitList handleDeleteClick={handleDeleteClick}
                        onReloadRequired={reloadServerData}
                        selectedResourceDimension={selectedResourceDimension}
                    />
                </>}
            </Container >
        </>
    );
};
