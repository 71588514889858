import { Button, LinearProgress, Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { payForVisit } from '../../store/visit/visit.actions';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { ButtonShadow } from '../ui/ButtonShadow';
import { L10N } from './L10N';

export const PaymentPage = () => {
    const dispatch = useDispatch();
    const { inviteInProgress } = useSelector((state: RootState) => state.invite);
    const { inProgress, purchaseResponse } = useSelector((state: RootState) => state.visit);
    const { user } = useSelector((state: RootState) => state.user);
    const urlParams = useParams<{ visitID: string }>();

    useEffect(() => {
        if (inProgress) return;
        if (!urlParams.visitID) return;

        if (urlParams.visitID !== purchaseResponse?.visitID) {
            if (!user) return;
            dispatch(payForVisit({
                accessToken: user?.accessToken ?? "",
                visitID: urlParams.visitID,
            }));
        }
    }, [purchaseResponse, urlParams, inProgress, user, dispatch]);

    return (
        <>

            <Container>
                <BackArrowHeader title={L10N({ key: "PaymentPage - Title" })} />
                {(inviteInProgress || inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto">
                        <img src={`/img/envelope.svg`} alt="heart" width="150" />
                    </Col>
                    <Col />
                </Row>
                <Row className="my-3">
                    <Col md={3} />
                    <Col md={6}>
                        <Typography variant="subtitle2" color="textPrimary" align="center" >
                            {L10N({ key: "PaymentPage - Description" })}
                        </Typography >
                    </Col>
                    <Col md={3} />
                </Row>
                <Row className="mb-3">
                    <Col md={4} />
                    <Col md={4}>
                        <a href={purchaseResponse?.paymentLink}>
                            <img src={purchaseResponse?.paymentQRCodeUrl} alt="QR" className="w-100" />
                        </a>
                    </Col>
                    <Col md={4} />
                </Row>
                <Row className="mb-3">
                    <Col md={3} />
                    <Col>
                        <Button href={purchaseResponse?.paymentLink} variant="contained" color="primary"
                            className="text-white w-100 m-0 h-100" style={{
                                color: 'white',
                                fontWeight: 'bold',
                            }}
                            fullWidth>
                            {L10N({ key: "PaymentPage - Finalize Payment" })}
                            <ButtonShadow />
                        </Button>
                    </Col>
                    <Col md={3} />
                </Row>

            </Container>
        </>
    );
};
