import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar } from "@material-ui/core";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import * as React from 'react';
import { useState } from "react";
import { useHistory } from 'react-router';
import { L10N } from "../pages/L10N";
import { MarketingPageTab } from "../pages/MarketingPage";
import { Routes } from "../pages/NavMenu";
import { LanguageSelectFlag } from "../ui/LanguageSelect";
import { ScreenSizeListener } from "../ui/ScreenSizeListener";

export const MarketingMenu = () => {
    const history = useHistory();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    function menuClick(tab: MarketingPageTab) {
        closeMenuClick();
        history.push(Routes.marketingPage(tab));
    }
    function openMenuClick(event: React.MouseEvent<HTMLElement>) {
        setMenuAnchor(event.currentTarget);
    }
    function closeMenuClick() {
        setMenuAnchor(null);
    }
    function gotoApp(event: React.MouseEvent<HTMLElement>) {
        history.push(Routes.home);
    };
    return (
        <>
            <AppBar position="static" color="transparent" elevation={0}>
                <ScreenSizeListener threshold="md"
                    whenAboveThreshold={
                        <Toolbar>
                            <img src="/img/logo-white.png" width={120} height={30} alt="bookee" style={{
                                position: 'absolute',
                            }} />
                            <div className="divCenterParent" style={{
                                marginLeft: '60px',
                            }}>
                                <div className="divCenterChild">
                                    <Button onClick={() => menuClick("bookings")}
                                        className="text-white text-weight-bold2">
                                        {L10N({ key: 'WARUM?' })}
                                    </Button>
                                    <Button onClick={() => menuClick("subscribe")}
                                        className="text-white text-weight-bold2 ">
                                        {L10N({ key: 'ABONNIEREN' })}
                                    </Button>
                                    <Button onClick={() => menuClick("about")}
                                        className="text-white text-weight-bold2 ">
                                        {L10N({ key: 'ÜBER UNS' })}
                                    </Button>
                                    <Button onClick={() => menuClick("features")}
                                        className="text-white text-weight-bold2 ">
                                        {L10N({ key: 'FEATURES' })}
                                    </Button>
                                    <Button onClick={() => menuClick("categories")}
                                        className="text-white text-weight-bold2 ">
                                        {L10N({ key: 'KATEGORIEN' })}
                                    </Button>
                                    <Button onClick={() => menuClick("contact")}
                                        className="text-white text-weight-bold2 ">
                                        {L10N({ key: 'KONTAKT' })}
                                    </Button>
                                    <Button onClick={gotoApp} variant="contained" size="small"
                                        className="text-weight-bold2 mx-2">
                                        <OpenInBrowserIcon />
                                        {L10N({ key: 'PROBIER ES AUS' })}
                                    </Button>
                                    <LanguageSelectFlag hideLabel />
                                </div>
                            </div>

                        </Toolbar>
                    }
                    whenThresholdOrBelow={<>
                        <Toolbar >
                            <IconButton style={{
                                position: 'absolute',
                            }}
                                onClick={openMenuClick}
                            >
                                <img src="/img/marketing/menu.svg" alt="menu" />
                            </IconButton>
                            <div className="divCenterParent">
                                <div className="divCenterChild">
                                    <img src="/img/logo-white.png" width={120} height={30} alt="bookee" />
                                </div>
                            </div>
                        </Toolbar>
                        <Menu anchorEl={menuAnchor}
                            open={Boolean(menuAnchor)}
                            onClose={closeMenuClick}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem onClick={() => menuClick("home")}>
                                {L10N({ key: 'HOME' })}
                            </MenuItem>
                            <MenuItem onClick={() => menuClick("bookings")}>
                                {L10N({ key: 'WARUM?' })}
                            </MenuItem>
                            <MenuItem onClick={() => menuClick("subscribe")}>
                                {L10N({ key: 'ABONNIEREN' })}
                            </MenuItem>
                            <MenuItem onClick={() => menuClick("about")}>
                                {L10N({ key: 'ÜBER UNS' })}
                            </MenuItem>
                            <MenuItem onClick={() => menuClick("features")}>
                                {L10N({ key: 'FEATURES' })}
                            </MenuItem>
                            <MenuItem onClick={() => menuClick("screenshots")}>
                                {L10N({ key: 'SCREENSHOTS' })}
                            </MenuItem>
                            <MenuItem onClick={() => menuClick("categories")}>
                                {L10N({ key: 'KATEGORIEN' })}
                            </MenuItem>
                            <MenuItem onClick={() => menuClick("contact")}>
                                {L10N({ key: 'KONTAKT' })}
                            </MenuItem>

                            <MenuItem onClick={gotoApp}>
                                <OpenInBrowserIcon />
                                {L10N({ key: 'PROBIER ES AUS' })}
                            </MenuItem>
                            <LanguageSelectFlag fullWidth hideLabel />
                        </Menu>
                    </>}
                />
            </AppBar>
        </>
    );
};
