import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { InviteTime } from '../../api/models/Invite';
import { changeSelectedDimension } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            display: 'block',
            textTransform: 'none',
            outline: 'none !important',
        },
        horizontalList: {
            display: "flex",
            overflowX: "auto",
            overflowY: "hidden",
            scrollbarWidth: "none",
            paddingBottom: "6px",
        },
        month: {
            fontWeight: 200,
        }
    })
);
export const WhatTimeSelector = (props: {
}) => {
    const dispatch = useDispatch();
    const { currentInvite } = useSelector((state: RootState) => state.invite);
    const classes = useStyles();

    function isSelected(m: InviteTime | undefined): boolean {
        if (!m) return false;
        return m.dimensionValue === currentInvite?.timeDimensionSelectedValue;
    }
    function onTimeClicked(m: InviteTime) {
        dispatch(changeSelectedDimension(m, "what_time"));
    }
    return (<><Container>
        <Row className="mt-4">
            <Col>
                {currentInvite?.timeDimension?.map && currentInvite?.timeDimension &&
                    <div className={classes.horizontalList + " "}>
                        {currentInvite?.timeDimension?.map && currentInvite?.timeDimension
                            ?.filter((v, i, a) => v.countOfOffers > 0)
                            ?.map((m, i) =>
                                <Col key={i} className={"col-auto p-2"}>
                                    <Button className={classes.btn}
                                        variant={isSelected(m) ? "contained" : "outlined"}
                                        color={isSelected(m) ? "primary" : "default"}
                                        size="small"
                                        onClick={() => onTimeClicked(m)}>
                                        {moment(m.dimensionValue, "HH:mm").utc(true).local().format("HH:mm")}
                                    </Button>
                                </Col>
                            )}
                    </div>
                }
            </Col>
        </Row></Container>

    </>);
};
