import { LinearProgress, Typography } from '@material-ui/core';
import * as React from 'react';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { ConfirmedVisit } from '../../api/models/Visit';
import { RootState } from '../../store/store';
import { loadAllVisitsAndReviews } from '../../store/visit/visit.actions';
import { VisitReviewItemCard } from '../details/VisitReviewItemCard';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { Animations } from '../ui/Animations';
import { VisitListItemCard } from '../visit/VisitListItemCard';
import { L10N } from './L10N';
import { Routes } from './NavMenu';
import { PageHeader } from './PageHeader';

export const VisitReviewPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { inProgress, completedReviews, notCompletedVisitReviews } = useSelector((state: RootState) => state.visit);
    const { user } = useSelector((state: RootState) => state.user);

    useMemo(() => {
        if (!user) return;
        dispatch(loadAllVisitsAndReviews(user));
    }, [dispatch, user]);

    async function visitClick(event: React.MouseEvent<any, MouseEvent>, item: ConfirmedVisit) {
        if (!item) return;
        const route = Routes.visitReviewEditPage(item.visitID, item.visitReviewID);
        history.push(route);
        event.stopPropagation();
        event.preventDefault();
    }
    return (
        <>
            {!(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.visitReview)} />}
            <Container className="px-5">
                {inProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <BackArrowHeader title="Reviews" />
                {notCompletedVisitReviews && notCompletedVisitReviews?.length === 0
                    && completedReviews && completedReviews?.length === 0
                    && <>
                        <Row className="mb-3">
                            <Col />
                            <Col className="col-auto">
                                <img src={`/img/envelope.svg`} alt="heart" width="150" />
                            </Col>
                            <Col />
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Typography align="center">
                                    {L10N({ key: "Finish a visit to review it" })}
                                </Typography>
                            </Col>
                        </Row>
                        <PageHeader showLogo logoNavigatesToHome />
                    </>}
                {((notCompletedVisitReviews?.length ?? 0) > 0) && <>
                    <Row className="my-3">
                        <Col>
                            <Typography variant="body2">
                                {L10N({ key: "Waiting for your rating and review:" })}
                            </Typography>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        {notCompletedVisitReviews?.map && notCompletedVisitReviews?.map((row, i) =>
                            <Col key={i} className={"mb-3 " + Animations.slide_in_left() + " " + Animations.delay(i)}
                                xs={12} md={6} >
                                <VisitListItemCard data={row} onCardClick={visitClick} showPhotoInsteadOfDate />
                            </Col>
                        )}
                    </Row>
                </>}
                {((completedReviews?.length ?? 0) > 0) && <>
                    <Row className="my-3">
                        <Col>
                            <Typography variant="body2">
                                {L10N({ key: "Your reviews:" })}
                            </Typography>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        {completedReviews?.map && completedReviews?.map((row, i) =>
                            <Col key={i} className={"mb-3 " + Animations.slide_in_left() + " " + Animations.delay(i)}
                                xs={12} md={6} >
                                <VisitReviewItemCard data={row} />
                            </Col>
                        )}
                    </Row>
                </>}
            </Container >
        </>
    );
};

