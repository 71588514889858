import { Animations } from '../ui/Animations';
import { Chip, LinearProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { extensions } from '../../api/extensions';
import { InviteCompany, InviteCompanyService, InviteOffer } from '../../api/models/Invite';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { CancelVisitDialogVisible } from '../../store/ui/ui.actions';
import { createVisit } from '../../store/visit/visit.actions';
import { InviteCard } from '../invite/InviteCard';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { ButtonShadow } from '../ui/ButtonShadow';
import { L10N } from './L10N';
import { Routes } from './NavMenu';

export const InvitePage = () => {
    const dispatch = useDispatch();

    const { inviteInProgress, currentInvite, createInviteMessage } = useSelector((state: RootState) => state.invite);
    const { inProgress, createVisitResponse } = useSelector((state: RootState) => state.visit);
    const { user } = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const btnRef = useRef<HTMLButtonElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const singleOffer = getSelectedOffer();
    const serviceToBeSelected = (currentInvite?.companyServiceDimension.length ?? 0) > 1
        && currentInvite?.companyDimensionSelectedValue;

    useEffect(() => {
        extensions.preventBackspace();
        return () => {
            extensions.restoreBackspace();
        }
    }, []);

    useEffect(() => {
        if (!createVisitResponse) return;
        if (!createVisitResponse.visitID) return;
        const visitID = createVisitResponse.visitID;

        if (createVisitResponse.shouldRedirectToVisitConfirmed) {
            history.push(Routes.visitConfirmedPage(visitID));
        } else if (createVisitResponse.shouldRedirectToPayment) {
            history.push(Routes.paymentPage(visitID));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createVisitResponse]);

    useEffect(() => {
        if (singleOffer) {
            btnRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [singleOffer]);

    function currentService(): InviteCompanyService | undefined {
        if (!currentInvite) return;
        const result = currentInvite
            ?.companyServiceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyServiceDimensionSelectedValue);
        return result;
    }
    function currentCompany(): InviteCompany | undefined {
        if (!currentInvite) return;
        const result = currentInvite
            ?.companyDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyDimensionSelectedValue);
        return result;
    }
    function getSelectedOffer(): InviteOffer | undefined {
        let theOfferIsKnown = (currentInvite?.offers?.length ?? 0) === 1;
        if (!theOfferIsKnown) {
            if (currentInvite?.companyDimensionSelectedValue
                && currentInvite?.companyServiceDimensionSelectedValue
                && currentInvite?.dateDimensionSelectedValue
                && currentInvite?.timeDimensionSelectedValue) {
                theOfferIsKnown = true;
            }
            else {
                return;
            }
        }
        const theOffer = currentInvite?.offers[0];
        if (!theOffer) return;
        return theOffer;
    }
    async function confirm() {
        if (!(user?.clientAccessGranted ?? false)) {
            history.push(Routes.loginAndReturn(Routes.invite));
            return;
        }
        const theOffer = getSelectedOffer();
        if (!theOffer) return;
        await dispatch(createVisit({
            visitIDToBeReplaced: currentInvite?.visitIDToBeReplaced,
            accessToken: user?.accessToken ?? "",
            offeredResourceID: theOffer?.offerID,
            visitStartForClient: theOffer.start,
            visitNotes: currentInvite?.visitNotes ?? "",
        }));
    }
    async function cancelClick() {
        if (!currentInvite) return;
        if (!currentInvite.visitIDToBeReplaced) return;
        if (!user) return;

        await dispatch(CancelVisitDialogVisible(true));
    }
    async function removeFiltersClick() {
        if (!createInviteMessage) return;
        await dispatch(changeInvite({
            query: "",
            offeredResourceID: createInviteMessage.offeredResourceID,
        }));
    }

    return (
        <>
            {!inviteInProgress && !currentInvite && <Redirect to={Routes.home} />}
            {!inviteInProgress && serviceToBeSelected
                && <Redirect to={Routes.detailsTab("company",
                    currentInvite?.companyDimensionSelectedValue,
                    currentCompany()?.displayName,
                    currentCompany()?.address
                )} />}
            <Container ref={containerRef}>
                <BackArrowHeader title={L10N({ key: "Book an Appointment" })} />
                {(inviteInProgress || inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="mb-3">
                    <Col>
                        <InviteCard />
                    </Col>
                </Row>
                {(inviteInProgress || inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n3" color="secondary" />
                        </Col>
                    </Row>
                </>}
                {createVisitResponse?.validationMessage &&
                    <Row className="my-3">
                        <Col xs={12}>
                            <Typography variant="subtitle2" color="primary" align="center" >
                                {createVisitResponse?.validationMessage}
                            </Typography >
                        </Col>
                    </Row>
                }
                {(!inviteInProgress
                    && ((currentInvite?.dateDimension?.length ?? 0) === 0))
                    ? <>
                        <Row className="my-3">
                            <Col xs={12}>
                                <Typography variant="subtitle2" color="primary" align="center" >
                                    {L10N({ key: "There are no offers that satisfy your criteria. We need to remove your filtering." })}
                                </Typography >
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col className="">
                                <Button onClick={removeFiltersClick} variant="contained" color="primary"
                                    fullWidth>
                                    {L10N({ key: "OK" })}
                                    <ButtonShadow animate />
                                </Button>
                            </Col>
                        </Row>
                    </>
                    : <>
                        {!singleOffer && <Typography color="primary">
                            {L10N({ key: "Please define your bookee" })}
                        </Typography >}
                        {currentService()?.paymentUpfront === true &&
                            <Row className="mb-3">
                                <Col className="">
                                    <Typography color="primary">
                                        {L10N({ key: "Upfront payment - you need to pay upfront for this visit" })}
                                    </Typography>
                                </Col>
                            </Row>
                        }
                        {currentCompany()?.isDemo === true &&
                            <Row className="my-3">
                            <Col className="">
                                <Chip label="demo" color="primary" className={ Animations.heartbeat()} />
                                </Col>
                            </Row>
                        }
                        <Row className="mb-3">
                            <Col className="">
                                <Button onClick={confirm} variant="contained" color="primary" fullWidth
                                    disableElevation
                                    ref={btnRef}
                                    disabled={user?.clientAccessGranted
                                        && (!singleOffer || inviteInProgress || inProgress)}>
                                    {user?.clientAccessGranted
                                        ? <>
                                            {currentService()?.paymentUpfront === true
                                                ? L10N({ key: "book and pay" })
                                                : L10N({ key: "book" })}
                                        </>
                                        : <>{L10N({ key: "Login to finalize" })}
                                        </>
                                    }
                                    {singleOffer && <ButtonShadow animate />}
                                </Button>
                            </Col>
                            {currentInvite?.visitIDToBeReplaced &&
                                <Col className="">
                                    <Button variant="outlined" color="primary"
                                        onClick={cancelClick}
                                        fullWidth>
                                        {L10N({ key: "Cancel Visit" })}
                                    </Button>
                                </Col>
                            }
                        </Row>
                    </>}
            </Container>
        </>
    );
};
