import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../../api/api';
import { MapCoords } from '../../api/models/Details';
import { AccessTokenBearer } from '../../api/models/Global';
import { RootState } from '../store';
import { hideProgress } from '../ui/ui.actions';
import {
    CHANGE_FAVORITES_IN_PROGRESS,
    CHANGE_FEATURED_IN_PROGRESS,
    CHANGE_SEARCH_HINTS,
    CHANGE_SEARCH_IN_PROGRESS,
    CHANGE_SEARCH_STRING
} from './search.types';

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const changeSearchString = (
    searchString: string,
    currentLocation?: MapCoords,
): AppThunk => async (dispatch, getState) => {
    const state = getState().search;
    const cleanSearchString = searchString
        .replace(/\s+/ig, "|")
        .replace(/\|/ig, " ");
    var cleanSearchStringWithoutTags = cleanSearchString;
    for (let i = 0; i < (state.searchHints?.results?.length ?? 0); i++) {
        const tag = state.searchHints?.results[i]?.encodedText ?? "";
        cleanSearchStringWithoutTags = cleanSearchStringWithoutTags.replace(tag, "");
    }
    for (let i = 0; i < (state.searchCategories?.results?.length ?? 0); i++) {
        const tag = state.searchCategories?.results[i]?.encodedText ?? "";
        cleanSearchStringWithoutTags = cleanSearchStringWithoutTags.replace(tag, "");
    }
    for (let i = 0; i < (state.exploreWhenHints?.results?.length ?? 0); i++) {
        const tag = state.exploreWhenHints?.results[i]?.encodedText ?? "";
        cleanSearchStringWithoutTags = cleanSearchStringWithoutTags.replace(tag, "");
    }

    await dispatch({
        type: CHANGE_SEARCH_STRING,
        payload: {
            searchString: cleanSearchString === " " ? "" : cleanSearchString,
            searchStringWithoutTags: cleanSearchStringWithoutTags === " " ? "" : cleanSearchStringWithoutTags,
            currentLocation: currentLocation,
        }
    });
    await dispatch(startSearch());
};
export async function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}
async function shouldCancelSearch(getState: () => RootState, ms: number): Promise<boolean> {
    const searchString = getState().search.searchString.trim();
    await sleep(ms);
    const currentSearchString = getState().search.searchString.trim();
    return (searchString !== currentSearchString);
}
export const startSearch = (
): AppThunk => async (dispatch, getState) => {
    const shouldCancel = await shouldCancelSearch(getState, 500);
    if (shouldCancel) { return; }

    const state = getState();
    const searchString = state.search.searchString.trim();

    await dispatch({
        type: CHANGE_SEARCH_IN_PROGRESS,
        payload: {
            searchResultsInProgress: true,
        }
    });
    const apiResponse = await api.Search.getResults(searchString, state.search.currentLocation);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_SEARCH_IN_PROGRESS,
        payload: {
            searchResultsInProgress: false,
            searchResults: apiResponse.result,
        }
    });
};
export const refreshHints = (
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const searchString = state.search.searchString.trim();
    await dispatch({
        type: CHANGE_SEARCH_HINTS,
        payload: {
            searchHintsInProgress: true,
            searchHints: [],
            searchCategories: [],
            exploreWhenHints: [],
        }
    });
    const apiResponseHints = await api.Search.getHints(searchString);
    const apiResponseCategories = await api.Search.getCategories(searchString);
    const apiResponseExploreWhenHints = await api.Search.getExploreWhenHints(searchString);
    await dispatch(hideProgress(apiResponseHints.error ?? apiResponseCategories.error));
    await dispatch({
        type: CHANGE_SEARCH_HINTS,
        payload: {
            searchHintsInProgress: false,
            searchHints: apiResponseHints.result,
            searchCategories: apiResponseCategories.result,
            exploreWhenHints: apiResponseExploreWhenHints.result,
        }
    });
};
export const refreshExploreWhenHints = (
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const searchString = state.search.searchString.trim();
    await dispatch({
        type: CHANGE_SEARCH_HINTS,
        payload: {
            searchHintsInProgress: true,
            searchHints: [],
        }
    });
    const apiResponseHints = await api.Search.getHints(searchString);
    const apiResponseCategories = await api.Search.getCategories(searchString);

    await dispatch(hideProgress(apiResponseHints.error ?? apiResponseCategories.error));
    await dispatch({
        type: CHANGE_SEARCH_HINTS,
        payload: {
            searchHintsInProgress: false,
            searchHints: apiResponseHints.result,
            searchCategories: apiResponseCategories.result,
        }
    });
};
export const startRefreshFeatured = (
): AppThunk => async (dispatch, getState) => {
    const shouldCancel = await shouldCancelSearch(getState, 500);
    if (shouldCancel) { return; }

    const state = getState();
    const searchString = state.search.searchString.trim();

    await dispatch({
        type: CHANGE_FEATURED_IN_PROGRESS,
        payload: {
            featuredResultsInProgress: true,
            featuredResults: [],
        }
    });
    const apiResponse = await api.Search.getFeaturedResults(searchString, state.search.currentLocation);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_FEATURED_IN_PROGRESS,
        payload: {
            featuredResultsInProgress: false,
            featuredResults: apiResponse.result,
        }
    });
};
export const refreshFavorites = (
    message: AccessTokenBearer
): AppThunk => async (dispatch, getState) => {
    await dispatch({
        type: CHANGE_FAVORITES_IN_PROGRESS,
        payload: {
            favoriteResultsInProgress: true,
            favoriteResults: [],
        }
    });
    const apiResponse = await api.Search.getFavorites(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_FAVORITES_IN_PROGRESS,
        payload: {
            favoriteResultsInProgress: false,
            favoriteResults: apiResponse.result,
        }
    });
};