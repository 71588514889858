import { ApiError } from '../../api/ApiError';
import { RESET_REDUX } from '../store';
import {
    API_ACTION_HAS_FINISHED, API_ACTION_HAS_STARTED, CHANGE_MODAL_ACTION, ErrorDetails,
    UIActionTypes
} from './ui.types';

export function showProgress(): UIActionTypes {
    return {
        type: API_ACTION_HAS_STARTED
    };
}
export function clearError(): UIActionTypes {
    return {
        type: API_ACTION_HAS_FINISHED,
        payload: {
            error: undefined,
        }
    };
}
export function onError(message?: string): UIActionTypes {
    const errorDetails: ErrorDetails = {
        message: message,
        time_miliseconds: Date.now(),
    };
    return {
        type: API_ACTION_HAS_FINISHED,
        payload: {
            error: errorDetails,
        }
    };
}
export function resetRedux(): UIActionTypes {
    return {
        type: RESET_REDUX,
    }
}
export function hideProgress(error?: ApiError): UIActionTypes {
    if (error?.type === "unauthorized") {
        return resetRedux();
    }
    const errorDetails: ErrorDetails | undefined = error === undefined
        ? undefined
        : {
            apiError: error,
            time_miliseconds: Date.now(),
        };
    return {
        type: API_ACTION_HAS_FINISHED,
        payload: {
            error: errorDetails,
        }
    };
}
export function CancelVisitDialogVisible(cancelVisitDialogVisible?: boolean): UIActionTypes {
    return {
        type: CHANGE_MODAL_ACTION,
        payload: {
            cancelVisitDialogVisible: cancelVisitDialogVisible,
            serviceDetailsDialogVisible: false,
        }
    };
}
export function ServiceDetailsDialogVisible(serviceDetailsDialogVisible?: boolean): UIActionTypes {
    return {
        type: CHANGE_MODAL_ACTION,
        payload: {
            cancelVisitDialogVisible: false,
            serviceDetailsDialogVisible: serviceDetailsDialogVisible,
        }
    };
}