import { CardProps, Divider, LinearProgress, Typography } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { ResourceSelector } from './ResourceSelector';
import { ServiceSelector } from './ServiceSelector';
import { Summary } from './Summary';
import { VisitNoteEditor } from './VisitNoteEditor';
import { WhatTimeSelector } from './WhatTimeSelector';
import { WhenSelector } from './WhenSelector';

export const InviteCard = (props: CardProps & {
    showText?: boolean,
    showMenu?: boolean,
    buttonTextNotStarted?: JSX.Element,
    buttonTextInProgress?: JSX.Element,
    buttonTextCompleted?: JSX.Element,
}) => {
    const { inviteInProgress } = useSelector((state: RootState) => state.invite);

    return (
        <>
            <Typography variant="body2" align="center" className="mx-5">
                {L10N({ key: "You can select your favourite staff for the service and select your preferred date and time" })}
            </Typography >
            <ServiceSelector hideIfOne />
            <ResourceSelector hideIfOne />
            <Divider />
            {inviteInProgress &&
                <LinearProgress className="w-100 " color="secondary" />
            }
            <WhenSelector />
            <WhatTimeSelector />
            <VisitNoteEditor />
            <Summary />
        </>
    );
};
