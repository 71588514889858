import { Action } from 'redux';
import { ApiError } from '../../api/ApiError';
import { CompanyDetails, ResourceDetails, ServiceDetails } from '../../api/models/Details';
import { ConfirmedVisit } from '../../api/models/Visit';

export const CHANGE_CURRENT_VISIT = '[DETAILS] CHANGE_CURRENT_VISIT';
export const CHANGE_CURRENT_COMPANY = '[DETAILS] CHANGE_CURRENT_COMPANY';
export const CHANGE_CURRENT_SERVICE = '[DETAILS] CHANGE_CURRENT_SERVICE';
export const CHANGE_CURRENT_RESOURCE = '[DETAILS] CHANGE_CURRENT_RESOURCE';
interface ChangeVisitAction extends Action {
    type: typeof CHANGE_CURRENT_VISIT,
    payload: {
        inProgress: boolean;
        visit?: ConfirmedVisit;
    }
}
interface ChangeCompanyAction extends Action {
    type: typeof CHANGE_CURRENT_COMPANY,
    payload: {
        inProgress: boolean;
        company?: CompanyDetails;
    }
}
interface ChangeServiceAction extends Action {
    type: typeof CHANGE_CURRENT_SERVICE,
    payload: {
        inProgress: boolean;
        service?: ServiceDetails;
    }
}
interface ChangeResourceAction extends Action {
    type: typeof CHANGE_CURRENT_RESOURCE,
    payload: {
        inProgress: boolean;
        resource?: ResourceDetails;
    }
}
export interface AllDetailsResposePayload {
    company?: CompanyDetails;
    service?: ServiceDetails;
    resource?: ResourceDetails;
    companyError?: ApiError;
    serviceError?: ApiError;
    resourceError?: ApiError;
}
export type DetailsActionTypes =
    ChangeVisitAction
    | ChangeCompanyAction
    | ChangeServiceAction
    | ChangeResourceAction
    ;
