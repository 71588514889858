import { Calendar, MyCompanies } from '../../api/models/Company';
import { SignalMessage } from '../../api/models/Global';
import {
    CompanyActionTypes
} from './company.types';

export interface CompanyState {
    inProgress: boolean;
    calendar?: Calendar;
    myCompanies?: MyCompanies;
    signalMessages?: SignalMessage[],
}
const initialCompanyState: CompanyState = {
    inProgress: false,
    calendar: undefined,
    myCompanies: undefined,
    signalMessages: undefined,
}
export function companyReducer(
    state = initialCompanyState,
    action: CompanyActionTypes
): CompanyState {
    switch (action.type) {
        default: return {
            ...state,
            ...action.payload,
        }
    }
}
