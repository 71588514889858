import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { CompanyEditModel, PublishRule, PublishRules } from '../../api/models/Edit';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { useFormikResult } from '../ui/FormikAPI';

export const FieldSetCompanyPublish = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {
    const { company, resources, services } = useSelector((state: RootState) => state.edit);
    const [publishRules, setPublishRules] = useState<{
        rulesOK: PublishRule[],
        rulesNotOK: PublishRule[],
        allowPublish: boolean
    }>({
        rulesOK: [],
        rulesNotOK: [],
        allowPublish: false,
    });

    useEffect(() => {
        const rules = PublishRules.AnalyzeCompany(company, services, resources);

        setPublishRules({
            rulesOK: rules.filter(m => m.isOK),
            rulesNotOK: rules.filter(m => !m.isOK),
            allowPublish: rules.every(m => m.isOK)
        });
    }, [company, resources, services]);

    return <>
        <Container>
            {!publishRules.allowPublish && <>
                <Row>
                    <Col>
                        <Typography variant="subtitle2" color="primary">
                            {L10N({ key: "We need the following information before publishing:" })}
                        </Typography>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {publishRules.rulesNotOK.map((m, i) => <React.Fragment key={m.rule + i}>
                            <FormControlLabel label={L10N({ key: "_editStep", editStep: m.editStep }) + ": " + m.rule}
                                control={<Checkbox checked={m.isOK} color="primary" readOnly />}
                            />
                            <br />
                        </React.Fragment>
                        )}
                    </Col>
                </Row>
            </>}
            <Row>
                <Col>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            className="text-black text-weight-bold2">
                            {L10N({ key: "Ready to publish" })}
                        </AccordionSummary>
                        <AccordionDetails className="d-block">
                            {publishRules.rulesOK.map((m, i) => <React.Fragment key={m.rule + i}>
                                <FormControlLabel label={L10N({ key: "_editStep", editStep: m.editStep }) + ": " + m.rule}
                                    control={<Checkbox checked={m.isOK} color="primary" readOnly />}
                                />
                                <br />
                            </React.Fragment>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Col>
            </Row>
            {(publishRules.allowPublish || !props.formik.values.isNotPublished)
                && <>
                    <Row>
                        <Col>
                            <Accordion expanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    className="text-black text-weight-bold2">
                                    {L10N({ key: "Publish" })}
                                </AccordionSummary>
                                <AccordionDetails className="d-block">
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={!props.formik.values.isNotPublished} color="primary"
                                                id="isNotPublished" name="isNotPublished"
                                                onChange={(event) => props.formik.setFieldValue(`isNotPublished`, !event.target.checked)}
                                            />
                                        }
                                        label={L10N({ key: "Mark for publishing" })}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                </>}
        </Container>
    </>;
}
