import { createStyles, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import { upperCase } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { changeLanguage } from '../../store/user/user.actions';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
        },
        select: {
            '& .MuiSelect-root': {
                padding: '17px 32.5px 17px 6px',
            },
        }
    })
);
export const LanguageSelect = (props: {
    lang: string,
    disabled?: boolean,
    onChange: (value: string) => void,
    fullWidth?: boolean,
    hideLabel?: boolean,
}) => {
    const classes = useStyles();
    function langMenuItem(code: string, name: string): JSX.Element {
        return (<MenuItem value={code}>
            <img src={`/img/flags/${code}.png`} height={30} alt={name}
                className={classes.img + " my-auto p-0"}
            />
            <Typography className="ml-4" align="right">
                {upperCase(code)}
            </Typography>
        </MenuItem>);
    }
    return (
        <TextField variant="outlined" color="primary"
            placeholder={L10N({ key: "Language" })}
            label={(props.hideLabel ?? false) ? undefined : L10N({ key: "Language" })}
            disabled={props.disabled}
            select
            fullWidth={props.fullWidth}
            value={props.lang}
            onChange={(event) => props.onChange(event.target.value)}
            className={classes.select}
        >
            {langMenuItem("en", L10N({ key: "English" }))}
            {langMenuItem("de", L10N({ key: "German" }))}
            {langMenuItem("ae", L10N({ key: "Arabic" }))}
            {langMenuItem("ru", L10N({ key: "Russian" }))}
        </TextField>
    );
};
export const LanguageSelectFlag = (props: {
    disabled?: boolean,
    fullWidth?: boolean,
    hideLabel?: boolean,
}) => {
    const dispatch = useDispatch();
    const { lang } = useSelector((state: RootState) => state.user);


    function langMenuItem(code: string, name: string): JSX.Element {
        return (<MenuItem value={code}
            className={" m-0 p-0"}
            style={{
                backgroundColor: 'transparent'
            }}

        >
            {/*<img src={`/img/flags/${code}.png`} height={30} alt={name}*/}
            {/*    className={" m-0 p-0"}*/}
            {/*/>*/}
            <Typography className="mr-4" align="right">
                {upperCase(code)}
            </Typography>
        </MenuItem>);
    }
    return (
        <TextField variant="standard"
            placeholder={L10N({ key: "Language" })}
            label={(props.hideLabel ?? false) ? undefined : L10N({ key: "Language" })}
            disabled={props.disabled}
            className={" m-0 p-0 "}
            style={{
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
            }}
            select
            SelectProps={{
                style: {
                    backgroundColor: 'rgba(120,120,120,0.50)',
                    paddingTop: 0,
                    height: '34px',
                    borderRadius: '8px',
                }
            }}
            fullWidth={props.fullWidth}
            value={lang}
            onChange={(event) => dispatch(changeLanguage(event.target.value))}
        >
            {langMenuItem("en", L10N({ key: "English" }))}
            {langMenuItem("de", L10N({ key: "German" }))}
            {langMenuItem("ae", L10N({ key: "Arabic" }))}
            {langMenuItem("ru", L10N({ key: "Russian" }))}
        </TextField>
    );
};

