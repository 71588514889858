import { ApiError } from '../../api/ApiError';
import { UserProfile } from '../../api/models/User';
import { L10N_current } from '../../components/pages/L10N';
import {
    CHANGE_USER,
    UserActionTypes
} from './user.types';

export interface UserState {
    inProgress: boolean,
    inProgressFavoriteCompanyID?: string,
    user: UserProfile | null,
    remindersLog?: string[],
    apiError?: ApiError,
    lang?: string,
    mobileClient?: string,
    mobileVersion?: string,
}
const initialUserState: UserState = {
    inProgress: false,
    inProgressFavoriteCompanyID: undefined,
    user: null,
    remindersLog: undefined,
    apiError: undefined,
    lang: undefined,
    mobileClient: undefined,
    mobileVersion: undefined,
}
export function userReducer(
    state = initialUserState,
    action: UserActionTypes
): UserState {
    if (action?.payload?.user || action?.payload?.lang) {
        const browserLanguage = navigator.language;
        const lang = action?.payload?.user?.language
            ?? action?.payload?.lang
            ?? browserLanguage;
        L10N_current.changeLocale(lang);
    }
    switch (action.type) {
        case CHANGE_USER: return {
            ...state,
            ...action.payload,
            ...{ lang: L10N_current.getLocale() },
        }
        default:
            return state
    }
}
