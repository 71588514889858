import { createStyles, Divider, IconButton, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateCompanyDetails } from '../../store/details/details.actions';
import { RootState } from '../../store/store';
import { CompanyDetailsViewTabDetailsResources } from '../details/CompanyDetailsViewTabDetailsResources';
import { CompanyMapView } from '../details/CompanyMapView';
import { L10N } from '../pages/L10N';
import { EditDetailsDialog } from './EditDetailsDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnEdit: {
            position: 'absolute',
            right: '15px',
            top: '-10px',
        },
    })
);

export const CompanyDetailsViewTabDetails = (props: {
}) => {
    const dispatch = useDispatch();
    const { company } = useSelector((state: RootState) => state.details);
    const { user } = useSelector((state: RootState) => state.user);
    const [editDialog, setEditDialog] = useState(false);
    const classes = useStyles();

    async function editDetailsClick(event: React.MouseEvent<any, MouseEvent>) {
        event.stopPropagation();
        event.preventDefault();

        setEditDialog(true);
    }
    async function editCancel() {
        setEditDialog(false);
    }
    async function editSave(details: string) {
        if (!user) return;
        if (!company) return;

        await dispatch(updateCompanyDetails({
            accessToken: user.accessToken,
            companyID: company.itemID,
            description: details,
        }))
        setEditDialog(false);
    }


    return (<>
        <EditDetailsDialog title="Edit" open={editDialog}
            details={company?.htmlDescription ?? company?.description ?? ""}
            onCancel={editCancel}
            onSave={editSave}
        />
        {company?.mapCoords && <>
            <div className="mb-3" />
            <CompanyMapView company={company} />
            {company?.address &&
                <Typography variant="body1" color="textPrimary" className="my-2">
                    <img alt="place" src={`/img/icons/icon_place.svg`} className="mr-2 d-inline" height="16" width="20" />
                    {company?.address}
                </Typography>
            }
            <Divider className="my-3" />
        </>}
        <Row className="">
            <Col xs={12}>
                <Typography variant="body1" color="textPrimary" >
                    {L10N({ key: "Company description:" })}
                </Typography>
                {user?.companyID && company?.itemID === user?.companyID &&
                    <IconButton className={classes.btnEdit} onClick={editDetailsClick}>
                        <EditIcon color="primary" />
                    </IconButton >
                }
                {company?.htmlDescription && <>
                    <div className="mb-3" />
                    <div dangerouslySetInnerHTML={{ __html: company?.htmlDescription }} />
                </>}
                {company?.description && <>
                    <div className="mb-3" />
                    <Typography variant="body1" color="textPrimary">{company?.description}</Typography>
                </>}
                {company?.learnMoreURL && <>
                    <div className="mb-3" />
                    <Link target="_blank" href={company?.learnMoreURL} >
                        {L10N({ key: "Learn more" })}
                    </Link >
                </>}
            </Col>
        </Row>
        <Divider className="my-3" />
        {company?.resources?.map && <CompanyDetailsViewTabDetailsResources />}
    </>);
};
