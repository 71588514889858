import { createStyles, GridList, GridListTile, makeStyles, Theme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { PhotoPositionEnum } from '../../api/models/Global';
import { Animations } from './Animations';
import { ScreenSizeListener } from './ScreenSizeListener';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        images: {
            position: 'relative',
        },
        image: {
            zIndex: 1,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'absolute',
        },
        image_prev: {
            zIndex: 0,
        },
        dots: {
            zIndex: 2,
            marginTop: '-35px',
            marginBottom: '35px',
            right: '20px',
            position: 'absolute',
        },
        dot: {
            margin: '0px 2px',
            fill: "#ed242c",
            color: 'red',
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            width: '100%',
            scrollbarWidth: 'none',
        },
    }),
);
export interface ImageSliderProps<T> {
    items: T[],
    imgSrc: (item: T) => string,
    imgPosition: (item: T) => PhotoPositionEnum | undefined,
    height?: string,
}
export const ImageSlider = <T,>(props: ImageSliderProps<T>) => {
    const [imageIndex, setImageIndex] = useState({
        current: 0,
        previous: -1
    });
    const classes = useStyles();

    useEffect(() => {
        if (props.items.length === 0) return;

        const timer = setTimeout(() => {
            setImageIndex({
                current: (imageIndex.current + 1) % props.items.length,
                previous: imageIndex.current,
            });
        }, 3000);
        return () => clearTimeout(timer);
    });

    return (<div className={classes.root}>
        <div className={classes.images + " noScroll"} style={{ height: props.height }}>
            {props.items.map((m, i) => <div key={i}
                style={{
                    backgroundImage: `url(${props.imgSrc(m)})`,
                }}
                className={classes.image +
                    (i === imageIndex.current ? " " + Animations.slide_in_left()
                        : i === imageIndex.previous ? " " + classes.image_prev + " " + Animations.fade_out()
                            : "d-none")}
            >
            </div>
            )}
        </div>
        <div className={classes.dots}>
            {props.items.map((m, i) =>
                <img key={i} alt={"slide" + i}
                    className={classes.dot}
                    src={i === imageIndex.current ? "/img/icons/icon_dot_selected.svg" : "/img/icons/icon_dot.svg"} />
            )}
        </div>
    </div>);
};

export const ImageSliderGridList = <T,>(props: ImageSliderProps<T>) => {
    const initialImageIndex = {
        current: 0,
        previous: -1,
        userInterrupted: false,
    };
    const [imageIndex, setImageIndex] = useState(initialImageIndex);
    const [height, setHeight] = useState(220);
    const classes = useStyles();

    function onSizeChanged(size: Breakpoint | "xxs") {
        switch (size) {
            case 'xs': setHeight(220); break;
            case 'sm': setHeight(280); break;
            case 'md': setHeight(320); break;
            case 'lg': setHeight(380); break;
            case 'xl': setHeight(520); break;
            default: setHeight(220);
        }
    }

    useEffect(() => {
        if (props.items.length === 0) return;

        const timer = setTimeout(() => {
            if (imageIndex && imageIndex.userInterrupted) return;
            setImageIndex({
                ...imageIndex,
                current: (imageIndex.current + 1) % props.items.length,
                previous: imageIndex.current,
            });
        }, 3000);
        return () => {
            clearTimeout(timer);
        };
    });
    function userInterrupted() {
        setImageIndex({
            ...imageIndex,
            userInterrupted: true,
        });
    }
    return (
        <div className={classes.root} onMouseDown={userInterrupted} >
            <ScreenSizeListener onSizeChange={onSizeChanged} />
            <GridList className={classes.gridList + " noScroll"} cellHeight={height} cols={1}>
                {props.items.map((m, i) => <GridListTile
                    key={i === 0
                        ? "c" + props.imgSrc(props.items[(i + imageIndex.current) % props.items.length])
                        : props.imgSrc(props.items[(i + imageIndex.current) % props.items.length])
                    }
                    className={i === 0
                        ? Animations.puff_in_center()
                        : ""}
                >
                    <div className="overlay-container"
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <div className="overlay-content blur15 z1000"
                            style={{
                                background: `url(${props.imgSrc(props.items[(i + imageIndex.current) % props.items.length])}`,
                                backgroundPositionX: 'center',
                                backgroundPositionY: 'center',
                                backgroundSize: 'cover',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                        <div className="overlay z2000"
                            style={{
                                background: `url(${props.imgSrc(props.items[(i + imageIndex.current) % props.items.length])}`,
                                backgroundPositionX: 'center',
                                backgroundPositionY: 'center',
                                backgroundRepeat: "no-repeat",
                                backgroundSize: 'contain',
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </div>
                </GridListTile>)}
            </GridList>

            <div className={classes.dots}>
                {props.items.map((m, i) =>
                    <img key={i} alt={"slide" + i}
                        className={classes.dot}
                        src={i === imageIndex.current ? "/img/icons/icon_dot_selected.svg" : "/img/icons/icon_dot.svg"} />
                )}
            </div>
        </div >);
};
