import { VisitReviewDetails } from '../../api/models/Details';
import { PurchaseResponse } from '../../api/models/Purchase';
import { ConfirmedVisit, CreateVisitMessage } from '../../api/models/Visit';
import {
    CreateVisitResponse,
    VisitActionTypes
} from './visit.types';

export interface VisitState {
    createVisitMessage?: CreateVisitMessage;
    createVisitResponse?: CreateVisitResponse;
    purchaseResponse?: PurchaseResponse;
    inProgress: boolean;
    currentVisit?: ConfirmedVisit;
    allVisits?: ConfirmedVisit[];
    completedVisits?: ConfirmedVisit[];
    notCompletedVisits?: ConfirmedVisit[];
    notCompletedVisitReviews?: ConfirmedVisit[];
    completedReviews?: VisitReviewDetails[];
    currentReview?: VisitReviewDetails;
}
const initialVisitState: VisitState = {
    createVisitMessage: undefined,
    createVisitResponse: undefined,
    purchaseResponse: undefined,
    inProgress: false,
    currentVisit: undefined,
    allVisits: undefined,
    completedVisits: undefined,
    notCompletedVisits: undefined,
    notCompletedVisitReviews: undefined,
    completedReviews: undefined,
    currentReview: undefined,
}
export function visitReducer(
    state = initialVisitState,
    action: VisitActionTypes
): VisitState {
    switch (action.type) {
        default: return {
            ...state,
            ...action.payload,
        }
    }
}
