import '../../scss/animations.scss';
export class Animations {
    static infinite(): string {
        return `animation-infinite`;
    }
    static delay(i: number) {
        if (i < 25 * 3) {
            return `animation-delay-${i}`;
        } else {
            return `animation-delay-${25 * 3}`;
        }
    }
    static delayRandom(): string {
        let i = Math.floor(30 * Math.random());
        return Animations.delay(i);
    }
    static delayRandom10(): string {
        let i = Math.floor(10 * Math.random());
        return Animations.delay(i);
    }
    static jelloHorizontal() {
        return `jello-horizontal`;
    }
    static slide_out_blurred_right() {
        return `slide-out-blurred-right`;
    }
    static slide_in_blurred_left() {
        return `slide-in-blurred-left`;
    }
    static slide_in_blurred_right() {
        return `slide-in-blurred-right`;
    }
    static bounce_in_top() {
        return `bounce-in-top`;
    }
    static bounce_in_top_small() {
        return `bounce-in-top-small`;
    }
    static slit_in_vertical() {
        return `slit-in-vertical`;
    }
    static slide_out_elliptic_left_bck() {
        return `slide-out-elliptic-left-bck`;
    }
    static roll_out_blurred_right() {
        return `roll-out-blurred-right`;
    }
    static roll_in_blurred_left() {
        return `roll-in-blurred-left`;
    }
    static roll_in_left() {
        return `roll-in-left`;
    }
    static roll_in_right() {
        return `roll-in-right`;
    }
    static roll_in_left_effect() {
        return `roll-in-left-effect`;
    }
    static puff_in_center() {
        return `puff-in-center`;
    }
    static swirl_in_fwd() {
        return `swirl-in-fwd`;
    }
    static heartbeat() {
        return `heartbeat`;
    }
    static tracking_out_contract_bck() {
        return `tracking-out-contract-bck`;
    }
    static fade_out() {
        return `fade-out`;
    }
    static slide_out_blurred_bottom() {
        return `slide-out-blurred-bottom`;
    }
    static swing_in_top_fwd() {
        return `swing-in-top-fwd`;
    }
    static swing_out_top_fwd() {
        return `swing-out-top-fwd`;
    }
    static slide_out_bck_center() {
        return `slide-out-bck-center`;
    }
    static slide_in_left() {
        return `slide-in-left`;
    }
    static slide_in_elliptic_top_fwd() {
        return `slide-in-elliptic-top-fwd`;
    }
    static slide_in_bottom() {
        return `slide-in-bottom`;
    }
    static text_pop_up_top() {
        return `text-pop-up-top`;
    }
    static shadow_drop_2_center() {
        return `shadow-drop-2-center`;
    }
    static flip_scale_up_hor() {
        return `flip-scale-up-hor`;
    }
    static rotate_out_2_cw() {
        return `rotate-out-2-cw`;
    }
    static flip_out_hor_top() {
        return `flip-out-hor-top`;
    }

};
