import * as yup from 'yup';
import { SchemaOf } from "yup";
import { L10N } from '../../components/pages/L10N';
import { AccessTokenValidationSchema, EditModel, isNumberTest, PriceAndCurrencyEditModel, PriceAndCurrencyEditModelValidationSchema } from './Edit';
import { AccessTokenBearer, BeePhoto, PriceAndCurrency } from "./Global";
import { VisitStatusEnum, VisitStatusEnumValuesArray } from './Visit';

export interface RequestVisitEditModel extends AccessTokenBearer {
    visitID: string,
}
export interface RequestVisitCreateModel extends AccessTokenBearer {
    companyID: string,
    resourceID?: string,
    serviceID?: string,
    date?: string,
    hour?: string,
}
export interface VisitEditModel extends EditModel {
    visitID: string,
    visitStart: string,
    visitNotes?: string | null,
    visitStatus: VisitStatusEnum,
    paymentUpfront: boolean,
    clientID?: string | null,
    clientPhone?: string | null,
    clientName: string,
    isClientBanned: boolean,
    priceAndCurrency: PriceAndCurrencyEditModel,
    durationInMinutes?: string | null,
    timeBeforeInMinutes?: string | null,
    timeAfterInMinutes?: string | null,
    companyID: string,
    resourceID: string,
    resourceName: string,
    serviceID?: string | null,
    serviceName: string,
    offerID?: string | null,
    availableResources: VisitEditResource[],
    availableServices: VisitEditService[],
}
export function CreateNewVisit(): VisitEditModel {
    return {
        accessToken: "",
        visitID: "",
        visitStart: "",
        visitNotes: "",
        visitStatus: "Requested",
        paymentUpfront: false,
        clientID: "",
        clientPhone: "",
        clientName: "",
        isClientBanned: false,
        priceAndCurrency: {
            price: "",
            currency: "EUR",
        },
        durationInMinutes: "10",
        timeBeforeInMinutes: "",
        timeAfterInMinutes: "",
        companyID: "",
        resourceID: "",
        resourceName: "",
        serviceID: "",
        serviceName: "",
        offerID: "",
        availableResources: [],
        availableServices: [],
    };
}
export interface VisitEditResource {
    resourceID: string,
    resourceName: string,
    isAvailable?: boolean,
    photos: BeePhoto[],
}
export interface VisitEditService {
    serviceID: string,
    serviceName: string,
    priceAndCurrency: PriceAndCurrency,
    durationInMinutes: number,
    timeBeforeInMinutes?: number,
    timeAfterInMinutes?: number,
    photos: BeePhoto[],
}
export const VisitStatusEnumValidationSchema = yup.mixed().oneOf<VisitStatusEnum>(VisitStatusEnumValuesArray).defined();
export const VisitEditModelValidationSchema: SchemaOf<VisitEditModel> = yup.object({
    accessToken: AccessTokenValidationSchema,
    visitID: yup.string().defined(),
    visitStart: yup.string().defined(),
    visitNotes: yup.string().defined().default("").optional().nullable(),
    visitStatus: VisitStatusEnumValidationSchema,
    paymentUpfront: yup.boolean().defined().default(false),
    clientID: yup.string().defined().optional().nullable(),
    clientPhone: yup.string().defined().optional().nullable(),
    clientName: yup.string().typeError(L10N({ key: "cannot be empty" })).defined(L10N({ key: "cannot be empty" })),
    isClientBanned: yup.boolean().defined().default(false),
    priceAndCurrency: PriceAndCurrencyEditModelValidationSchema.optional().nullable(),
    durationInMinutes: yup.string()
        .test(isNumberTest)
        .optional().nullable(),
    timeBeforeInMinutes: yup.string()
        .test(isNumberTest)
        .optional().nullable(),
    timeAfterInMinutes: yup.string()
        .test(isNumberTest)
        .optional().nullable(),
    companyID: yup.string().defined(),
    resourceID: yup.string().typeError(L10N({ key: "cannot be empty" })).defined(L10N({ key: "cannot be empty" })),
    resourceName: yup.string().typeError(L10N({ key: "cannot be empty" })).defined(L10N({ key: "cannot be empty" })),
    serviceID: yup.string().defined().optional().nullable(),
    serviceName: yup.string().typeError(L10N({ key: "cannot be empty" })).defined(L10N({ key: "cannot be empty" })),
    offerID: yup.string().defined().optional().nullable(),
    availableResources: yup.array(),
    availableServices: yup.array(),
});