import { CreateInviteMessage, Invite } from '../../api/models/Invite';
import {
    CHANGE_INVITE,
    InviteActionTypes
} from './invite.types';

export interface InviteState {
    createInviteMessage?: CreateInviteMessage;
    inviteInProgress: boolean;
    serverInvite?: Invite;
    currentInvite?: Invite;
}
const initialInviteState: InviteState = {
    createInviteMessage: undefined,
    inviteInProgress: false,
    serverInvite: undefined,
    currentInvite: undefined,
}
export function inviteReducer(
    state = initialInviteState,
    action: InviteActionTypes
): InviteState {
    switch (action.type) {
        case CHANGE_INVITE: return {
            ...state,
            ...action.payload,
        }
        default:
            return state
    }
}
