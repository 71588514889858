import makeUrls from '@culturehq/add-to-calendar/dist/makeUrls';
import { Button, createStyles, makeStyles, PropTypes, Theme } from '@material-ui/core';
import * as React from 'react';
import { L10N } from '../pages/L10N';
import { ButtonShadow } from './ButtonShadow';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        atcButton: {
            '&:hover': {
                color: theme.palette.primary.contrastText,
            }
        },
    })
);
export const AddToCalendarButton = (props: {
    name: string,
    details: string,
    location: string,
    startsAt: string,
    endsAt: string,
    variant?: 'text' | 'outlined' | 'contained';
    color?: PropTypes.Color;
    fullWidth?: boolean,
    withBeeShadow?: boolean,
    size?: 'small' | 'medium' | 'large',
    startIcon?: React.ReactNode,
    className?: string,
}) => {
    const urls = makeUrls({
        ...props,
        location: (props.location ?? "").replace('\n', ' ').replace('\r', ' '),
        details: props.details ?? "",
    });
    const classes = useStyles();

    return (
        <Button href={urls.ics}
            rel="noopener noreferrer"
            variant={props.variant}
            color={props.color}
            fullWidth={props.fullWidth}
            className={props.className ?? classes.atcButton}
            disableElevation={props.withBeeShadow}
            startIcon={props.startIcon}
            size={props.size}
        >
            {L10N({ key: "Add to calendar" })}
            {props.withBeeShadow && <ButtonShadow />}
        </Button>
    );
};
