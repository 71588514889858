import { Accordion, AccordionDetails, AccordionSummary, createStyles, Divider, GridList, GridListTile, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { api } from '../../api/api';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { ScreenSizeListener } from '../ui/ScreenSizeListener';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            width: '100%',
        },
    }),
);

export const ResourceDetailsView = (props: {
    defaultExpanded: boolean,
}) => {
    const [gridListCols, setGridListCols] = useState(4.5);
    const { resource } = useSelector((state: RootState) => state.details);
    const classes = useStyles();

    return (
        <Accordion defaultExpanded={props.defaultExpanded}>
            <ScreenSizeListener onGridListColsChange={setGridListCols} />
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{resource?.title}</AccordionSummary>
            <Divider />
            <AccordionDetails>
                <Container>
                    <Row>
                        <Col>
                            {resource && <>
                                <GridList className={classes.gridList} cellHeight={220} cols={gridListCols} >
                                    {resource?.photos?.map && resource?.photos?.map((m, i) =>
                                        <GridListTile key={i} >
                                            <img src={api.Search.getPhotoUrl(m)} alt={"resource photo " + i} />
                                        </GridListTile>
                                    )}
                                </GridList >
                                <Typography variant="subtitle1">{resource?.title}</Typography>
                                <Typography variant="subtitle2">{resource?.subtitle}</Typography>
                                {resource?.htmlDescription && <>
                                    <div dangerouslySetInnerHTML={{ __html: resource?.htmlDescription }} />
                                </>}
                                {resource?.description && <>
                                    <Typography variant="body2">{resource?.description}</Typography>
                                </>}
                                {resource?.learnMoreURL && <>
                                    <Link target="" href={resource?.learnMoreURL} >
                                        {L10N({ key: "Learn more" })}
                                    </Link >
                                </>}
                            </>}
                        </Col>
                    </Row>
                </Container>
            </AccordionDetails>
        </Accordion>
    );
};
