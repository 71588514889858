import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { Animations } from './Animations';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            top: '80%',
            left: '5%',
            right: '5%',
            bottom: '-30%',
            filter: 'blur(7px)',
            opacity: '0.5',
            background: 'url(/img/bee-bg.svg)',
            backgroundClip: 'content-box',
            zIndex:-1,
        },
    })
);

export const ButtonShadow = (props: {
    animate?: boolean,
}) => {
    const classes = useStyles();
    return (<div className={classes.root
        + (props.animate ? " " + Animations.bounce_in_top_small() : "")} />);
};
