import { CardHeader, CardMedia, CardProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { api } from '../../api/api';
import { ConfirmedVisit } from '../../api/models/Visit';
import { DimensionIcon } from '../ui/DimensionIcon';
import { EmiCard } from '../ui/EmiCard';

export const CompanyCard = (props: CardProps & {
    data: ConfirmedVisit,
    height?: number,
}) => {
    const { data, ...cardProps } = props;
    return (
        <>
            <EmiCard
                {...cardProps}
                elevation={1}
            >
                {props.data && props.data.photos && props.data.photos.length > 0 &&
                    <CardMedia component="img" height={props.height ?? 124}
                        image={api.Search.getPhotoUrl(props.data.photos[0])}
                        title={props.data.companyName}
                    />
                }
                <CardHeader
                    title={<>
                        <Typography variant="body1" align="left">
                            {props.data.companyName}
                        </Typography>
                        <Typography variant="body2" align="left">
                            {props.data.companyCategory}
                        </Typography>
                        {props.data.addressLine &&
                            <div>
                                <Typography variant="body2" align="left">
                                    <DimensionIcon type='company' size="small" />
                                    {props.data.addressLine}
                                </Typography>
                            </div>
                        }
                    </>}
                />
            </EmiCard>
        </>
    );
};
