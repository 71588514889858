import { Avatar, Chip } from '@material-ui/core';
import React from "react";
import { SearchHint } from '../../api/models/Search';
import { L10N } from '../pages/L10N';
import { DimensionIcon } from '../ui/DimensionIcon';

export interface SearchChipProps {
    data: SearchHint,
    isChecked?: boolean,
    onChange?: (isChecked: boolean) => void,
}
export const SearchChip = (props: SearchChipProps) => {

    function onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (props.onChange) {
            props.onChange(!props.isChecked);
        }
        event.stopPropagation();
    }
    return (
        <Chip label={L10N({ key: "_hint", hint: props.data.hintText })}
            avatar={<Avatar color="default"> <DimensionIcon type={props.data.hintType} variant={props.isChecked ? "light" : "dark"} /></Avatar>}
            onClick={props.isChecked !== null ? onClick : undefined}
            variant={props.isChecked ? "default" : "outlined"}
            color={props.isChecked ? "primary" : undefined}
            className={"m-1 " + (props.isChecked ? "text-white bg-gradient-bee" : "bg-transparent")} />
    );
};
