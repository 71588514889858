import { createStyles, GridList, GridListTile, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { api } from '../../api/api';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tile: {
            "& div": {
                borderRadius: '8px',
            }
        },
    }),
);
export const CompanyDetailsViewTabPortfolio = (props: {
}) => {
    const { company } = useSelector((state: RootState) => state.details);
    const classes = useStyles();
    function colsInRow(i: number): number {
        const row = Math.floor(i / 2);
        const col = i % 2;
        if (row % 2) {
            return (col % 2) ? 2 : 1;
        } else {
            return (col % 2) ? 1 : 2;
        }
    }
    return (<>
        {(company?.portfolio?.length ?? 0) === 0 && <>
            <Row className="mt-5">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/envelope.svg`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="mb-3">
                <Col>
                    <Typography align="center">
                        {L10N({ key: "This company has no portoflio" })}
                    </Typography>
                </Col>
            </Row>
        </>}
        {company?.portfolio?.map && <>
            <GridList cellHeight={260} cols={3} spacing={8}>
                {company?.portfolio.map((m, i) => (
                    <GridListTile key={m.objectID + "#" + m.photoIndex}
                        className={classes.tile}
                        cols={colsInRow(i)}>
                        <img src={api.Search.getPhotoUrl(m)} alt={"portfolio#" + m.photoIndex} />
                    </GridListTile>
                ))}
            </GridList>
        </>}
    </>);
};
