import { Button, CircularProgress, createStyles, FormControlLabel, FormHelperText, IconButton, Link, makeStyles, Switch, TextField, Theme } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router';
import { nameof } from "../../api/extensions";
import { RootState } from '../../store/store';
import { refreshAccessToken, refreshPIN, updateUserProfile } from "../../store/user/user.actions";
import { ButtonShadow } from "../ui/ButtonShadow";
import { extractCountry, extractInCountryPhone, extractPrefix, PhoneCountrySelect } from '../ui/PhoneCountrySelect';
import { BackArrowHeader } from "./BackArrowHeader";
import { L10N } from './L10N';
import { Routes } from "./NavMenu";
import { PageHeader } from './PageHeader';

export type LOGIN_STATE_ENUM =
    "phone"
    | "pin"
    | "details"
    | "loggedin";

export interface LoginPageState {
    phoneCountry: string,
    phone: string,
    pin: string,
    firstName: string,
    lastName: string,
    consent: boolean,
    loginState: LOGIN_STATE_ENUM,
    loginStateMessage: string,
    showValidation: boolean,
    returnUrl?: string,
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '8px',
            height: theme.spacing(8),
        },
        container: {
            backgroundImage: 'url(/img/bg-1.svg)',
            backgroundRepeat: 'repeat',
            height: '100vh',
            width: '100vw',
            maxWidth: '100vw !important',
            paddingTop: '30px',
        },
    })
);
export const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const urlParams = useParams<{ returnUrl: string }>();
    const { user, inProgress, apiError } = useSelector((state: RootState) => state.user);
    const [state, setState] = useState<LoginPageState>({
        phoneCountry: "DE",
        phone: "",
        pin: "",
        firstName: "",
        lastName: "",
        consent: false,
        loginState: "phone",
        loginStateMessage: "",
        showValidation: false,
        returnUrl: urlParams?.returnUrl
            ? decodeURIComponent(urlParams?.returnUrl)
            : undefined,
    });
    useEffect(() => {
        if (inProgress) return;
        let loginState: LOGIN_STATE_ENUM = "phone";
        if (user && user.pinValidationRequired) {
            loginState = "pin";
        } else if (user && user.accountDetailsRequired) {
            loginState = "details";
        } else if (user
            && user.accessToken
            && user.clientAccessGranted) {
            loginState = "loggedin";
        }
        setState(state => {
            const phoneCountry = extractCountry(user?.phone ?? state.phone) ?? state.phoneCountry;
            const phone = extractInCountryPhone(user?.phone) ?? state.phone;
            return {
                ...state,
                phoneCountry: phoneCountry,
                phone: phone,
                firstName: user?.firstName ?? state.firstName,
                lastName: user?.lastName ?? state.lastName,
                consent: user?.consent ?? state.consent,
                loginState: loginState,
            }
        });
    }, [user, inProgress]);

    function currentPhoneWithPrefix(): string {
        let prefix = extractPrefix(state.phoneCountry);
        return prefix + state.phone;
    }
    async function loginWithPhoneClick(event?: React.MouseEvent<any, MouseEvent>) {
        setState({ ...state, showValidation: true });
        const phone = currentPhoneWithPrefix();
        if (!phone || !state.phone) return;

        dispatch(refreshPIN({
            phone: phone,
        }));
        setState({ ...state, showValidation: false });
    }
    async function validatePinClick(event?: React.MouseEvent<any, MouseEvent>) {
        setState({ ...state, showValidation: true });
        const phone = currentPhoneWithPrefix();
        if (!phone || !state.phone || !state.pin) return;
        dispatch(refreshAccessToken({
            phone: phone,
            pin: state.pin,
        }));
        setState({ ...state, showValidation: false });
    }
    async function saveAccountClick(event?: React.MouseEvent<any, MouseEvent>) {
        setState({ ...state, showValidation: true });
        if (!user) return;
        if (!state.firstName) return;
        if (!state.lastName) return;
        if (!state.consent) return;
        user.firstName = state.firstName;
        user.lastName = state.lastName;
        user.consent = state.consent;
        dispatch(updateUserProfile(user));
        setState({
            ...state,
            showValidation: false,
            returnUrl: Routes.home
        });
    }
    async function handleTermsClick(event?: React.MouseEvent<any, MouseEvent>) {
        history.push(Routes.profilePage("terms"));
    }
    function buttonWithHintRow(hint: string,
        btnText: string,
        onClick: (event: any) => void)
        : JSX.Element {
        return (<>
            <Row className="mb-3 mt-5">
                <Col >
                </Col>
                <Col className="col-auto">
                    <Link variant="body1" color="textPrimary"
                        href={L10N({ key: "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: " })
                            + state.loginState} >
                        {hint}
                    </Link>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <Button onClick={onClick} disableElevation
                        className={classes.btn}
                        variant="contained" color="primary" fullWidth
                        disabled={inProgress}>
                        {inProgress && <CircularProgress color="secondary" />}
                        {!inProgress && btnText}
                        <ButtonShadow />
                    </Button>
                </Col>
            </Row>
        </>);
    }

    function validationMessageIfNeeded(loginState: LOGIN_STATE_ENUM, fieldName?: keyof LoginPageState): string | undefined {
        if (!state.showValidation && !apiError) return undefined;
        if (loginState !== state.loginState) return undefined;
        if (loginState === "phone") {
            if (!state.phone) return L10N({ key: "cannot be empty" });
        } else if (loginState === "pin") {
            if (!state.pin) return L10N({ key: "cannot be empty" });
        } else if (loginState === "details") {
            if (fieldName === nameof<LoginPageState>("firstName")) {
                if (!state.firstName) return L10N({ key: "cannot be empty" });
            }
            if (fieldName === nameof<LoginPageState>("lastName")) {
                if (!state.lastName) return L10N({ key: "cannot be empty" });
            }
            if (fieldName === nameof<LoginPageState>("consent")) {
                if (!state.consent) return L10N({ key: "Your consent is required" });
            }
        }
        if (apiError?.type === "validation") return apiError?.validation?.message;

        return apiError?.message ?? undefined;
    }
    function formatPIN(value: string): string {
        if (!value) return value;

        const noDash = value.replace("-", "");
        const first3 = noDash.substr(0, 3);
        if (noDash.length <= 3) return first3;

        const theRest = noDash.substr(3);
        return `${first3}-${theRest}`;
    }
    function onPhoneChanged(newPhone: string) {
        const country = extractCountry(newPhone);
        const inCountryPhone = extractInCountryPhone(newPhone);
        if (country && inCountryPhone) {
            setState({
                ...state,
                phoneCountry: country,
                phone: inCountryPhone,
                loginState: "phone",
            });
        } else {
            setState({
                ...state,
                phone: newPhone,
                loginState: "phone",
            });
        }
    }
    async function submitOnEnter(event: React.KeyboardEvent<HTMLDivElement>, submitFunction: (event?: React.MouseEvent<any, MouseEvent>) => Promise<void>) {
        if (event.keyCode === 13) {
            await submitFunction();
            event.preventDefault();
            event.stopPropagation();
        }
    }
    return (<>
        {state.loginState === "loggedin" && <Redirect to={state.returnUrl ?? Routes.home} />}

        <Container className={classes.container}>
            <Row className="">
                <Col xs={0} md={2} lg={3} xl={4} />
                <Col xs={12} md={8} lg={6} xl={4}>
                    <Container>
                        <Row className="my-3" />
                        <PageHeader title="" showLogo logoNavigatesToHome />
                        <Row className="my-3" />
                        <BackArrowHeader title={L10N({ key: "Log into your account" })} />
                        <Row className="my-3">
                            <Col className="pr-1 col-auto" >
                                <PhoneCountrySelect
                                    disabled={state.loginState !== "phone"}
                                    phoneCountry={state.phoneCountry}
                                    onChange={(value) => setState({
                                        ...state,
                                        phoneCountry: value,
                                    })}
                                />
                            </Col>
                            <Col className="pl-1">
                                <TextField fullWidth variant="outlined"
                                    placeholder={L10N({ key: "Phone number" })}
                                    label={L10N({ key: "Phone number" })}
                                    helperText={validationMessageIfNeeded("phone")}
                                    value={state.phone}
                                    disabled={state.loginState !== "phone"}
                                    onChange={(event) => onPhoneChanged(event.target.value)}
                                    type="tel"
                                    autoComplete="tel"
                                    onKeyUp={event => submitOnEnter(event, loginWithPhoneClick)}
                                    autoFocus
                                    InputProps={{
                                        endAdornment: (<>
                                            <IconButton onClick={() => onPhoneChanged("")} disabled={!state.phone} >
                                                <ClearIcon color="disabled" />
                                            </IconButton>
                                        </>
                                        )
                                    }}
                                />
                            </Col>
                        </Row>
                        {state.loginState === "phone" && <>
                            {buttonWithHintRow(L10N({ key: "Cannot login?" }), L10N({ key: "Log in" }), loginWithPhoneClick)}
                        </>}
                        {state.loginState === "pin" && <>
                            <Row className="my-3">
                                <Col xs={12}>
                                    <TextField fullWidth variant="outlined" color="primary"
                                        placeholder={L10N({ key: "PIN" })}
                                        label={L10N({ key: "PIN" })}
                                        helperText={validationMessageIfNeeded("pin")}
                                        value={formatPIN(state.pin)}
                                        onChange={(event) => setState({
                                            ...state,
                                            pin: formatPIN(event.target.value),
                                        })}
                                        autoComplete="one-time-code"
                                        onKeyUp={event => submitOnEnter(event, validatePinClick)}
                                        autoFocus
                                    />
                                </Col>
                            </Row>
                            {buttonWithHintRow(L10N({ key: "Not getting SMS?" }), L10N({ key: "Log in" }), validatePinClick)}
                        </>}
                        {state.loginState === "details" && <>
                            <Row className="my-3">
                                <Col xs={12}>
                                    <TextField fullWidth variant="outlined" color="primary"
                                        placeholder={L10N({ key: "First Name" })}
                                        label={L10N({ key: "First Name" })}
                                        helperText={validationMessageIfNeeded("details", "firstName")}
                                        value={state.firstName}
                                        onChange={(event) => setState({
                                            ...state,
                                            firstName: event.target.value,
                                        })}
                                        autoComplete="given-name"
                                        autoFocus
                                    />
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col xs={12}>
                                    <TextField fullWidth variant="outlined" color="primary"
                                        placeholder={L10N({ key: "Last Name" })}
                                        label={L10N({ key: "Last Name" })}
                                        helperText={validationMessageIfNeeded("details", "lastName")}
                                        value={state.lastName}
                                        onChange={(event) => setState({
                                            ...state,
                                            lastName: event.target.value,
                                        })}
                                        onKeyUp={event => submitOnEnter(event, saveAccountClick)}
                                        autoComplete="family-name"
                                    />
                                </Col>
                            </Row>
                            <Row className="my-3">
                                <Col className="col-auto pr-0">
                                    <FormControlLabel
                                        control={<Switch
                                            color="primary"
                                            size="medium"
                                            checked={state.consent}
                                            onChange={(event) => setState({
                                                ...state,
                                                consent: event.target.checked,
                                            })}
                                        />}
                                        label={L10N({ key: "I agree to " })}
                                    />
                                    <FormHelperText className="ml-3">{validationMessageIfNeeded("details", "consent")}</FormHelperText>
                                </Col>
                                <Col className="col-auto pl-0">
                                    <Button onClick={handleTermsClick} color="primary">
                                        {L10N({ key: "terms and conditions" })}
                                    </Button>
                                </Col>
                                <Col />
                            </Row>
                            {buttonWithHintRow(L10N({ key: "Cannot login?" }), L10N({ key: "Save account" }), saveAccountClick)}
                        </>}
                    </Container>
                </Col>
                <Col xs={0} md={2} lg={3} xl={4} />
            </Row>
        </Container >
    </>
    );
};
