import { ThunkAction } from 'redux-thunk';
import { api } from '../../api/api';
import { UpdateCompanyMessage } from '../../api/models/Details';
import { AccessTokenBearer, DimensionTypes } from '../../api/models/Global';
import { RootState } from '../store';
import { hideProgress } from '../ui/ui.actions';
import { UIActionTypes } from '../ui/ui.types';
import {
    AllDetailsResposePayload,
    CHANGE_CURRENT_COMPANY,
    CHANGE_CURRENT_RESOURCE,
    CHANGE_CURRENT_SERVICE,
    CHANGE_CURRENT_VISIT,
    DetailsActionTypes
} from './details.types';
type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    DetailsActionTypes | UIActionTypes
>
export const loadAllDetails = (
    clearVisit: boolean,
    companyID?: string,
    serviceID?: string,
    resourceID?: string,
): AppThunk => async dispatch => {
    let initialPayload;
    if (clearVisit) {
        initialPayload = {
            inProgress: true,
            visit: undefined,
            company: undefined,
            service: undefined,
            resource: undefined,
        }
    } else {
        initialPayload = {
            inProgress: true,
            company: undefined,
            service: undefined,
            resource: undefined,
        }
    }
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: initialPayload,
    });
    const promises: Promise<void>[] = [];
    const allDetails: AllDetailsResposePayload = {
        company: undefined,
        service: undefined,
        resource: undefined,
        companyError: undefined,
        serviceError: undefined,
        resourceError: undefined,
    };
    if (companyID) {
        promises.push(api.Details.getCompany(companyID).then(result => {
            allDetails.company = result.result;
            allDetails.companyError = result.error;
        }));
    }
    if (serviceID) {
        promises.push(api.Details.getService(serviceID).then(result => {
            allDetails.service = result.result;
            allDetails.companyError = result.error;
        }));
    }
    if (resourceID) {
        promises.push(api.Details.getResource(resourceID).then(result => {
            allDetails.resource = result.result;
            allDetails.companyError = result.error;
        }));
    }
    await Promise.all(promises);
    await dispatch(hideProgress(allDetails.companyError ?? allDetails.serviceError ?? allDetails.resourceError));
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: {
            inProgress: false,
            company: allDetails.company,
            service: allDetails.service,
            resource: allDetails.resource,
        }
    });
};
export const loadDetails = (
    itemID: string,
    itemType: DimensionTypes
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: {
            inProgress: true,
            company: undefined,
            service: undefined,
            resource: undefined,
        }
    });
    switch (itemType) {
        case 'company': await dispatch(loadCompanyDetails(itemID)); break;
        case 'service': await dispatch(loadServiceDetails(itemID)); break;
        case 'resource': await dispatch(loadResourceDetails(itemID)); break;
        default: throw Error();
    }
};
export const loadVisitAndAllDetails = (
    message: AccessTokenBearer,
    visitID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CURRENT_VISIT,
        payload: {
            inProgress: true,
            visit: undefined,
        }
    });
    const apiResponse = await api.Details.getVisit({
        accessToken: message.accessToken,
        visitID: visitID,
    });
    await dispatch(hideProgress(apiResponse.error));
    const visit = apiResponse.result;
    await dispatch({
        type: CHANGE_CURRENT_VISIT,
        payload: {
            inProgress: true,
            visit: apiResponse.result,
        }
    });
    await dispatch(loadAllDetails(false, visit?.companyID, visit?.serviceID, visit?.resourceID));
};
export const loadCompanyDetails = (
    companyID: string
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: {
            inProgress: true,
            company: undefined,
        }
    });
    const apiResponse = await api.Details.getCompany(companyID);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: {
            inProgress: false,
            company: apiResponse.result,
        }
    });
    };
export const updateCompanyDetails = (
    message: UpdateCompanyMessage
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: {
            inProgress: true,
            company: undefined,
        }
    });
    const apiResponse = await api.Details.updateCompanyDetails(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: {
            inProgress: false,
            company: apiResponse.result,
        }
    });
};
export const loadServiceDetails = (
    serviceID: string
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CURRENT_SERVICE,
        payload: {
            inProgress: true,
            service: undefined,
        }
    });
    const apiResponse = await api.Details.getService(serviceID);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_CURRENT_SERVICE,
        payload: {
            inProgress: false,
            service: apiResponse.result,
        }
    });
};
export const loadResourceDetails = (
    resourceID: string
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_CURRENT_RESOURCE,
        payload: {
            inProgress: true,
            resource: undefined,
        }
    });
    const apiResponse = await api.Details.getResource(resourceID);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_CURRENT_COMPANY,
        payload: {
            inProgress: false,
            resource: apiResponse.result,
        }
    });
};
