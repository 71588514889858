import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearVisitIDFromCurrentInvite } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { CancelVisitDialogVisible } from '../../store/ui/ui.actions';
import { updateVisit } from '../../store/visit/visit.actions';
import { L10N } from '../pages/L10N';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '16px',
        },
    })
);
export const CancelVisitDialog = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const { cancelVisitDialogVisible } = useSelector((state: RootState) => state.ui);
    const { inviteInProgress, currentInvite } = useSelector((state: RootState) => state.invite);
    const classes = useStyles();

    async function handleClose() {
        await dispatch(CancelVisitDialogVisible(false));
    }
    async function cancelConfirmClick() {
        if (!currentInvite) return;
        if (!currentInvite.visitIDToBeReplaced) return;
        if (!user) return;

        await dispatch(updateVisit({
            accessToken: user?.accessToken,
            visitID: currentInvite.visitIDToBeReplaced,
            shouldCancel: true,
        }));
        await dispatch(clearVisitIDFromCurrentInvite());
        await handleClose();
    }
    return (
        <>
            <Dialog
                open={cancelVisitDialogVisible ?? false}
                onClose={handleClose}>
                <DialogTitle>
                    {L10N({ key: "Cancel visit?" })}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {L10N({ key: "Are you sure that you want to cancel your visit?" })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        className={classes.btn}
                        color="primary" variant="outlined">
                        {L10N({ key: "No, I decided to come" })}
                    </Button>
                    <Button onClick={cancelConfirmClick}
                        className={classes.btn}
                        color="primary" autoFocus variant="contained">
                        {inviteInProgress && <CircularProgress color="secondary" />}
                        {!inviteInProgress && L10N({ key: "Yes, please cancel" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
