import { Avatar, createStyles, Divider, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { api } from '../../api/api';
import { ResourceDetails } from '../../api/models/Details';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        avatar: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            margin: '2px',
            cursor: 'pointer',
        },
        avatarText: {
            maxWidth: theme.spacing(12),
        },
        horizontalList: {
            display: "flex",
            overflow: "scroll",
            scrollbarWidth: "none",
            paddingBottom: "6px",
        },
        selected: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            borderColor: theme.palette.primary.main,
            borderWidth: '3px',
            borderStyle: 'solid',
        },
        divCenterParent: {
            float: 'left',
            position: 'relative',
            left: '50%',
        },
        divCenterChild: {
            float: 'left',
            position: 'relative',
            left: '-50%',
        },
        workingHourRow: {
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.secondary.light,
            margin: '5px'
        },
        externalHTML: {
            ...theme.typography.body1,
            '& ::marker': {
                color: theme.palette.primary.main,
            },
            '& h2': theme.typography.subtitle2,
        },
    }),
);

export const CompanyDetailsViewTabDetailsResources = (props: {
}) => {
    const { company } = useSelector((state: RootState) => state.details);
    const classes = useStyles();
    const [current, setCurrent] = useState<ResourceDetails | undefined>(undefined);

    useEffect(() => {
        setCurrent(company?.resources[0]);
    }, [company]);

    return (<>
        {company?.resources?.map && <Row className="">
            <Col xs={12}>
                <Typography variant="body1" color="textPrimary" >
                    {L10N({ key: "Staff members:" })}
                </Typography>
            </Col>
            <div className={classes.horizontalList + " noScroll"}>
                {company?.resources?.map((m, i) =>
                    <Col className={"col-auto p-2"} key={m.itemID} >
                        <div className={"float-left mx-2"}
                            onClick={() => setCurrent(m)}
                        >
                            <div className={classes.divCenterParent}>
                                <div className={classes.divCenterChild}>
                                    <Avatar
                                        alt={m.title}
                                        className={classes.avatar
                                            + (m === current
                                                ? ` ${classes.selected} `
                                                : ``)}
                                        src={api.Search.getFirstPhotoUrl(m.photos)} />
                                </div>
                            </div>
                            <div className="w-100"></div>
                            <div className={"float-left w-100 mt-1 " + classes.avatarText} >
                                <Typography variant="body2" align="center">
                                    {m.title}
                                </Typography>
                            </div>
                        </div>
                    </Col>
                )}
            </div>
        </Row>}
        {current && <>
            <Row>
                <Col className="">
                    <Typography variant="subtitle1">{current?.title}</Typography>
                    <Typography variant="body1" color="textSecondary">{current?.subtitle}</Typography>
                </Col>
            </Row>
            <Row className="">
                <Col xs={12}>
                    {current?.htmlDescription && <>
                        <div className="mb-3" />
                        <div className={classes.externalHTML}
                            dangerouslySetInnerHTML={{ __html: current?.htmlDescription }} />
                    </>}
                    {current?.description && <>
                        <div className="mb-3" />
                        <Typography variant="body1" color="textPrimary">
                            {current?.description}
                        </Typography>
                    </>}
                    {current?.learnMoreURL && <>
                        <div className="mb-3" />
                        <Link target="_blank" href={current?.learnMoreURL} >
                            {L10N({ key: "Learn more" })}
                        </Link >
                    </>}
                </Col>
            </Row>
            {current?.contactDetails &&
                <Row className="">
                    <Col xs={12}>
                        <Divider className="my-4" />
                        <Typography variant="body1" color="textPrimary">
                            {L10N({ key: "Contact details:" })}
                        </Typography>
                        <div className="mb-3" />
                        <Typography variant="body1" color="textPrimary">
                            {current?.contactDetails}
                        </Typography>
                    </Col>
                </Row>
            }
            {current?.availability?.map && <>
                <Row>
                    <Col className="">
                        <Divider className="my-4" />
                        <Typography variant="body1" color="textPrimary" >
                            {L10N({ key: "Working Hours:" })}
                        </Typography>
                    </Col>
                </Row>
                {current.availability.map((m, i) => <Row key={m.date} className={classes.workingHourRow} >
                    <Col className="col-auto">
                        <Typography variant="body1" color={moment(m.date).day() === 0 ? "primary" : "inherit"} >
                            {L10N({ key: "_dateAsDayOrTomorrow", date: m.date })}
                        </Typography>
                    </Col>
                    <Col />
                    <Col className="col-auto">
                        {m.workingHours?.map && m.workingHours?.map((w, wi) => <React.Fragment key={w.from + " " + w.to}>
                            <Typography variant="body1">
                                {L10N({ key: "_dateAsHHmm", date: w.from })} -
                                {L10N({ key: "_dateAsHHmm", date: w.to })}
                            </Typography>
                        </React.Fragment>)}
                        {((m.workingHours?.length ?? 0) === 0) && "-"}
                    </Col>
                </Row>)}
            </>}
        </>}
    </>);
};
