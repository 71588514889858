import { Button, Divider, List, ListItem, Toolbar } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { L10N } from '../pages/L10N';
import { MarketingPageTab } from "../pages/MarketingPage";
import { Routes } from "../pages/NavMenu";
import { ScreenSizeListener } from '../ui/ScreenSizeListener';
import { VersionInfo } from '../ui/VersionInfo';

export const MarketingFooter = () => {
    const history = useHistory();

    function menuClick(tab: MarketingPageTab | "terms" | "legal") {
        if (tab === "terms") {
            history.push(Routes.profilePage("terms"));
        } else if (tab === "legal") {
            history.push(Routes.legal);
        }
        else {
            history.push(Routes.marketingPage(tab));
        }
    }
    return (
        <>
            <div className="bg-black"
                style={{
                    minHeight: '240px',
                    width: '100%',
                }} >
                <Container >
                    <Row>
                        <Col xs={12} className="order-first px-0 my-3">
                            <ScreenSizeListener threshold="md"
                                whenAboveThreshold={<>
                                    <Toolbar>
                                        <img src="/img/logo-white.png" height={30} alt="bookee" style={{
                                        }} />
                                        <Button onClick={() => menuClick("home")}
                                            className="text-white text-weight-bold2 ml-3">
                                            {L10N({ key: 'HOME' })}
                                        </Button>
                                        <Button onClick={() => menuClick("subscribe")}
                                            className="text-white text-weight-bold2 ">
                                            {L10N({ key: 'ABONNIEREN' })}
                                        </Button>
                                        <Button onClick={() => menuClick("bookings")}
                                            className="text-white text-weight-bold2 ">
                                            {L10N({ key: 'WARUM?' })}
                                        </Button>
                                        <Button onClick={() => menuClick("about")}
                                            className="text-white text-weight-bold2 ">
                                            {L10N({ key: 'ÜBER UNS' })}
                                        </Button>
                                        <Button onClick={() => menuClick("features")}
                                            className="text-white text-weight-bold2 ">
                                            {L10N({ key: 'FEATURES' })}
                                        </Button>
                                        <Button onClick={() => menuClick("screenshots")}
                                            className="text-white text-weight-bold2 ">
                                            {L10N({ key: 'SCREENSHOTS' })}
                                        </Button>
                                        <Button onClick={() => menuClick("categories")}
                                            className="text-white text-weight-bold2 ">
                                            {L10N({ key: 'KATEGORIEN' })}
                                        </Button>
                                        <Button onClick={() => menuClick("contact")}
                                            className="text-white text-weight-bold2 ">
                                            {L10N({ key: 'KONTAKT' })}
                                        </Button>

                                    </Toolbar>
                                    <Divider className="bg-white mx-4 my-4" />
                                    <List className="text-white mx-4 ">
                                        <ListItem button onClick={() => menuClick("terms")}>
                                            {L10N({ key: 'Datenschutz &amp; AGB' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("legal")}>
                                            {L10N({ key: 'Impressum' })}
                                        </ListItem>
                                        <ListItem >
                                            &copy;{moment(undefined, false).format('yyyy')} Bookee - E-Star Media Marc Krol. All rights reserved.
                                        </ListItem>
                                        <ListItem >
                                            <VersionInfo />
                                        </ListItem>
                                    </List>
                                </>
                                }
                                whenThresholdOrBelow={<>
                                    <Toolbar >
                                        <img src="/img/logo-white.png" height={30} alt="bookee" />
                                    </Toolbar>
                                    <Divider className="bg-white mx-5 my-4" />
                                    <List className="text-white mx-5 ">
                                        <ListItem button onClick={() => menuClick("home")} >
                                            {L10N({ key: 'HOME' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("subscribe")}>
                                            {L10N({ key: 'ABONNIEREN' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("bookings")}>
                                            {L10N({ key: 'WARUM?' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("about")}>
                                            {L10N({ key: 'ÜBER UNS' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("features")}>
                                            {L10N({ key: 'FEATURES' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("screenshots")}>
                                            {L10N({ key: 'SCREENSHOTS' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("categories")}>
                                            {L10N({ key: 'KATEGORIEN' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("contact")}>
                                            {L10N({ key: 'KONTAKT' })}
                                        </ListItem>
                                    </List>
                                    <Divider className="bg-white mx-5 my-4" />
                                    <List className="text-white mx-5 ">
                                        <ListItem button onClick={() => menuClick("terms")}>
                                            {L10N({ key: 'Datenschutz &amp; AGB' })}
                                        </ListItem>
                                        <ListItem button onClick={() => menuClick("legal")}>
                                            {L10N({ key: 'Impressum' })}
                                        </ListItem>
                                        <ListItem >
                                            &copy;{moment(undefined, false).format('yyyy')} Bookee - E-Star Media Marc Krol. All rights reserved.
                                        </ListItem>
                                        <ListItem >
                                            <VersionInfo />
                                        </ListItem>
                                    </List>
                                </>}
                            />
                        </Col>
                    </Row>
                </Container >
            </div>
        </>
    );
};
