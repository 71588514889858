import { Button, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { createPopup } from '@typeform/embed';
import '@typeform/embed/build/css/popup.css';
import * as React from 'react';
import { useEffect } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { L10N } from "../pages/L10N";
import { MarketingPageTab } from "../pages/MarketingPage";
import { EmiCard } from "../ui/EmiCard";
import { MarketingGradientShadowInverse } from "./MarketingGradientShadow";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            backgroundImage: 'url(/img/marketing/subscribe-bg.svg)',
        },
        circles: {
            backgroundImage: 'url(/img/marketing/subscribe-circles.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            padding: '8px 26px',
            marginTop: '26px',
            width: '100%',
            height: '100%',
            position: 'absolute',
            opacity: '0.4',
        },
        btnRegister: {
            textTransform: 'none',
            borderRadius: '30px',
            margin: 'auto',
            alignSelf: 'center',
            width: '280px',
        },
        button: {
            margin: '40px auto 20px auto',
            width: '85%',
            display: 'block',
            padding: '0px',
            borderRadius: 20,
            overflow: 'hidden',
            clip: 'auto',
            height: '40px',
            position: 'relative',
        },
        buttonBg: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'black',
            opacity: '0.2',
            top: '0px',
            left: '0px',
        },
        recommended: {
            border: '#ffffff 1px solid',
            borderRadius: 20,
            width: '60%',
            left: '0px',
            right: '0px',
            padding: '8px 16px',
            margin: '0px auto 0px auto',
            position: 'fixed',
            top: '10px',
        },
        shadow: {
            position: 'absolute',
            top: '70%',
            left: '15%',
            right: '15%',
            bottom: '-5%',
            filter: 'blur(10px)',
            opacity: '0.4',
            background: 'url(/img/bee-bg.svg)',
            backgroundClip: 'content-box',
            zIndex: -1,
        },
    }),
);
export const Marketing09_Subscribe = () => {
    const urlParams = useParams<{ tab: MarketingPageTab, referral: string }>();
    const classes = useStyles();
    const { open } = createPopup("exx6sPLS", {
        hidden: { referral: urlParams?.referral ?? "direct" }
    });
    useEffect(() => {
        if (urlParams
            && urlParams?.tab === "subscribe"
            && urlParams?.referral
            && urlParams?.referral !== ""
        ) {
            open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={classes.background}>
                <Container >
                    <Row className="mt-3">
                        <Col />
                        <Col className="col-auto"
                            style={{
                                backgroundImage: 'url(/img/marketing/app-circles.svg)',
                                backgroundRepeat: 'no-repeat',
                                minHeight: '150px',
                            }}>
                            <Typography variant="h4" align="center"
                                className=""
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: '32px',
                                    marginLeft: '15px',
                                }}>
                                {L10N({ key: 'Angebote für Dienstleister' })}
                            </Typography>
                        </Col>
                        <Col />
                    </Row>
                    <Row className="mt-5">
                        <Col xs={12} md={6} lg={true} className="px-5">
                            <EmiCard className={"bg-gradient-bee-vertical my-3 text-white "}>
                                <div className={classes.circles}>
                                </div>
                                <div className="z1000">
                                    <Typography variant="h3"
                                        align="center" style={{
                                            marginTop: '80px',
                                            marginBottom: '40px',
                                            fontWeight: 'bold',
                                            textShadow: '0px 2px 8px #000000'
                                        }}>
                                        {L10N({ key: '49€' })}
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        className="my-3"
                                        style={{
                                            fontWeight: 'bolder',
                                            color: '#1FE0A0',
                                        }}>
                                        {L10N({ key: '30 Tage kostenfrei testen' })}
                                        <br />
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        style={{
                                            fontWeight: 'bold',
                                        }}>
                                        {L10N({ key: 'Monats-Abo' })}
                                        <br />
                                        {L10N({ key: 'Vorzugspreis' })}
                                        <br />
                                        {L10N({ key: 'monatlich kündbar' })}
                                        <br />
                                        {L10N({ key: 'inkl. Werbe-Material' })}
                                        <br />
                                    </Typography>
                                    <div className={classes.button}>
                                        <div className={classes.buttonBg}>
                                        </div>
                                        <Button
                                            href={"https://buy.stripe.com/00g9CBby069R9mU28d"}
                                            className="text-white w-100 m-0 h-100" style={{
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}>
                                            {L10N({ key: 'Buchen' })}
                                        </Button>
                                    </div>
                                </div>

                            </EmiCard>
                            <div className={classes.shadow}>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={true} className="px-5">
                            <EmiCard className={"bg-gradient-bee2-vertical my-3 text-white "}>
                                <div className={classes.recommended}>
                                    <div />
                                    <Typography align="center" className="text-white text-weight-bold2">
                                        {L10N({ key: 'Empfehlung' })}
                                    </Typography>
                                </div>
                                <div className={classes.circles}>
                                </div>
                                <div className="z1000">
                                    <Typography variant="h3"
                                        align="center" style={{
                                            marginTop: '80px',
                                            marginBottom: '40px',
                                            fontWeight: 'bold',
                                            textShadow: '0px 2px 8px #000000'
                                        }}>
                                        {L10N({ key: '499€' })}
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        className="my-3"
                                        style={{
                                            fontWeight: 'bolder',
                                            color: '#1FE0A0',
                                        }}>
                                        {L10N({ key: '30 Tage kostenfrei testen' })}
                                        <br />
                                    </Typography>
                                    <Typography variant="subtitle2" align="center"
                                        style={{
                                            fontWeight: 'bold',
                                        }}>
                                        {L10N({ key: 'Jahres-Abo' })}
                                        <br />
                                        {L10N({ key: 'Vorzugspreis' })}
                                        <br />
                                        {L10N({ key: '12 Monate zum Preis von 10' })}
                                        <br />
                                        {L10N({ key: 'inkl. Werbe-Material' })}
                                        <br />
                                    </Typography>
                                    <div className={classes.button}>
                                        <div className={classes.buttonBg}>
                                        </div>
                                        <Button
                                            href={"https://buy.stripe.com/28og0Z31u9m30Qo6ou"}
                                            className="text-white w-100 m-0 h-100" style={{
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}>
                                            {L10N({ key: 'Buchen' })}
                                        </Button>
                                    </div>
                                </div>

                            </EmiCard>
                            <div className={classes.shadow}>
                            </div>

                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col />
                        <Col xs={12} lg={true} className="my-3">
                            <Typography variant="subtitle2" align="center" style={{
                                color: 'white',
                                fontWeight: 'bold',
                            }}>
                                {L10N({ key: 'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen' })}
                            </Typography>
                        </Col>
                        <Col />
                    </Row>
                    <Row />
                </Container>
                <MarketingGradientShadowInverse />
            </div>
        </>
    );
};
