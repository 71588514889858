import { Button, Typography } from "@material-ui/core";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { L10N } from "../pages/L10N";
import { Routes } from "../pages/NavMenu";
import { Animations } from "../ui/Animations";
import { MarketingMenu } from "./MarketingMenu";

export const Marketing01_MenuAndHeader = () => {
    const history = useHistory();
    function gotoApp(event: React.MouseEvent<HTMLElement>) {
        history.push(Routes.home);
    };
    return (
        <>
            <div className="bg-gradient-bee-diagonal"
                style={{
                    overflow: 'hidden',
                }}
            >
                <Container style={{
                    backgroundImage: 'url(/img/marketing/home-diagonal-bg.svg)',
                    backgroundRepeat: 'repeat',
                    overflow: 'hidden',
                }}>
                    <Row
                    >
                        <Col xs={12} className="order-first px-0 my-3">
                            <MarketingMenu />
                        </Col>
                        <Col xs={{ span: 12, order: 2, }}
                            md={{ span: true, order: 1, }}
                            xl={{ span: true, order: 1, }}
                            className="align-content-middle d-flex my-5"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="h6"
                                className="pr-3"
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}>
                                {L10N({ key: 'MIT BOOKEE KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN' })}
                            </Typography >
                            <div className="my-3">
                                <Button className="my-3 mr-3" size="small"
                                    href="https://play.google.com/store/apps/details?id=csp.bee.mobile.droid"
                                    target="_blank">
                                    <img src="/img/marketing/home-download-play.png" alt="download-play" height="50" />
                                </Button>
                                <Button className="my-3 mr-3" size="small"
                                    href="https://apps.apple.com/us/app/bookee/id1580602112#?platform=iphone"
                                    target="_blank">
                                    <img src="/img/marketing/home-download-appstore.svg" alt="download-appstore" height="50" />
                                </Button>
                                <Button className="my-3 mr-3 py-0 ml-1" variant="contained"
                                    onClick={gotoApp}
                                    style={{
                                        background: 'black',
                                        color: 'white',
                                        height: '50px',
                                        fontWeight: 'bold',
                                        border: 'solid 1px gray',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <OpenInBrowserIcon />
                                    <Typography variant="subtitle1" className="ml-2">
                                        {L10N({ key: 'Probier es aus' })}
                                    </Typography>
                                </Button>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 1, }}
                            md={{ span: true, order: 2, }}
                            xl={{ span: true, order: 2, }}
                            style={{
                                minHeight: '450px',
                            }}
                        >
                            <img src="/img/marketing/home-iphone.svg" alt="iphone" height="340"
                                style={{
                                    zIndex: 2,
                                    position: 'absolute',
                                    bottom: 50,
                                    right: 230,
                                    marginBottom: '-12px',
                                }}
                            />
                            <img src="/img/marketing/home-ipad.svg" alt="ipad" height="360"
                                className={Animations.puff_in_center()}
                                style={{
                                    zIndex: 1,
                                    position: 'absolute',
                                    bottom: 50,
                                    right: 30,
                                }}
                            />
                            <img src="/img/marketing/home-circles.svg" alt="circles" height="360"
                                className={Animations.jelloHorizontal() + " " + Animations.infinite()}
                                style={{
                                    zIndex: 0,
                                    position: 'absolute',
                                    bottom: 0,
                                    right: -150,
                                }}
                            />
                        </Col >
                        <Col xs={0}
                            xl={{ span: 2, order: 3, }}
                        />
                    </Row>
                </Container >
            </div>
        </>
    );
};
