import { Typography } from "@material-ui/core";
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { L10N } from "../pages/L10N";
import { MarketingGradientShadow } from "./MarketingGradientShadow";

export const Marketing06_Video = () => {

    return (
        <>
            <MarketingGradientShadow />
            <Container >
                <Row className="mt-3">
                    <Col />
                    <Col className="col-auto"
                        style={{
                            backgroundImage: 'url(/img/marketing/app-circles.svg)',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '150px',
                        }}>
                        <Typography variant="h4" align="center"
                            className=""
                            style={{
                                fontWeight: 'bold',
                                marginTop: '32px',
                                marginLeft: '15px',
                            }}>
                            {L10N({ key: 'Was ist Bookee?' })}
                        </Typography>
                    </Col>
                    <Col />
                </Row>
                <Row className="">

                    <Col className="" style={{
                        position: 'relative',
                        width: '100%',
                        height: 0,
                        paddingBottom: '56.25%',
                    }}>
                        <iframe src="https://www.youtube.com/embed/PnaMH3rriDs"
                            title="letsbookee.com"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mt-5" />
            </Container>
        </>
    );
};
