import { CircularProgress, Fab, Tooltip } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { toggleFavorites } from '../../store/user/user.actions';
import { L10N } from '../pages/L10N';

export const FavoriteIcon = (props: {
    companyID: string,
    className?: string,
}) => {
    const dispatch = useDispatch();
    const { user, inProgress, inProgressFavoriteCompanyID } = useSelector((state: RootState) => state.user);
    const [showTooltip, setShowTooltip] = useState(false);

    async function handleFavClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();

        if (!user) {
            setShowTooltip(true);
            return;
        } else {
            setShowTooltip(false);
        }
        await dispatch(toggleFavorites(user, props.companyID));
    }
    function isFavOfUser(): boolean {
        if (!user) return false;
        const isFav = user?.favoriteCompanyIDs?.find((v, i, a) => v === props.companyID);
        return isFav ? true : false;
    }
    const isChecked = isFavOfUser();
    const iconName = isChecked ? "icon_heart" : "icon_heart_dark";
    return (<Tooltip title={user ? "" : L10N({ key: "Login to finalize" })}
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        leaveDelay={2000}>
        <div>
            <Fab size="small"
                style={{ backgroundColor: 'transparent' }}
                className={"my-2 mx-2 " + props.className}
                color={isChecked ? "primary" : "secondary"}
                onClick={handleFavClick}
            >
                {inProgress && inProgressFavoriteCompanyID === props.companyID
                    ? <CircularProgress color={isChecked ? "secondary" : "primary"} />
                    : <img src={`/img/icons/${iconName}.svg`} alt="heart" />
                }
            </Fab>
        </div>
    </Tooltip >);
};
