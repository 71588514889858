import { Avatar, CardActionArea, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { api } from '../../api/api';
import { ResourceEditModel } from '../../api/models/Edit';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { EmiCard } from '../ui/EmiCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            margin: '2px',
            cursor: 'pointer',
        },
        card: {
            minHeight: '80px',
            padding: theme.spacing(1),
            margin: theme.spacing(1),
        },
    })
);
export const ResourceListCard = (props: {
    cardIndex: number,
    model: ResourceEditModel,
    onClick: (item: ResourceEditModel) => void,
}) => {
    const classes = useStyles();
    return <EmiCard
        elevation={1}
        className={classes.card
            + " " + Animations.swing_in_top_fwd() + " " + Animations.delay(props.cardIndex)}
        onClick={() => props.onClick(props.model)}
    >
        <CardActionArea>
            <Container className="m-0 p-0">
                <Row>
                    <Col className="col-auto pr-0">
                        <Avatar
                            alt={props.model.title}
                            className={classes.avatar}
                            src={api.Search.getFirstPhotoUrl(props.model.photos)} />
                    </Col>
                    <Col className="pl-1">
                        <Typography variant="body2" color="textPrimary">
                            {props.model.displayName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {props.model.title}
                        </Typography>
                    </Col>
                </Row>
            </Container>
        </CardActionArea>
    </EmiCard>;
}
export const ResourceListCardAdd = (props: {
    cardIndex: number,
    onClick: () => void,
}) => {
    const classes = useStyles();
    return <EmiCard
        elevation={1}
        className={classes.card
            + " " + Animations.swing_in_top_fwd() + " " + Animations.delay(props.cardIndex)}
        onClick={() => props.onClick()}
    >
        <CardActionArea>
            <Typography variant="body2" align="center">
                <AddCircleOutlineIcon fontSize="large" className="my-3" />
            </Typography>
        </CardActionArea>
    </EmiCard>;
}
export const FieldSetResourceList = (props: {
    onSelected: (item: ResourceEditModel) => void,
    onNew: () => void,
}) => {
    const { editInProgress, resources } = useSelector((state: RootState) => state.edit);

    return <>
        <Container>
            {!editInProgress && !resources?.map && <Row>
                <Col>
                    <Typography>{L10N({ key: 'No results found' })}</Typography>
                </Col>
            </Row>}
            <Row>
                {resources?.map && resources.map((m, i) =>
                    <Col key={m.resourceID} xs={12} sm={6} md={4} lg={3}>
                        <ResourceListCard cardIndex={i} model={m}
                            onClick={props.onSelected}
                        />
                    </Col>
                )}
                <Col key={"new"} xs={12} sm={6} md={4} lg={3}>
                    <ResourceListCardAdd cardIndex={resources?.length ?? 0}
                        onClick={props.onNew}
                    />
                </Col>
            </Row>
        </Container>
    </>;
}