import { Button, createStyles, Divider, GridList, GridListTile, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { api } from '../../api/api';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { L10N } from '../pages/L10N';
import { Routes } from '../pages/NavMenu';
import { ImageSlider } from '../ui/ImageSlider';
import { ScreenSizeListener } from '../ui/ScreenSizeListener';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            width: '100%',
        },
        backArrowHeader: {
            position: 'absolute',
            top: '30px',
            opacity: '0.7',
            zIndex: 10,
        },
    }),
);

export const ServiceDetailsView = (props: {
    onBookClick: () => void,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [gridListCols, setGridListCols] = useState(4.5);
    const { service } = useSelector((state: RootState) => state.details);
    const { searchResults } = useSelector((state: RootState) => state.search);
    const classes = useStyles();
    async function bookClick() {
        if (!service) return;
        if (!service.offeredResourceID) return;

        props.onBookClick();

        await dispatch(changeInvite({
            query: searchResults?.query ?? "",
            offeredResourceID: service.offeredResourceID,
        }));
        history.push(Routes.invite);
    }
    return (<>
        <ScreenSizeListener onGridListColsChange={setGridListCols}
            threshold="sm"
            whenThresholdOrBelow={service?.photos &&
                <ImageSlider items={service?.photos}
                    imgSrc={(item) => api.Search.getPhotoUrl(item) ?? ""}
                    imgPosition={item => item.position}
                    height="300px" />}
            whenAboveThreshold={<GridList className={classes.gridList} cellHeight={220} cols={gridListCols} >
                {service?.photos?.map && service?.photos?.map((m, i) =>
                    <GridListTile key={i} >
                        <img src={api.Search.getPhotoUrl(m)} alt={"service photo " + i} />
                    </GridListTile>
                )}
            </GridList >}
        />
        <Container className="my-3">
            <BackArrowHeader className={classes.backArrowHeader} dark />
            <Row>
                <Col className="">
                    <Typography variant="subtitle1">{service?.title}</Typography>
                    <Typography variant="body1" color="textPrimary">{service?.subtitle}</Typography>
                </Col>
            </Row>
            <Row className="">
                <Col xs={12}>
                    <Divider className="my-3" />
                    <Typography variant="subtitle2" color="textPrimary" >
                        {L10N({ key: "Description:" })}
                    </Typography>
                    {service?.htmlDescription && <>
                        <div className="mb-3" />
                        <div dangerouslySetInnerHTML={{ __html: service?.htmlDescription }} />
                    </>}
                    {service?.description && <>
                        <div className="mb-3" />
                        <Typography variant="body1" color="textPrimary">{service?.description}</Typography>
                    </>}
                    {service?.learnMoreURL && <>
                        <div className="mb-3" />
                        <Link target="_blank" href={service?.learnMoreURL} >
                            {L10N({ key: "Learn more" })}
                        </Link >
                    </>}
                    <div className="mb-3" />
                    <Row className="mb-3">
                        <Col className="">
                            <Button onClick={bookClick} variant="contained" color="primary" fullWidth
                                disabled={!service?.offeredResourceID}>
                                {L10N({ key: "book" })}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>);
};

export const ServiceDetailsDialogView = (props: {
}) => {
    const [gridListCols, setGridListCols] = useState(4.5);
    const { service } = useSelector((state: RootState) => state.details);
    const classes = useStyles();

    return (<>
        <ScreenSizeListener onGridListColsChange={setGridListCols}
            threshold="xl"
            whenThresholdOrBelow={service?.photos &&
                <ImageSlider items={service?.photos}
                    imgSrc={(item) => api.Search.getPhotoUrl(item) ?? ""}
                    imgPosition={item => item.position}
                    height="300px" />}
            whenAboveThreshold={<GridList className={classes.gridList} cellHeight={220} cols={gridListCols} >
                {service?.photos?.map && service?.photos?.map((m, i) =>
                    <GridListTile key={i} >
                        <img src={api.Search.getPhotoUrl(m)} alt={"service photo " + i} />
                    </GridListTile>
                )}
            </GridList >}
        />
        <Container className="my-3">
            <Row>
                <Col className="">
                    <Typography variant="body1" color="textPrimary">{service?.subtitle}</Typography>
                </Col>
            </Row>
            <Row className="">
                <Col xs={12}>
                    <Divider className="my-3" />
                    <Typography variant="subtitle2" color="textPrimary" >
                        {L10N({ key: "Description:" })}
                    </Typography>
                    {service?.htmlDescription && <>
                        <div className="mb-3" />
                        <div dangerouslySetInnerHTML={{ __html: service?.htmlDescription }} />
                    </>}
                    {service?.description && <>
                        <div className="mb-3" />
                        <Typography variant="body1" color="textPrimary">{service?.description}</Typography>
                    </>}
                    {service?.learnMoreURL && <>
                        <div className="mb-3" />
                        <Link target="_blank" href={service?.learnMoreURL} >
                            {L10N({ key: "Learn more" })}
                        </Link >
                    </>}
                </Col>
            </Row>
        </Container>
    </>);
};
