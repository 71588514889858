import { Action } from 'redux';
import { ApiError } from '../../api/ApiError';
import { UserProfile } from '../../api/models/User';

export const CHANGE_USER = 'CHANGE_USER';

interface ChangeUserAction extends Action {
    type: typeof CHANGE_USER,
    payload: {
        inProgress: boolean,
        user: UserProfile,
        lang?: string,
        apiError?: ApiError,
    }
}
export type UserActionTypes = ChangeUserAction;
