import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { SearchCategoryChip } from './SearchCategoryChip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollButtonArea: {
            height: '54px',
            width: '40px',
            margin: '10px 0px',
            '& .MuiButton-root': {
                minWidth: 20,
                height: '100%',
                padding: '8px',
                margin: '5px 0px',
            },
        },
    })
);
export const SearchCategoryChips = (props: {
    showAnything?: boolean,
}) => {
    const dispatch = useDispatch();
    const { searchString, searchCategories } = useSelector((state: RootState) => state.search);
    const [scrollButtonsState, setScrollButtonsState] = useState({ prev: false, next: true });
    const [hoverOnCategories, setHoverOnCategories] = useState(false);
    const classes = useStyles();

    const ref = React.useRef<HTMLDivElement>(null);

    function handleChange(isChecked: boolean, chip: string) {
        let newSearchString = searchString;
        for (var i = 0; i < (searchCategories?.results.length ?? 0); i++) {
            const categoryText = searchCategories?.results[i]?.encodedText;
            if (!categoryText) continue;

            const regEx = new RegExp(categoryText, "ig");
            newSearchString = newSearchString.replace(regEx, "");
        }
        if (isChecked) {
            newSearchString = newSearchString + " " + chip;
        }
        dispatch(changeSearchString(newSearchString));
    }
    function onMouseDownPrev(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        const scroll = ref.current;
        if (!scroll) return;

        scroll.scrollBy({ behavior: 'smooth', left: -scroll.clientWidth / 3 })
        event.stopPropagation();
    }
    function onMouseDownNext(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        const scroll = ref.current;
        if (!scroll) return;

        scroll.scrollBy({ behavior: 'smooth', left: scroll.clientWidth / 3 })
        event.stopPropagation();
    }
    function onScroll(event: React.UIEvent<HTMLDivElement>) {
        const scroll = ref.current;
        if (!scroll) return;

        setScrollButtonsState({
            prev: scroll.scrollLeft > 0,
            next: scroll.scrollLeft + scroll.clientWidth < scroll.scrollWidth,
        });
    }
    function onMouseOver() {
        if (hoverOnCategories) return;
        setHoverOnCategories(true);
    }
    function onMouseOut() {
        if (!hoverOnCategories) return;
        setHoverOnCategories(false);
    }
    return (
        <Row className="mb-3"
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {searchCategories?.results?.map && <>
                {!isMobile &&
                    <Col className={"col-auto pr-0 " + classes.scrollButtonArea}>
                        <Button onClick={onMouseDownPrev} color="primary"
                            variant={scrollButtonsState.prev ? "contained" : "outlined"}
                            className={hoverOnCategories ? "d-block" : "d-none"}
                            disabled={!scrollButtonsState.prev}
                        >
                            <img src="/img/icons/icon_prev_white.svg" alt="prev" />
                        </Button>
                    </Col>
                }
                <Col className="p-0 m-0 ">
                    <div ref={ref}
                        onScroll={onScroll}
                        className="noScroll"
                        style={{
                            display: "flex",
                            overflow: "scroll",
                            scrollbarWidth: "none",
                            paddingBottom: "6px",
                        }}>
                        {searchCategories.results
                            ?.filter((m, i) => props.showAnything || i !== 0)
                            ?.map((m, i) =>
                                <div key={i} className="float-left" >
                                    <SearchCategoryChip key={i} showText
                                        onChange={(isChecked) => handleChange(isChecked, m.encodedText)}
                                        isChecked={searchString.includes(m.encodedText)}
                                        data={m} />
                                </div>
                            )}
                    </div>
                </Col>
                {!isMobile &&
                    <Col className={"col-auto pl-0 " + classes.scrollButtonArea}>
                        <Button onClick={onMouseDownNext} color="primary"
                            variant={scrollButtonsState.next ? "contained" : "outlined"}
                            className={hoverOnCategories ? "d-block" : "d-none"}
                            disabled={!scrollButtonsState.next}
                        >
                            <img src="/img/icons/icon_next_white.svg" alt="next" />
                        </Button>
                    </Col>
                }
            </>}
        </Row>
    );
}
