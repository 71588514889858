import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { MapCoords } from "../models/Details";

export class GeocodingEndpoint {
    async getCoords(address: string): Promise<ApiResponse<MapCoords | undefined>> {
        //https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=AIzaSyAAB-pgbzDAcfFU2Ej9W3kWjT8dcPpvbIE
        const apiKey = "AIzaSyAAB-pgbzDAcfFU2Ej9W3kWjT8dcPpvbIE";
        const encodedAddress = encodeURIComponent(address);
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;

        const apiResponse = await api.createRequest<MapCoords | undefined>({
            externalUrl: url,
            method: "GET",
            resultJsonTransformer: (json) => {
                console.log(json);
                if (json.status !== "OK") return undefined;
                if (json.results.length === 0) return undefined;

                return {
                    lat: json.results[0].geometry.location.lat,
                    lng: json.results[0].geometry.location.lng,
                };
            },
        });

        return apiResponse;
    }
}