import { Action } from 'redux';
import { CreateInviteMessage, Invite } from '../../api/models/Invite';

export const CHANGE_INVITE = '[INVITE] CHANGE_INVITE';
interface ChangeInviteAction extends Action {
    type: typeof CHANGE_INVITE,
    payload: {
        createInviteMessage?: CreateInviteMessage;
        inviteInProgress: boolean;
        serverInvite?: Invite;
        currentInvite?: Invite;
    }
}
export type InviteActionTypes = ChangeInviteAction
;
