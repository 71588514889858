import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from 'react';
import { useRef, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { L10N } from "../pages/L10N";
import { Animations } from "../ui/Animations";
import { TabsSwitch } from "../ui/TabsSwitch";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoColumn: {
            margin: '0px',
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        switch: {
        },
        feature: {
            background: 'url(/img/marketing/features-bg.svg)',
            borderRadius: '8px',
            padding: '16px',
            margin: '16px',
            color: 'white',
            transitionDelay: '0s',
            transitionDuration: '0.8s',
            transitionTimingFunction: 'ease-in-out',
            transitionProperty: 'all',
            position: 'relative',
        },
        doneRight: {
            position: 'absolute',
            right: '-45px',
            marginTop: '0px',
        },
        doneLeft: {
            position: 'absolute',
            left: '-45px',
            marginTop: '0px',
        },
        circles: {
            position: 'absolute',
            left: '-15px',
            marginTop: '500px',
        },
    }),
);

export const Marketing03_Features = (props: {
    offset: number,
}) => {
    const classes = useStyles();
    const ref = useRef<HTMLDivElement>(null);
    const [tab, setTab] = useState(0);


    let top = ref?.current?.getBoundingClientRect()?.top ?? 0;
    top = top - 200;
    top = Math.min(0, top);
    top = Math.max(top, -5);
    return (
        <>
            <div className="bg-gradient-bee-diagonal"
                ref={ref}
                style={{
                    zIndex: 1,
                    position: 'relative',
                    marginTop: '0px',
                    overflow: 'hidden',
                }}>
                <Container >
                    <Row className="mb-3">
                        <Col />
                        <Col className="col-auto">
                            <Typography variant="h4" align="center"
                                className=""
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginTop: '32px',
                                    marginLeft: '25px',
                                }}>
                                {L10N({ key: 'Features' })}
                            </Typography>
                        </Col>
                        <Col />
                    </Row>
                    <Row className="my-5">
                        <Col className="m-0 p-0" />
                        <Col className="col-auto">
                            <TabsSwitch tabs={[
                                L10N({ key: "Für Besucher" }),
                                L10N({ key: "Für Dienstleister" }),
                            ]}
                                selectedTab={tab}
                                onTabChanged={setTab}
                                groupClassName={classes.switch}
                            />
                        </Col>
                        <Col className="m-0 p-0" />
                    </Row>
                    <Row className="mt-3 d-none d-lg-flex">
                        <Col xs={4} className={classes.photoColumn + " pr-4"}>
                            <div className={classes.feature + " " + (tab === 0 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(1))} style={{
                                marginLeft: 100 * top / 10 + 10,
                                marginRight: -100 * top / 10 + 10,
                            }}
                            >
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneRight + " " + (tab === 0 && Animations.heartbeat() + " " + Animations.delay(2))} />
                                <Typography variant="subtitle2" >
                                    {L10N({ key: '100% online' })}
                                </Typography>
                            </div>
                            <div className={classes.feature + " " + (tab === 0 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(3))} style={{
                                marginLeft: 200 * top / 10 + 10,
                                marginRight: -200 * top / 10 + 10,
                            }}>
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneRight + " " + (tab === 0 && Animations.heartbeat() + " " + Animations.delay(4))} />
                                <Typography variant="subtitle2">
                                    {L10N({ key: '100% kostenlos' })}
                                </Typography>
                            </div>
                            <div className={classes.feature + " " + (tab === 0 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(7))} style={{
                                marginLeft: 200 * top / 10 + 10,
                                marginRight: -200 * top / 10 + 10,
                            }}>
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneRight + " " + (tab === 0 && Animations.heartbeat() + " " + Animations.delay(6))} />
                                <Typography variant="subtitle2">
                                    {L10N({ key: 'SMS-Erinnerung' })}
                                </Typography>
                            </div>
                            <div className={classes.feature + " " + (tab === 0 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(7))} style={{
                                marginLeft: 100 * top / 10 + 10,
                                marginRight: -100 * top / 10 + 10,
                            }}>
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneRight + " " + (tab === 0 && Animations.heartbeat() + " " + Animations.delay(8))} />
                                <Typography variant="subtitle2">
                                    {L10N({ key: 'jederzeit buchbar' })}
                                </Typography>
                            </div>
                        </Col>
                        <Col xs={4} className={classes.photoColumn} style={{
                            WebkitTransform: `rotate3d(0,1,0,${90 - top * 90 / 5}deg)`,/* intentionally inverted to smooth transition in Firefox*/
                            transform: `rotate3d(0,1,0,${90 - top * 90 / 5}deg)`,
                            transitionDelay: '0s',
                            transitionDuration: '0.8s',
                            transitionTimingFunction: 'ease-in-out',
                            transitionProperty: 'all',
                        }}>
                            <img src="/img/marketing/features-bee1.svg" alt="bee1" />
                        </Col>
                        <Col xs={4} className={classes.photoColumn + " pl-4"}>
                            <img src="/img/marketing/features-circles.svg" alt="done" className={classes.circles} />

                            <div className={classes.feature + " " + (tab === 1 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(1))} style={{
                                marginLeft: -100 * top / 10 + 10,
                                marginRight: 100 * top / 10 + 10,
                            }}>
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneLeft + " " + (tab === 1 && Animations.heartbeat() + " " + Animations.delay(2))} />
                                <Typography variant="subtitle2">
                                    {L10N({ key: '24h Buchungsassistent' })}
                                </Typography>
                            </div>
                            <div className={classes.feature + " " + (tab === 1 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(3))} style={{
                                marginLeft: -200 * top / 10 + 10,
                                marginRight: 200 * top / 10 + 10,
                            }}>
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneLeft + " " + (tab === 1 && Animations.heartbeat() + " " + Animations.delay(4))} />
                                <Typography variant="subtitle2">
                                    {L10N({ key: 'komplette Übersicht' })}
                                </Typography>
                            </div>
                            <div className={classes.feature + " " + (tab === 1 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(5))} style={{
                                marginLeft: -200 * top / 10 + 10,
                                marginRight: 200 * top / 10 + 10,
                            }}>
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneLeft + " " + (tab === 1 && Animations.heartbeat() + " " + Animations.delay(6))} />
                                <Typography variant="subtitle2">
                                    {L10N({ key: 'Erinnerungs-SMS mit Hinweise an Kunden' })}
                                </Typography>
                            </div>
                            <div className={classes.feature + " " + (tab === 1 && "" + Animations.shadow_drop_2_center() + " " + Animations.delay(7))} style={{
                                marginLeft: -100 * top / 10 + 10,
                                marginRight: 100 * top / 10 + 10,
                            }}>
                                <img src="/img/marketing/features-done-icon.svg" alt="done"
                                    className={classes.doneLeft + " " + (tab === 1 && Animations.heartbeat() + " " + Animations.delay(8))} />
                                <Typography variant="subtitle2">
                                    {L10N({ key: 'weniger Zeit am Telefon' })}
                                </Typography>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3 d-flex d-lg-none">
                        <Col className={classes.photoColumn + " mx-1 ml-5 px-4"}>
                            {tab === 0 && <>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_left() + " " + Animations.delay(1)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: '100% online' })}
                                    </Typography>
                                </div>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_left() + " " + Animations.delay(3)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: '100% kostenlos' })}
                                    </Typography>
                                </div>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_left() + " " + Animations.delay(5)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: 'SMS-Erinnerung' })}
                                    </Typography>
                                </div>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_left() + " " + Animations.delay(7)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: 'jederzeit buchbar' })}
                                    </Typography>
                                </div>
                            </>}
                            {tab === 1 && <>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_right() + " " + Animations.delay(1)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: '24h Buchungsassistent' })}
                                    </Typography>
                                </div>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_right() + " " + Animations.delay(3)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: 'komplette Übersicht' })}
                                    </Typography>
                                </div>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_right() + " " + Animations.delay(5)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: 'Erinnerungs-SMS mit Hinweise an Kunden' })}
                                    </Typography>
                                </div>
                                <div className={classes.feature + " " + Animations.slide_in_blurred_right() + " " + Animations.delay(7)} >
                                    <img src="/img/marketing/features-done-icon.svg" alt="done" className={classes.doneLeft} />
                                    <Typography variant="subtitle2">
                                        {L10N({ key: 'weniger Zeit am Telefon' })}
                                    </Typography>
                                </div>
                            </>}
                        </Col>
                    </Row>
                </Container >
            </div>
        </>
    );
};
