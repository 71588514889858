import { Button, CardProps, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ConfirmedVisit } from '../../api/models/Visit';
import { L10N } from '../pages/L10N';
import { Routes } from '../pages/NavMenu';
import { AddToCalendarButton } from '../ui/AddToCalendar';
import { DimensionIcon } from '../ui/DimensionIcon';
import { CompanyCard } from './CompanyCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn_calendar: {
            textTransform: 'none',
            outline: 'none !important',
            borderRadius: '20px',
            backgroundColor: '#d61643',
            color: 'white',
            display: 'inline-block',
            width: 'auto',
        },
    })
);
export const VisitSummaryCard = (props: CardProps & {
    data: ConfirmedVisit,
}) => {
    const history = useHistory();
    const classes = useStyles();
    function handleOK() {
        history.push(Routes.visitTab("upcoming"));
    }

    return (<>
        <Container>
            <Row>
                <Col className="d-none d-lg-flex" lg={2} />
                <Col className="">
                    <Paper elevation={8} className="bg-gradient-bee-vertical text-white" square>
                        <div className="py-5 px-4" style={{
                            textAlign: 'center',
                        }}>
                            <img alt="success" src="/img/icons/icon_done_white.svg" className="my-4 mx-auto d-flex" width="64" />
                            <Typography variant="subtitle1" align="center">
                                {props.data.visitStatus === 'Confirmed' && L10N({ key: "Appointment confirmed" })}
                                {props.data.visitStatus === 'Requested' && L10N({ key: "Appointment requested" })}
                            </Typography>
                            <Typography variant="subtitle1" align="center" className="my-2">
                                {L10N({ key: "_dateAsddd", date: props.data.visitStartForClient })},&nbsp;
                                {L10N({ key: "_dateAsDDMMMHHmm", date: props.data.visitStartForClient })}
                            </Typography>
                            <Typography variant="subtitle1" align="center">{props.data.serviceName}</Typography>
                            {props.data
                                && props.data.visitStartForClient
                                && props.data.visitEndForClient
                                &&
                                <AddToCalendarButton
                                    name={props.data.serviceName}
                                    details={props.data.remarks}
                                    location={props.data.addressLine}
                                    startsAt={props.data.visitStartForClient}
                                    endsAt={props.data.visitEndForClient}
                                    className={classes.btn_calendar + " mt-3 "}
                                    variant="contained"
                                    color="default"
                                    size="small"
                                    startIcon={<DimensionIcon type="when" />}
                                />
                            }
                            <CompanyCard data={props.data} height={200} className="my-5" />
                            {props.data.paymentUpfront === true &&
                                <Typography variant="body1" align="center" className="text-white" >
                                    {L10N({ key: "Upfront payment - you need to pay upfront for this visit" })}
                                </Typography>
                            }
                            <Typography variant="body1" align="center" className="text-white" >
                                {props.data.visitStatus === 'Requested' && L10N({ key: "VisitSummaryCard:Your request will be answered shortly." })}
                                {props.data.visitStatus === 'Confirmed' && L10N({ key: "You're done!" })}
                            </Typography>
                            <br />
                            <Typography variant="body1" align="center" className="text-white" >
                                {props.data.visitStatus === 'Requested' && L10N({ key: "VisitSummaryCard:When your request is accepted(1/2)" })}
                                {props.data.visitStatus === 'Confirmed' && L10N({ key: "We'll send you a reminder" })}
                                <br />
                                {props.data.visitStatus === 'Requested' && L10N({ key: "VisitSummaryCard:When your request is accepted(2/2)" })}
                                {props.data.visitStatus === 'Confirmed' && L10N({ key: "before your appointment" })}
                            </Typography>
                            <Typography variant="subtitle1" align="center" className="my-3 text-gray" >
                                {L10N({ key: "THANK YOU" })}
                            </Typography>
                            <Button className="bg-white mx-auto d-flex"
                                onClick={() => handleOK()}
                                variant="contained" fullWidth
                                disableElevation
                                color="default">
                                {L10N({ key: "OK, got it" })}
                            </Button>
                        </div>
                    </Paper>
                </Col>
                <Col className="d-none d-lg-flex" lg={2} />
            </Row>
        </Container>
    </>);
};
