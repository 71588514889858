import { IconButton, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { DateTimeRangeEditModel, ResourceEditModel, WorkingHoursEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { EmiCard } from '../ui/EmiCard';
import { useFormikResult } from '../ui/FormikAPI';
import { BeeTimePicker } from '../ui/TimePicker';

export const WorkingHoursListCard = (props: {
    formik: useFormikResult<ResourceEditModel>,
    cardIndex: number,
    model: WorkingHoursEditModel,
    onAdd: () => void,
    onDelete: (model: DateTimeRangeEditModel, index: number) => void,
    fieldNamePrefix: (index: number, suffix: string) => string
}) => {

    return <EmiCard
        elevation={1}
        className={"my-2 mx-1 p-2"
            + " " + Animations.swing_in_top_fwd() + " " + Animations.delay(props.cardIndex)}
    >
        <Typography variant="body2" color="textPrimary">
            {L10N({ key: '_dateAsdddd', date: props.model.dayOfWeek_asDate })}
        </Typography>
        <Container className="m-0 p-0">
            <Row>
                <Col className="mx-0 ">
                    <Container className="m-0 p-0">
                        {props.model.dayWorkingHours?.length > 0
                            ? props.model.dayWorkingHours?.map((m, i) => <div key={props.model.dayOfWeek + i}>
                                <Row >
                                    <Col className="mr-1">
                                        <BeeTimePicker valueHHmm={m.fromHHmm} fieldName={props.fieldNamePrefix(i, ".fromHHmm")} formik={props.formik} />
                                    </Col>
                                    <Col className="ml-1">
                                        <BeeTimePicker valueHHmm={m.toHHmm} fieldName={props.fieldNamePrefix(i, ".toHHmm")} formik={props.formik} />
                                    </Col>
                                    <Col className="col-auto px-0">
                                        <IconButton className="center-relative-x"
                                            onClick={() => props.onDelete(m, i)}>
                                            <HighlightOffIcon fontSize="small" />
                                        </IconButton>
                                    </Col>
                                </Row>
                            </div>
                            )
                            :
                            <Row >
                                <Col className="my-1">
                                    <Typography variant="body2" color="textPrimary">
                                        {L10N({ key: "Closed" })}
                                    </Typography>
                                </Col>
                            </Row>
                        }
                    </Container>
                </Col>
                <Col className="col-auto pl-0">
                    <IconButton className="center-relative-x"
                        onClick={props.onAdd}>
                        <AddCircleIcon fontSize="small" color="primary" />
                    </IconButton>
                </Col>
            </Row>
        </Container>
    </EmiCard>;
}