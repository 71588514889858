import { IconButton } from '@material-ui/core';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Routes } from '../pages/NavMenu';

export const MarketingPageAnchor = React.forwardRef((
    props: {
        showBack?: boolean,
        white?: boolean,
    },
    ref: React.ForwardedRef<HTMLDivElement>) => {
    const history = useHistory();
    function backClick() {
        history.push(Routes.marketingPage("home"));
    }
    return (
        <Container ref={ref} style={{
            height: '0px',
        }}  >
            <Row className="">
                <Col />
                <Col className="col-auto">
                    {props.showBack &&
                        <IconButton onClick={backClick} style={{
                        position: 'absolute',
                            right:'10px',
                            zIndex: 10,
                    }}>
                        {props.white
                            ? <img src="/img/icons/icon_up.svg" alt="back" />
                            : <img src="/img/icons/icon_up_dark.svg" alt="back" />
                        }
                        </IconButton>
                    }
                </Col>
            </Row>
        </Container>
    );
});
