import { KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getIn } from 'formik';
import * as React from 'react';
import { ResourceEditModel } from '../../api/models/Edit';
import { useFormikResult } from '../ui/FormikAPI';

export const BeeTimePicker = (props: {
    formik: useFormikResult<ResourceEditModel>,
    valueHHmm: string,
    fieldName: string,
}) => {
    function onChange(date: MaterialUiPickersDate | null, value?: string | null) {
        if (date && isNaN(date.getTime())) return;
        if (!date) {
            props.formik.setFieldValue(props.fieldName, "");
        } else {
            props.formik.setFieldValue(props.fieldName, value);
        }
    }
    return <KeyboardTimePicker views={['hours', 'minutes']} openTo="hours" ampm={false} minutesStep={5}
        inputProps={{ style: { textAlign: 'right', minWidth: 40 } }}
        format="HH:mm"
        value={new Date(`2000-01-01T${props.valueHHmm}:00+01:00`)}
        id={props.fieldName}
        name={props.fieldName}
        error={getIn(props.formik.touched, props.fieldName) && Boolean(getIn(props.formik.errors, props.fieldName))}
        helperText={getIn(props.formik.touched, props.fieldName) && getIn(props.formik.errors, props.fieldName)}
        onChange={onChange}
    />;
}
export const BeeDateTimePicker = (props: {
    value: string,
    fieldName: string,
    label: string,
    error: boolean,
    helperText: string,
    onChange: (value: string) => void,
}) => {
    function onChange(date: MaterialUiPickersDate | null, value?: string | null) {
        props.onChange(value ?? "");
    }
    return <KeyboardDateTimePicker openTo="date" ampm={false} minutesStep={5}
        inputProps={{ style: { textAlign: 'right', minWidth: 40 } }}
        value={new Date(props.value)}
        label={props.label}
        id={props.fieldName}
        name={props.fieldName}
        error={props.error}
        helperText={props.helperText}
        onChange={onChange}
    />;
}