import { faEuroSign, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import * as React from 'react';
import { DimensionTypes } from '../../api/models/Global';

export const DimensionIcon = (props: {
    type: DimensionTypes,
    size?: 'inherit' | 'default' | 'small' | 'large',
    variant?: 'light' | 'dark',
}) => {
    function getAvatar(): JSX.Element {
        const suffix = !props.variant || props.variant === "light"
            ? "" : "_dark";
        switch (props.type.toLowerCase()) {
            case "duration": return <><HourglassEmptyIcon fontSize={props.size} /></>;
            case "price": return <><FontAwesomeIcon icon={faEuroSign} fontSize={props.size} /></>;
            case "service": return <><FontAwesomeIcon icon={faHandshake} fontSize={props.size} /></>;
            case "when": return <><img alt="calendar" src={`/img/icons/icon_calendar${suffix}.svg`} className="mx-1" /></>;
            case "what_time": return <><img alt="clock" src={`/img/icons/icon_clock${suffix}.svg`} className="mx-1" width="16" /></>;
            case "day_part": return <><TimelapseIcon fontSize={props.size} /></>;
            case "company": return <><img alt="company" src="/img/icons/icon_place.svg" className="mx-1" /></>;
            case "resource": return <><AssignmentIndIcon fontSize={props.size} /></>;
            default: return <>?{props.type}?</>;
        }
    }
    return (getAvatar());
};
