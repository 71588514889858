import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { AccessTokenBearer } from "../models/Global";
import { UserListItem, UserProfile, UserRefreshAccessTokenMessage, UserRefreshPINMessage } from "../models/User";

export class UsersEndpoint {
    async refreshPIN(message: UserRefreshPINMessage): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/refreshPIN`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async refreshAccessToken(message: UserRefreshAccessTokenMessage): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/refreshAccessToken`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async getCurrentUser(message: AccessTokenBearer): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/getUser`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async updateUserProfile(message: UserProfile): Promise<ApiResponse<UserProfile>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/updateUser`,
            method: "PUT",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserProfile,
        });
        return apiResponse;
    }
    async logout(message: AccessTokenBearer): Promise<ApiResponse<void>> {
        const apiResponse = await api.createRequest<void>({
            endpoint: `search/logout`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: undefined,
        });
        return apiResponse;
    }
    async refreshReminders(): Promise<ApiResponse<string[]>> {
        const apiResponse = await api.createRequest<string[]>({
            endpoint: `search/refresh-reminders`,
            method: "GET",
            resultJsonTransformer: (json) => json as string[],
        });
        return apiResponse;
    }
    async reloadStore(message: AccessTokenBearer): Promise<ApiResponse<void>> {
        const apiResponse = await api.createRequest<void>({
            endpoint: `search/reloadStore`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: undefined,
        });
        return apiResponse;
    }
    async getUserList(message: AccessTokenBearer): Promise<ApiResponse<UserListItem[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/getUserList`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as UserListItem[],
        });
        return apiResponse;
    }
}