import { Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { refreshHints, startSearch } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { SearchBar } from '../search/SearchBar';
import { SearchCategoryChips } from '../search/SearchCategoryChips';
import { SearchChips } from '../search/SearchChips';
import { SearchTable } from '../search/SearchTable';
import { L10N } from './L10N';
import { PageHeader } from './PageHeader';

export const SearchPage = () => {
    const dispatch = useDispatch();
    const { searchResults, hasSearched, searchResultsInProgress } = useSelector((state: RootState) => state.search);

    useEffect(() => {
        dispatch(refreshHints());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasSearched || searchResultsInProgress) return;

        dispatch(startSearch());
    }, [dispatch, hasSearched, searchResultsInProgress]);


    return (
        <Container>
            <PageHeader title="" showLogo showMarketing />
            <Row className="mb-3 mt-n2">
                <Col>
                    <Typography variant="body1" color="primary" align="center" 
                        className="pr-3 text-uppercase">
                        {L10N({ key: "Bookee Tagline" })}
                    </Typography >
                </Col>
            </Row>
            <Row className="mb-3">
                <SearchBar />
            </Row>
            <SearchCategoryChips />
            <Row className="mb-3 ">
                <SearchChips />
            </Row>
            {hasSearched && !searchResultsInProgress && <>
                {(searchResults?.results?.length ?? 0) === 0 && <PageHeader title={L10N({ key: "No results found" })} noMarginTop />}
            </>}
            <Row className="mb-3">
                <SearchTable />
            </Row>
        </Container>
    );
}
