import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../../api/api';
import { ClientEditModel, CompanyEditModel, RequestCompanyCreateModel, ResourceEditModel, ServiceEditModel, UpdatePhotoMessage } from '../../api/models/Edit';
import { RequestVisitCreateModel, VisitEditModel } from '../../api/models/EditVisit';
import { AccessTokenBearer } from '../../api/models/Global';
import { RootState } from '../store';
import { hideProgress } from '../ui/ui.actions';
import { getCurrentUser } from '../user/user.actions';
import { EditState } from './edit.reducer';
import {
    CHANGE_STATE
} from './edit.types';

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const changeState = (
    message: EditState
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            ...message
        }
    });
};
export const loadCoordsFromAddress = (
    address: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Geocoding.getCoords(address);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            coords: apiResponse.result,
        }
    });
};
export const updatePhoto = (
    message: UpdatePhotoMessage
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.updatePhoto(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
        }
    });
};
export const loadCompanyForEdit = (
    message: AccessTokenBearer,
    companyID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.getCompany({
        accessToken: message.accessToken,
        companyID: companyID,
    });
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            company: apiResponse.result,
        }
    });
};
export const loadCompanyResourcesForEdit = (
    message: AccessTokenBearer,
    companyID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.getCompanyResources({
        accessToken: message.accessToken,
        companyID: companyID,
    });
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            resources: apiResponse.result,
        }
    });
};
export const loadCompanyServicesForEdit = (
    message: AccessTokenBearer,
    companyID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.getCompanyServices({
        accessToken: message.accessToken,
        companyID: companyID,
    });
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            services: apiResponse.result,
        }
    });
};
export const loadAllCompanyForEdit = (
    message: AccessTokenBearer,
    companyID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponseCompany = await api.Edit.getCompany({
        accessToken: message.accessToken,
        companyID: companyID,
    });
    const apiResponseResources = await api.Edit.getCompanyResources({
        accessToken: message.accessToken,
        companyID: companyID,
    });
    const apiResponseServices = await api.Edit.getCompanyServices({
        accessToken: message.accessToken,
        companyID: companyID,
    });
    const error = apiResponseCompany.error
        ?? apiResponseResources.error
        ?? apiResponseServices.error;

    await dispatch(hideProgress(error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            company: apiResponseCompany.result,
            resources: apiResponseResources.result,
            services: apiResponseServices.result,
        }
    });
};
export const CreateCompanyAndReload = (
    message: RequestCompanyCreateModel,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.createCompany(message);
    await dispatch(hideProgress(apiResponse.error));

    if (apiResponse.result?.companyID) {
        await dispatch(loadAllCompanyForEdit(message, apiResponse.result?.companyID));
        await dispatch(getCurrentUser(message));
    } else {
        await dispatch({
            type: CHANGE_STATE,
            payload: {
                editInProgress: false,
            }
        });
    }
};
export const CreateVisit = (
    message: RequestVisitCreateModel,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.createVisit(message);
    await dispatch(hideProgress(apiResponse.error));

    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            visit: apiResponse.result,
        }
    });
};
export const UpdateCompanyAndReload = (
    message: CompanyEditModel,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.updateCompany(message);
    await dispatch(hideProgress(apiResponse.error));

    if (apiResponse.result?.companyID) {
        await dispatch(loadAllCompanyForEdit(message, apiResponse.result?.companyID));
    } else {
        await dispatch({
            type: CHANGE_STATE,
            payload: {
                editInProgress: false,
            }
        });
    }
};
export const UpdateCompanyResourceAndReload = (
    message: ResourceEditModel,
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.updateCompanyResource(message);
    await dispatch(hideProgress(apiResponse.error));

    if (state.edit?.company?.companyID) {
        await dispatch(loadAllCompanyForEdit(message, state.edit.company.companyID));
    } else {
        await dispatch({
            type: CHANGE_STATE,
            payload: {
                editInProgress: false,
            }
        });
    }
};
export const UpdateCompanyServiceAndReload = (
    message: ServiceEditModel,
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.updateCompanyService(message);
    await dispatch(hideProgress(apiResponse.error));

    if (state.edit?.company?.companyID) {
        await dispatch(loadAllCompanyForEdit(message, state.edit.company.companyID));
    } else {
        await dispatch({
            type: CHANGE_STATE,
            payload: {
                editInProgress: false,
            }
        });
    }
};
export const loadClientsForEdit = (
    message: AccessTokenBearer,
    companyID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.getCompanyClients({
        accessToken: message.accessToken,
        companyID: companyID,
    });
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            clients: apiResponse.result,
        }
    });
};
export const UpdateCompanyClient = (
    message: ClientEditModel,
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
            editedClient: message,
        }
    });
    const apiResponse = await api.Edit.updateCompanyClient(message);
    await dispatch(hideProgress(apiResponse.error));

    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            editedClient: undefined,
            clients: state.edit.clients
                ?.map(m => m.userID !== apiResponse.result?.userID
                    ? m
                    : apiResponse.result),
        }
    });
};
export const loadVisitForEdit = (
    message: AccessTokenBearer,
    visitID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.getVisit({
        accessToken: message.accessToken,
        visitID: visitID,
    });
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            visit: apiResponse.result,
        }
    });
    };
export const RefreshResourceAvailabilityIfPossible = (
    message: VisitEditModel,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editRefreshInProgress: true,
        }
    });
    const apiResponse = await api.Edit.refreshVisitResourcesAvailability(message);
    await dispatch(hideProgress(apiResponse.error));

    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editRefreshInProgress: false,
            visit: apiResponse.result,
        }
    });
};
export const UpdateVisitAndReload = (
    message: VisitEditModel,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: true,
        }
    });
    const apiResponse = await api.Edit.updateVisit(message);
    await dispatch(hideProgress(apiResponse.error));

    await dispatch({
        type: CHANGE_STATE,
        payload: {
            editInProgress: false,
            visit: apiResponse.result,
        }
    });
};