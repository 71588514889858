import { IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { clearError, onError } from '../../store/ui/ui.actions';
import { L10N } from '../pages/L10N';


export const ErrorHandler = () => {
    const dispatch = useDispatch();
    const { error } = useSelector((state: RootState) => state.ui);
    const [open, setOpen] = React.useState(false);

    const windowErrorListener = useCallback((event: ErrorEvent) => {
        console.log(event);
        dispatch(onError(event.message));
    }, [dispatch]);
    const windowUnhandledRejectionListener = useCallback((event: PromiseRejectionEvent) => {
        console.log(event);
        dispatch(onError(event.reason.message));
    }, [dispatch]);
    useEffect(() => {
        window.addEventListener("error", windowErrorListener);
        window.addEventListener("unhandledrejection", windowUnhandledRejectionListener);
        return () => {
            window.removeEventListener("error", windowErrorListener);
            window.removeEventListener("unhandledrejection", windowUnhandledRejectionListener);
        };
    }, [windowErrorListener, windowUnhandledRejectionListener]);

    useEffect(() => {
        if (error) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [error]);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(clearError());
    };
    function getMessage(): JSX.Element {
        if (!error) return <></>;
        var timestamp = "" + error.time_miliseconds;// error.time_miliseconds.toLocaleString('pl-PL');
        var prefix = `[${timestamp}] ${L10N({ key: "Error occurred:" })} ${error.message}\r\n`;
        console.log(error);
        switch (error.apiError?.type) {
            case "nullResponse": return <>{prefix + L10N({ key: "at server" })}</>;
            case "unauthorized": return <>{L10N({ key: "Your session has expired." })}</>;
            case "validation": return <>{L10N({ key: "Invalid data provided." })}</>;
            case "error":
            default: return <>{prefix + L10N({ key: "contact us and help us resolving the issue." })}</>;
        }
    }
    return <>
        <Snackbar open={open}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            message={getMessage()}
            action={<>
                <IconButton size="small" onClick={handleClose} color="secondary">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </>}
        />
    </>;
};