import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormik } from 'formik';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { CreateNewVisit, VisitEditModelValidationSchema } from '../../api/models/EditVisit';
import { RefreshResourceAvailabilityIfPossible, UpdateVisitAndReload } from '../../store/edit/edit.actions';
import { RootState } from '../../store/store';
import { FormVisitDetails } from '../edit/FormVisitDetails';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '16px',
        },
    })
);
export const EditVistDialog = (props: {
    open: boolean,
    onClose: () => void,
    onReloadRequired: () => Promise<void>,
}) => {
    const dispatch = useDispatch();
    const { inProgress } = useSelector((state: RootState) => state.company);
    const { user } = useSelector((state: RootState) => state.user);
    const { visit, editInProgress } = useSelector((state: RootState) => state.edit);
    const classes = useStyles();
    const theme = useTheme();
    const screenSizeIsSmall = !useMediaQuery(theme.breakpoints.up('sm'));

    const formik = useFormik({
        initialValues: visit ?? CreateNewVisit(),
        enableReinitialize: true,
        validationSchema: VisitEditModelValidationSchema,
        onSubmit: async (values) => {
            values.accessToken = user?.accessToken ?? "";
            await dispatch(UpdateVisitAndReload(values));
            props.onClose();
            await props.onReloadRequired();
        },
    });
    async function onCancel() {
        formik.resetForm();
        props.onClose();
    }
    function onSubmitCapture() {
        if (!formik.isValid) {
            console.log(formik.errors);
        }
        formik.submitForm();
    }
    async function refreshAvailableResources() {
        if (!user) return;

        console.log("refreshAvailableResources");
        console.log(visit);
        console.log(formik.values);
        await dispatch(RefreshResourceAvailabilityIfPossible({ ...visit, ...formik.values, accessToken: user.accessToken }));
    }
    return (
        <>
            <Dialog disableBackdropClick
                fullScreen={isMobile || screenSizeIsSmall}
                open={props.open}
                onClose={onCancel}>
                <DialogTitle>{L10N({ key: "Edit" })}</DialogTitle>
                <DialogContent>
                    {!inProgress && !editInProgress &&
                        <FormVisitDetails formik={formik} onRefreshAvailableResources={refreshAvailableResources} />
                    }
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" type="submit"
                        onClick={onSubmitCapture}
                        onSubmitCapture={onSubmitCapture}
                        disabled={!formik.dirty}
                        className={classes.btn}>
                        {inProgress
                            ? <CircularProgress />
                            : L10N({ key: "Save" })
                        }
                    </Button>
                    <Button onClick={onCancel}
                        className={classes.btn}
                        color="primary" autoFocus variant="outlined">
                        {L10N({ key: "Cancel" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};