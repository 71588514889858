import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        visitNotes: {
            borderTop: "solid 1px #AFAFAF",
            '& .MuiInput-root:before': {
                borderBottomColor: "#AFAFAF",
            },
            '& .MuiInput-input': {
                color: theme.palette.text.primary,
                marginTop: '10px',
                marginBottom: '10px',
                marginLeft: '10px',
                marginRight: '10px',
                fontSize: "small",
            },
            '& .Mui-focused .MuiInput-input': {
                color: theme.palette.primary.dark,
            },
        },
    })
);

export const VisitNoteEditor = (props: {
}) => {
    const { currentInvite } = useSelector((state: RootState) => state.invite);
    const [renderCounter, setRenderCounter] = useState(0);
    const classes = useStyles();

    function changeVisitNote(value: string) {
        if (!currentInvite) return;
        currentInvite.visitNotes = value;
        setRenderCounter(renderCounter + 1);
    }
    return (<>
        <TextField fullWidth
            className={"my-1 " + classes.visitNotes}
            placeholder={L10N({ key: "Do you have any special notes? Leave note here." })}
            value={currentInvite?.visitNotes}
            multiline
            onChange={(event) => changeVisitNote(event.target.value)}
        />
    </>);
};
