import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { ServiceDetailsDialogVisible } from '../../store/ui/ui.actions';
import { L10N } from '../pages/L10N';
import { Routes } from '../pages/NavMenu';
import { ServiceDetailsDialogView } from './ServiceDetailsView';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '16px',
        },
    })
);
export const ServiceDetailsDialog = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { serviceDetailsDialogVisible } = useSelector((state: RootState) => state.ui);
    const classes = useStyles();
    const { service } = useSelector((state: RootState) => state.details);
    const { searchResults } = useSelector((state: RootState) => state.search);

    async function handleClose() {
        await dispatch(ServiceDetailsDialogVisible(false));
    }
    async function bookClick() {
        if (!service) return;
        if (!service.offeredResourceID) return;

        await dispatch(changeInvite({
            query: searchResults?.query ?? "",
            offeredResourceID: service.offeredResourceID,
        }));
        await dispatch(ServiceDetailsDialogVisible(false));

        history.push(Routes.invite);
    }
    return (
        <>
            <Dialog
                open={serviceDetailsDialogVisible ?? false}
                onClose={handleClose}>
                <DialogTitle>{service?.title}</DialogTitle>
                <DialogContent>
                    <ServiceDetailsDialogView />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        className={classes.btn}
                        color="primary" variant="outlined">
                        {L10N({ key: "OK" })}
                    </Button>
                    <Button onClick={bookClick}
                        className={classes.btn}
                        color="primary" autoFocus variant="contained">
                        {L10N({ key: "Book" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
