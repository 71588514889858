import { Button, CircularProgress, Fab, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import NotInterested from '@material-ui/icons/NotInterested';
import SpeedIcon from '@material-ui/icons/Speed';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { api } from '../../api/api';
import { KlarnaApiCredentials } from '../../api/models/Edit';
import { PriceAndCurrency } from '../../api/models/Global';
import { GetKlarnaStatusResponse } from '../../api/models/Purchase';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { Routes } from '../pages/NavMenu';

export const TestKlarnaButton = (props: {
    klarnaApiCredentials?: KlarnaApiCredentials
}) => {
    const { user } = useSelector((state: RootState) => state.user);
    const [klarnaResult, setKlarnaResult] = useState({
        inProgress: false,
        result: undefined as (boolean | undefined),
    });
    async function onClick() {
        if (klarnaResult.inProgress) return;
        if (!user) return;
        if (!props.klarnaApiCredentials) return;

        setKlarnaResult({
            inProgress: true,
            result: undefined,
        });
        const apiResponse = await api.Purchase.testKlarna({
            accessToken: user.accessToken,
            klarnaApiCredentials: props.klarnaApiCredentials,
        });
        setKlarnaResult({
            inProgress: false,
            result: apiResponse.result,
        });
    }
    return (
        <>
            <Fab onClick={onClick} color="primary" size="small"
                disabled={!props.klarnaApiCredentials
                    || !props.klarnaApiCredentials.url
                    || !props.klarnaApiCredentials.username
                    || !props.klarnaApiCredentials.password
                }
            >
                {klarnaResult.inProgress ? <CircularProgress /> : <SpeedIcon />}
            </Fab>
            <Typography className="mx-3 d-inline-block">
                {klarnaResult.result === undefined && <><HelpOutlineIcon />{L10N({ key: "Klarna Connection Test" })}</>}
                {klarnaResult.result === true && <><DoneAllIcon className="text-success" />{L10N({ key: "Klarna Connection Successful" })}</>}
                {klarnaResult.result === false && <><NotInterested className="text-danger" />{L10N({ key: "Klarna Connection Not Working" })}</>}
            </Typography>
        </>
    )
}

export const CheckKlarnaStatusAndPayIfPossible = (props: {
    visitID?: string,
    klarnaOrderCompleted?: boolean,
    priceAndcurrency?: PriceAndCurrency,
    autoLoad?: boolean,
}) => {
    const history = useHistory();
    const { user } = useSelector((state: RootState) => state.user);
    const [klarnaResult, setKlarnaResult] = useState({
        inProgress: false,
        result: undefined as (GetKlarnaStatusResponse | undefined),
    });
    useEffect(() => {
        if (!props.autoLoad) return;
        reloadResult();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.autoLoad]);
    async function onClick(event: React.MouseEvent<any, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();

        await reloadResult();
    }
    async function reloadResult() {
        if (klarnaResult.inProgress) return;
        if (!user) return;
        if (!props.visitID) return;

        setKlarnaResult({
            inProgress: true,
            result: undefined,
        });
        const apiResponse = await api.Purchase.checkStatus({
            accessToken: user.accessToken,
            visitID: props.visitID,
        });
        setKlarnaResult({
            inProgress: false,
            result: apiResponse.result,
        });
    }
    function paymentClick(event: React.MouseEvent<any, MouseEvent>) {
        if (!props.visitID) return;
        event.preventDefault();
        event.stopPropagation();

        history.push(Routes.paymentPage(props.visitID));
    }
    function isValidForPayment(): boolean {
        if (props.klarnaOrderCompleted) return false;

        switch (klarnaResult.result?.klarnaStatus) {
            case "":
            case null:
            case undefined:
            case "payment_impossible":
            case "checkout_complete":
                return false;
            case "checkout_incomplete":
            default:
                return true;
        }
    }
    function renderStatus() {
        const paidStatus = <><DoneAllIcon className="text-success" />{L10N({ key: "Paid" })}</>;
        if (props.klarnaOrderCompleted) return paidStatus;

        switch (klarnaResult.result?.klarnaStatus) {
            case "":
            case null:
            case undefined: return <></>;
            case "payment_impossible": return <><NotInterested className="text-danger" />{L10N({ key: "Online payment impossible" })}</>;
            case "checkout_complete": return paidStatus;
            case "checkout_incomplete": return <></>;
            default: return <><HelpOutlineIcon className="text-success" /> {klarnaResult.result?.klarnaStatus}</>;
        }
    }
    return (
        <>
            {!props.klarnaOrderCompleted && !klarnaResult.result &&
                <Fab onClick={onClick} color="primary" size="small"
                    disabled={!props.visitID}>
                    {klarnaResult.inProgress ? <CircularProgress /> : L10N({ key: "_currency", price: props.priceAndcurrency })}
                </Fab>
            }
            <Typography className="d-inline-block">
                {renderStatus()}
            </Typography>
            {isValidForPayment() &&
                <Button onClick={paymentClick}
                    color="primary" variant="contained" size="small"
                    disabled={!props.visitID}>
                    {L10N({ key: "Pay" })}
                </Button>
            }
        </>
    )
}
