import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../../api/api';
import { DimensionTypes } from '../../api/models/Global';
import { CreateInviteMessage, Invite, InviteDimension } from '../../api/models/Invite';
import { RootState } from '../store';
import { hideProgress } from '../ui/ui.actions';
import { clearVisitResponse } from '../visit/visit.actions';
import {
    CHANGE_INVITE
} from './invite.types';

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const changeInvite = (
    message: CreateInviteMessage
): AppThunk => async dispatch => {
    await dispatch(clearVisitResponse());

    await dispatch({
        type: CHANGE_INVITE,
        payload: {
            createInviteMessage: message,
            inviteInProgress: true,
            serverInvite: undefined,
            currentInvite: undefined,
        }
    });
    const apiResponse = await api.Invite.createInvite(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_INVITE,
        payload: {
            createInviteMessage: message,
            inviteInProgress: false,
            serverInvite: apiResponse.result,
            currentInvite: apiResponse.result ? rebuildInvite(apiResponse.result, apiResponse.result) : undefined,
        }
    });
};
function rebuildInvite(invite: Invite, serverInvite: Invite): Invite {
    const filteredOffers = serverInvite.offers.filter((v, i, a) => {
        const result =
            (!invite.durationDimensionSelectedValue || v.du === invite.durationDimensionSelectedValue)
            && (!invite.priceDimensionSelectedValue || v.p === invite.priceDimensionSelectedValue)
            && (!invite.companyServiceDimensionSelectedValue || v.s === invite.companyServiceDimensionSelectedValue)
            && (!invite.companyDimensionSelectedValue || v.c === invite.companyDimensionSelectedValue)
            && (!invite.companyResourceDimensionSelectedValue || v.r === invite.companyResourceDimensionSelectedValue)
            && (!invite.timeDimensionSelectedValue || v.t === invite.timeDimensionSelectedValue)
            && (!invite.dayPartDimensionSelectedValue || v.dp === invite.dayPartDimensionSelectedValue)
            && (!invite.dateDimensionSelectedValue || v.da === invite.dateDimensionSelectedValue)
            ;
        return result;
    });
    const result: Invite = {
        ...invite,
        offers: filteredOffers,
    }
    result.durationDimension.forEach(function (m, i, a) {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.du === m.dimensionValue }).length;
    });
    result.priceDimension.forEach((m, i) => {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.p === m.dimensionValue }).length;
    });
    result.companyServiceDimension.forEach((m, i) => {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.s === m.dimensionValue }).length;
    });
    result.companyDimension.forEach((m, i) => {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.c === m.dimensionValue }).length;
    });
    result.companyResourceDimension.forEach((m, i) => {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.r === m.dimensionValue }).length;
    });
    result.timeDimension.forEach((m, i) => {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.t === m.dimensionValue }).length;
    });
    result.dayPartDimension.forEach((m, i) => {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.dp === m.dimensionValue }).length;
    });
    result.dateDimension.forEach((m, i) => {
        m.countOfOffers = result.offers.filter((v, i, a) => { return v.da === m.dimensionValue }).length;
    });

    if (!result.durationDimensionSelectedValue) {
        const filtered = result.durationDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.durationDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.priceDimensionSelectedValue) {
        const filtered = result.priceDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.priceDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.companyServiceDimensionSelectedValue) {
        const filtered = result.companyServiceDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.companyServiceDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.companyDimensionSelectedValue) {
        const filtered = result.companyDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.companyDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.companyResourceDimensionSelectedValue) {
        const filtered = result.companyResourceDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.companyResourceDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.timeDimensionSelectedValue) {
        const filtered = result.timeDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.timeDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.dayPartDimensionSelectedValue) {
        const filtered = result.dayPartDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.dayPartDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }
    if (!result.dateDimensionSelectedValue) {
        const filtered = result.dateDimension.filter((v, i, a) => v.countOfOffers > 0);
        result.dateDimensionSelectedValue = filtered.length === 1 ? filtered[0].dimensionValue : undefined;
    }

    return result;
}
export const changeSelectedDimension = (
    dimensionValue: InviteDimension,
    dimensionType: DimensionTypes,
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    await dispatch({
        type: CHANGE_INVITE,
        payload: {
            ...state.invite,
            inviteInProgress: true,
        }
    });

    if (state?.invite?.currentInvite && state?.invite?.serverInvite) {
        if (dimensionValue.countOfOffers === 0) {
            state.invite.currentInvite.durationDimensionSelectedValue = undefined;
            state.invite.currentInvite.priceDimensionSelectedValue = undefined;
            state.invite.currentInvite.companyServiceDimensionSelectedValue = undefined;
            state.invite.currentInvite.companyDimensionSelectedValue = undefined;
            state.invite.currentInvite.companyResourceDimensionSelectedValue = undefined;
            state.invite.currentInvite.timeDimensionSelectedValue = undefined;
            state.invite.currentInvite.dayPartDimensionSelectedValue = undefined;
            state.invite.currentInvite.dateDimensionSelectedValue = undefined;
        }
        switch (dimensionType) {
            case "duration": state.invite.currentInvite.durationDimensionSelectedValue =
                state.invite.currentInvite.durationDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
            case "price": state.invite.currentInvite.priceDimensionSelectedValue =
                state.invite.currentInvite.priceDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
            case "service": state.invite.currentInvite.companyServiceDimensionSelectedValue =
                state.invite.currentInvite.companyServiceDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
            case "when": state.invite.currentInvite.dateDimensionSelectedValue =
                state.invite.currentInvite.dateDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
            case "what_time": state.invite.currentInvite.timeDimensionSelectedValue =
                state.invite.currentInvite.timeDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
            case "day_part": state.invite.currentInvite.dayPartDimensionSelectedValue =
                state.invite.currentInvite.dayPartDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
            case "company": state.invite.currentInvite.companyDimensionSelectedValue =
                state.invite.currentInvite.companyDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
            case "resource": state.invite.currentInvite.companyResourceDimensionSelectedValue =
                state.invite.currentInvite.companyResourceDimensionSelectedValue === dimensionValue.dimensionValue
                    ? undefined : dimensionValue.dimensionValue; break;
        }
        const nextInvite = rebuildInvite(state?.invite?.currentInvite, state?.invite?.serverInvite);
        await dispatch({
            type: CHANGE_INVITE,
            payload: {
                ...state.invite,
                inviteInProgress: false,
                currentInvite: nextInvite,
            }
        });
    } else {
        await dispatch({
            type: CHANGE_INVITE,
            payload: {
                ...state.invite,
                inviteInProgress: false,
            }
        });
    }
};
export const clearSelectedDimensions = (
): AppThunk => async (dispatch, getState) => {
    const state = getState();
    await dispatch({
        type: CHANGE_INVITE,
        payload: {
            ...state.invite,
            inviteInProgress: true,
        }
    });
    if (state?.invite?.currentInvite && state?.invite?.serverInvite) {
        state.invite.currentInvite.durationDimensionSelectedValue = undefined;
        state.invite.currentInvite.priceDimensionSelectedValue = undefined;
        state.invite.currentInvite.companyServiceDimensionSelectedValue = undefined;
        state.invite.currentInvite.companyDimensionSelectedValue = undefined;
        state.invite.currentInvite.companyResourceDimensionSelectedValue = undefined;
        state.invite.currentInvite.dateDimensionSelectedValue = undefined;
        state.invite.currentInvite.timeDimensionSelectedValue = undefined;
        state.invite.currentInvite.dayPartDimensionSelectedValue = undefined;

        const nextInvite = rebuildInvite(state?.invite?.currentInvite, state?.invite?.serverInvite);
        await dispatch({
            type: CHANGE_INVITE,
            payload: {
                ...state.invite,
                inviteInProgress: false,
                currentInvite: nextInvite,
            }
        });
    } else {
        await dispatch({
            type: CHANGE_INVITE,
            payload: {
                ...state.invite,
                inviteInProgress: false,
            }
        });
    }
};

export const clearVisitIDFromCurrentInvite = (
): AppThunk => async (dispatch, getState) => {
    const state = getState().invite;
    const currentInvite = state.currentInvite;
    if (!currentInvite) return;
    currentInvite.visitIDToBeReplaced = undefined;
    await dispatch({
        type: CHANGE_INVITE,
        payload: {
            ...state,
            currentInvite: currentInvite
        }
    });
};
