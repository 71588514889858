import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import { ContentState, convertToRaw, Editor, EditorState } from 'draft-js';
import * as React from 'react';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '16px',
        },
    })
);
export const EditDetailsDialog = (props: {
    title: string,
    details: string,
    open: boolean,
    onSave: (details: string) => void,
    onCancel: () => void,
}) => {
    const [editorState, setEditorState] = React.useState(
        EditorState.createWithContent(ContentState.createFromText(props.details))
    );
    const editor = React.useRef(null);
    const classes = useStyles();

    async function onSave() {
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        props.onSave(value);
    }
    async function onCancel() {
        props.onCancel();
    }
    return (
        <>
            <Dialog
                open={props.open}
                onClose={onCancel}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <Editor
                        ref={editor}
                        editorState={editorState}
                        onChange={editorState => setEditorState(editorState)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSave}
                        className={classes.btn}
                        color="primary" variant="contained">
                        {L10N({ key: "Save" })}
                    </Button>
                    <Button onClick={onCancel}
                        className={classes.btn}
                        color="primary" autoFocus variant="outlined">
                        {L10N({ key: "Cancel" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};