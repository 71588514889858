import { Button, Container, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { InviteDate } from '../../api/models/Invite';
import { changeInvite, changeSelectedDimension } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            display: 'block',
            textTransform: 'none',
            outline: 'none !important',
            minWidth: '50px',
        },
        month: {
            fontWeight: 200,
        }
    })
);
export const WhenSelector = (props: {
}) => {
    const dispatch = useDispatch();
    const { currentInvite, createInviteMessage } = useSelector((state: RootState) => state.invite);
    const classes = useStyles();

    function isSelected(m: InviteDate | undefined): boolean {
        if (!m) return false;
        return m.dimensionValue === currentInvite?.dateDimensionSelectedValue;
    }
    function getAvailable(m: moment.Moment): InviteDate | undefined {
        if (!currentInvite?.dateDimension) return undefined;

        const result = currentInvite
            ?.dateDimension
            ?.find((v, i, a) => moment(v.start).toJSON() === m.toJSON());

        return result;
    }
    function onDateClicked(m: InviteDate) {
        dispatch(changeSelectedDimension(m, "when"));
    }
    function nextNumbers(n: number): number[] {
        const result = Array.from({ length: n }, (v, i) => i);
        return result;
    }
    function calendarStart(): moment.Moment {
        const first = currentInvite?.dateDimension[0];
        const weekStart = moment(first?.start).startOf('isoWeek').utc(true);
        const monthStart = moment(first?.start).startOf('month').utc(true);

        if (weekStart > monthStart) {
            return weekStart;
        } else {
            return monthStart;
        }
    }
    function calendarEnd(): moment.Moment {
        const last = currentInvite?.dateDimension[currentInvite?.dateDimension.length - 1];
        return moment(last?.start).startOf('isoWeek').add(7, 'days');
    }
    function dayName(n: number): string {
        const dayName = L10N({ key: '_dateAsddd', date: calendarStart().add(n, 'days').toISOString() });

        return dayName.toUpperCase();
    }
    function daySinceStart(weekIndex: number, dayIndex: number): number {
        return weekIndex * 7 + dayIndex;
    }
    function daySinceStartAsMoment(weekIndex: number, dayIndex: number): moment.Moment {
        return calendarStart().add(daySinceStart(weekIndex, dayIndex), 'days');
    }
    async function onNextDatesBatchClick() {
        if (createInviteMessage) {
            await dispatch(changeInvite({
                ...createInviteMessage,
                dateBatchNumber: (createInviteMessage?.dateBatchNumber ?? 0) + 1
            }));
        }
    }
    async function onPrevDatesBatchClick() {
        if (createInviteMessage) {
            await dispatch(changeInvite({
                ...createInviteMessage,
                dateBatchNumber: (createInviteMessage?.dateBatchNumber ?? 0) - 1
            }));
        }
    }
    function renderDayButton(weekIndex: number, dayIndex: number): JSX.Element {
        const dayMoment = daySinceStartAsMoment(weekIndex, dayIndex);
        const available = getAvailable(dayMoment);
        const selected = isSelected(available);

        return (<>
            {dayMoment < calendarEnd() &&
                <Button className={"w-100 m-1 "
                    + classes.btn
                    + (available?.countOfOffers === 0 ? " text-gray" : "")
                }
                    variant={selected ? "contained" :
                        available ? "outlined"
                            : "text"}
                    color={selected ? "primary" : "default"}
                    size="small"
                    disabled={!available}
                    onClick={available ? () => onDateClicked(available) : undefined}>
                    <Moment add={{ days: daySinceStart(weekIndex, dayIndex) }} format="D">{calendarStart()}</Moment>
                </Button>
            }
        </>);
    }
    let prevMonthName = "";
    function renderMonthIfChanged(weekIndex: number, dayIndex: number): JSX.Element {
        const dayMoment = daySinceStartAsMoment(weekIndex, dayIndex);
        if (dayMoment >= calendarEnd()) return <></>;

        const monthName = L10N({ key: '_dateAsMMMMYYYY', date: daySinceStartAsMoment(weekIndex, dayIndex).toISOString() });
        if (monthName === prevMonthName) return <></>;
        prevMonthName = monthName;
        return <>
            {nextNumbers(7).map((m, i) => (m >= dayIndex) && <Col key={"dayGapPre" + monthName + m} />)}
            <Col xs={12}>
                <Typography variant="subtitle1" align="left" className={"my-3 " + classes.month}>
                    {monthName}
                </Typography>
            </Col>
            {nextNumbers(7).map((m, i) => (m < dayIndex) && <Col key={"dayGapPost" + monthName + m} />)}
        </>;
    }
    return (<><Container>
        <Row className="align-items-center">
            <Col />
            <Col className="col-auto">
                {currentInvite?.hasEarlier &&
                    <IconButton onClick={() => onPrevDatesBatchClick()} >
                        <img src="/img/icons/icon_prev.svg" alt="prev" />
                    </IconButton>
                }
                {currentInvite?.hasLater &&
                    <IconButton onClick={() => onNextDatesBatchClick()} >
                        <img src="/img/icons/icon_next.svg" alt="next" />
                    </IconButton>
                }
            </Col>
        </Row>
        <Row>
            {nextNumbers(7).map((m, i) =>
                <Col key={"dn" + (-1000 - i)}>
                    <Button className={"w-100 " + classes.btn} disabled>
                        <Typography variant="body2" align="center" className="w-100">
                            {dayName(i)}
                        </Typography>
                    </Button>
                </Col>
            )}
        </Row>
        <Row>
            <div className="w-100" />
            {nextNumbers(10).map((m, weekIndex) => <React.Fragment key={"w" + (weekIndex)}>
                {nextNumbers(7).map((m, dayIndex) => <React.Fragment key={"d" + (weekIndex * 7 + dayIndex)}>
                    {renderMonthIfChanged(weekIndex, dayIndex)}
                    <Col>
                        {renderDayButton(weekIndex, dayIndex)}
                    </Col>
                </React.Fragment>
                )}
                <div className="w-100" />
            </React.Fragment>)}
        </Row></Container>
    </>);
};
