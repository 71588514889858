import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { setMobileClient } from '../../store/user/user.actions';
import { Routes } from './NavMenu';

export const MobilePage = () => {
    const dispatch = useDispatch();
    const urlParams = useParams<{ client?: string, version?: string }>();
    useEffect(() => {
        dispatch(setMobileClient(urlParams.client, urlParams.version));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    return (
        <Redirect to={Routes.home} />
    );
}
