import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, createStyles, FormControlLabel, InputAdornment, makeStyles, MenuItem, Switch, TextField, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getIn } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api/api';
import { PhotoEditModel, ServiceEditModel } from '../../api/models/Edit';
import { SearchCategory_Anything } from '../../api/models/Search';
import { refreshHints } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { AvatarUpload } from '../ui/AvatarUpload';
import { CategoryIcon } from '../ui/CategoryIcon';
import { useFormikResult } from '../ui/FormikAPI';
import { AppBarSubmitCancel } from './AppBarSubmitCancel';
import { OffersCheckboxCard } from './OffersCheckboxCard';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectItemImg: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
            width: '20px',
            height: '20px',
        },
        menuText: {
            marginLeft: '20px',
        },
    })
);
export const FormServiceDetails = (props: {
    formik: useFormikResult<ServiceEditModel>,
}) => {
    const dispatch = useDispatch();
    const { searchCategories, searchHintsInProgress } = useSelector((state: RootState) => state.search);
    const classes = useStyles();
    useEffect(() => {
        if ((searchCategories?.results?.length ?? 0) === 0) {
            dispatch(refreshHints());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
        <form onSubmit={props.formik.handleSubmit}>
            <Container>
                <Row>
                    {props.formik.values.photos?.length > 0
                        ? props.formik.values.photos.map((m, i) =>
                            <Col key={m.photoIndex} className="col-auto">
                                <AvatarUpload
                                    variant="rounded"
                                    src={props.formik.values.photos?.[i]?.base64 ?? api.Search.getPhotoUrl(m) ?? ""}
                                    editMode={true}
                                    onChange={(base64) => props.formik.setFieldValue(`photos[${i}].base64`, base64)}
                                    fileId={"" + i}
                                    alt={props.formik.values.description} />
                            </Col>
                        )
                        :
                        <Col className="col-auto">
                            <AvatarUpload
                                variant="rounded"
                                src=""
                                addMode
                                onChange={(base64) => props.formik.setFieldValue(`photos[0]`, {
                                    base64: base64,
                                    accessToken: "",
                                    objectID: props.formik.values.serviceID,
                                    photoIndex: 0,
                                    versionStamp: moment().toISOString(),
                                    isDeleted: false,
                                    position: 'Default',
                                } as PhotoEditModel)}
                                fileId={"new"}
                                alt={props.formik.values.description} />
                        </Col>
                    }
                </Row>

                <TextField className="mb-3" fullWidth
                    disabled
                    id="serviceID" name="serviceID" label={L10N({ key: "Service ID" })}
                    value={props.formik.values.serviceID ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.serviceID && Boolean(props.formik.errors.serviceID)}
                    helperText={props.formik.touched.serviceID && props.formik.errors.serviceID}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    required
                    id="serviceName" name="serviceName" label={L10N({ key: "Service Name" })}
                    value={props.formik.values.serviceName ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.serviceName && Boolean(props.formik.errors.serviceName)}
                    helperText={props.formik.touched.serviceName && props.formik.errors.serviceName}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="serviceGroupName" name="serviceGroupName" label={L10N({ key: "Service Category" })}
                    value={props.formik.values.serviceGroupName ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.serviceGroupName && Boolean(props.formik.errors.serviceGroupName)}
                    helperText={props.formik.touched.serviceGroupName && props.formik.errors.serviceGroupName}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    select
                    id="category" name="category" label={L10N({ key: "Bookee Category" })}
                    value={props.formik.values.category ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.category && Boolean(props.formik.errors.category)}
                    helperText={props.formik.touched.category && props.formik.errors.category}
                >
                    {searchHintsInProgress && <CircularProgress />}
                    {searchCategories?.results?.map &&
                        searchCategories.results
                            .filter((m, i, a) => m.text !== SearchCategory_Anything)
                            .map((m, i) => (
                                <MenuItem key={m.encodedText} value={m.text}>
                                    <CategoryIcon name={m.text} className={classes.selectItemImg} />
                                    <Typography variant="body1" color={m.hasResults ? "textPrimary" : "textSecondary"} className={classes.menuText}>
                                        {L10N({ key: "_category", cat: m.text })}
                                    </Typography>
                                </MenuItem>
                            ))}
                </TextField>
                <TextField variant="outlined" className="mb-3" fullWidth
                    multiline rows={5}
                    id="description" name="description" label={L10N({ key: "Description" })}
                    value={props.formik.values.description ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.description && Boolean(props.formik.errors.description)}
                    helperText={props.formik.touched.description && props.formik.errors.description}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="defaultPrice.price" name="defaultPrice.price" label={L10N({ key: "Default Price" })}
                    value={props.formik.values.defaultPrice?.price ?? "0"}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.defaultPrice && Boolean(props.formik.errors.defaultPrice)}
                    helperText={props.formik.touched.defaultPrice && getIn(props.formik.errors, "defaultPrice.price")}
                    InputProps={{ endAdornment: <InputAdornment position="end">{L10N({ key: '_defaultCurrency' })}</InputAdornment> }}
                />
                <Row>
                    <Col>
                        <TextField variant="outlined" className="mb-3" fullWidth
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            id="timeBeforeInMinutes" name="timeBeforeInMinutes" label={L10N({ key: "Time before [minutes]" })}
                            value={props.formik.values.timeBeforeInMinutes ?? "0"}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.timeBeforeInMinutes && Boolean(props.formik.errors.timeBeforeInMinutes)}
                            helperText={props.formik.touched.timeBeforeInMinutes && props.formik.errors.timeBeforeInMinutes}
                        />
                    </Col>
                    <Col>
                        <TextField variant="outlined" className="mb-3" fullWidth
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            id="durationInMinutes" name="durationInMinutes" label={L10N({ key: "Duration [minutes]" })}
                            value={props.formik.values.durationInMinutes ?? "0"}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.durationInMinutes && Boolean(props.formik.errors.durationInMinutes)}
                            helperText={props.formik.touched.durationInMinutes && props.formik.errors.durationInMinutes}
                        />
                    </Col>
                    <Col>
                        <TextField variant="outlined" className="mb-3" fullWidth
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            id="timeAfterInMinutes" name="timeAfterInMinutes" label={L10N({ key: "Time after [minutes]" })}
                            value={props.formik.values.timeAfterInMinutes ?? "0"}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.timeAfterInMinutes && Boolean(props.formik.errors.timeAfterInMinutes)}
                            helperText={props.formik.touched.timeAfterInMinutes && props.formik.errors.timeAfterInMinutes}
                        />
                    </Col>
                </Row>
                <FormControlLabel
                    control={<Switch color="primary" size="medium"
                        disabled
                        checked={props.formik.values.paymentUpfront ?? false}
                        id="paymentUpfront" name="paymentUpfront"
                        onChange={props.formik.handleChange}
                    />}
                    label={L10N({ key: "Upfront payment - the client has to pay upfront online while booking a visit" })}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    id="paymentUpfrontPrice" name="paymentUpfrontPrice" label={L10N({ key: "Upfront payment - price" })}
                    value={props.formik.values.paymentUpfrontPrice ?? ""}
                    disabled={!props.formik.values.paymentUpfront}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.paymentUpfrontPrice && Boolean(props.formik.errors.paymentUpfrontPrice)}
                    helperText={props.formik.touched.paymentUpfrontPrice && props.formik.errors.paymentUpfrontPrice}
                    InputProps={{ endAdornment: <InputAdornment position="end">{L10N({ key: '_defaultCurrency' })}</InputAdornment> }}
                /><br />
                <FormControlLabel
                    control={<Switch color="primary" size="medium"
                        checked={props.formik.values.manualConfirmationRequired ?? false}
                        id="manualConfirmationRequired" name="manualConfirmationRequired"
                        onChange={props.formik.handleChange}
                    />}
                    label={L10N({ key: "Require manual confirmation of the booking" })}
                /><br />
                {props.formik.values.offers?.length > 0 &&
                    <Row>
                        <Col>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    className="text-black text-weight-bold2">
                                    {L10N({ key: "Resources" })}
                                </AccordionSummary>
                                <AccordionDetails className="d-block">
                                    <Container>
                                        <Row>
                                            <Col >
                                                <OffersCheckboxCard model={props.formik.values.offers}
                                                    showFromServicePerspective
                                                    onToggleChecked={(m, i) => props.formik.setFieldValue(`offers[${i}].isAvailable`, !m.isAvailable)}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                }
                {props.formik.values.serviceID && props.formik.values.serviceID !== "" &&
                    <Row>
                        <Col>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    className="text-black text-weight-bold2">
                                    {L10N({ key: "Delete" })}
                                </AccordionSummary>
                                <AccordionDetails className="d-block">
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={props.formik.values.isDeleted ?? false} color="primary"
                                                id="isDeleted" name="isDeleted"
                                                onChange={props.formik.handleChange}
                                            />
                                        }
                                        label={L10N({ key: "Mark for deletion" })}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                }
            </Container>
            {props.formik.dirty && <AppBarSubmitCancel onCancel={() => props.formik.resetForm()} formik={props.formik} />}
        </form>
    </>;
}