import { createPopup } from '@typeform/embed';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Marketing01_MenuAndHeader } from "../marketing/Marketing01_MenuAndHeader";
import { Marketing02_AboutApp } from "../marketing/Marketing02_AboutApp";
import { Marketing03_Features } from "../marketing/Marketing03_Features";
import { Marketing04_Categories } from "../marketing/Marketing04_Categories";
import { Marketing05_Screenshots } from "../marketing/Marketing05_Screenshots";
import { Marketing06_Video } from "../marketing/Marketing06_Video";
import { Marketing07_AboutUs } from "../marketing/Marketing07_AboutUs";
import { Marketing08_ContactUs } from "../marketing/Marketing08_ContactUs";
import { Marketing09_Subscribe } from "../marketing/Marketing09_Subscribe";
import { MarketingFooter } from "../marketing/MarketingFooter";
import { MarketingPageAnchor } from "../marketing/MarketingPageAnchor";

export type MarketingPageTab =
    "home"
    | "bookings"
    | "register"
    | "about"
    | "features"
    | "screenshots"
    | "team"
    | "categories"
    | "contact"
    | "subscribe";

export const MarketingPage = () => {
    const urlParams = useParams<{ tab: MarketingPageTab, referral: string }>();
    const [offset, setOffset] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const { open } = createPopup("exx6sPLS");
    function openPopUp() {
        if (isOpen) return;
        setIsOpen(true);
        open();
    }

    const homeRef = useRef<HTMLDivElement>(null);
    const bookingsRef = useRef<HTMLDivElement>(null);
    const aboutRef = useRef<HTMLDivElement>(null);
    const featuresRef = useRef<HTMLDivElement>(null);
    const screenshotsRef = useRef<HTMLDivElement>(null);
    const teamRef = useRef<HTMLDivElement>(null);
    const categoriesRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);
    const subscribeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        switch (urlParams.tab) {
            case "home": homeRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "bookings": bookingsRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "about": aboutRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "features": featuresRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "screenshots": screenshotsRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "team": teamRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "categories": categoriesRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "contact": contactRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "subscribe": subscribeRef.current?.scrollIntoView({ behavior: "smooth" }); break;
            case "register": openPopUp(); subscribeRef.current?.scrollIntoView({ behavior: "smooth" }); break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    function handleScroll(event: Event) {
        setOffset(window.pageYOffset);
    };
    return (
        <>
            <MarketingPageAnchor ref={homeRef} />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing01_MenuAndHeader />

            <MarketingPageAnchor ref={teamRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing06_Video />

            <MarketingPageAnchor ref={subscribeRef} showBack white />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing09_Subscribe />

            <MarketingPageAnchor ref={screenshotsRef} showBack white />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing05_Screenshots />


            <MarketingPageAnchor ref={bookingsRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing02_AboutApp />

            <MarketingPageAnchor ref={featuresRef} showBack white />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing03_Features offset={offset} />

            <MarketingPageAnchor ref={categoriesRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing04_Categories />


            <MarketingPageAnchor ref={aboutRef} showBack white />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing07_AboutUs />

            <MarketingPageAnchor ref={contactRef} showBack />
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Marketing08_ContactUs />



            <MarketingPageAnchor showBack  />
            <MarketingFooter />
        </>
    );
};
