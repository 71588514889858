export function nameof<T>(name: keyof T): keyof T {
    return name;
}
export class extensions {

    static captureBackspace(e: any) {
        var rx = /INPUT|SELECT|TEXTAREA/i;

        if (e.which === 8) { // 8 == backspace
            if (e.target.getAttribute('contenteditable') !== null) return;

            if (!rx.test(e.target.tagName) || e.target.disabled || e.target.readOnly) {
                e.preventDefault();
            }
        }
    }
    static preventBackspace() {
        window.addEventListener('keydown', extensions.captureBackspace, true)
    }
    static restoreBackspace() {
        window.removeEventListener('keydown', extensions.captureBackspace, true)
    }
    static arrayItemsEqual(thisArray: any[], array: any[]): boolean {

        // compare lengths - can save a lot of time 
        if (thisArray.length !== array.length)
            return false;

        for (var i = 0, l = thisArray.length; i < l; i++) {
            // Check if we have nested arrays
            if (thisArray[i] instanceof Array && array[i] instanceof Array) {
                // recurse into the nested arrays
                if (!thisArray[i].equals(array[i]))
                    return false;
            }
            else if (thisArray[i] !== array[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
        return true;
    }
    static shuffleArray(array: any[]) {
        let i = array.length - 1;
        for (; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }
    static getShuffledArray(sourceArray: any[], differentFromSource: boolean): any[] {
        let array = Array.from(sourceArray);

        extensions.shuffleArray(array);
        if (differentFromSource && array.length > 1) {
            while (extensions.arrayItemsEqual(sourceArray, array)) {
                extensions.shuffleArray(array);
            }
        }

        return array;
    }
}