import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { MarketingQuestionMessage } from "../models/Marketing";

export class MarketingEndpoint {
    async postQuestion(message: MarketingQuestionMessage): Promise<ApiResponse<boolean>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/marketing-question`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as boolean,
        });
        return apiResponse;
    }
}