import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import DoneIcon from '@material-ui/icons/Done';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api/api';
import { AccessTokenBearer, SignalMessage } from '../../api/models/Global';
import { setSignalMessages } from '../../store/company/company.actions';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        snackbar: {
            "& .MuiSnackbarContent-root": {
                backgroundColor: 'transparent',
            },
        },
    })
);
export const SignalListener = (props: {
    onClose?: () => Promise<void>,
}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const { signalMessages } = useSelector((state: RootState) => state.company);
    const [connection, setConnection] = useState<HubConnection | null>(null);
    const classes = useStyles();

    useEffect(() => {
        if (!user?.accessToken) {
            setConnection(null);
            return;
        }
        if (connection) return;

        const newConnection = new HubConnectionBuilder()
            .withUrl(api.signalrUrl)
            .withAutomaticReconnect()
            .build();
        setConnection(newConnection);
        return () => {
            newConnection?.stop();
            setConnection(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {

        if (connection && connection.state === "Disconnected") {
            connection.start()
                .then(result => {
                    if (user?.accessToken) {
                        const bearer: AccessTokenBearer = {
                            accessToken: user.accessToken,
                        };
                        connection.send('JoinAsUser', bearer);
                    }
                    connection.on('ReceiveMessage', message => {
                        dispatch(setSignalMessages([...signalMessages ?? [], message]));
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection, user, dispatch, signalMessages]);

    async function handleClose(data: SignalMessage) {
        if (!signalMessages) return;
        await dispatch(setSignalMessages(signalMessages.filter(item => item !== data)));
        if (props.onClose) {
            await props.onClose();
        }
    }
    const Message = (props: {
        data: SignalMessage,
    }) => {

        return (
            <Snackbar open={true}
                className={" " + classes.snackbar}
                onClose={() => handleClose(props.data)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                message={
                    <div className="">
                        <Typography variant="body2">
                            {L10N({ key: "_dateAsTimeAgo", date: props.data.timeStamp })}
                        </Typography>
                        <Typography variant="body1">
                            {L10N({ key: "_signalMessageType", signalMessageType: props.data.messageType })}
                        </Typography>
                    </div>
                }
                action={<>
                    <IconButton size="small" onClick={() => handleClose(props.data)} className="bg-gradient-bee-diagonal text-white">
                        <DoneIcon fontSize="small" />
                    </IconButton>
                </>} />
        );
    }
    return (
        <div>
            {signalMessages && signalMessages.map(m =>
                <Message key={m.timeStamp + m.itemID + m.messageType}
                    data={m} />)
            }
        </div>
    );
};