import { MapCoords } from '../../api/models/Details';
import { SearchCategories, SearchHints, SearchResults } from '../../api/models/Search';
import {
    CHANGE_FAVORITES_IN_PROGRESS,
    CHANGE_FEATURED_IN_PROGRESS,
    CHANGE_SEARCH_HINTS,
    CHANGE_SEARCH_IN_PROGRESS,
    CHANGE_SEARCH_STRING,
    SearchActionTypes
} from './search.types';

export interface SearchState {
    hasSearched: boolean;
    searchString: string;
    searchStringWithoutTags: string;
    searchHintsInProgress: boolean;
    searchHints?: SearchHints;
    searchCategories?: SearchCategories;
    exploreWhenHints?: SearchHints;
    searchResultsInProgress: boolean;
    searchResults?: SearchResults;
    searchResultsPrev?: SearchResults;
    featuredResultsInProgress: boolean;
    featuredResults?: SearchResults;
    favoriteResultsInProgress: boolean;
    favoriteResults?: SearchResults;
    currentLocation?: MapCoords;
}
const initialSearchState: SearchState = {
    hasSearched: false,
    searchString: "",
    searchStringWithoutTags: "",
    searchHintsInProgress: false,
    searchHints: undefined,
    searchCategories: undefined,
    exploreWhenHints: undefined,
    searchResultsInProgress: false,
    searchResults: undefined,
    searchResultsPrev: undefined,
    featuredResultsInProgress: false,
    featuredResults: undefined,
    favoriteResultsInProgress: false,
    favoriteResults: undefined,
    currentLocation: undefined,
}
export function searchReducer(
    state = initialSearchState,
    action: SearchActionTypes
): SearchState {
    switch (action.type) {
        case CHANGE_SEARCH_STRING: return {
            ...state,
            searchString: action.payload.searchString,
            searchStringWithoutTags: action.payload.searchStringWithoutTags,
            currentLocation: action.payload.currentLocation,
        }
        case CHANGE_SEARCH_HINTS: return {
            ...state,
            searchHintsInProgress: action.payload.searchHintsInProgress,
            searchHints: action.payload.searchHints,
            searchCategories: action.payload.searchCategories,
            exploreWhenHints: action.payload.exploreWhenHints,
        }
        case CHANGE_SEARCH_IN_PROGRESS: return {
            ...state,
            hasSearched: true,
            searchResultsInProgress: action.payload.searchResultsInProgress,
            searchResultsPrev: state.searchResults,
            searchResults: action.payload.searchResults,
        }
        case CHANGE_FEATURED_IN_PROGRESS: return {
            ...state,
            featuredResultsInProgress: action.payload.featuredResultsInProgress,
            featuredResults: action.payload.featuredResults,
        }
        case CHANGE_FAVORITES_IN_PROGRESS: return {
            ...state,
            favoriteResultsInProgress: action.payload.favoriteResultsInProgress,
            favoriteResults: action.payload.favoriteResults,
        }
        default:
            return state
    }
}
