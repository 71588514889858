import { AppBar, Badge, Button, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DimensionTypes } from '../../api/models/Global';
import { RootState } from '../../store/store';
import { MenuIcon } from '../ui/MenuIcon';
import { EditStep } from './EditPage';
import { L10N } from './L10N';
import { MarketingPageTab } from './MarketingPage';
import { ProfileTab } from './ProfilePage';

export class Routes {
    static home = "/";
    static mobileHome = "/mobile/:client?/:version?";

    static login = "/login/:returnUrl";
    static loginAndReturn(returnUrl: string) {
        const encoded = encodeURIComponent(returnUrl);
        return `/login/${encoded}`;
    }
    static explore = "/explore";
    static favourites = "/favourites";
    static profile = "/profile/:tab";
    static profilePage(tab?: ProfileTab): string {
        return `/profile/${tab ?? "default"}`;
    }
    static calendar = "/calendar/:tab/:mode";
    static calendarPage(tab: "company" | "resource", mode: "calendar" | "list"): string {
        return `/calendar/${tab}/${mode}`;
    }
    static accountDetails = "/accountDetails";
    static terms = "/terms";
    static legal = "/legal";
    static recommend = "/recommend";
    static statistics = "/statistics/:tab";
    static statisticsTab(tab?: "visit" | "referral"): string {
        return `/statistics/${tab ?? "visit"}`;
    }
    static clients = "/clients/:tab";
    static clientsTab(tab?: "banned" | "all"): string {
        return `/clients/${tab ?? "banned"}`;
    }
    static invite = "/invite";
    static visit = "/visit/:tab";
    static visitTab(tab?: "upcoming" | "completed"): string {
        return `/visit/${tab ?? "upcoming"}`;
    }
    static marketing = "/marketing/:tab/:referral?";
    static marketingPage(tab?: MarketingPageTab, referral?: string): string {
        if (referral) {
            return `/marketing/${tab ?? "home"}/${referral}`;
        } else {
            return `/marketing/${tab ?? "home"}`;
        }
    }
    static visitReview = "/visitReview";
    static visitReviewEdit = "/visitReviewEdit/:visitID/:visitReviewID";
    static visitReviewEditPage(visitID?: string, visitReviewID?: string): string {
        return `/visitReviewEdit/${visitID ?? "default"}/${visitReviewID ?? "default"}`;
    }
    static visitConfirmed = "/visitConfirmed/:visitID/:visitAccessToken?";
    static visitConfirmedPage(visitID: string, visitAccessToken?: string): string {
        return `/visitConfirmed/${visitID ?? "default"}/${visitAccessToken ?? ""}`;
    }
    static details = "/details/:tab/:id";
    static detailsTab(type: DimensionTypes, id?: string, companyName?: string, companyAddress?: string): string {
        const encodedCompanyName = (companyName && companyName !== "")
            ? `company=${encodeURIComponent(companyName)}`
            : "";
        const encodedCompanyAddress = (companyAddress && companyAddress !== "")
            ? `address=${encodeURIComponent(companyAddress)}`
            : "";
        const suffix = (encodedCompanyName !== "") && (encodedCompanyAddress !== "")
            ? `?${encodedCompanyName}&${encodedCompanyAddress}`
            : (encodedCompanyName !== "")
                ? `?${encodedCompanyName}`
                : (encodedCompanyAddress !== "")
                    ? `?${encodedCompanyAddress}`
                    : "";
        if (id) return `/details/${type}/${id}${suffix}`;
        else return `/details/${type}`;
    }
    static createCompany = "/createCompanyPage/:referral?";
    static createCompanyPage(referral?: string): string {
        return `/createCompanyPage/${referral ?? ""}`;
    }
    static edit = "/edit/:companyID/:step/:selectedServiceID/:selectedResourceID";
    static editPage(companyID: string, step?: EditStep, selectedServiceID?: string, selectedResourceID?: string): string {
        const stepValue: EditStep = step ?? "about";
        return `/edit/${companyID}/${stepValue}/${selectedServiceID ?? "-"}/${selectedResourceID ?? "-"}`;
    }
    static payment = "/payment/:visitID";
    static paymentPage(visitID: string): string {
        return `/payment/${visitID ?? "default"}`;
    }
    static superAdmin = "/superAdmin";
    static superAdminPage(): string {
        return this.superAdmin;
    }
}
export const NavMenuItem = (props: {
    page: "home" | "explore" | "appointments" | "favourites" | "profile",
    text: string,
    route: string,
    notificationBadge?: number,
}) => {
    const history = useHistory();
    const location = useLocation();

    function isActive(path: string): boolean {
        const pathIncluded = location.pathname.includes(path);
        switch (path) {
            case "/": return location.pathname === "/" || !pathIncluded;
            default: return pathIncluded;
        }
    }
    function classIfActive(path: string) {
        return isActive(path)
            ? "mw-20 border-3 border-top border-primary rounded-0 mx-1 "
            : "mw-20 mx-1 ";
    }
    function handleClick(path: string) {
        history.push(path);
    }
    return (<Button className={classIfActive(props.route)}
        color={isActive(props.route) ? "primary" : "default"}
        disableElevation={!isActive(props.route)}
        onClick={() => handleClick(props.route)}>
        <div>
            <Badge badgeContent={props.notificationBadge} color="primary" max={9}>
                <MenuIcon name={props.page} selected={isActive(props.route)} />
            </Badge>
            <Typography variant="body2" className="mt-1"
                style={{
                    fontSize: "xx-small",
                }}
                color={isActive(props.route) ? "primary" : "textSecondary"}
            >
                {props.text}</Typography>
        </div>
    </Button>);
}
export const NavMenu = (props: {
}) => {
    const { user } = useSelector((state: RootState) => state.user);
    return (
        <AppBar position="fixed" className="bee-appBar" color="inherit"
            style={{
                top: 'auto',
                bottom: 0,
                zIndex: 1000,
            }} >
            <Toolbar className={"p-0 mx-auto " + (user ? "forceRender1" : "forceRender2")}>
                <NavMenuItem page="home" text={L10N({ key: "home" })} route={Routes.home} />
                <NavMenuItem page="explore" text={L10N({ key: "explore" })} route={Routes.explore} />
                <NavMenuItem page="appointments" text={L10N({ key: "appointments" })} route={Routes.visitTab()} />
                <NavMenuItem page="favourites" text={L10N({ key: "favourites" })} route={Routes.favourites} />
                <NavMenuItem page="profile" text={L10N({ key: "profile" })} route={Routes.profilePage()}
                    notificationBadge={user?.notificationBadges?.numberOfVisitsToConfirm ?? 0}
                />
            </Toolbar>
        </AppBar>
    );
}
