import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ServiceDetailsDialog } from '../details/ServiceDetailsDialog';
import { CancelVisitDialog } from '../invite/CancelVisitDialog';

export const ModalDialogs = () => {
    const { cancelVisitDialogVisible, serviceDetailsDialogVisible } = useSelector((state: RootState) => state.ui);

    return (
        <>
            {cancelVisitDialogVisible && <CancelVisitDialog />}
            {serviceDetailsDialogVisible && <ServiceDetailsDialog />}
        </>
    );
};
