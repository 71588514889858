import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BackArrowHeader } from './BackArrowHeader';
import { L10N } from './L10N';

export const LegalNoticePage = (props: {}) => {

    return (<>
        {<Container>
            <Row className="my-3" />
            <BackArrowHeader title={L10N({ key: "Terms of Use" })} />
            <Row className="my-5">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/envelope.svg`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="mb-3">
                <Col>
                    <p><strong><span>Kontaktadresse</span></strong></p>
                    <p><span>E-Star Media Marc Krol<br />&nbsp;Am Sandfeld 18<br />&nbsp;76149 Karlsruhe<br />&nbsp;Deutschland<br />&nbsp;</span></p>
                    <p><span><a href="http://www.letsbookee.com"><span>www.letsbookee.com</span></a></span><span><br />&nbsp;E-Mail:&nbsp;</span><span><a href="mailto:info@letsbookee.com"><span>info@letsbookee.com</span></a></span></p>
                    <p><span>Zust&auml;ndiges Finanzamt: Finanzamt Pforzheim<br />&nbsp;USt-IdNr.: Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz: <br />&nbsp;DE 303940558</span><span>&nbsp;</span></p>
                    <p><span>&nbsp;</span></p>
                    <p><span>Plattform der EU-Kommission zur Online-Streitbeilegung:<a href="https://ec.europa.eu/consumers/odr"><span>&nbsp;https://ec.europa.eu/consumers/odr </span></a></span></p>
                    <p><span>Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.<br />&nbsp;<br />&nbsp;</span><span>Vertreten durch: Marc Krol und Mateusz Sawicki<br />&nbsp;</span><strong><span><br />&nbsp;Bankverbindung</span></strong></p>
                    <p><span>Kontoinhaber: E-Star Media Marc Krol</span><span><br /> <span>Kreditinstitut: Sparkasse Pforzheim</span><br /> <span>SWIFT: PZHSDE66&nbsp;</span></span></p>
                    <p><span>IBAN: DE18 6665 0085 0007 0353 81</span><span><br />&nbsp;</span></p>
                    <p><strong><span>Urheber- und Kennzeichenrecht</span></strong></p>
                    <p><span>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zur&uuml;ckzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte gesch&uuml;tzten Marken- und Warenzeichen unterliegen uneingeschr&auml;nkt den Bestimmungen des jeweils g&uuml;ltigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigent&uuml;mer. Allein aufgrund der blo&szlig;en Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter gesch&uuml;tzt sind! Das Copyright f&uuml;r ver&ouml;ffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielf&auml;ltigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdr&uuml;ckliche Zustimmung des Autors nicht gestattet.</span></p>
                    <p><strong><span>Kundendienst</span></strong></p>
                    <p><span>Sie erreichen unseren Kundendienst f&uuml;r Fragen Reklamationen und Beanstandungen unter Email unter&nbsp;</span><span><a href="mailto:info@letsbookee.com"><span>info@letsbookee.com</span></a></span><span>.</span><span><br /></span>
                        <span>F&uuml;r Anfragen k&ouml;nnen Sie auch das Kontaktformular auf unserer Internetseite nutzen.</span></p>
                    <p><strong><span><br />&nbsp;</span></strong><strong><span><br />&nbsp;</span></strong></p>
                    <p>&nbsp;</p>
                </Col>
            </Row>
        </Container >}
    </>
    );
};
