import { ThunkAction } from 'redux-thunk';
import { api } from '../../api/api';
import { VisitReviewUpdateMessage } from '../../api/models/Details';
import { AccessTokenBearer } from '../../api/models/Global';
import { PurchaseRequest } from '../../api/models/Purchase';
import { CreateVisitMessage, UpdateVisitMessage } from '../../api/models/Visit';
import { loadVisitAndAllDetails } from '../details/details.actions';
import { RootState } from '../store';
import { hideProgress } from '../ui/ui.actions';
import { UIActionTypes } from '../ui/ui.types';
import {
    ChangeVisitPayload,
    CHANGE_ALL_VISITS,
    CHANGE_VISIT,
    CHANGE_VISIT_RESPONSE,
    CreateVisitResponse,
    VisitActionTypes
} from './visit.types';
export type VisitAppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    VisitActionTypes | UIActionTypes
>
export const clearVisitResponse = (
): VisitAppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_VISIT_RESPONSE,
        payload: {
            createVisitResponse: undefined,
        }
    });
};
export const createVisit = (
    message: CreateVisitMessage
): VisitAppThunk<Promise<CreateVisitResponse | undefined>> => async dispatch => {
    await dispatch({
        type: CHANGE_VISIT,
        payload: {
            createVisitMessage: message,
            createVisitResponse: undefined,
            inProgress: true,
            currentVisit: undefined,
        }
    });
    const apiResponse = await api.Visit.createVisit(message);
    const createVisitResponse: CreateVisitResponse = {
        visitID: apiResponse.result?.visitID,
        validationMessage: apiResponse.error?.validation?.message,
    };
    const payload: ChangeVisitPayload = {
        createVisitMessage: message,
        createVisitResponse: createVisitResponse,
        inProgress: false,
        currentVisit: undefined,
    };
    if (apiResponse.error?.type === "validation") {
        createVisitResponse.shouldRedirectToVisitConfirmed = false;
        createVisitResponse.shouldRedirectToPayment = false;

    } else if (apiResponse.result?.paymentUpfront) {
        createVisitResponse.shouldRedirectToVisitConfirmed = false;
        createVisitResponse.shouldRedirectToPayment = true;
    } else {
        payload.currentVisit = apiResponse.result;
        createVisitResponse.shouldRedirectToVisitConfirmed = true;
        createVisitResponse.shouldRedirectToPayment = false;
    }
    await dispatch(hideProgress());
    await dispatch({
        type: CHANGE_VISIT,
        payload: payload,
    });
    if (apiResponse.result) {
        await dispatch(loadVisitAndAllDetails(
            message,
            apiResponse.result.visitID,
        ));
    }
    return createVisitResponse;
};
export const updateVisit = (
    message: UpdateVisitMessage
): VisitAppThunk<Promise<string | undefined>> => async dispatch => {
    await dispatch({
        type: CHANGE_VISIT,
        payload: {
            inProgress: true,
            currentVisit: undefined,
        }
    });
    const apiResponse = await api.Visit.updateVisit(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_VISIT,
        payload: {
            inProgress: false,
            currentVisit: apiResponse.result,
        }
    });
    if (apiResponse.result) {
        await dispatch(loadVisitAndAllDetails(
            message,
            apiResponse.result.visitID,
        ));
    }
    return apiResponse.result?.visitID;
};
export const payForVisit = (
    message: PurchaseRequest
): VisitAppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_VISIT,
        payload: {
            inProgress: true,
            purchaseResponse: undefined,
        }
    });
    const apiResponse = await api.Purchase.postPurchase(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_VISIT,
        payload: {
            inProgress: false,
            purchaseResponse: apiResponse.result,
        }
    });
};
export const loadAllVisits = (
    message: AccessTokenBearer
): VisitAppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            inProgress: true,
            allVisits: undefined,
            completedVisits: undefined,
            notCompletedVisits: undefined,
        }
    });
    const apiResponse = await api.Visit.getAllVisits(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            inProgress: false,
            allVisits: apiResponse.result,
            completedVisits: apiResponse.result?.filter((v, i, a) => v.visitCompleted),
            notCompletedVisits: apiResponse.result?.filter((v, i, a) => !v.visitCompleted),
        }
    });
};
export const loadAllVisitsAndReviews = (
    message: AccessTokenBearer
): VisitAppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            inProgress: true,
            completedVisits: undefined,
            notCompletedVisits: undefined,
            completedReviews: undefined,
            notCompletedReviews: undefined,
        }
    });
    const apiResponseVisits = await api.Visit.getAllVisits(message);
    const apiResponseReviews = await api.Visit.getAllReviews(message);
    await dispatch(hideProgress(apiResponseVisits.error ?? apiResponseReviews.error));
    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            inProgress: false,
            allVisits: apiResponseVisits.result,
            completedVisits: apiResponseVisits.result?.filter((v, i, a) => v.visitCompleted),
            notCompletedVisits: apiResponseVisits.result?.filter((v, i, a) => !v.visitCompleted),
            completedReviews: apiResponseReviews.result,
            notCompletedVisitReviews: apiResponseVisits.result?.filter((v, i, a) => v.visitCompleted && !v.visitReviewID),
        }
    });
};
export const selectReviewOrCreate = (
    message: AccessTokenBearer,
    visitID?: string, visitReviewID?: string
): VisitAppThunk => async (dispatch, getState) => {
    await dispatch(loadAllVisitsAndReviews(message));

    const state = getState().visit;

    const currentVisit = visitID ? state.completedVisits?.find(v => v.visitID === visitID) : undefined;
    const currentReviewByID = visitReviewID ? state.completedReviews?.find(v => v.visitReviewID === visitReviewID) : undefined;
    const currentReviewByVisitID = visitID ? state.completedReviews?.find(v => v.visitID === visitID) : undefined;
    const currentReview = currentReviewByID
        ?? currentReviewByVisitID
        ??
    {
        visitID: visitID,
        visitReviewID: visitReviewID,
        photos: currentVisit?.photos,
        ratingScore: 0,
        reviewTitle: "",
        reviewDetails: "",
    };
    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            ...state,
            currentReview: currentReview,
        }
    });
};
export const clearCurrentReview = (
): VisitAppThunk => async (dispatch, getState) => {
    const state = getState().visit;
    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            ...state,
            currentReview: undefined,
        }
    });
};
export const updateReview = (
    message: VisitReviewUpdateMessage
): VisitAppThunk => async (dispatch, getState) => {
    const state = getState().visit;

    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            inProgress: true,
        }
    });
    const apiResponse = await api.Visit.updateUserReview(message);
    await dispatch(hideProgress(apiResponse.error));

    await dispatch({
        type: CHANGE_ALL_VISITS,
        payload: {
            ...state,
            inProgress: false,
            currentReview: apiResponse.result,
        }
    });
};