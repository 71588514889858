import { IconButton, LinearProgress, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

export const SearchBar = (props: {
    handleClickMore?: () => void,
}) => {
    const dispatch = useDispatch();
    const { searchString, searchStringWithoutTags, searchResultsInProgress } = useSelector((state: RootState) => state.search);
    function onChange(value: string) {
        dispatch(changeSearchString(value));
    }
    function handleClickMore() {
        if (!props.handleClickMore) return;
        props.handleClickMore();
    }
    return (
        <>
            <Col>
                <TextField fullWidth variant="outlined"
                    placeholder={L10N({ key: "Search" })}                        
                    value={searchStringWithoutTags}
                    onChange={(event) => onChange(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <SearchIcon color={searchString ? "primary" : "disabled"} className="mr-1" />
                        ),
                        endAdornment: (<>
                            <IconButton onClick={() => onChange("")} disabled={!searchString} style={{ order: 1 }}>
                                <ClearIcon color="disabled" />
                            </IconButton>
                            {props.handleClickMore &&
                                <IconButton onClick={() => handleClickMore()} style={{ order: 1 }}>
                                    <MoreVertIcon color="disabled" />
                                </IconButton>
                            }
                        </>
                        )
                    }}
                />
                {searchResultsInProgress &&
                    <LinearProgress className="w-100 mt-n1" color="secondary" />
                }
            </Col>
        </>
    );
}
