import { createStyles, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { L10N } from "../pages/L10N";
import { Animations } from "../ui/Animations";
import { MarketingGradientShadow } from "./MarketingGradientShadow";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoColumn: {
            margin: '0px',
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
            "& img": {
                margin: '0 -60px',
                verticalAlign: 'bottom',
            },
        },
    }),
);

export const Marketing02_AboutApp = () => {
    const classes = useStyles();

    return (
        <>
            <MarketingGradientShadow />

            <Container style={{
                overflow:'hidden',
            }}
            >
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto"
                        style={{
                            backgroundImage: 'url(/img/marketing/app-circles.svg)',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '150px',
                        }}>
                        <Typography variant="h4" align="center"
                            className=""
                            style={{
                                fontWeight: 'bold',
                                marginTop: '32px',
                                marginLeft: '15px',
                            }}>
                            {L10N({ key: 'Warum Bookee?' })}
                        </Typography>
                    </Col>
                    <Col />
                </Row>
                <Row className="">
                    <Col xs={6} lg={3} className="m-0 p-0">
                        <Paper elevation={3} className="p-3 m-3">
                            <Typography variant="subtitle2">
                                {L10N({ key: '100% kostenlos für Besucher' })}
                            </Typography>
                        </Paper>
                    </Col>
                    <Col xs={6} lg={3} className="m-0 p-0">
                        <Paper elevation={3} className="p-3 m-3">
                            <Typography variant="subtitle2">
                                {L10N({ key: '24 / 7 / 365 Termine' })}
                            </Typography>
                        </Paper>
                    </Col>
                    <Col xs={6} lg={3} className="m-0 p-0">
                        <Paper elevation={3} className="p-3 m-3">
                            <Typography variant="subtitle2">
                                {L10N({ key: 'Unabhängige Flexibilität' })}
                            </Typography>
                        </Paper>
                    </Col>
                    <Col xs={6} lg={3} className="m-0 p-0">
                        <Paper elevation={3} className="p-3 m-3">
                            <Typography variant="subtitle2">
                                {L10N({ key: 'Direkt und zeitsparend' })}
                            </Typography>
                        </Paper>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={1} className={classes.photoColumn}>
                    </Col>
                    <Col xs={2} className={classes.photoColumn + " " + Animations.roll_in_left() + " " + Animations.delay(4)}>
                        <img src="/img/marketing/app-bee1.svg" alt="bee1" />
                    </Col>
                    <Col xs={2} className={classes.photoColumn + " " + Animations.roll_in_left() + " " + Animations.delay(8)}>
                        <img src="/img/marketing/app-bee2.svg" alt="bee2" />
                    </Col>
                    <Col xs={2} className={classes.photoColumn + " " + Animations.swirl_in_fwd() + " " + Animations.delay(12)}>
                        <img src="/img/marketing/app-bee3.svg" alt="bee3" />
                    </Col>
                    <Col xs={2} className={classes.photoColumn + " " + Animations.roll_in_right() + " " + Animations.delay(8)}>
                        <img src="/img/marketing/app-bee4.svg" alt="bee4" />
                    </Col>
                    <Col xs={2} className={classes.photoColumn + " " + Animations.roll_in_right() + " " + Animations.delay(4)}>
                        <img src="/img/marketing/app-bee5.svg" alt="bee5" />
                    </Col>
                    <Col xs={1} className={classes.photoColumn}>
                    </Col>
                </Row>
            </Container >
        </>
    );
};
