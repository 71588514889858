import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { GetKlarnaStatusRequest, GetKlarnaStatusResponse, PurchaseRequest, PurchaseResponse, TestKlarnaRequest } from "../models/Purchase";

export class PurchaseEndpoint {
    async postPurchase(message: PurchaseRequest): Promise<ApiResponse<PurchaseResponse>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/purchase`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as PurchaseResponse,
        });
        return apiResponse;
    }
    async testKlarna(message: TestKlarnaRequest): Promise<ApiResponse<boolean>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/testKlarna`,
            method: "POST",
            json: JSON.stringify(message),
            resultTransformer: async rawResponse => "true" === await rawResponse.text(),
        });
        return apiResponse;
    }
    async checkStatus(message: GetKlarnaStatusRequest): Promise<ApiResponse<GetKlarnaStatusResponse>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/checkStatus`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as GetKlarnaStatusResponse,
        });
        return apiResponse;
    }
}