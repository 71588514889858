import { Button, Checkbox, FormControlLabel, LinearProgress, TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { RequestCompanyCreateModel } from '../../api/models/Edit';
import { CreateCompanyAndReload } from '../../store/edit/edit.actions';
import { RootState } from '../../store/store';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { ButtonShadow } from '../ui/ButtonShadow';
import { L10N } from './L10N';
import { Routes } from './NavMenu';

export const CreateCompanyPage = () => {
    const urlParams = useParams<{ referral: string }>();
    const dispatch = useDispatch();
    const { user, inProgress } = useSelector((state: RootState) => state.user);
    const { editInProgress } = useSelector((state: RootState) => state.edit);
    const [referralCode, setReferralCode] = useState(urlParams?.referral ?? "");
    const [noReferralCodeCheck, setNoReferralCodeCheck] = useState(false);
    function onClick() {
        if (!user) return;

        const message: RequestCompanyCreateModel = {
            accessToken: user.accessToken,
            referralCode: referralCode,
        };
        dispatch(CreateCompanyAndReload(message));
    }
    const referralInUrl = urlParams && urlParams.referral && urlParams.referral !== null && urlParams.referral !== undefined;

    return (
        <>
            {!inProgress && !(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.createCompanyPage(referralCode))} />}
            {!inProgress && user?.companyID && <Redirect to={Routes.editPage(user.companyID)} />}
            <Container>
                <Row className="my-3" />
                <BackArrowHeader title={L10N({ key: 'Create Company' })} />
                {editInProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto">
                        <img src={`/img/envelope.svg`} alt="heart" width="150" />
                    </Col>
                    <Col />
                </Row>
                {!referralInUrl &&
                    <Row className="mt-3">
                        <Col md={3} />
                        <Col className="">
                            <Typography variant="body1" align="center">
                                {L10N({ key: "Please enter partner code below or check that you don't have it" })}
                            </Typography>
                        </Col>
                        <Col md={3} />
                    </Row>
                }
                <Row className="mt-5">
                    <Col md={3} />
                    <Col className="px-5">
                        <TextField variant="outlined" color="primary"
                            placeholder={L10N({ key: "Referral code" })}
                            label={L10N({ key: "Referral code" })}
                            value={referralCode}
                            onChange={event => setReferralCode(event.target.value)}
                            disabled={referralInUrl || noReferralCodeCheck}
                            fullWidth />
                    </Col>
                    <Col md={3} />
                </Row>
                {!referralInUrl &&
                    <Row className="my-3">
                        <Col md={3} />
                        <Col className="px-5">
                            <FormControlLabel
                                disabled={referralCode !== ""}
                                control={<Checkbox checked={noReferralCodeCheck} color="primary"
                                    onChange={event => setNoReferralCodeCheck(event.target.checked)}
                                />}
                                label={L10N({ key: "I don't have referral code" })}
                            />
                        </Col>
                        <Col md={3} />
                    </Row>
                }
                <Row className="my-5">
                    <Col md={3} />
                    <Col className="px-5">
                        <Button variant="contained" color="primary"
                            disabled={!noReferralCodeCheck && (!referralCode || referralCode === "")}
                            disableElevation
                            onClick={onClick}
                            fullWidth>
                            {L10N({ key: "Create Company - save" })}
                            <ButtonShadow />
                        </Button>
                    </Col>
                    <Col md={3} />
                </Row>
            </Container>
        </>
    );
};
