import { AccessTokenBearer, BeePhoto, PriceAndCurrency } from "./Global";

export interface IDetails {
    itemID: string,
    itemType: string,
    title: string,
    subtitle: string,
    description: string,
    htmlDescription: string,
    learnMoreURL: string,
    photos: BeePhoto[],
}
export interface CompanyDetails extends IDetails {
    ratingScore?: number,
    ratingVotes?: number,
    address: string,
    category?: string,
    mapCoords: MapCoords,
    serviceGroups: CompanyOfferServiceGroup[],
    resources: ResourceDetails[],
    portfolio: BeePhoto[],
    reviews: VisitReviewDetails[],
    isDemo: boolean,
}
export interface CompanyOfferServiceGroup {
    serviceGroupName: string,
    services: CompanyOfferService[],
}
export interface CompanyOfferService {
    offeredResourceID: string[],
    serviceID: string,
    serviceName: string,
    price: PriceAndCurrency,
    durationInMinutes: number,
    timeBeforeInMinutes: number,
    timeAfterInMinutes: number,
}
export interface VisitReviewDetails {
    userID: string,
    visitID: string,
    visitReviewID: string,
    reviewAuthor: string,
    reviewDate: string,
    ratingScore: number,
    reviewTitle: string,
    reviewDetails: string,
    photos: BeePhoto[],
}
export interface VisitReviewUpdateMessage extends VisitReviewDetails, AccessTokenBearer {
}
export interface ServiceDetails extends IDetails {
    offeredResourceID: string[],
}
export interface ResourceDetails extends IDetails {
    availability: ResourceAvailability[],
    contactDetails?: string,
}
export interface ResourceAvailability {
    date: string,
    workingHours: DateTimeRange[],
}
export interface DateTimeRange {
    from: string,
    to: string,
}
export interface MapCoords {
    lat: number,
    lng: number,
}
export const berlinMapCoords: MapCoords = { lat: 52.520008, lng: 13.404954 };
export interface UpdateCompanyMessage extends AccessTokenBearer {
    companyID: string,
    description: string,
}