import { MapCoords } from '../../api/models/Details';
import { ClientEditModel, CompanyEditModel, ResourceEditModel, ServiceEditModel } from '../../api/models/Edit';
import { VisitEditModel } from '../../api/models/EditVisit';
import {
    CHANGE_STATE,
    EditActionTypes
} from './edit.types';

export interface EditState {
    editInProgress: boolean,
    editRefreshInProgress: boolean,
    editedClient?: ClientEditModel,
    company?: CompanyEditModel,
    resources?: ResourceEditModel[],
    services?: ServiceEditModel[],
    clients?: ClientEditModel[],
    coords?: MapCoords[],
    visit?: VisitEditModel,
}
const initialEditState: EditState = {
    editInProgress: false,
    editRefreshInProgress: false,
    editedClient: undefined,
    company: undefined,
    resources: undefined,
    services: undefined,
    clients: undefined,
    coords: undefined,
    visit: undefined,
}
export function editReducer(
    state = initialEditState,
    action: EditActionTypes
): EditState {
    switch (action.type) {
        case CHANGE_STATE: return {
            ...state,
            ...action.payload,
        }
        default:
            return state
    }
}
