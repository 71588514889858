import { Action } from 'redux';
import { ApiError } from '../../api/ApiError';
import { RESET_REDUX } from '../store';

export const API_ACTION_HAS_STARTED = '[UI] API_ACTION_HAS_STARTED';
export const API_ACTION_HAS_FINISHED = '[UI] API_ACTION_HAS_FINISHED';
export const CHANGE_MODAL_ACTION = '[UI] CHANGE_MODAL_ACTION';

export interface ErrorDetails {
    message?: string;
    apiError?: ApiError;
    time_miliseconds: number;
}
interface ResetRedux extends Action {
    readonly type: typeof RESET_REDUX,
}
interface ApiActionHasStarted extends Action {
    readonly type: typeof API_ACTION_HAS_STARTED,
}
interface ApiActionHasFinished extends Action {
    readonly type: typeof API_ACTION_HAS_FINISHED,
    payload: {
        error: ErrorDetails | undefined;
    }
}
interface ChangeModalAction extends Action {
    readonly type: typeof CHANGE_MODAL_ACTION,
    payload: {
        cancelVisitDialogVisible?: boolean;
        serviceDetailsDialogVisible?: boolean;
    }
}
export type UIActionTypes = ApiActionHasStarted
    | ApiActionHasFinished
    | ChangeModalAction
    | ResetRedux;
