import { Avatar, Badge, Button, CardActionArea, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, useParams } from 'react-router-dom';
import { api } from '../../api/api';
import { RootState } from '../../store/store';
import { logout, refreshCurrentUser } from '../../store/user/user.actions';
import { Animations } from '../ui/Animations';
import { L10N } from './L10N';
import { Routes } from './NavMenu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userAvatar: {
            width: '70px',
            height: '70px',
            backgroundColor: theme.palette.primary.light,
        },
        menuButton: {
            borderColor: theme.palette.secondary.light,
            textTransform: 'none',
        },
        menuButtonIcon: {
            width: '16px',
            height: '16px',
        },
    }),
);
export const MenuButton = (props: {
    disabled?: boolean,
    inProgress?: boolean,
    text: string,
    onClick?: (tab?: ProfileTab) => void,
    destination?: ProfileTab,
    i: number,
    notificationBadge?: number,
}) => {
    const history = useHistory();
    const classes = useStyles();

    function handleClick() {
        if (props.onClick) {
            props.onClick(props.destination);
            return;
        } else {
            history.push(Routes.profilePage(props.destination));
        }
    }

    return (<Button disabled={props.disabled} variant="outlined"
        onClick={(event) => handleClick()}
        className={"m-1"
            + " " + classes.menuButton
            + " " + Animations.delay(props.i)
            + " " + Animations.slide_in_bottom()
        }
        endIcon={
            <Badge badgeContent={props.notificationBadge} color="primary"
                max={9}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                {!props.inProgress && <img alt="next" src="/img/icons/icon_next.svg" className={classes.menuButtonIcon} />}
            </Badge>
        }
        fullWidth>
        {props.inProgress && <CircularProgress />}
        {!props.inProgress &&
            <Typography variant="subtitle2" align="left" className="m-2 w-100" >
                {props.text}
            </Typography>}
    </Button>);
}
export type ProfileTab = ""
    | "default"
    | "createCompany"
    | "editCompany"
    | "clients"
    | "companyCalendar"
    | "resourceCalendar"
    | "recommend"
    | "statistics"
    | "account"
    | "reviews"
    | "terms"
    | "about"
    | "superAdmin"
    ;

export const ProfilePage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user, inProgress } = useSelector((state: RootState) => state.user);
    const urlParams = useParams<{ tab: ProfileTab }>();
    const classes = useStyles();
    useEffect(() => {
        if (inProgress) return;
        if (!user) return;
        dispatch(refreshCurrentUser(user));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function accountClick() {
        history.push(Routes.profilePage("account"));
    }
    function loginClick() {
        history.push(Routes.loginAndReturn(Routes.profilePage()));
    }
    async function logoutClick() {
        if (!user) return;
        await dispatch(logout(user));
    }
    function tabRequiresUser(): boolean {
        switch (urlParams.tab) {
            case "createCompany":
            case "editCompany":
            case "clients":
            case "companyCalendar":
            case "resourceCalendar":
            case "account":
            case "reviews":
            case "recommend":
            case "statistics":
                return true;
            default:
                return false;
        }
    }
    const authorized = (user?.clientAccessGranted ?? false);
    let i = 0;
    return (<>
        {tabRequiresUser() && !authorized && <Redirect to={Routes.loginAndReturn(Routes.profilePage())} />}
        <Container className="px-5"
            style={{ overflowY: 'hidden' }}
        >
            {urlParams?.tab === "default" && <>
                <CardActionArea onClick={accountClick}>
                    <Row className="my-5" >
                        <Col className="col-auto" >
                            <Avatar
                                src={api.Search.getFirstPhotoUrl(user?.photos)}
                                className={"m-1 " + classes.userAvatar}
                                alt={user?.firstName} />
                        </Col >
                        <Col className="col-auto align-items-center d-flex" >
                            <div className=" " >
                                <Typography variant="subtitle2">
                                    {user?.firstName}&nbsp;{user?.lastName}
                                </Typography>
                                <Typography >
                                    {user?.phone}
                                </Typography>
                            </div>
                        </Col >
                        <Col />
                    </Row>
                </CardActionArea>
                {authorized && user?.isSuperAdmin &&
                    <MenuButton i={i++} text="Super Admin" destination="superAdmin" />
                }
                {authorized && !user?.companyID &&
                    <MenuButton i={i++} text={L10N({ key: "Create Company" })} destination="createCompany" />
                }
                {authorized && user?.companyID &&
                    <MenuButton i={i++} text={L10N({ key: "Edit Company" })} destination="editCompany" />
                }
                {authorized && user?.companyID &&
                    <MenuButton i={i++} text={L10N({ key: "Company Calendar" })} destination="companyCalendar" notificationBadge={user?.notificationBadges?.numberOfVisitsToConfirmForCompany} />
                }
                {authorized && user?.resourceID &&
                    <MenuButton i={i++} text={L10N({ key: "My Calendar" })} destination="resourceCalendar" notificationBadge={user?.notificationBadges?.numberOfVisitsToConfirmForResource} />
                }
                {authorized && user?.companyID &&
                    <MenuButton i={i++} text={L10N({ key: "Clients" })} destination="clients" />
                }
                <MenuButton i={i++} text={L10N({ key: "Recommend Bookee" })} destination="recommend" />
                {authorized &&
                    <MenuButton i={i++} text={L10N({ key: "Statistics &amp; Reports", })} destination="statistics" />
                }
                {authorized &&
                    <MenuButton i={i++} text={L10N({ key: "Account details" })} destination="account" />
                }
                {authorized &&
                    <MenuButton i={i++} text={L10N({ key: "Reviews" })} destination="reviews" />
                }
                <MenuButton i={i++} text={L10N({ key: "Terms and conditions" })} destination="terms" />
                <MenuButton i={i++} text={L10N({ key: "About us" })} destination="about" />
                {authorized &&
                    <MenuButton i={i++} text={L10N({ key: "Logout" })} onClick={logoutClick} inProgress={inProgress} />
                }
                {!authorized &&
                    <MenuButton i={i++} text={L10N({ key: "Login" })} onClick={loginClick} inProgress={inProgress} />
                }
            </>}
            {urlParams?.tab !== "default" && <>
                <Typography variant="subtitle2">
                    {urlParams?.tab}
                </Typography>
            </>}
            {urlParams?.tab === "createCompany" && <Redirect to={Routes.createCompanyPage()} />}
            {urlParams?.tab === "editCompany" && <Redirect to={Routes.editPage(user?.companyID ?? "")} />}
            {urlParams?.tab === "clients" && <Redirect to={Routes.clientsTab()} />}
            {urlParams?.tab === "account" && <Redirect to={Routes.accountDetails} />}
            {urlParams?.tab === "terms" && <Redirect to={Routes.terms} />}
            {urlParams?.tab === "about" && <Redirect to={Routes.marketingPage("home")} />}
            {urlParams?.tab === "statistics" && <Redirect to={Routes.statisticsTab("visit")} />}
            {urlParams?.tab === "recommend" && <Redirect to={Routes.recommend} />}
            {urlParams?.tab === "reviews" && <Redirect to={Routes.visitReview} />}
            {urlParams?.tab === "companyCalendar" && user?.companyID && <Redirect to={Routes.calendarPage("company", "calendar")} />}
            {urlParams?.tab === "resourceCalendar" && user?.resourceID && <Redirect to={Routes.calendarPage("resource", "list")} />}
            {urlParams?.tab === "superAdmin" && <Redirect to={Routes.superAdminPage()} />}
        </Container>
    </>);
}
