import { Avatar, CardHeader, CircularProgress, IconButton, LinearProgress, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { api } from '../../api/api';
import { ClientEditModel } from '../../api/models/Edit';
import { loadClientsForEdit, UpdateCompanyClient } from '../../store/edit/edit.actions';
import { RootState } from '../../store/store';
import { ConfirmDialog } from '../ui/ConfirmDialog';
import { EmiCard } from '../ui/EmiCard';
import { TabsSwitch } from '../ui/TabsSwitch';
import { BackArrowHeader } from './BackArrowHeader';
import { L10N } from './L10N';
import { Routes } from './NavMenu';
import { PageHeader } from './PageHeader';

export const ClientCard = (props: {
    data: ClientEditModel,
    onLockConfirmed: () => Promise<void>,
}) => {
    const { editInProgress, editedClient } = useSelector((state: RootState) => state.edit);
    const [dialogState, setDialogState] = React.useState({
        dialogVisible: false,
        inProgress: false,
    });
    function onLockCancelled() {
        setDialogState({
            dialogVisible: false,
            inProgress: false,
        });
    }
    async function onLockConfirmed() {
        setDialogState({
            dialogVisible: true,
            inProgress: true,
        });
        await props.onLockConfirmed();
        setDialogState({
            dialogVisible: false,
            inProgress: false,
        });
    }
    function onLockClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        setDialogState({
            dialogVisible: true,
            inProgress: false,
        });
    }

    return <>
        <EmiCard
            elevation={1}
        >
            <Container>
                <ConfirmDialog title={L10N({ key: "Are you sure?" })}
                    contents={props.data.isBanned
                        ? L10N({ key: "Click OK to un-block this client." })
                        : L10N({ key: "Click OK to block this client from your company." })
                    }
                    isVisible={dialogState.dialogVisible}
                    inProgress={dialogState.inProgress}
                    onOK={onLockConfirmed}
                    onCancel={onLockCancelled}
                />
                <Row className="">
                    <Col className="p-0 align-self-center col-auto">
                        <Avatar alt={props.data.lastName ?? ""}
                            className="m-1"
                            src={api.Search.getFirstPhotoUrl(props.data.photos)} />
                    </Col>
                    <Col className="p-0 ">
                        <CardHeader
                            title={<>
                                <Typography variant="body1" align="left">
                                    {props.data.firstName}&nbsp;
                                        {props.data.lastName}&nbsp;
                                        <a href={`tel:${props.data.phone}`}>{props.data.phone}</a>
                                </Typography>
                            </>}
                        />
                    </Col>
                    <Col className="p-0 align-self-center col-auto">
                        <IconButton onClick={onLockClick}>
                            {props.data.userID === editedClient?.userID && editInProgress
                                ? <CircularProgress />
                                : <>
                                    {props.data.isBanned
                                        ? <LockIcon color="primary" />
                                        : <LockOpenIcon color="action" />
                                    }
                                </>
                            }
                        </IconButton>
                    </Col>
                </Row>
            </Container>
        </EmiCard>
    </>;
}
export const ClientsTab = (props: {
    data?: ClientEditModel[],
    onLockConfirmed: (card: ClientEditModel) => Promise<void>,
}) => {
    return <>
        {props.data && props.data.length > 0
            ?
            <>
                <Row className="mb-3">
                    {props.data?.map && props.data
                        ?.map((m, i) =>
                            <Col key={i} className="mb-3"
                                xs={12} md={6} >
                                <ClientCard data={m} onLockConfirmed={() => props.onLockConfirmed(m)} />
                            </Col>
                        )}
                </Row>
            </>
            :
            <>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <img src={`/img/envelope.svg`} alt="heart" width="150" />
                    </Col>
                    <Col />
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Typography align="center">
                            {L10N({ key: "No results found" })}
                        </Typography>
                    </Col>
                </Row>
                <PageHeader showLogo logoNavigatesToHome />
            </>
        }
    </>;
}
export const ClientsPage = (props: {}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user, inProgress } = useSelector((state: RootState) => state.user);
    const { editInProgress, clients } = useSelector((state: RootState) => state.edit);
    const [selectedTab, setSelectedTab] = useState(0);
    const urlParams = useParams<{ tab: "banned" | "all" }>();
    useEffect(() => {
        if (inProgress) return;
        if (!user) return;
        if (!user.companyID) return;

        dispatch(loadClientsForEdit(user, user.companyID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!urlParams.tab || urlParams.tab === "banned") {
            setSelectedTab(0);
        } else {
            setSelectedTab(1);
        }
    }, [urlParams, setSelectedTab]);
    function onTabChanged(value: number) {
        if (value === 0) {
            history.push(Routes.clientsTab("banned"));
        } else {
            history.push(Routes.clientsTab("all"));
        }
    }
    async function onLockConfirmed(card: ClientEditModel): Promise<void> {
        if (!user) return;
        if (!user?.accessToken) return;

        card.accessToken = user.accessToken;
        card.isBanned = !card.isBanned;
        await dispatch(UpdateCompanyClient(card));
    }
    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.clientsTab())} />}
        <Container>
            <Row className="my-3" />
            <BackArrowHeader title={L10N({ key: "Clients", })} />
            {editInProgress && <>
                <Row>
                    <Col xs={12}>
                        <LinearProgress className="w-100 mt-n1" color="secondary" />
                    </Col>
                </Row>
            </>}
            <Row className="my-3">
                <Col className="p-0" />
                <Col className="col-auto">
                    <TabsSwitch selectedTab={selectedTab} onTabChanged={onTabChanged}
                        tabs={[
                            L10N({ key: "All clients" }),
                            L10N({ key: "Banned clients" }),
                        ]}
                    />
                </Col>
                <Col className="p-0" />
            </Row>
            {selectedTab === 0 && <ClientsTab data={clients} onLockConfirmed={onLockConfirmed} />}
            {selectedTab === 1 && <ClientsTab data={clients?.filter(m => m.isBanned)} onLockConfirmed={onLockConfirmed} />}
        </Container >
    </>
    );
};
