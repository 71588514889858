import * as React from 'react';
export const MenuIcon = (props: {
    name: "home" | "explore" | "appointments" | "favourites" | "profile",
    selected?: boolean,
}) => {
    return <img src={props.selected
        ? `/img/menu/menu-${props.name}-sel.svg`
        : `/img/menu/menu-${props.name}.svg`}
        style={{
            maxWidth: '26px',
            maxHeight: '26px',
        }}
        alt={props.name} />
};
