import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { L10N } from '../pages/L10N';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '40px',
            maxHeight: '40px',
        },
        rootYAVU: {
            maxWidth: '60px',
            maxHeight: '60px',
        },
    })
);
export function CategoryIconURL(categoryName: string, white?: boolean): string {
    const isYAVU = categoryName.startsWith("_YAVU");
    const fileType = isYAVU
        ? ".png"
        : ".svg";
    const src = white
        ? `/img/categories/${categoryName}-1${fileType}`
        : `/img/categories/${categoryName}${fileType}`;

    return src;
}
export const CategoryIcon = (props: {
    name: string,
    white?: boolean,
    noSizeLimit?: boolean,
    className?: string,
}) => {
    const classes = useStyles();
    const isYAVU = props.name.startsWith("_YAVU");

    const rootClass = props.noSizeLimit ? ""
        : isYAVU ? classes.rootYAVU
            : classes.root;
    const src = CategoryIconURL(props.name, props.white);
    return <img src={src}
        className={rootClass + " " + props.className}
        alt={L10N({ key: "_category", cat: props.name })} />
};
