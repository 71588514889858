import { Avatar, Button, CardProps, createStyles, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { api } from '../../api/api';
import { VisitReviewDetails } from '../../api/models/Details';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { Routes } from '../pages/NavMenu';
import { EmiCard } from '../ui/EmiCard';
import { Rating } from '../ui/Rating';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            margin: '2px',
        },
        writeReviewButton: {
            textTransform: 'none',
            padding: '0px 15px',
            borderRadius: '15px',
        }
    })
);

export const VisitReviewItemCard = (props: CardProps & {
    data: VisitReviewDetails,
}) => {
    const history = useHistory();
    const { user } = useSelector((state: RootState) => state.user);
    const { data,
        ...cardProps } = props;
    const classes = useStyles();
    function editClick(event: React.MouseEvent<any, MouseEvent>) {
        if (!data) return;
        event.preventDefault();
        event.stopPropagation();
        const route = Routes.visitReviewEditPage(data.visitID, data.visitReviewID);
        history.push(route);
    }
    return (
        <>
            <EmiCard
                {...cardProps}
                elevation={1}
            >
                <Container>
                    <Row className="px-3 pt-3">
                        <Col className="pl-0 align-self-center col-auto" >
                            <Avatar
                                alt={data.reviewAuthor}
                                className={classes.avatar}
                                src={api.Search.getFirstPhotoUrl(data.photos)} />
                        </Col>
                        <Col className="p-0 align-self-center" >
                            <Typography variant="body1" align="left">
                                {data.reviewAuthor}
                            </Typography>
                            <Rating value={data.ratingScore} className="float-left mt-n1" />
                            <Typography variant="body1" align="left">
                                {L10N({ key: "_dateAsTimeAgo", date: data.reviewDate })}
                            </Typography>
                        </Col>
                    </Row>
                    <Row className="px-3 pb-3">
                        <Col className="p-0">
                            <Typography variant="body1" color="textPrimary" align="left" className="my-1">
                                {data.reviewTitle}
                            </Typography>
                            <Typography variant="body2" color="textPrimary" align="left">
                                {data.reviewDetails}
                            </Typography>
                        </Col>
                    </Row>
                    <Row className="pb-3">
                        <Col />
                        <Col className="col-auto" >
                            {user && props.data?.userID === user?.userID &&
                                <Button className={classes.writeReviewButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={editClick}>
                                    {L10N({ key: "Edit" })}
                                </Button>}
                        </Col>
                    </Row>
                </Container>
            </EmiCard>
        </>
    );
};
