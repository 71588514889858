import { CircularProgress, createStyles, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchCategory } from '../../api/models/Search';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CategoryIcon } from '../ui/CategoryIcon';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectItemImg: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
            width: '20px',
            height: '20px',
        },
        menuText: {
            marginLeft: '20px',
        },
    })
);
export const SearchCategorySelect = (props: {
}) => {
    const dispatch = useDispatch();
    const { searchString, searchCategories } = useSelector((state: RootState) => state.search);
    const classes = useStyles();

    function changeChip(currentSearchString: string, isChecked: boolean, chip: string): string {
        let newSearchString = currentSearchString;
        if (!isChecked) {
            const regEx = new RegExp(chip, "ig");
            newSearchString = newSearchString.replace(regEx, "");
        } else {
            newSearchString = newSearchString + " " + chip;
        }
        return newSearchString;
    }
    function currentChip(): SearchCategory | undefined {
        if (!searchCategories?.results) return undefined;

        for (var i = 0; i < searchCategories.results.length; i++) {
            const c = searchCategories.results[i];
            if (searchString.includes(c.encodedText)) {
                return c;
            }
        }
        return searchCategories.results[0];
    }
    function handleChange(encodedText: string) {
        if (!searchCategories) return;
        let newSearchString = searchString;
        for (var i = 0; i < searchCategories.results.length; i++) {
            const c = searchCategories.results[i];
            if (c.encodedText === encodedText) {
                newSearchString = changeChip(newSearchString, true, c.encodedText);
            } else {
                newSearchString = changeChip(newSearchString, false, c.encodedText);
            }
        }
        dispatch(changeSearchString(newSearchString));
    }
    return (<>
        {!searchCategories?.results && <CircularProgress />}
        {searchCategories?.results && <TextField variant="outlined" color="primary" fullWidth size="small"
            select
            value={currentChip()?.encodedText}
            onChange={(event) => handleChange(event.target.value)}
        >
            {searchCategories.results
                .map((m, i) =>
                    <MenuItem key={m.encodedText}
                        value={m.encodedText}  >
                        <CategoryIcon name={m.text} className={classes.selectItemImg} />
                        <Typography variant="body1" color={m.hasResults ? "textPrimary" : "textSecondary"} className={classes.menuText}>
                            {L10N({ key: "_category", cat: m.text })}
                        </Typography>
                    </MenuItem>)}
        </TextField>}
    </>);
}
