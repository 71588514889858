import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { berlinMapCoords, MapCoords } from "../models/Details";
import { AccessTokenBearer, BeePhoto } from "../models/Global";
import { SearchCategories, SearchHints, SearchResults } from "../models/Search";

export class SearchEndpoint {
    async getHints(query?: string): Promise<ApiResponse<SearchHints>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/hints?q=${query}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchHints,
        });
        return apiResponse;
    }
    async getExploreWhenHints(query?: string): Promise<ApiResponse<SearchHints>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/exploreWhenHints?q=${query}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchHints,
        });
        return apiResponse;
    }
    async getCategories(query?: string): Promise<ApiResponse<SearchCategories>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/categories?q=${query}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchCategories,
        });
        return apiResponse;
    }
    async getResults(query: string, currentLocation?: MapCoords): Promise<ApiResponse<SearchResults>> {
        const coords = currentLocation ?? berlinMapCoords;
        const apiResponse = await api.createRequest({
            endpoint: `search/results?q=${query}&lat=${coords?.lat ?? 0}&lng=${coords?.lng ?? 0}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchResults,
        });
        return apiResponse;
    }
    async getFavorites(message: AccessTokenBearer): Promise<ApiResponse<SearchResults>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/favorites`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as SearchResults,
        });
        return apiResponse;
    }
    async getFeaturedResults(query: string, currentLocation?: MapCoords): Promise<ApiResponse<SearchResults>> {
        const coords = currentLocation ?? berlinMapCoords;
        const apiResponse = await api.createRequest({
            endpoint: `search/featured?q=${query}&lat=${coords?.lat ?? 0}&lng=${coords?.lng ?? 0}`,
            method: "GET",
            resultJsonTransformer: (json) => json as SearchResults,
        });
        return apiResponse;
    }
    getPhotoUrl(photo: BeePhoto | undefined): string | undefined {
        if (!photo) return undefined;
        const apiRequest = {
            ignoreAuthenticate: true,
            endpoint: `search/images?ObjectID=${photo.objectID}&PhotoIndex=${photo.photoIndex}&VersionStamp=${photo.versionStamp}`,
            method: "GET",
        };
        return api.url + apiRequest.endpoint;
    }
    getFirstPhotoUrl(photos: BeePhoto[] | undefined): string | undefined {
        const placeholder = api.url + `search/images?ObjectID=${"881F7E9C-2EE2-4497-ADB3-763DAE4A7C9B"}&PhotoIndex=${0}&VersionStamp=${"2021-04-18"}`;

        if (!photos) return placeholder;
        if (photos.length === 0) return placeholder;
        const photo = photos[0];
        return this.getPhotoUrl(photo);
    }
}