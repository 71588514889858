import { Accordion, AccordionDetails, Avatar, Badge, CircularProgress, Fab, FormControlLabel, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import EventIcon from '@material-ui/icons/Event';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutlined';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { getIn } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { nameof } from '../../api/extensions';
import { VisitEditModel } from '../../api/models/EditVisit';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { useFormikResult } from '../ui/FormikAPI';
import moment from 'moment';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

export const FormVisitDetails = (props: {
    formik: useFormikResult<VisitEditModel>,
    onRefreshAvailableResources: () => Promise<void>,
}) => {
    const { editRefreshInProgress } = useSelector((state: RootState) => state.edit);
    const [pickerVisible, setPickerVisible] = useState(false);

    function clearAvailableResources() {
        props.formik.setFieldValue(nameof<VisitEditModel>("resourceID"), "00000000-0000-0000-0000-000000000000");
        props.formik.setFieldValue(nameof<VisitEditModel>("resourceName"), "");
        props.formik.setFieldValue(nameof<VisitEditModel>("availableResources"), []);
    }
    async function refreshAvailableResources() {
        await props.onRefreshAvailableResources();
    }
    return <>
        <form onSubmit={props.formik.handleSubmit} onSubmitCapture={() => console.log(props.formik.errors)}>
            <Container>
                <Row className="mb-1">
                    <Col >
                        <TextField className="mb-3" fullWidth
                            disabled
                            label={L10N({ key: "Visit ID" })}
                            value={props.formik.values.visitID ?? ""}
                        />
                    </Col>
                    <Col className="col-auto">
                        <TextField className="mb-3" fullWidth
                            disabled
                            label={L10N({ key: "Visit Status" })}
                            value={L10N({ key: "_status", status: props.formik.values.visitStatus })}
                        />
                    </Col>
                </Row>
                {props.formik.initialValues.visitStatus !== 'CanceledByCompany'
                    && <Row className="mb-3">
                        <Col >
                            <FormControlLabel control={
                                <Switch color="primary" size="medium"
                                    checked={props.formik.values.visitStatus === "CanceledByCompany"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.formik.setFieldValue(nameof<VisitEditModel>('visitStatus'), "CanceledByCompany");
                                        } else {
                                            props.formik.setFieldValue(nameof<VisitEditModel>('visitStatus'), props.formik.initialValues.visitStatus);
                                        }
                                    }}
                                />
                            }
                                label={L10N({ key: "Cancel Visit" })}
                            />
                        </Col>
                    </Row>
                }
                {props.formik.initialValues.visitStatus === 'Requested' &&
                    <>
                        <FormControlLabel control={
                            <Badge badgeContent={props.formik.values.visitStatus === "Confirmed" ? 0 : 1}
                                color="primary" max={9}>
                                <Switch color="primary" size="medium"
                                    checked={props.formik.values.visitStatus === "Confirmed"}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.formik.setFieldValue(nameof<VisitEditModel>('visitStatus'), "Confirmed");
                                        } else {
                                            props.formik.setFieldValue(nameof<VisitEditModel>('visitStatus'), props.formik.initialValues.visitStatus);
                                        }
                                    }}
                                />
                            </Badge>
                        }
                            label={L10N({ key: "Confirm the visit to client" })}
                        /><br /><br />
                    </>
                }
                <Row className="mb-3">
                    <Col className="pr-0">
                        <TextField variant="outlined" fullWidth
                            required
                            id="serviceName" name="serviceName" label={L10N({ key: "Service Name" })}
                            value={props.formik.values.serviceName ?? ""}
                            onChange={(e) => {
                                props.formik.setFieldValue(nameof<VisitEditModel>('serviceName'), e.target.value);
                                props.formik.setFieldValue(nameof<VisitEditModel>("serviceID"), "");
                                clearAvailableResources();
                            }}
                            error={props.formik.touched.serviceName && Boolean(props.formik.errors.serviceName)}
                            helperText={props.formik.touched.serviceName && props.formik.errors.serviceName}
                        />
                    </Col>
                    <Col className="col-auto pl-0">
                        <TextField variant="outlined" className=""
                            hidden={props.formik.values.availableServices.length === 0}
                            select
                            value={""}
                            onChange={(e) => {
                                const selectedServiceName = e.target.value;
                                const service = props.formik.values.availableServices.find(m => m.serviceName === selectedServiceName);
                                props.formik.setFieldValue(nameof<VisitEditModel>('serviceName'), service?.serviceName);
                                props.formik.setFieldValue(nameof<VisitEditModel>("serviceID"), service?.serviceID);
                                props.formik.setFieldValue(nameof<VisitEditModel>('priceAndCurrency'), service?.priceAndCurrency);
                                props.formik.setFieldValue(nameof<VisitEditModel>('durationInMinutes'), service?.durationInMinutes);
                                props.formik.setFieldValue(nameof<VisitEditModel>('timeAfterInMinutes'), service?.timeAfterInMinutes);
                                props.formik.setFieldValue(nameof<VisitEditModel>('timeBeforeInMinutes'), service?.timeBeforeInMinutes);
                                clearAvailableResources();
                            }}
                        >
                            {props.formik.values.availableServices.map((m, i) =>
                                <MenuItem key={`${props.formik.values.visitID}-${m.serviceID}`} value={m.serviceName} >
                                    <Container className="m-0 p-0">
                                        <Row>
                                            <Col className="col-auto pr-0">
                                                <Avatar variant="square"
                                                    alt={m.serviceName}
                                                    src={api.Search.getFirstPhotoUrl(m.photos)} />
                                            </Col>
                                            <Col className="pl-1">
                                                <Typography variant="body2" color="textPrimary">
                                                    {m.serviceName}
                                                </Typography>
                                                <Typography variant="body2" display="inline" color="textPrimary">
                                                    {L10N({ key: "_priceAndCurrency", price: m?.priceAndCurrency })}
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                </Typography>
                                                <Typography variant="body1" display="inline">
                                                    {L10N({
                                                        key: "_duration",
                                                        duration: (m?.durationInMinutes ?? 0),
                                                    })}
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </Container>
                                </MenuItem>)
                            }
                        </TextField>
                    </Col>
                </Row>
                <TextField variant="outlined" className="mb-3" fullWidth
                    required
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="priceAndCurrency.price" name="priceAndCurrency.price" label={L10N({ key: "Default Price" })}
                    value={props.formik.values.priceAndCurrency?.price ?? "0"}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.priceAndCurrency && Boolean(props.formik.errors.priceAndCurrency)}
                    helperText={props.formik.touched.priceAndCurrency && getIn(props.formik.errors, "priceAndCurrency.price")}
                    InputProps={
                        {
                            startAdornment: <InputAdornment position="start" >
                                <IconButton edge="start" onClick={() => {
                                    const price = +(props.formik.values.priceAndCurrency.price ?? "0") || 0;
                                    let newPrice = price - 5;
                                    if (newPrice < 0) newPrice = 0;
                                    props.formik.setFieldValue('priceAndCurrency.price', newPrice);
                                }} >
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end" >
                                {L10N({ key: "_currency", price: props.formik.values.priceAndCurrency })}

                                <IconButton edge="end" onClick={() => {
                                    const price = +(props.formik.values.priceAndCurrency.price ?? "0") || 0;
                                    let newPrice = price + 5;
                                    if (newPrice > 10000) return;
                                    props.formik.setFieldValue('priceAndCurrency.price', newPrice);
                                }} >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                        }
                    }
                />
                <TextField variant="outlined" fullWidth
                    required
                    id="visitStart" name="visitStart" label={L10N({ key: "Visit Start" })}
                    value={L10N({ key: '_dateAsDDMMMHHmm', date: props.formik.values.visitStart })}
                    error={props.formik.touched.visitStart && Boolean(props.formik.errors.visitStart)}
                    helperText={props.formik.touched.visitStart && props.formik.errors.visitStart}
                    InputProps={
                        {
                            endAdornment: <InputAdornment position="end" >
                                <IconButton edge="end" onClick={() => setPickerVisible(!pickerVisible)} >
                                    <EventIcon color={pickerVisible ? "primary" : undefined} />
                                </IconButton>
                            </InputAdornment>,
                        }
                    }
                />
                <br />
                {pickerVisible &&
                    <Accordion defaultExpanded={true} className={Animations.slide_in_elliptic_top_fwd()}>
                        <AccordionDetails className="d-block px-0">
                            <KeyboardDateTimePicker ampm={false} minutesStep={5}
                                variant="static"
                                disablePast
                                format="yyyy-MM-dd HH:mm"
                                value={
                                    new Date(props.formik.values.visitStart.replace(/-/g, "/"))
                                    /* hack for Safari on iPhone and Mac https://stackoverflow.com/questions/4310953/invalid-date-in-safari */
                                }
                                onChange={(date, value) => {
                                    if (date && isNaN(date.getTime())) return;
                                    if (!date) {
                                        props.formik.setFieldValue(nameof<VisitEditModel>('visitStart'), "");
                                        clearAvailableResources();
                                    } else {
                                        props.formik.setFieldValue(nameof<VisitEditModel>('visitStart'), value);
                                        clearAvailableResources();
                                    }
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                }
                <br />
                {Boolean(props.formik.values.timeBeforeInMinutes)
                    && Boolean((+(props.formik.values.timeBeforeInMinutes ?? "0")) > 0) === true
                    && <>
                        <TextField fullWidth className={Animations.slide_in_elliptic_top_fwd()}
                            label={L10N({ key: "Visit Start for Client" })}
                            value={L10N({
                                key: '_dateAsDDMMMHHmm',
                                date: moment(props.formik.values.visitStart).subtract(props.formik.values.timeBeforeInMinutes, "m").toISOString(),
                            })}
                        />
                        <br />
                        <br />
                    </>}
                <TextField variant="outlined" className="mb-3" fullWidth
                    id="timeBeforeInMinutes" name="timeBeforeInMinutes" label={L10N({ key: "Time before [minutes]" })}
                    value={props.formik.values.timeBeforeInMinutes ?? "0"}
                    onChange={(e) => {
                        props.formik.setFieldValue(nameof<VisitEditModel>('timeBeforeInMinutes'), e.target.value);
                        clearAvailableResources();
                    }}
                    error={props.formik.touched.timeBeforeInMinutes && Boolean(props.formik.errors.timeBeforeInMinutes)}
                    helperText={props.formik.touched.timeBeforeInMinutes && props.formik.errors.timeBeforeInMinutes}
                    InputProps={
                        {
                            startAdornment: <InputAdornment position="start" >
                                <IconButton edge="start" onClick={() => {
                                    const timeBeforeInMinutes = +(props.formik.values.timeBeforeInMinutes ?? "0") || 0;
                                    let newTimeBeforeInMinutes = timeBeforeInMinutes - 15;
                                    if (newTimeBeforeInMinutes < 0) newTimeBeforeInMinutes = 0;
                                    props.formik.setFieldValue(nameof<VisitEditModel>('timeBeforeInMinutes'), newTimeBeforeInMinutes);
                                    clearAvailableResources();
                                }} >
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end" >
                                <IconButton edge="end" onClick={() => {
                                    const timeBeforeInMinutes = +(props.formik.values.timeBeforeInMinutes ?? "0") || 0;
                                    let newTimeBeforeInMinutes = timeBeforeInMinutes + 15;
                                    if (newTimeBeforeInMinutes > 8 * 60) return;
                                    props.formik.setFieldValue(nameof<VisitEditModel>('timeBeforeInMinutes'), newTimeBeforeInMinutes);
                                    clearAvailableResources();
                                }} >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                        }
                    }
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    required
                    id="durationInMinutes" name="durationInMinutes" label={L10N({ key: "Duration [minutes]" })}
                    value={props.formik.values.durationInMinutes ?? "0"}
                    onChange={(e) => {
                        props.formik.setFieldValue(nameof<VisitEditModel>('durationInMinutes'), e.target.value);
                        clearAvailableResources();
                    }}
                    error={props.formik.touched.durationInMinutes && Boolean(props.formik.errors.durationInMinutes)}
                    helperText={props.formik.touched.durationInMinutes && props.formik.errors.durationInMinutes}
                    InputProps={
                        {
                            startAdornment: <InputAdornment position="start" >
                                <IconButton edge="start" onClick={() => {
                                    const duration = +(props.formik.values.durationInMinutes ?? "0") || 0;
                                    let newDuration = duration - 15;
                                    if (newDuration < 10) newDuration = 10;
                                    props.formik.setFieldValue(nameof<VisitEditModel>('durationInMinutes'), newDuration);
                                    clearAvailableResources();
                                }} >
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end" >
                                <IconButton edge="end" onClick={() => {
                                    const duration = +(props.formik.values.durationInMinutes ?? "0") || 0;
                                    let newDuration = duration + 15;
                                    if (newDuration > 8 * 60) return;
                                    props.formik.setFieldValue(nameof<VisitEditModel>('durationInMinutes'), newDuration);
                                    clearAvailableResources();
                                }} >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                        }
                    }
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    id="timeAfterInMinutes" name="timeAfterInMinutes" label={L10N({ key: "Time after [minutes]" })}
                    value={props.formik.values.timeAfterInMinutes ?? "0"}
                    onChange={(e) => {
                        props.formik.setFieldValue(nameof<VisitEditModel>('timeAfterInMinutes'), e.target.value);
                        clearAvailableResources();
                    }}
                    error={props.formik.touched.timeAfterInMinutes && Boolean(props.formik.errors.timeAfterInMinutes)}
                    helperText={props.formik.touched.timeAfterInMinutes && props.formik.errors.timeAfterInMinutes}
                    InputProps={
                        {
                            startAdornment: <InputAdornment position="start" >
                                <IconButton edge="start" onClick={() => {
                                    const timeAfterInMinutes = +(props.formik.values.timeAfterInMinutes ?? "0") || 0;
                                    let newTimeAfterInMinutes = timeAfterInMinutes - 15;
                                    if (newTimeAfterInMinutes < 0) newTimeAfterInMinutes = 0;
                                    props.formik.setFieldValue(nameof<VisitEditModel>('timeAfterInMinutes'), newTimeAfterInMinutes);
                                    clearAvailableResources();
                                }} >
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                            endAdornment: <InputAdornment position="end" >
                                <IconButton edge="end" onClick={() => {
                                    const timeAfterInMinutes = +(props.formik.values.timeAfterInMinutes ?? "0") || 0;
                                    let newTimeAfterInMinutes = timeAfterInMinutes + 15;
                                    if (newTimeAfterInMinutes > 8 * 60) return;
                                    props.formik.setFieldValue(nameof<VisitEditModel>('timeAfterInMinutes'), newTimeAfterInMinutes);
                                    clearAvailableResources();
                                }} >
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </InputAdornment>,
                        }
                    }
                /><br />
                <Row className="mb-3">
                    <Col className="pr-0">
                        <TextField variant="outlined" fullWidth
                            required
                            id="resourceName" name="resourceName" label={L10N({ key: "Resource Name" })}
                            value={props.formik.values.resourceName ?? ""}
                            error={props.formik.touched.resourceName && Boolean(props.formik.errors.resourceName)}
                            helperText={props.formik.touched.resourceName && props.formik.errors.resourceName}
                        />
                    </Col>
                    <Col className="col-auto pl-0">
                        {props.formik.values.availableResources?.length > 0
                            ?
                            <TextField variant="outlined" className=""
                                hidden={props.formik.values.availableResources.length === 0}
                                select
                                value={""}
                                onChange={(e) => {
                                    const selectedResourceName = e.target.value;
                                    const resource = props.formik.values.availableResources.find(m => m.resourceName === selectedResourceName);
                                    props.formik.setFieldValue(nameof<VisitEditModel>('resourceName'), resource?.resourceName);
                                    props.formik.setFieldValue(nameof<VisitEditModel>("resourceID"), resource?.resourceID);
                                }}
                            >
                                {props.formik.values.availableResources.map((m, i) =>
                                    <MenuItem key={`${props.formik.values.visitID}-${m.resourceID}`} value={m.resourceName} >
                                        <Container className="m-0 p-0">
                                            <Row>
                                                <Col className="col-auto pr-0">
                                                    <Switch color="primary" size="small" readOnly
                                                        checked={m.isAvailable} />
                                                </Col>
                                                <Col className="col-auto pr-0">
                                                    <Avatar
                                                        alt={m.resourceName}
                                                        src={api.Search.getFirstPhotoUrl(m.photos)} />
                                                </Col>
                                                <Col className="pl-1">
                                                    <Typography variant="body1" color={m.isAvailable ? "textPrimary" : "textSecondary"}>
                                                        {m.resourceName}
                                                    </Typography>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </MenuItem>)
                                }
                            </TextField>
                            : <>
                                <Fab onClick={refreshAvailableResources}
                                    color="primary"
                                    className="m-1" size="small"
                                    disabled={editRefreshInProgress} >
                                    {editRefreshInProgress && <CircularProgress color="secondary" />}
                                    {!editRefreshInProgress && <RefreshOutlinedIcon />}
                                </Fab>
                            </>
                        }
                    </Col>
                </Row>
                <TextField variant="outlined" className="mb-3" fullWidth
                    id="clientPhone" name="clientPhone" label={L10N({ key: "Client Phone" })}
                    value={props.formik.values.clientPhone ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.clientPhone && Boolean(props.formik.errors.clientPhone)}
                    helperText={props.formik.touched.clientPhone && props.formik.errors.clientPhone}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    required
                    id="clientName" name="clientName" label={L10N({ key: "Client Name" })}
                    value={props.formik.values.clientName ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.clientName && Boolean(props.formik.errors.clientName)}
                    helperText={props.formik.touched.clientName && props.formik.errors.clientName}
                /><br />
                <FormControlLabel control={<Switch color="primary" size="medium"
                    id="isClientBanned" name="isClientBanned"
                    checked={props.formik.values.isClientBanned ?? false}
                    onChange={props.formik.handleChange}
                />}
                    label={L10N({ key: "Block - the client will not be allowed to book a visit with your company" })}
                /><br />
                <FormControlLabel control={<Switch color="primary" size="medium"
                    id="paymentUpfront" name="paymentUpfront"
                    checked={props.formik.values.paymentUpfront ?? false}
                    onChange={props.formik.handleChange}
                />}
                    label={L10N({ key: "Upfront payment - the client has to pay upfront online while booking a visit" })}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    multiline rows={3}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="visitNotes" name="visitNotes" label={L10N({ key: "Notes:" })}
                    value={props.formik.values.visitNotes ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.visitNotes && Boolean(props.formik.errors.visitNotes)}
                    helperText={props.formik.touched.visitNotes && props.formik.errors.visitNotes}
                /><br />
            </Container>
        </form>
    </>;
}