import { CompanyDetails, ResourceDetails, ServiceDetails } from '../../api/models/Details';
import { ConfirmedVisit } from '../../api/models/Visit';
import {
    DetailsActionTypes
} from './details.types';

export interface DetailsState {
    inProgress: boolean;
    visit?: ConfirmedVisit;
    company?: CompanyDetails;
    service?: ServiceDetails;
    resource?: ResourceDetails;
}
const initialDetailsState: DetailsState = {
    inProgress: false,
    visit: undefined,
    company: undefined,
    service: undefined,
    resource: undefined,
}
export function detailsReducer(
    state = initialDetailsState,
    action: DetailsActionTypes
): DetailsState {
    switch (action.type) {
        default: return {
            ...state,
            ...action.payload,
        }
    }
}
