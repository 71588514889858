import { Avatar, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api/api';
import { InviteCompanyResource } from '../../api/models/Invite';
import { changeSelectedDimension } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            display: 'block',
            textTransform: 'none',
            outline: 'none !important',
        },
        avatar: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            margin: '2px',
        },
        avatarText: {
            maxWidth: theme.spacing(12),
        },
        halfTransparent: {
            opacity: '0.3',
        },
        horizontalList: {
            display: "flex",
            overflow: "scroll",
            scrollbarWidth: "none",
            paddingBottom: "6px",
        },
        divCenterParent: {
            float: 'left',
            position: 'relative',
            left: '50%',
        },
        divCenterChild: {
            float: 'left',
            position: 'relative',
            left: '-50%',
        }
    })
);
export const ResourceSelector = (props: {
    hideIfOne?: boolean,
}) => {
    const dispatch = useDispatch();
    const { currentInvite } = useSelector((state: RootState) => state.invite);
    const classes = useStyles();

    function isSelected(m: InviteCompanyResource): boolean {
        return m.dimensionValue === currentInvite?.companyResourceDimensionSelectedValue;
    }
    function onCompanyResourceClicked(m: InviteCompanyResource) {
        dispatch(changeSelectedDimension(m, "resource"));
    }
    return (<>
        {currentInvite?.companyResourceDimension?.map
            && (!props.hideIfOne || currentInvite?.companyResourceDimension?.length > 1)
            && currentInvite?.companyResourceDimension
            && <div className={classes.horizontalList + " noScroll"}>
                {currentInvite?.companyResourceDimension?.map && currentInvite?.companyResourceDimension
                    ?.map((m, i) =>
                        <Col key={i} className={"col-auto p-2"}>
                            <Button className={classes.btn}
                                onClick={() => onCompanyResourceClicked(m)}>
                                <div className={classes.divCenterParent}>
                                    <div className={classes.divCenterChild}>
                                        <div className={"float-left rounded-circle "
                                            + (isSelected(m) ? "bg-gradient-bee" : "")
                                            + (m.countOfOffers === 0 ? " " + classes.halfTransparent : "")}
                                        >
                                            <Avatar key={i}
                                                className={classes.avatar}
                                                src={api.Search.getPhotoUrl(m.photos[0])} alt={m.displayName} />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className={"float-left w-100 mt-1 " + classes.avatarText} >
                                    <Typography variant="body2" align="center"
                                        className={isSelected(m) ? "text-bee" : ""}>
                                        {m.displayName}
                                    </Typography>
                                </div>
                            </Button>
                        </Col>
                    )}
            </div>
        }
    </>);
};
