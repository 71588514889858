import { AnyAction, applyMiddleware, CombinedState, combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { L10N_current } from '../components/pages/L10N';
import { companyReducer, CompanyState } from './company/company.reducer';
import { detailsReducer, DetailsState } from './details/details.reducer';
import { editReducer, EditState } from './edit/edit.reducer';
import { inviteReducer, InviteState } from './invite/invite.reducer';
import { searchReducer, SearchState } from './search/search.reducer';
import { uiReducer, UIState } from './ui/ui.reducer';
import { userReducer, UserState } from './user/user.reducer';
import { visitReducer, VisitState } from './visit/visit.reducer';
export const RESET_REDUX = 'RESET_REDUX';

export interface RootState {
    company: CompanyState,
    invite: InviteState,
    search: SearchState,
    user: UserState,
    ui: UIState,
    details: DetailsState,
    visit: VisitState,
    edit: EditState,
}
const appReducer = combineReducers<RootState>({
    company: companyReducer,
    invite: inviteReducer,
    search: searchReducer,
    user: userReducer,
    ui: uiReducer,
    details: detailsReducer,
    visit: visitReducer,
    edit: editReducer,
});
const rootReducer = (state: CombinedState<RootState> | undefined, action: AnyAction) => {
    if (action.type === RESET_REDUX) {
        L10N_current.changeLocale(undefined);
        storage.removeItem('persist:root');
        state = undefined;
    }
    return appReducer(state, action);
};
const persistedReducer = persistReducer({ key: 'root', whitelist: ['user'], storage }, rootReducer)
export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

