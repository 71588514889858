import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { OfferEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';

export const OffersCheckboxCard = (props: {
    model: OfferEditModel[],
    showFromServicePerspective?: boolean,
    onToggleChecked: (item: OfferEditModel, index: number) => void,
}) => {
    return <Container >
        {props.model.length > 0
            ? props.model.map((m, i) =>
                <Row key={m.resourceID + " " + m.serviceID}>
                    <Col className="col-auto">
                        <FormControlLabel
                            control={<Checkbox checked={m.isAvailable} color="primary"
                                onChange={() => props.onToggleChecked(m, i)} />}
                            label={props.showFromServicePerspective
                                ? m.resourceDisplayName
                                : m.serviceDisplayName}
                        />
                    </Col>
                </Row>
            )
            :
            <Row >
                <Col className="">
                    <Typography >
                        {props.showFromServicePerspective
                            ? L10N({ key: "Add some resources to configure your offer" })
                            : L10N({ key: "Add some services to configure your offer" })
                        }
                    </Typography>
                </Col>
            </Row>
        }
    </Container>;
}