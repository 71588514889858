import { CardActionArea, CardHeader, CardMedia, CardProps, createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { ToBackgroundPosition } from '../../api/models/Global';
import { SearchResult } from '../../api/models/Search';
import { L10N } from '../pages/L10N';
import { CategoryIcon } from '../ui/CategoryIcon';
import { EmiCard } from '../ui/EmiCard';
import { FavoriteIcon } from '../ui/FavoriteIcon';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rating: {
            position: 'absolute',
            right: '10px',
            marginRight: '10px',
            bottom: '5px',
            zIndex: 10,
            background: theme.palette.primary.dark,
            padding: '1px 7px',
            borderRadius: theme.spacing(1.5),
            opacity: '0.75',
            height: theme.spacing(3),
            '& p': {
                display: 'inline-block',
                verticalAlign: 'text-top',
            },
            '& img': {
                marginRight: theme.spacing(1),
                display: 'inline-block',
                verticalAlign: 'unset',
            },
        },
        demo: {
            position: 'absolute',
            left: '5px',
            marginRight: '10px',
            top: '5px',
            zIndex: 10,
            background: theme.palette.primary.light,
            padding: '1px 7px',
            borderRadius: theme.spacing(1.5),
            opacity: '0.35',
            height: theme.spacing(3),
            '& p': {
                display: 'inline-block',
                verticalAlign: 'text-top',
            },
        },
        imgPlaceholder: {
            '& .MuiCardMedia-root': {
            },
            '& h6': {
                position: 'absolute',
                top: '0px',
                width: '100%',
                padding: '20px 20px',
            },
        },
        imgPlaceholderIcon: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '60px',
            height: '60px',
            maxWidth: '60px !important',
            maxHeight: '60px !important',
            marginLeft: '-30px',
            marginTop: '-30px',
        },
        fav: {
            margin: '16px 8px 0px 0px !important',
        },
        cardHeaderCol: {
            minHeight: '85px',
        },
        cardHeader: {
            padding: '10px 0px 10px 10px',
            marginRight: '0px',
            zIndex: 1,
        },
        cardContainer: {
            minHeight: 100,
        },
    })
);
export const SearchCard = (props: CardProps & {
    data: SearchResult,
    onCardClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, card: SearchResult) => void,
    showText?: boolean,
    showImage?: boolean,
    inProgress?: boolean,
    index: number,
}) => {

    const classes = useStyles();
    const {
        data,
        onCardClick,
        showText,
        showImage,
        inProgress,
        index,
        ...cardProps } = props;
    function getBgPosition(): "bottom" | "center" | "top" {
        if (!data.photos) return "top";
        if (data.photos.length === 0) return "top";
        var first = data.photos[0];
        var result = ToBackgroundPosition(first.position);
        return result;
    }
    return (
        <>
            <EmiCard {...cardProps}
                elevation={1}
                onClick={(event) => props.onCardClick(event, data)}
            >
                <CardActionArea>
                    {(data?.photos?.length ?? 0) === 0
                        ? <div className={classes.imgPlaceholder}>
                            <CardMedia component="img" height="160"
                                image={'/img/bee-bg.svg'}
                                title={data.title}
                            />
                            <CategoryIcon name={data.subtitle} className={classes.imgPlaceholderIcon} white />
                        </div>
                        : props.showImage && <CardMedia title={data.title} >
                            <div style={{
                                background: `url(${api.Search.getFirstPhotoUrl(data.photos)}`,
                                backgroundPositionX: 'center',
                                backgroundPositionY: getBgPosition(),
                                backgroundSize: 'cover',
                                height: 160,
                            }}
                            />
                        </CardMedia>
                    }
                    {data?.ratingScore && <div className={classes.rating}>
                        <img alt="star" src={`/img/icons/icon_star_empty.svg`} height="16" width="20" />
                        <Typography variant="body1" className="text-white">
                            {data.ratingScore}
                        </Typography>
                    </div>}
                    {data?.isDemo &&
                        <div className={classes.demo}>
                            <Typography variant="body1" className="text-white">
                                demo
                            </Typography>
                        </div>
                    }
                </CardActionArea>
                <Container className={classes.cardContainer}>
                    <Row>
                        <Col className={"p-0 align-self-start " + classes.cardHeaderCol} >
                            <CardHeader className={classes.cardHeader}
                                title={<CardActionArea>
                                    <Typography variant="body1">
                                        {data.title}
                                    </Typography>
                                    <Typography variant="body2" >
                                        {L10N({ key: "_category", cat: data.subtitle })}
                                    </Typography>
                                    {data.addressLine && data.addressLine.length > 0 &&
                                        <div>
                                            <Typography variant="body2" >
                                                {data.addressLine.map((m, i) => <span key={m}>
                                                    {m}<br />
                                                </span>)}
                                            </Typography>
                                        </div>
                                    }
                                </CardActionArea>}
                            />
                        </Col>
                        <Col className="col-auto p-0">
                            <FavoriteIcon companyID={data.companyID} className={classes.fav} />
                        </Col>
                    </Row>
                </Container>
                {props.inProgress && <LinearProgress color="primary" />}

                {props.showText &&
                    <CardActionArea>
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {data.details}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                }
            </EmiCard>
        </>
    );
};
