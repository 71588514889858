import { CircularProgress } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export const FabProgress = (props: {
}) => {
    const { progressVisible } = useSelector((state: RootState) => state.ui);

    return (
        <>
            {progressVisible &&
                <Fab className="m-1">
                    <CircularProgress />
                </Fab>
            }
        </>
    )
}
