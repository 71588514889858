import { Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

export const Summary = (props: {
}) => {
    const { currentInvite } = useSelector((state: RootState) => state.invite);

    function currentService(): string {
        if (!currentInvite) return "";
        const result = currentInvite
            ?.companyServiceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyServiceDimensionSelectedValue);
        return result?.displayName ?? "";
    }
    function currentResource(): string {
        if (!currentInvite) return "";
        const result = currentInvite
            ?.companyResourceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.companyResourceDimensionSelectedValue);
        return result?.displayName ?? "";
    }
    function currentPrice(): string {
        if (!currentInvite) return "";
        const result = currentInvite
            ?.priceDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.priceDimensionSelectedValue);

        return L10N({
            key: '_priceAndCurrency',
            price: result,
        });
    }
    function currentDate(): string {
        if (!currentInvite) return "";
        const result = currentInvite
            ?.dateDimension
            ?.find((v, i, a) => v.dimensionValue === currentInvite?.dateDimensionSelectedValue);

        return L10N({
            key: '_dateAsOn',
            date: result?.start,
        });
    }
    return (<>
        <Row className="mt-3 mx-1">
            <Col className="col-auto px-1">
                <Typography variant="subtitle2" >
                    {currentService()}
                </Typography>
            </Col>
            <Col className="m-0 p-0" />
            <Col className="col-auto px-1" >
                <Typography variant="subtitle2" >
                    {currentPrice()}
                </Typography>
            </Col>
        </Row>
        <Row className="mx-1">
            <Col className="col-auto px-1">
                <Typography>
                    {currentResource()}
                </Typography>
            </Col>
            <Col />
            <Col className="col-auto px-1">
                <Typography>
                    {currentDate()}&nbsp;
                    {currentInvite?.timeDimensionSelectedValue &&
                        moment(currentInvite?.timeDimensionSelectedValue, "HH:mm").utc(true).local().format("HH:mm")}
                </Typography>
            </Col>
        </Row>
    </>);
};
