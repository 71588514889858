import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { L10N } from '../pages/L10N';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '16px',
        },
    })
);
export const ConfirmDialog = (props: {
    isVisible: boolean,
    inProgress: boolean,
    onOK: () => Promise<void>,
    onCancel: () => void,
    title: string,
    contents: string,
}) => {
    const classes = useStyles();

    function handleCancel() {
        props.onCancel();
    }
    async function handleOK(): Promise<void>{
        await props.onOK();
    }
    return (
        <>
            <Dialog
                open={props.isVisible}
                onClose={handleCancel}>
                <DialogTitle>
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.contents}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOK}
                        className={classes.btn}
                        color="primary" variant="outlined">
                        {props.inProgress
                            ? <CircularProgress />
                            : L10N({ key: "OK" })
                        }
                    </Button>
                    <Button onClick={handleCancel}
                        className={classes.btn}
                        color="primary" autoFocus variant="contained">
                        {L10N({ key: "Cancel" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
