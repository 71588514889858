import { Action } from 'redux';
import { MapCoords } from '../../api/models/Details';
import { SearchCategories, SearchHints, SearchResults } from '../../api/models/Search';

export const CHANGE_SEARCH_STRING = '[SEARCH] CHANGE_SEARCH_STRING';
export const CHANGE_SEARCH_IN_PROGRESS = '[SEARCH] CHANGE_SEARCH_IN_PROGRESS';
export const CHANGE_SEARCH_HINTS = '[SEARCH] CHANGE_SEARCH_HINTS';
export const CHANGE_FEATURED_IN_PROGRESS = '[SEARCH] CHANGE_FEATURED_IN_PROGRESS';
export const CHANGE_FAVORITES_IN_PROGRESS = '[SEARCH] CHANGE_FAVORITES_IN_PROGRESS';

interface ChangeSearchStringAction extends Action {
    type: typeof CHANGE_SEARCH_STRING,
    payload: {
        searchString: string;
        searchStringWithoutTags: string;
        currentLocation?: MapCoords;
    }
}
interface ChangeSearchInProgressAction extends Action {
    type: typeof CHANGE_SEARCH_IN_PROGRESS,
    payload: {
        searchResultsInProgress: boolean;
        searchResults: SearchResults;
    }
}
interface ChangeFeaturedInProgressAction extends Action {
    type: typeof CHANGE_FEATURED_IN_PROGRESS,
    payload: {
        featuredResultsInProgress: boolean;
        featuredResults: SearchResults;
    }
}
interface ChangeFavoritesInProgressAction extends Action {
    type: typeof CHANGE_FAVORITES_IN_PROGRESS,
    payload: {
        favoriteResultsInProgress: boolean;
        favoriteResults: SearchResults;
    }
}
interface ChangeSearchHintsAction extends Action {
    type: typeof CHANGE_SEARCH_HINTS,
    payload: {
        searchHintsInProgress: boolean;
        searchHints: SearchHints;
        searchCategories: SearchCategories;
        exploreWhenHints: SearchHints;
    }
}
export type SearchActionTypes =
    ChangeSearchStringAction
    | ChangeSearchInProgressAction
    | ChangeSearchHintsAction
    | ChangeFeaturedInProgressAction
    | ChangeFavoritesInProgressAction
    ;
