import { Button, CircularProgress, createStyles, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { api } from "../../api/api";
import { nameof } from '../../api/extensions';
import { RootState } from '../../store/store';
import { updateUserProfile } from '../../store/user/user.actions';
import { AvatarUpload } from "../ui/AvatarUpload";
import { ButtonShadow } from "../ui/ButtonShadow";
import { LanguageSelect } from "../ui/LanguageSelect";
import { extractCountry, extractInCountryPhone, PhoneCountrySelect } from "../ui/PhoneCountrySelect";
import { BackArrowHeader } from "./BackArrowHeader";
import { L10N } from "./L10N";
import { Routes } from './NavMenu';

export interface AccountDetailsPageState {
    avatarBase64?: string,
    avatarImgSrc: string,
    firstName: string,
    lastName: string,
    language: string,
    phoneCountry: string,
    phone: string,
    email: string,
    editMode: boolean,
    showValidation: boolean,
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userAvatar: {
            width: '130px',
            height: '130px',
            backgroundColor: theme.palette.primary.light,
        },
        btn: {
            textTransform: 'none',
            borderRadius: '8px',
            height: theme.spacing(8),
        },
        btnEditAvatar: {
            width: '40px',
            height: '40px',
            margin: '0 ',
            padding: '0',
            zIndex: 10,
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    })
);
export const AccountDetailsPage = (props: {}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { user, inProgress, apiError } = useSelector((state: RootState) => state.user);
    const [state, setState] = useState<AccountDetailsPageState>({
        avatarBase64: undefined,
        avatarImgSrc: "",
        firstName: "",
        lastName: "",
        language: "",
        phoneCountry: "",
        phone: "",
        email: "",
        editMode: false,
        showValidation: false,
    });
    useEffect(() => {
        if (inProgress) return;

        setState((state: AccountDetailsPageState) => {
            return {
                ...state,
                avatarBase64: undefined,
                avatarImgSrc: api.Search.getFirstPhotoUrl(user?.photos) ?? state.avatarImgSrc,
                phoneCountry: extractCountry(user?.phone ?? state.phone) ?? state.phoneCountry,
                phone: user?.phone.substr(3) ?? state.phone,
                firstName: user?.firstName ?? state.firstName,
                lastName: user?.lastName ?? state.lastName,
                language: user?.language ?? state.language,
                email: user?.email ?? state.email,
            };
        });

    }, [user, inProgress]);
    async function saveAccountClick(event?: React.MouseEvent<any, MouseEvent>) {
        setState({ ...state, showValidation: true });
        if (!user) return;
        if (!state.firstName) return;
        if (!state.lastName) return;
        user.avatarBase64ToUpload = state.avatarBase64;
        user.firstName = state.firstName;
        user.lastName = state.lastName;
        user.language = state.language;
        dispatch(updateUserProfile(user));
        setState({ ...state, showValidation: false });
    }
    function validationMessageIfNeeded(fieldName?: keyof AccountDetailsPageState): string | undefined {
        if (!state.showValidation && !apiError) return undefined;

        if (fieldName === nameof<AccountDetailsPageState>("firstName")) {
            if (!state.firstName) return L10N({ key: "cannot be empty" });
        }
        if (fieldName === nameof<AccountDetailsPageState>("lastName")) {
            if (!state.lastName) return L10N({ key: "cannot be empty" });
        }

        if (apiError?.type === "validation") return apiError?.validation?.message;

        return apiError?.message ?? undefined;
    }
    function onPhoneChanged(newPhone: string) {
        const country = extractCountry(newPhone);
        const inCountryPhone = extractInCountryPhone(newPhone);
        if (country && inCountryPhone) {
            setState({
                ...state,
                phoneCountry: country,
                phone: inCountryPhone,
            });
        } else {
            setState({
                ...state,
                phone: newPhone,
            });
        }
    }
    async function submitOnEnter(event: React.KeyboardEvent<HTMLDivElement>, submitFunction: (event?: React.MouseEvent<any, MouseEvent>) => Promise<void>) {
        if (event.keyCode === 13) {
            await submitFunction();
            event.preventDefault();
            event.stopPropagation();
        }
    }
    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.accountDetails)} />}
        {<Container>
            <Row className="">
                <Col xs={0} md={2} lg={3} xl={4} />
                <Col xs={12} md={8} lg={6} xl={4}>
                    <Container>
                        <Row className="my-3" />
                        <BackArrowHeader title={state.editMode
                            ? L10N({ key: "Edit Profile" })
                            : L10N({ key: "Account Details" })} />
                        <Row className="mt-5">
                            <Col />
                            <Col className="col-auto">
                                <AvatarUpload src={state.avatarBase64 ?? state.avatarImgSrc}
                                    editMode={state.editMode}
                                    onChange={(base64) => setState({
                                        ...state,
                                        avatarBase64: base64,
                                    })}
                                    inProgress={inProgress}
                                    alt={state.firstName} />
                            </Col>
                            <Col />
                        </Row>
                        <Row className="my-3">
                            <Col xs={12}>
                                <TextField fullWidth variant="outlined" color="primary"
                                    disabled={!state.editMode}
                                    placeholder={L10N({ key: "First Name" })}
                                    label={L10N({ key: "First Name" })}
                                    helperText={validationMessageIfNeeded("firstName")}
                                    value={state.firstName}
                                    onChange={(event) => setState({
                                        ...state,
                                        firstName: event.target.value,
                                    })}
                                    autoComplete="given-name"
                                />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs={12}>
                                <TextField fullWidth variant="outlined" color="primary"
                                    disabled={!state.editMode}
                                    placeholder={L10N({ key: "Last Name" })}
                                    label={L10N({ key: "Last Name" })}
                                    helperText={validationMessageIfNeeded("lastName")}
                                    value={state.lastName}
                                    onChange={(event) => setState({
                                        ...state,
                                        lastName: event.target.value,
                                    })}
                                    autoComplete="family-name"
                                />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs={12}>
                                <LanguageSelect fullWidth
                                    disabled={!state.editMode} 
                                    lang={state.language}
                                    onChange={(value) => setState({
                                        ...state,
                                        language: value,
                                    })}
                                />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col className="pr-1 col-auto" >
                                <PhoneCountrySelect
                                    disabled
                                    phoneCountry={state.phoneCountry}
                                    onChange={(value) => setState({
                                        ...state,
                                        phoneCountry: value,
                                    })}
                                />
                            </Col>
                            <Col className="pl-1">
                                <TextField fullWidth variant="outlined"
                                    placeholder={L10N({ key: "Phone number" })}
                                    label={L10N({ key: "Phone number" })}
                                    helperText={validationMessageIfNeeded("phone")}
                                    value={state.phone}
                                    disabled
                                    onChange={(event) => onPhoneChanged(event.target.value)}
                                    type="tel"
                                    autoComplete="tel"
                                    onKeyUp={event => submitOnEnter(event, saveAccountClick)}
                                    autoFocus
                                />
                            </Col>
                            {state.editMode && <Col xs={12}>
                                <Typography variant="body2">
                                    {L10N({ key: "Please contact us to change your phone number" })}
                                </Typography>
                            </Col>}
                        </Row>
                        <Row className="">
                            <Col>
                                {state.editMode ?
                                    <Button onClick={saveAccountClick}
                                        className={classes.btn}
                                        disableElevation
                                        variant="contained" color="primary" fullWidth
                                        disabled={inProgress || !state.editMode}>
                                        {inProgress && <CircularProgress color="secondary" />}
                                        {!inProgress && L10N({ key: "Save"})}
                                        <ButtonShadow />
                                    </Button>
                                    :
                                    <Button onClick={(event) => setState({
                                        ...state,
                                        editMode: true,
                                    })}
                                        className={classes.btn}
                                        disableElevation
                                        variant="contained" color="primary" fullWidth
                                        disabled={inProgress}>
                                        {inProgress && <CircularProgress color="secondary" />}
                                        {!inProgress && L10N({ key: "Edit Profile"})}
                                        <ButtonShadow />
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={0} md={2} lg={3} xl={4} />
            </Row>
        </Container >}
    </>
    );
};
