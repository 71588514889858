import { CircularProgress, createStyles, FormControl, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchHint } from '../../api/models/Search';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectMenu: {
            '& ul li img': {
                display: 'none',
            }
        },
        selectItemImg: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
            width: '20px',
            height: '20px',
        },
        menuText: {
            marginLeft: '20px',
        },
    })
);
export const SearchWhenSelect = (props: {
}) => {
    const dispatch = useDispatch();
    const { searchString, exploreWhenHints } = useSelector((state: RootState) => state.search);
    const classes = useStyles();

    function changeChip(currentSearchString: string, isChecked: boolean, chip: string): string {
        let newSearchString = currentSearchString;
        if (!isChecked) {
            const regEx = new RegExp(chip, "ig");
            newSearchString = newSearchString.replace(regEx, "");
        } else {
            newSearchString = newSearchString + " " + chip;
        }
        return newSearchString;
    }
    function currentChip(): SearchHint | undefined {
        if (!exploreWhenHints?.results) return undefined;

        for (var i = 0; i < exploreWhenHints.results.length; i++) {
            const c = exploreWhenHints.results[i];
            if (searchString.includes(c.encodedText)) {
                return c;
            }
        }
        return exploreWhenHints.results[0];
    }
    function handleChange(encodedText: string) {
        if (!exploreWhenHints) return;
        let newSearchString = searchString;
        for (var i = 0; i < exploreWhenHints.results.length; i++) {
            const c = exploreWhenHints.results[i];
            if (c.encodedText === encodedText) {
                newSearchString = changeChip(newSearchString, true, c.encodedText);
            } else {
                newSearchString = changeChip(newSearchString, false, c.encodedText);
            }
        }
        dispatch(changeSearchString(newSearchString));
    }
    return (<>
        {!exploreWhenHints?.results && <CircularProgress />}
        {exploreWhenHints?.results && <FormControl variant="outlined" size="small" color="primary" fullWidth>
            <Select
                value={currentChip()?.encodedText}
                onChange={(event) => handleChange("" + event.target.value)}
                MenuProps={{
                    className: classes.selectMenu,
                }}
            >
                {exploreWhenHints.results
                    .map((m, i) =>
                        <MenuItem key={m.encodedText}
                            value={m.encodedText} className="" >
                            <img src="/img/icons/icon_calendar_bee.svg" alt="calendar"
                                width="30" className={classes.selectItemImg} />
                            <Typography variant="body1" color="textPrimary" className={classes.menuText}>
                                {moment.isDate(m.hintText) && L10N({ key: "_dateAsDD", date: m.hintText })}
                                {!moment.isDate(m.hintText) && L10N({ key: "_hint", hint: m.hintText })}
                            </Typography>
                        </MenuItem>)}
            </Select>
        </FormControl>}
    </>);
};
