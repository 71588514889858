import { Fab, Typography } from '@material-ui/core';
import React from "react";
import { SearchCategory } from '../../api/models/Search';
import { L10N } from '../pages/L10N';
import { CategoryIcon } from '../ui/CategoryIcon';

export interface SearchCategoryChipProps {
    data: SearchCategory,
    isChecked?: boolean,
    onChange?: (isChecked: boolean) => void,
    showText?: boolean,
}
export const SearchCategoryChip = (props: SearchCategoryChipProps) => {

    function onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (props.onChange) {
            props.onChange(!props.isChecked);
        }
        event.stopPropagation();
    }
    return (
        <div>
            <Fab size="large"
                style={{
                    width: '67px',
                    height: '67px',
                }}
                className={"my-2 mx-2"}
                color={props.isChecked ? "primary" : "secondary"}
                onPointerDown={props.isChecked !== null ? onClick : undefined}
                title={L10N({ key: "_category", cat: props.data.text })}
            >
                <CategoryIcon name={props.data.text} white={props.isChecked} />
            </Fab>
            {props.showText &&
                <Typography variant="body2" align="center" color={props.data.hasResults ? "textPrimary" : "textSecondary"}>
                    {L10N({ key: "_category", cat: props.data.text })}
                </Typography>
            }
        </div>
    );
};
