import { Avatar, Typography } from '@material-ui/core';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { api } from '../../api/api';
import { CalendarEntry, CalendarSlot } from "../../api/models/Company";
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { Animations } from '../ui/Animations';
import { CalendarVisitListItemCard } from './CalendarVisitListItemCard';

export const CalendarVisitList = (props: {
    handleDeleteClick: (slot: CalendarSlot) => Promise<void>,
    selectedResourceDimension?: string,
    onReloadRequired: () => Promise<void>,
}) => {
    const { calendar } = useSelector((state: RootState) => state.company);
    function renderHeader(m: CalendarEntry): JSX.Element {
        if (!calendar) return <></>;
        const dateDim = calendar?.dateDimension.find((v, i, a) => v.dimensionValue === m.dimensionsValues[2]);
        const resDim = calendar?.resourceDimension.find((v, i, a) => v.resourceID === m.dimensionsValues[1]);
        if (!dateDim || !resDim) return <></>;
        return <>
            <Typography align="center"
                variant="body2"
                className={dateDim.isSatOrSun ? "text-secondary" : ""} >
                {L10N({ key: "_dateAsDayOrTomorrow", date: dateDim.date })}<br />
                {L10N({ key: "_dateAsOn", date: dateDim.date })}
            </Typography>
            <Avatar className={"mx-auto " + Animations.slide_in_blurred_left()}
                alt={resDim.displayName}
                src={api.Search.getPhotoUrl(resDim.photo)} />
        </>
    }
    const items = calendar?.entries?.map && calendar?.entries
        ?.filter((v, i, a) => !props.selectedResourceDimension || v.dimensionsValues[1] === props.selectedResourceDimension)
        ?.filter((v, i, a) => v.visitSlots?.length > 0);
    return (
        <>
            <Row >
                <Col xs={false} md={4} />
                <Col xs={12} md={4}>
                    {items?.length === 0
                        ? <Container>
                            <Row className="mb-3">
                                <Col />
                                <Col className="col-auto">
                                    <img src={`/img/envelope.svg`} alt="heart" width="150" />
                                </Col>
                                <Col />
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Typography align="center">
                                        {L10N({ key: "No results found" })}
                                    </Typography>
                                </Col>
                            </Row>
                        </Container>
                        : items
                            ?.map((m, i) =>
                                <div className="mb-3" key={m.dimensionsValues.join("|") + "I=" + i}>
                                    {renderHeader(m)}
                                    {m?.visitSlots?.map && m?.visitSlots
                                        ?.map((slot, index) => <React.Fragment key={m.dimensionsValues.join("|") + "ID=" + slot.visitID}>
                                            <CalendarVisitListItemCard data={slot}
                                                className="mb-1"
                                                minHeight={undefined}
                                                canCollapse={false}
                                                onCardDeleteClick={async () => await props.handleDeleteClick(slot)}
                                                onReloadRequired={props.onReloadRequired}
                                            />
                                        </React.Fragment>)}
                                </div>
                            )}
                </Col>
                <Col xs={false} md={4} />
            </Row>
        </>
    );
};
