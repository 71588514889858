import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from 'react';
import { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { L10N } from "../pages/L10N";
import { Animations } from "../ui/Animations";
import { TabsSwitch } from "../ui/TabsSwitch";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoColumn: {
            margin: '0px',
            padding: '0px',
            display: 'flex',
            justifyContent: 'center',
            "& img": {
                margin: '0 0px',
                verticalAlign: 'bottom',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            },
        },
        photoColumnProvider: {
            margin: '0px',
            padding: '4px',
            display: 'flex',
            justifyContent: 'center',
            "& img": {
                margin: '0 0px',
                verticalAlign: 'bottom',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            },
        },
        switch: {
        },
        feature: {
            background: 'url(/img/marketing/features-bg.svg)',
            borderRadius: '8px',
            padding: '16px',
            margin: '16px',
            color: 'white',
        },
        doneRight: {
            position: 'absolute',
            right: '-15px',
            marginTop: '10px',
        },
        doneLeft: {
            position: 'absolute',
            left: '-15px',
            marginTop: '10px',
        },
        circles: {
            position: 'absolute',
            left: '-15px',
            marginTop: '500px',
        },
    }),
);
export const Marketing05_Screenshots = () => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);

    return (
        <>
            <div className="bg-gradient-bee-diagonal"
                style={{
                    zIndex: 1,
                    position: 'relative',
                    marginTop: '0px',
                }}>
                <Container >
                    <Row className="mb-3">
                        <Col />
                        <Col className="col-auto">
                            <Typography variant="h4" align="center"
                                className=""
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginTop: '32px',
                                    marginLeft: '15px',
                                }}>
                                {L10N({ key: 'Screenshots' })}
                            </Typography>
                        </Col>
                        <Col />
                    </Row>
                    <Row className="my-5">
                        <Col className="m-0 p-0" />
                        <Col className="col-auto">
                            <TabsSwitch tabs={[
                                L10N({ key: "Besucher" }),
                                L10N({ key: "Dienstleister" })
                            ]}
                                selectedTab={tab}
                                onTabChanged={setTab}
                                groupClassName={classes.switch}
                            />
                        </Col>
                        <Col className="m-0 p-0" />
                    </Row>
                    {tab === 0 && <Row className="mt-3" style={{
                        height: 450,
                        maxHeight: 450,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        overflowX: 'auto',
                    }}>
                        <Col xs={6} md={3} className={classes.photoColumn + " " + Animations.roll_in_left() + " " + Animations.delay(4)}>
                            <img src="/img/marketing/screenshots-bee1.svg" alt="bee1" />
                        </Col>
                        <Col xs={6} md={3} className={classes.photoColumn + " " + Animations.roll_in_left() + " " + Animations.delay(8)}>
                            <img src="/img/marketing/screenshots-bee2.svg" alt="bee2" />
                        </Col>
                        <Col xs={6} md={3} className={classes.photoColumn + " " + Animations.swirl_in_fwd() + " " + Animations.delay(12)}>
                            <img src="/img/marketing/screenshots-bee3.svg" alt="bee3" />
                        </Col>
                        <Col xs={6} md={3} className={classes.photoColumn + " " + Animations.roll_in_right() + " " + Animations.delay(8)}>
                            <img src="/img/marketing/screenshots-bee4.svg" alt="bee4" />
                        </Col>
                        <Col xs={6} md={3} className={classes.photoColumn + " " + Animations.roll_in_right() + " " + Animations.delay(4)}>
                            <img src="/img/marketing/screenshots-bee5.svg" alt="bee5" />
                        </Col>
                    </Row>
                    }
                    {tab === 1 && <Row className="mt-3" style={{
                        height: 450,
                        maxHeight: 450,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        overflowX: 'auto',
                    }}>
                        <Col xs={6} md={3} className={classes.photoColumnProvider + " " + Animations.roll_in_left() + " " + Animations.delay(4)}>
                            <img src="/img/marketing/screenshots-beeProvider1.svg" alt="bee1" />
                        </Col>
                        <Col xs={8} md={4} className={classes.photoColumnProvider + " " + Animations.roll_in_left() + " " + Animations.delay(8)}>
                            <img src="/img/marketing/screenshots-beeProvider2.svg" alt="bee2" />
                        </Col>
                        <Col xs={10} md={5} className={classes.photoColumnProvider + " " + Animations.swirl_in_fwd() + " " + Animations.delay(12)}>
                            <img src="/img/marketing/screenshots-beeProvider3.svg" alt="bee3" />
                        </Col>
                    </Row>
                    }
                </Container >
            </div>
        </>
    );
};
