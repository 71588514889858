import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { CreateNextDateTimeRange, PhotoEditModel, ResourceEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { AvatarUpload } from '../ui/AvatarUpload';
import { useFormikResult } from '../ui/FormikAPI';
import { AppBarSubmitCancel } from './AppBarSubmitCancel';
import { OffersCheckboxCard } from './OffersCheckboxCard';
import { WorkingHoursListCard } from './WorkingHoursListCard';

export const FormResourceDetails = (props: {
    formik: useFormikResult<ResourceEditModel>,
}) => {

    return <>
        <form onSubmit={props.formik.handleSubmit} onSubmitCapture={() => console.log(props.formik.errors)}>
            <Container>
                <Row>
                    {props.formik.values.photos?.length > 0
                        ? props.formik.values.photos.map((m, i) =>
                            <Col key={m.photoIndex} className="col-auto">
                                <AvatarUpload
                                    variant="circular"
                                    src={props.formik.values.photos?.[i]?.base64 ?? api.Search.getPhotoUrl(m) ?? ""}
                                    editMode
                                    onChange={(base64) => props.formik.setFieldValue(`photos[${i}].base64`, base64)}
                                    fileId={"" + i}
                                    alt={props.formik.values.description ?? ""} />
                            </Col>
                        )
                        :
                        <Col className="col-auto">
                            <AvatarUpload
                                variant="rounded"
                                src=""
                                addMode
                                onChange={(base64) => props.formik.setFieldValue(`photos[0]`, {
                                    base64: base64,
                                    accessToken: "",
                                    objectID: props.formik.values.resourceID,
                                    photoIndex: 0,
                                    versionStamp: moment().toISOString(),
                                    isDeleted: false,
                                    position: 'Default',
                                } as PhotoEditModel)}
                                fileId={"new"}
                                alt={props.formik.values.description ?? ""} />
                        </Col>
                    }
                </Row>
                <TextField className="mb-3" fullWidth
                    disabled
                    id="resourceID" name="resourceID" label={L10N({ key: "Resource ID" })}
                    value={props.formik.values.resourceID}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.resourceID && Boolean(props.formik.errors.resourceID)}
                    helperText={props.formik.touched.resourceID && props.formik.errors.resourceID}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    required
                    id="displayName" name="displayName" label={L10N({ key: "Display Name" })}
                    value={props.formik.values.displayName ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.displayName && Boolean(props.formik.errors.displayName)}
                    helperText={props.formik.touched.displayName && props.formik.errors.displayName}
                /><br />
                <Row>
                    <Col xs={12} md={6}>
                        <TextField variant="outlined" className="mb-3" fullWidth
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            id="firstName" name="firstName" label={L10N({ key: "First Name" })}
                            value={props.formik.values.firstName ?? ""}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.firstName && Boolean(props.formik.errors.firstName)}
                            helperText={props.formik.touched.firstName && props.formik.errors.firstName}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <TextField variant="outlined" className="mb-3" fullWidth
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            id="lastName" name="lastName" label={L10N({ key: "Last Name" })}
                            value={props.formik.values.lastName ?? ""}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.lastName && Boolean(props.formik.errors.lastName)}
                            helperText={props.formik.touched.lastName && props.formik.errors.lastName}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <TextField variant="outlined" className="mb-3" fullWidth
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            id="title" name="title" label={L10N({ key: "Title" })}
                            value={props.formik.values.title ?? ""}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.title && Boolean(props.formik.errors.title)}
                            helperText={props.formik.touched.title && props.formik.errors.title}
                        />       </Col>
                    <Col xs={12} md={6}>
                        <TextField variant="outlined" className="mb-3" fullWidth
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            id="subtitle" name="subtitle" label={L10N({ key: "Subtitle" })}
                            value={props.formik.values.subtitle ?? ""}
                            onChange={props.formik.handleChange}
                            error={props.formik.touched.subtitle && Boolean(props.formik.errors.subtitle)}
                            helperText={props.formik.touched.subtitle && props.formik.errors.subtitle}
                        />
                    </Col>
                </Row>
                <TextField variant="outlined" className="mb-3" fullWidth
                    multiline rows={5}
                    id="description" name="description" label={L10N({ key: "Description" })}
                    value={props.formik.values.description ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.description && Boolean(props.formik.errors.description)}
                    helperText={props.formik.touched.description && props.formik.errors.description}
                /><br />
                <TextField variant="outlined" className="mb-3" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="contactDetails" name="contactDetails" label={L10N({ key: "Resource edit: Phone number" })}
                    value={props.formik.values.contactDetails ?? ""}
                    onChange={props.formik.handleChange}
                    error={props.formik.touched.contactDetails && Boolean(props.formik.errors.contactDetails)}
                    helperText={props.formik.touched.contactDetails && props.formik.errors.contactDetails}
                /><br />
                <Row className="mb-3">
                    <Col>
                        <Accordion defaultExpanded={false}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                className="text-black text-weight-bold2">
                                {L10N({ key: "Working hours" })}
                            </AccordionSummary>
                            <AccordionDetails className="d-block">
                                <Container className="p-0 m-0">
                                    <Row>
                                        {props.formik.values.workingHours?.map && props.formik.values.workingHours.map((m, i) =>
                                            <Col key={m.dayOfWeek} sm={12} md={6} xl={6} className="m-0 p-0 p-md-2">
                                                <WorkingHoursListCard formik={props.formik}
                                                    cardIndex={i} model={m}
                                                    fieldNamePrefix={(index, suffix) => `workingHours[${i}].dayWorkingHours[${index}]${suffix}`}
                                                    onAdd={() => props.formik.setFieldValue(
                                                        `workingHours[${i}].dayWorkingHours[${m.dayWorkingHours?.length ?? 0}]`,
                                                        CreateNextDateTimeRange(m)
                                                    )}
                                                    onDelete={(model, index) => props.formik.setFieldValue(
                                                        `workingHours[${i}].dayWorkingHours`,
                                                        m.dayWorkingHours.filter((mFilter, iFilter) => iFilter !== index)
                                                    )}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Container>
                            </AccordionDetails>
                        </Accordion>
                    </Col>
                </Row>
                {props.formik.values.offers?.length > 0 &&
                    <Row>
                        <Col>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    className="text-black text-weight-bold2">
                                    {L10N({ key: "Services" })}
                                </AccordionSummary>
                                <AccordionDetails className="d-block">
                                    <Container className="p-0 m-0">
                                        <Row>
                                            <Col >
                                                <OffersCheckboxCard model={props.formik.values.offers}
                                                    onToggleChecked={(m, i) => props.formik.setFieldValue(`offers[${i}].isAvailable`, !m.isAvailable)}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                }
                {props.formik.values.resourceID && props.formik.values.resourceID !== "" &&
                    <Row>
                        <Col>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    className="text-black text-weight-bold2">
                                    {L10N({ key: "Delete" })}
                                </AccordionSummary>
                                <AccordionDetails className="d-block">
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={props.formik.values.isDeleted ?? false} color="primary"
                                                id="isDeleted" name="isDeleted"
                                                onChange={props.formik.handleChange}
                                            />
                                        }
                                        label={L10N({ key: "Mark for deletion" })}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>
                }
            </Container>
            {props.formik.dirty && <AppBarSubmitCancel onCancel={() => props.formik.resetForm()} formik={props.formik} />}
        </form>
    </>;
}