import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CreateInviteMessage } from '../../api/models/Invite';
import { SearchResult } from '../../api/models/Search';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { Routes } from '../pages/NavMenu';
import { Animations } from '../ui/Animations';
import { ScreenSizeListener } from '../ui/ScreenSizeListener';
import { SearchCard } from './SearchCard';

export const SearchTableRow = (props: {
    rowIndex: number,
    row: SearchResult[],
    colCount: number,
    inviteInProgress: boolean,
    createInviteMessage?: CreateInviteMessage,
    handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, item: SearchResult) => void,

}) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const threshold = 0.1;
    const rootMargin = "0px";
    const observer = React.useRef<IntersectionObserver | undefined>();

    React.useEffect(() => {
        if (observer?.current) {
            observer.current.disconnect();
            observer.current = undefined;
        }
        if (isVisible) return;

        const observerCached = observer.current;
        observer.current = new IntersectionObserver(
            (entries, observer) => {
                if (entries.find(m => m.isIntersecting)) {
                    observer.disconnect();
                    if (!isVisible && ref.current) {
                        setIsVisible(true);
                    }
                }
            },
            {
                rootMargin,
                threshold
            });
        if (ref.current) {
            observer.current.observe(ref.current);
        }
        return () => {
            if (observer?.current) {
                observer.current.disconnect();
                observer.current = undefined;
            }
            if (observerCached) {
                observerCached.disconnect();
            }
        };
    }, [isVisible]);

    return <Row
        className="mb-3"
        style={{ minHeight: 100 }}
    >
        <div ref={ref} />
        {props.row.map((col, colIndex) =>
            <Col key={"_" + colIndex + "_" + col.offeredResourceID.join("_")}
                className="w-100"
            >
                <SearchCard
                    className={isVisible
                        ? Animations.swing_in_top_fwd()
                        : ""}
                    data={col}
                    index={colIndex}
                    showImage={isVisible || props.rowIndex <= 2}
                    inProgress={props.inviteInProgress && props.createInviteMessage?.offeredResourceID === col.offeredResourceID}
                    onCardClick={props.handleClick} />
            </Col>)}
    </Row>;
}

export const SearchTable = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { searchResults } = useSelector((state: RootState) => state.search);
    const { inviteInProgress, createInviteMessage } = useSelector((state: RootState) => state.invite);
    const [cols, setCols] = useState(4);
    const [groupedResults, setGroupedResults] = useState<{
        grouped: SearchResult[][],
        sourceTimeStamp: string,
        cols: number,
    }>();

    async function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>, item: SearchResult) {
        if (event.isPropagationStopped()) return;
        if (event.isDefaultPrevented()) return;

        await dispatch(changeInvite({
            query: searchResults?.query ?? "",
            offeredResourceID: item.offeredResourceID,
        }));
        history.push(Routes.invite);
        event.stopPropagation();
    }
    useEffect(() => {
        if (!searchResults?.results) {
            setGroupedResults(undefined);
            return;
        }
        if (searchResults.timeStamp === groupedResults?.sourceTimeStamp
            && cols === groupedResults?.cols) {
            return;
        }

        const results = searchResults?.results.reduce((groups, curr, currIndex) => {
            const key = Math.floor(currIndex / cols);
            while (groups.length <= key) { groups.push([]) };
            groups[key].push(curr);
            return groups;
        }, new Array<SearchResult[]>());
        setGroupedResults({
            grouped: results,
            sourceTimeStamp: searchResults.timeStamp,
            cols: cols,
        });

    }, [searchResults, cols, setGroupedResults, groupedResults]);

    function onSizeChange(size: Breakpoint | "xxs") {
        let newCols = 4;
        switch (size) {
            case 'xxs': newCols = 1; break;
            case 'xs': newCols = 1; break;
            case 'sm': newCols = 2; break;
            case 'md': newCols = 3; break;
            case 'lg': newCols = 4; break;
            case 'xl':
            default: newCols = 4; break;
        }
        if (cols !== newCols) {
            setCols(newCols);
        }
    }
    return (
        <><Col>
            <Container>
                <ScreenSizeListener onSizeChange={onSizeChange} />
                {groupedResults?.grouped && groupedResults.grouped.map((row, rowIndex) =>
                    <SearchTableRow key={"_" + rowIndex + "_" + row.map(m => m.companyID).join("_")}
                        colCount={cols}
                        row={row}
                        rowIndex={rowIndex}
                        inviteInProgress={inviteInProgress}
                        createInviteMessage={createInviteMessage}
                        handleClick={handleClick}
                    />)}
            </Container>
        </Col>
        </>
    );
}
