import { Avatar, CircularProgress, createStyles, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import { default as React } from "react";
import Resizer from "react-image-file-resizer";
import { PhotoPositionEnum, ToObjectPosition } from "../../api/models/Global";
import { Rotator } from "./ImageResizer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        userAvatar: {
            alignSelf: 'center',
            width: '130px',
            height: '130px',
            backgroundColor: theme.palette.primary.light,
        },
        btnEditAvatarLabel: {
            display: 'flex',
            alignSelf: 'center',
            marginTop: '-20px',
        },
        btnEditAvatar: {
            width: '40px',
            height: '40px',
            margin: '0',
            padding: '0',
            border: 'solid 1px',
            borderColor: theme.palette.primary.light,
            zIndex: 10,
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        btnRotateAvatar: {
            width: '40px',
            height: '40px',
            padding: '0',
            backgroundColor: theme.palette.background.paper,
            border: 'solid 1px',
            borderColor: theme.palette.primary.light,
            zIndex: 10,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    })
);
export const AvatarUpload = (props: {
    src: string,
    editMode?: boolean,
    addMode?: boolean,
    deleteMode?: boolean,
    positionMode?: boolean,
    position?: PhotoPositionEnum,
    inProgress?: boolean,
    alt: string,
    onChange?: (base64?: string, position?: PhotoPositionEnum) => void,
    onDelete?: () => void,
    fileId?: string,
    variant?: 'circular' | 'rounded' | 'square' | 'img',
}) => {
    const classes = useStyles();

    const resizeFile = (file: File) =>
        new Promise<string>((resolve) => {
            Rotator.createRotatedImage(file, "blob",
                (rotatedImage) => {
                    if (!(rotatedImage instanceof Blob)) return;

                    Resizer.imageFileResizer(
                        rotatedImage,
                        640,
                        640,
                        "png",
                        1,
                        0,
                        (uri) => {
                            resolve(uri.toString());
                        },
                        "base64"
                    );
                },
                error => { console.error(error); }
            );
        });
    async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) return;
        if (event.target.files.length <= 0) return;
        if (!props.onChange) return;

        const file = event.target.files[0];
        const base64 = await resizeFile(file);
        props.onChange(base64);
    }
    async function rotateImage() {
        if (!props.onChange) return;
        if (!props.src) return;

        const blob = await (await fetch(props.src)).blob();
        Resizer.imageFileResizer(blob, 640, 640, "png", 1, 90, (uri) => {
            if (props.onChange) {
                props.onChange(uri.toString());
            }
        }, "base64");
    }
    async function deleteImage() {
        if (!props.onDelete) return;

        if (props.onDelete) {
            props.onDelete();
        }
    }
    async function togglePosition() {
        if (!props.onChange) return;

        const nextPosition = getNextPosition();
        const blob = await (await fetch(props.src)).blob();
        Resizer.imageFileResizer(blob, 640, 640, "png", 1, 0, (uri) => {
            if (props.onChange) {
                props.onChange(uri.toString(), nextPosition);
            }
        }, "base64");
    }
    function getNextPosition(): PhotoPositionEnum {
        switch (props.position) {
            case "Top": return "Center";
            case "Center": return "Bottom";
            case "Bottom": return "Default";
            default: return "Top";
        }
    }
    return (<><div className={classes.root}>
        {props.variant === "img" &&
            <img src={props.addMode ? "/img/placeholder-300x202.jpg" : props.src} alt=""
                className={classes.userAvatar}
                style={{
                    objectFit: "contain",
                    objectPosition: ToObjectPosition(props.position),
                }}
            />
        }
        {props.variant !== "img" &&
            <Avatar src={props.addMode ? "/img/placeholder-300x202.jpg" : props.src}
                className={classes.userAvatar}
                variant={props.variant ?? "circular"}
            />
        }
        {(props.editMode || props.addMode)
            && <><div className={classes.btnEditAvatarLabel}>
                <input accept="image/*"
                    id={"icon-button-photo" + props.fileId}
                    className="d-none"
                    onChange={handleChange}
                    type="file"
                />
                <label
                    htmlFor={"icon-button-photo" + props.fileId}>
                    <IconButton className={classes.btnEditAvatar} component="span">
                        {props.inProgress && <CircularProgress />}
                        {!props.inProgress && <img src="/img/icons/icon_camera.svg" alt="camera" />}
                    </IconButton>
                </label>
                {!props.addMode &&
                    <IconButton className={classes.btnRotateAvatar}
                        color="primary"
                        onClick={rotateImage}>
                        <Typography variant="body1" color="primary">
                            90&deg;
                        </Typography>
                    </IconButton>
                }
                {props.deleteMode &&
                    <IconButton className={classes.btnRotateAvatar}
                        color="primary"
                        onClick={deleteImage}>
                        <Typography variant="body1" color="primary">
                            <ClearIcon fontSize="small" />
                        </Typography>
                    </IconButton>
                }
                {props.positionMode &&
                    <IconButton className={classes.btnRotateAvatar}
                        color="primary"
                        onClick={togglePosition}>
                        <Typography variant="body1" color="primary">
                            <VerticalAlignCenterIcon fontSize="small" />
                        </Typography>
                    </IconButton>
                }
            </div>
            </>}
    </div>
    </>);
}