import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { CompanyDetails, ResourceDetails, ServiceDetails, UpdateCompanyMessage } from "../models/Details";
import { AccessTokenBearer } from "../models/Global";
import { ConfirmedVisit, GetVisitMessage } from "../models/Visit";

export class DetailsEndpoint {
    async getCompany(id: string): Promise<ApiResponse<CompanyDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/company-details?id=${id}`,
            method: "GET",
            resultJsonTransformer: (json) => json as CompanyDetails,
        });
        return apiResponse;
    }
    async getCompanyList(message: AccessTokenBearer): Promise<ApiResponse<CompanyDetails[]>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/company-list`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as CompanyDetails[],
        });
        return apiResponse;
    }
    async getService(id: string): Promise<ApiResponse<ServiceDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/service-details?id=${id}`,
            method: "GET",
            resultJsonTransformer: (json) => json as ServiceDetails,
        });
        return apiResponse;
    }
    async getResource(id: string): Promise<ApiResponse<ResourceDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/resource-details?id=${id}`,
            method: "GET",
            resultJsonTransformer: (json) => json as ResourceDetails,
        });
        return apiResponse;
    }
    async getVisit(message: GetVisitMessage): Promise<ApiResponse<ConfirmedVisit>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/confirmed-visit`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as ConfirmedVisit,
        });
        return apiResponse;
    }
    async updateCompanyDetails(message: UpdateCompanyMessage): Promise<ApiResponse<CompanyDetails>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/update-company`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as CompanyDetails,
        });
        return apiResponse;
    }
}