import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api/api';
import { AssignCompanyMessage } from '../../api/models/Edit';
import { RootState } from '../../store/store';
import { assignCompany } from '../../store/user/user.actions';
import { CompanyDetailsViewTabDetails } from '../details/CompanyDetailsViewTabDetails';
import { CompanyDetailsViewTabPortfolio } from '../details/CompanyDetailsViewTabPortfolio';
import { CompanyDetailsViewTabReviews } from '../details/CompanyDetailsViewTabReviews';
import { CompanyDetailsViewTabServices } from '../details/CompanyDetailsViewTabServices';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { L10N } from '../pages/L10N';
import { FavoriteIcon } from '../ui/FavoriteIcon';
import { ImageSliderGridList } from '../ui/ImageSlider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
            width: '100%',
        },
        backArrowHeader: {
            position: 'absolute',
            top: '30px',
            opacity: '0.7',
            zIndex: 10,
        },
        tabs: {
            '& .MuiTab-root': {
                flex: 'auto',
            },
        },
    }),
);

export const CompanyDetailsView = (props: {
    defaultExpanded: boolean,
}) => {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState("services");
    const { company } = useSelector((state: RootState) => state.details);
    const { user } = useSelector((state: RootState) => state.user);
    const ref = useRef<HTMLDivElement | null>(null);
    const classes = useStyles();

    function onBookClick() {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
    function onAssignCompanyClick() {
        if (!user) return;
        if (!company) return;

        const assignCompanyMessage: AssignCompanyMessage = {
            accessToken: user.accessToken,
            companyID: company.itemID,
        };
        dispatch(assignCompany(assignCompanyMessage));
    }
    return (<>
        <div ref={ref} />
        {company?.photos && <ImageSliderGridList items={company?.photos}
            imgSrc={(item) => api.Search.getPhotoUrl(item) ?? ""}
            imgPosition={item => item.position}
            height="300px" />}
        {company && <>
            <Container className="my-3" >
                <BackArrowHeader className={classes.backArrowHeader} dark />
                <Row>
                    <Col className="">
                        <Typography variant="subtitle1">{company?.title}</Typography>
                        <Typography variant="body1" color="textSecondary">
                            {L10N({ key: "_category", cat: company?.subtitle })}
                        </Typography>
                        {company?.ratingScore &&
                            <Typography variant="body1" color="textPrimary" className="my-2">
                                <img alt="star" src={`/img/icons/icon_star_full.svg`} className="mr-2 d-inline" height="16" width="20" />
                                {company?.ratingScore}
                                {company?.ratingVotes && ` (${company?.ratingVotes} ${L10N({ key: "Reviews" })})`}
                            </Typography>
                        }
                        {company?.address &&
                            <Typography variant="body1" color="textPrimary" className="my-2">
                                <img alt="place" src={`/img/icons/icon_place.svg`} className="mr-2 d-inline" height="16" width="20" />
                                {company?.address}
                            </Typography>
                        }
                    </Col>
                    <Col className="col-auto align-items-center d-flex">
                        <FavoriteIcon companyID={company.itemID} />
                    </Col>
                </Row>
            </Container>
            <Tabs value={currentTab} onChange={(event, value) => setCurrentTab(value)}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
                textColor="primary">
                <Tab value={"services"} label={L10N({ key: "Services" })} />
                <Tab value={"reviews"} label={L10N({ key: "Reviews" })} />
                <Tab value={"portfolio"} label={L10N({ key: "Portfolio" })} />
                <Tab value={"details"} label={L10N({ key: "Details" })} />
            </Tabs>
            <Container className="my-3">
                <Row>
                    <Col>
                        {currentTab === "services" && <CompanyDetailsViewTabServices key={"services"} onBookClick={onBookClick} />}
                        {currentTab === "reviews" && <CompanyDetailsViewTabReviews key={"reviews"} />}
                        {currentTab === "portfolio" && <CompanyDetailsViewTabPortfolio key={"portfolio"} />}
                        {currentTab === "details" && <CompanyDetailsViewTabDetails key={"details"} />}
                    </Col>
                </Row>
                {user?.isSuperAdmin === true &&
                    <Row className="">
                        <Col>
                            <Container >
                                <Row className="mx-n5">
                                    <Col className="">
                                        <Accordion defaultExpanded={false}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                            >{L10N({ key: "Learn more" })}</AccordionSummary>
                                            <AccordionDetails className="d-block">
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={onAssignCompanyClick}>
                                                    {L10N({ key: "SuperAdmin: Assign Company" })}
                                                </Button>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>
                }
            </Container>
        </>}
    </>);
};
