import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/pl";
import { PriceAndCurrencyEditModel } from "../../api/models/Edit";
import { PriceAndCurrency, SignalMessageType } from "../../api/models/Global";
import { VisitStatusEnum } from "../../api/models/Visit";
import { EditStep } from "./EditPage";

export type L10N_resx_keys = {
    "resx_locale": "pl" | "de" | "en" | "ae" | "ru",
    "_defaultCurrency": string,
    "_Go to marketing website": string,
    "Arabic": string,
    "Russian": string,
    "Ukrainian": string,
    "About us": string,
    "Account details": string,
    "Account Details": string,
    "Add": string,
    "Add some resources to configure your offer": string,
    "Add some services to configure your offer": string,
    "Add to calendar": string,
    "Add to Calendar": string,
    "Additional info:": string,
    "Address": string,
    "All clients": string,
    "All services": string,
    "Apple or Microsoft": string,
    "Appointment confirmed": string,
    "Appointment requested": string,
    "appointments": string,
    "Are you sure?": string,
    "Are you sure that you want to cancel your visit?": string,
    "at server": string,
    "Austria": string,
    "Back": string,
    "Banned clients": string,
    "Belgium": string,
    "Block - the client will not be allowed to book a visit with your company": string,
    "book": string,
    "book and pay": string,
    "Book": string,
    "Book again": string,
    "Book an Appointment": string,
    "Booked Service:": string,
    "Bookee Category": string,
    "Cancel": string,
    "Cancel Visit": string,
    "Cancel visit?": string,
    "cannot be empty": string,
    "Cannot login?": string,
    "Change": string,
    "Click OK to un-block this client.": string,
    "Click OK to block this client from your company.": string,
    "Client Name": string,
    "Client Phone": string,
    "Clients": string,
    "Closed": string,
    "Company Calendar": string,
    "Company Email": string,
    "Company ID": string,
    "Company description:": string,
    "Company Name": string,
    "Company Website": string,
    "Completed": string,
    "Completed visits:": string,
    "Confirm the visit to client": string,
    "Contact Details for Clients": string,
    "Contact Details": string,
    "Contact Email for Bookee": string,
    "Contact Phone for Bookee": string,
    "Contact details:": string,
    "contact us and help us resolving the issue.": string,
    "Create Company": string,
    "Create Company - save": string,
    "Czech Republic": string,
    "India": string,
    "Day": string,
    "Duration [minutes]": string,
    "Default Price": string,
    "Delete": string,
    "Demo": string,
    "Description": string,
    "Description:": string,
    "Details": string,
    "Display Name": string,
    "Deutschland": string,
    "Do you have any special notes? Leave note here.": string,
    "Download": string,
    "Edit": string,
    "Edit Company": string,
    "Edit Profile": string,
    "Edit review": string,
    "emailsubject:Invite to join bookee": string,
    "emailbody1:Check out this new bookee: ": string,
    "emailbody2:Remember to use my referral code": string,
    "English": string,
    "Enter a valid email": string,
    "Enter a valid number": string,
    "Error occurred:": string,
    "explore": string,
    "Explore offers around you": string,
    "Favorites": string,
    "favourites": string,
    "Featured": string,
    "Finish a visit to review it": string,
    "First Name": string,
    "From the total of:": string,
    "From the total of:...visits": string,
    "German": string,
    "Google": string,
    "Here is your special code.": string,
    "home": string,
    "https://letsbookee.com": string,
    "I agree to ": string,
    "I don't have referral code": string,
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": string,
    "Are you satisfied with Bookee and want to find even more offers?": string,
    "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.": string,
    "Get started now, support us and secure your reward!": string,
    "Invalid data provided.": string,
    "Invalid number": string,
    "Invite to join bookee": string,
    "Klarna API url": string,
    "Klarna Connection Not Working": string,
    "Klarna Connection Successful": string,
    "Klarna Connection Test": string,
    "Klarna Intro Text": string,
    "Klarna Payments": string,
    "Klarna username": string,
    "Klarna password": string,
    "Language": string,
    "Last Name": string,
    "Latitude": string,
    "Learn more": string,
    "loading...": string,
    "Loading...": string,
    "Log in": string,
    "Log into your account": string,
    "Login": string,
    "Login to finalize": string,
    "Logout": string,
    "Longitude": string,
    "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ": string,
    "Map cannot be loaded": string,
    "Mark for deletion": string,
    "Mark for publishing": string,
    "Month": string,
    "Monthly payments:": string,
    "Monthly payments:...Clients": string,
    "Must be later": string,
    "My Appointments": string,
    "My Calendar": string,
    "Next": string,
    "No results found": string,
    "No visit selected": string,
    "No, I decided to come": string,
    "Not getting SMS?": string,
    "Notes:": string,
    "OK": string,
    "OK, got it": string,
    "Online payment impossible": string,
    "OR": string,
    "Paid": string,
    "Pay": string,
    "PaymentPage - Title": string,
    "PaymentPage - Description": string,
    "PaymentPage - Finalize Payment": string,
    "Phone number": string,
    "Photo": string,
    "PIN": string,
    "Please enter partner code below or check that you don't have it": string,
    "Please contact us to change your phone number": string,
    "Please define your bookee": string,
    "Poland": string,
    "Polish": string,
    "Portfolio": string,
    "Prepare": string,
    "profile": string,
    "Publish": string,
    "Rate and review your experience": string,
    "Ready to publish": string,
    "Recommend Bookee": string,
    "Recommend Bookee and gain instant benefits": string,
    "Referral code": string,
    "Referral statistics": string,
    "refresh reminders:": string,
    "Replace": string,
    "Require manual confirmation of the booking": string,
    "Resource ID": string,
    "Resource Name": string,
    "Resource edit: Phone number": string,
    "Resources": string,
    "Return": string,
    "Review": string,
    "Reviews": string,
    "Save": string,
    "Save account": string,
    "Search": string,
    "Search Keywords": string,
    "Send to contacts": string,
    "Service Category": string,
    "Service ID": string,
    "Service Name": string,
    "Services": string,
    "Share": string,
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": string,
    "Show directions": string,
    "Specify time as HH:mm": string,
    "Staff members:": string,
    "Statistics &amp; Reports": string,
    "Subtitle": string,
    "SuperAdmin: Assign Company": string,
    "Switzerland": string,
    "Bookee Tagline": string,
    "terms and conditions": string,
    "Terms and conditions": string,
    "Terms of Use": string,
    "THANK YOU": string,
    "There are no offers that satisfy your criteria. We need to remove your filtering.": string,
    "This company has no portoflio": string,
    "This company has no reviews yet": string,
    "Time after [minutes]": string,
    "Time before [minutes]": string,
    "Title": string,
    "Under construction": string,
    "United Arab Emirates": string,
    "Unique clients:": string,
    "Upcoming": string,
    "Update map": string,
    "Upfront payment": string,
    "Upfront payment - price": string,
    "Upfront payment - you need to pay upfront for this visit": string,
    "Upfront payment - the client has to pay upfront online while booking a visit": string,
    "View as client": string,
    "Visit ID": string,
    "Visit Start": string,
    "Visit Start for Client": string,
    "Visit statistics": string,
    "Visit Status": string,
    "VisitSummaryCard:When your request is accepted(1/2)": string,
    "VisitSummaryCard:When your request is accepted(2/2)": string,
    "VisitSummaryCard:Your request will be answered shortly.": string,
    "Waiting for your rating and review:": string,
    "We need the following information before publishing:": string,
    "Week": string,
    "Working Hours:": string,
    "Working hours": string,
    "Write here...": string,
    "Write review": string,
    "Yearly payments:": string,
    "Yearly payments:...Clients": string,
    "Yes, please cancel": string,
    "You can select your favourite staff for the service and select your preferred date and time": string,
    "You have no completed visits": string,
    "You have no favorites yet": string,
    "You have no upcoming visits": string,
    "You're done!": string,
    "We'll send you a reminder": string,
    "You've almost earned additional:": string,
    "You've earned:": string,
    "before your appointment": string,
    "Your company calendar...": string,
    "Your consent is required": string,
    "Your referrals:": string,
    "Your referrals:...Clients": string,
    "Your reviews:": string,
    "Your session has expired.": string,
    "Zoom": string,
    "Besucher": string,
    "Dienstleister": string,
    "Für Besucher": string,
    "Für Dienstleister": string,
    "Email": string,
    "Nachricht": string,
    "Name": string,
    '100% kostenlos für Besucher': string,
    '100% kostenlos': string,
    '100% online': string,
    '12 Monate zum Preis von 10': string,
    '24 / 7 / 365 Termine': string,
    '24h Buchungsassistent': string,
    '30 Tage kostenfrei testen': string,
    '49€': string,
    '499€': string,
    'ABONNIEREN': string,
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': string,
    'Am Sandfeld 18, 76149 Karlsruhe': string,
    'Angebote für Dienstleister': string,
    'Buchen': string,
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': string,
    'Datenschutz &amp; AGB': string,
    'Direkt und zeitsparend': string,
    'Empfehlung': string,
    'Erinnerungs-SMS mit Hinweise an Kunden': string,
    'FEATURES': string,
    'Features': string,
    'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': string,
    'HOME': string,
    'Hier sind wir': string,
    'Impressum': string,
    'Jahres-Abo': string,
    'KATEGORIEN': string,
    'KONTAKT': string,
    'Kategorien': string,
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': string,
    'Kontaktiere uns': string,
    'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': string,
    'Monats-Abo': string,
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': string,
    'Nachricht erfolgreich gesendet': string,
    'Nutze unser Kontaktformular': string,
    'PROBIER ES AUS': string,
    'Probier es aus': string,
    'SCREENSHOTS': string,
    'SMS-Erinnerung': string,
    'Screenshots': string,
    'Senden': string,
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': string,
    'TEAM': string,
    'Unabhängige Flexibilität': string,
    'Vorzugspreis': string,
    'WARUM?': string,
    'Warum Bookee?': string,
    'Was ist Bookee?': string,
    'bitte füllen Sie das Formular aus': string,
    'inkl. Werbe-Material': string,
    'jederzeit buchbar': string,
    'komplette Übersicht': string,
    'monatlich kündbar': string,
    'weniger Zeit am Telefon': string,
    'ÜBER UNS': string,
    'MIT BOOKEE KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': string,
}

export type L10N_resx_date = {
    "_dateAsOn": (value?: string) => string,
    "_dateAsTimeAgo": (value?: string) => string,
    "_dateAsDD": (value?: string) => string,
    "_dateAsddd": (value?: string) => string,
    "_dateAsdddd": (value?: string) => string,
    "_dateAsMM": (value?: string) => string,
    "_dateAsMMM": (value?: string) => string,
    "_dateAsMMMM": (value?: string) => string,
    "_dateAsMMMMYYYY": (value?: string) => string,
    "_dateAsHHmm": (value?: string) => string,
    "_dateAsDDMMMHHmm": (value?: string) => string,
    "_dateAsDayOrTomorrow": (value?: string) => string,
}
export type L10N_resx_duration = {
    "_duration": (value?: number) => string,
}
export type L10N_resx_category = {
    "_category": (value?: string) => string,
}
export type L10N_resx_hint = {
    "_hint": (value?: string) => string,
}
export type L10N_resx_editStep = {
    "_editStep": (value?: EditStep) => string,
}
export type L10N_resx_status = {
    "_status": (value?: VisitStatusEnum) => string,
}
export type L10N_resx_price = {
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => string,
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => string,
}
export type L10N_resx_signal = {
    "_signalMessageType": (value?: SignalMessageType) => string,
}
export type L10N_resx = L10N_resx_keys
    & L10N_resx_date
    & L10N_resx_duration
    & L10N_resx_category
    & L10N_resx_hint
    & L10N_resx_editStep
    & L10N_resx_status
    & L10N_resx_price
    & L10N_resx_signal;

export const L10N_date_en: L10N_resx_date = {
    "_dateAsOn": (value?: string) => moment(value).locale("en-gb").format("on MMM, DD"),
    "_dateAsTimeAgo": (value?: string) => moment(value).locale("en-gb").fromNow(),
    "_dateAsDD": (value?: string) => moment(value).locale("en-gb").format("DD"),
    "_dateAsddd": (value?: string) => moment(value).locale("en-gb").format("ddd"),
    "_dateAsdddd": (value?: string) => moment(value).locale("en-gb").format("dddd"),
    "_dateAsMM": (value?: string) => moment(value).locale("en-gb").format("MM"),
    "_dateAsMMM": (value?: string) => moment(value).locale("en-gb").format("MMM"),
    "_dateAsMMMM": (value?: string) => moment(value).locale("en-gb").format("MMMM"),
    "_dateAsMMMMYYYY": (value?: string) => moment(value).locale("en-gb").format("MMMM, YYYY"),
    "_dateAsHHmm": (value?: string) => moment(value).locale("en-gb").format("HH:mm"),
    "_dateAsDDMMMHHmm": (value?: string) => moment(value).locale("en-gb").format("DD MMM HH:mm"),
    "_dateAsDayOrTomorrow": (value?: string) => moment(value).locale("en-gb").calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] ddd',
        sameElse: 'ddd'
    }),
}
export const L10N_duration_en: L10N_resx_duration = {
    "_duration": (value?: number) => {
        if (!value) {
            return "-";
        } else if (value < 60) {
            return `${value}min`;
        } else if (value % 60 === 0) {
            return `${value / 60}h`;
        } else {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        }
    },
}
export const L10N_category_en: L10N_resx_category = {
    "_category": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "anything";
            case "barber": return "barber";
            case "beauty-salon": return "beauty-salon";
            case "book": return "education";
            case "car-shops": return "car-shops";
            case "coach": return "Personal Trainer";
            case "doctor": return "doctor";
            case "ear": return "ear";
            case "exercise": return "exercise";
            case "flat-tire": return "flat-tire";
            case "glasses": return "glasses";
            case "hairdresser": return "Hairdresser";
            case "hairstyle": return "pet groomer";
            case "lips": return "Beauty Doc";
            case "massage": return "Massage";
            case "mortar": return "mortar";
            case "nail-polish": return "Nail salon";
            case "nutritionist": return "Nutrition";
            case "physical-therapy": return "Physiotherapy";
            case "piercing": return "Piercing";
            case "psychology": return "psychology";
            case "tattoo": return "Tattoo";
            case "tennis": return "sports";
            case "tooth": return "tooth";
            case "veterinarian": return "veterinarian";
            case "_YAVU_Trainer": return "YAVU Trainer";
            case "_YAVU_Beauty": return "YAVU Beauty";
            default: return value;
        }
    },
}
export const L10N_hint_en: L10N_resx_hint = {
    "_hint": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anytime": return "anytime";
            case "today": return "today";
            case "tomorrow": return "tomorrow";
            default: return value;
        }
    },
}
export const L10N_editStep_en: L10N_resx_editStep = {
    "_editStep": (value?: EditStep) => {
        switch (value) {
            case "about": return "About you";
            case "address": return "Your address";
            case "for clients": return "For clients";
            case "services": return "Services";
            case "resources": return "Resources";
            case "publish": return "Publish";
            default: return value ?? "";
        }
    },
}
export const L10N_status_en: L10N_resx_status = {
    "_status": (value?: VisitStatusEnum) => {
        switch (value) {
            case undefined: return "";
            case "Draft": return "Draft";
            case "Requested": return "Requested";
            case "Confirmed": return "Confirmed";
            case "Done": return "Done";
            case "CanceledByClient": return "Canceled by Client";
            case "CanceledByCompany": return "Canceled by Company";
            case "RescheduledByClient": return "Rescheduled by Client";
            default: return value;
        }
    },
}
export const L10N_price_en: L10N_resx_price = {
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        if ((value?.price ?? 0) === 0) return "free";
        const currency = (!value?.currency) ? "AED"
            : value?.currency?.toUpperCase() === "EUR" ? "AED"
                : value?.currency?.toUpperCase() === "USD" ? "AED"
                    : value?.currency;
        return `${currency} ${value?.price}`;
    },
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        const currency = (!value?.currency) ? "AED"
            : value?.currency?.toUpperCase() === "EUR" ? "AED"
                : value?.currency?.toUpperCase() === "USD" ? "AED"
                    : value?.currency;
        return `${currency}`;
    },
}
export const L10N_signal_en: L10N_resx_signal = {
    "_signalMessageType": (value?: SignalMessageType) => {
        switch (value) {
            case undefined: return "";
            case "Custom": return "Someone is interested in your company";
            case "CompanyViewed": return "Someone has just viewed your company";
            case "InviteCreated": return "Someone is looking for a visit";
            case "VisitCancelled": return "Someone has cancelled a visit";
            case "VisitConfirmed": return "Someone has confirmed a visit";
            case "VisitRescheduled": return "Someone has rescheduled a visit";
            default: return value;
        }
    },
}
export const L10N_keys_en: L10N_resx_keys = {
    "resx_locale": "en",
    "_defaultCurrency": "AED",
    "_Go to marketing website": "Info",
    "Arabic": "Arabic",
    "Russian": "Russian",
    "Ukrainian": "Ukrainian",
    "About us": "About us",
    "Account details": "Account details",
    "Account Details": "Account Details",
    "Add": "Add",
    "Add some resources to configure your offer": "Add some resources to configure your offer",
    "Add some services to configure your offer": "Add some services to configure your offer",
    "Add to calendar": "Add to calendar",
    "Add to Calendar": "Add to Calendar",
    "Additional info:": "Additional info:",
    "Address": "Address",
    "All clients": "All clients",
    "All services": "All services",
    "Apple or Microsoft": "Apple or Microsoft",
    "Appointment confirmed": "Appointment confirmed",
    "Appointment requested": "Appointment requested",
    "appointments": "appointments",
    "Are you sure?": "Are you sure?",
    "Are you sure that you want to cancel your visit?": "Are you sure that you want to cancel your visit?",
    "at server": "at server",
    "Austria": "Austria",
    "Back": "Back",
    "Banned clients": "Banned clients",
    "Belgium": "Belgium",
    "Block - the client will not be allowed to book a visit with your company": "Block - the client will not be allowed to book a visit with your company",
    "book": "book",
    "book and pay": "book and pay",
    "Book": "Book",
    "Book again": "Book again",
    "Book an Appointment": "Book an Appointment",
    "Booked Service:": "Booked Service:",
    "Bookee Category": "Bookee Category",
    "Cancel": "Cancel",
    "Cancel Visit": "Cancel Visit",
    "Cancel visit?": "Cancel visit?",
    "cannot be empty": "cannot be empty",
    "Cannot login?": "Cannot login?",
    "Change": "Change",
    "Click OK to un-block this client.": "Click OK to un-block this client.",
    "Click OK to block this client from your company.": "Click OK to block this client from your company.",
    "Client Name": "Client Name",
    "Client Phone": "Client Phone",
    "Clients": "Clients",
    "Closed": "Closed",
    "Company Calendar": "Company Calendar",
    "Company Email": "Company Email",
    "Company ID": "Company ID",
    "Company description:": "Company description",
    "Company Name": "Company Name",
    "Company Website": "Company Website",
    "Completed": "Completed",
    "Completed visits:": "Completed visits:",
    "Confirm the visit to client": "Confirm the visit to client",
    "Contact Details for Clients": "Contact Details for Clients",
    "Contact Details": "Contact Details",
    "Contact details:": "Contact details:",
    "Contact Email for Bookee": "Contact Email for Bookee",
    "Contact Phone for Bookee": "Contact Phone for Bookee",
    "contact us and help us resolving the issue.": "contact us and help us resolving the issue.",
    "Create Company": "Add your offer",
    "Create Company - save": "Create",
    "Czech Republic": "Czech Republic",
    "India": "India",
    "Day": "Day",
    "Delete": "Delete",
    "Duration [minutes]": "Default Duration [minutes]",
    "Default Price": "Default Price",
    "Demo": "Demo",
    "Description": "Description",
    "Description:": "Description:",
    "Details": "Details",
    "Display Name": "Display Name",
    "Deutschland": "Deutschland",
    "Do you have any special notes? Leave note here.": "Do you have any special notes? Leave note here.",
    "Download": "Download",
    "Edit": "Edit",
    "Edit Company": "Edit Company",
    "Edit Profile": "Edit Profile",
    "Edit review": "Edit review",
    "emailsubject:Invite to join bookee": "Invite to join bookee",
    "emailbody1:Check out this new bookee: ": "You can follow this link to register to Bookee: ",
    "emailbody2:Remember to use my referral code": " (this is from my partnership program)",
    "English": "English",
    "Enter a valid email": "Enter a valid email",
    "Enter a valid number": "Enter a valid number",
    "Error occurred:": "Error occurred:",
    "explore": "explore",
    "Explore offers around you": "Explore offers around you",
    "Favorites": "Favorites",
    "favourites": "favourites",
    "Featured": "Featured",
    "Finish a visit to review it": "Finish a visit to review it",
    "First Name": "First Name",
    "From the total of:": "From the total of:",
    "From the total of:...visits": "visits",
    "German": "German",
    "Google": "Google",
    "Here is your special code.": "Here is your referral link.",
    "home": "home",
    "https://letsbookee.com": "https://letsbookee.com",
    "I agree to ": "I agree to ",
    "I don't have referral code": "I don't have referral code",
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!",
    "Are you satisfied with Bookee and want to find even more offers?": "Are you satisfied with Bookee and want to find even more offers?",
    "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.": "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.",
    "Get started now, support us and secure your reward!": "Get started now, support us and secure your reward!",
    "Invalid number": "Invalid number",
    "Invalid data provided.": "Invalid data provided.",
    "Invite to join bookee": "Invite to join bookee",
    "Klarna API url": "Klarna API URL",
    "Klarna Connection Not Working": "Klarna Connection Not Working",
    "Klarna Connection Successful": "Klarna Connection Successful",
    "Klarna Connection Test": "Test Klarna Connection",
    "Klarna Intro Text": "If you already use Klarna in the shop, you can connect your Klarna account here",
    "Klarna Payments": "Upfront payments system - soon available",
    "Klarna username": "Klarna login",
    "Klarna password": "Klarna password",
    "Language": "Language",
    "Last Name": "Last Name",
    "Latitude": "Latitude",
    "Learn more": "Learn more",
    "loading...": "loading...",
    "Loading...": "Loading...",
    "Log in": "Log in",
    "Log into your account": "Log into your account",
    "Login": "Login",
    "Login to finalize": "Login to finalize",
    "Logout": "Logout",
    "Longitude": "Longitude",
    "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ": "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ",
    "Map cannot be loaded": "Map cannot be loaded",
    "Mark for deletion": "Mark for deletion",
    "Mark for publishing": "Show the offer to clients",
    "Month": "Month",
    "Monthly payments:": "Monthly payments:",
    "Monthly payments:...Clients": "Clients",
    "Must be later": "Must be later",
    "My Appointments": "My Appointments",
    "My Calendar": "My Calendar",
    "Next": "Next",
    "No results found": "No results found",
    "No visit selected": "No visit selected",
    "No, I decided to come": "No, I decided to come",
    "Not getting SMS?": "Not getting SMS?",
    "Notes:": "Notes:",
    "OK": "OK",
    "OK, got it": "OK, got it",
    "Online payment impossible": "Online payment impossible",
    "OR": "OR",
    "Paid": "Paid",
    "Pay": "Pay",
    "PaymentPage - Title": "Pay for the visit",
    "PaymentPage - Description": "Our payments are supported with external provider. You can finalize the payment using the button below, or scanning the QR code.",
    "PaymentPage - Finalize Payment": "Finalize Payment",
    "Phone number": "Phone number",
    "Photo": "Photo",
    "PIN": "PIN",
    "Please enter partner code below or check that you don't have it": "Please enter partner code below or check that you don't have it",
    "Please contact us to change your phone number": "Please contact us to change your phone number",
    "Please define your bookee": "Please select date and time of your visit",
    "Poland": "Poland",
    "Polish": "Polish",
    "Portfolio": "Portfolio",
    "Prepare": "Prepare",
    "profile": "profile",
    "Publish": "Publish",
    "Rate and review your experience": "Rate and review your experience",
    "Ready to publish": "Ready to publish",
    "Recommend Bookee": "Recommend Bookee",
    "Recommend Bookee and gain instant benefits": "Recommend Bookee and gain instant benefits",
    "Referral code": "Referral code",
    "Referral statistics": "Referral statistics",
    "refresh reminders:": "refresh reminders:",
    "Replace": "Replace",
    "Require manual confirmation of the booking": "Require manual confirmation of the booking",
    "Resource ID": "Resource ID",
    "Resource Name": "Resource Name",
    "Resource edit: Phone number": "Phone number",
    "Resources": "Resources",
    "Return": "Return",
    "Review": "Review",
    "Reviews": "Reviews",
    "Save": "Save",
    "Save account": "Save account",
    "Search": "Search",
    "Search Keywords": "Search Keywords",
    "Send to contacts": "Send to contacts",
    "Service Category": "Service Category",
    "Service ID": "Service ID",
    "Service Name": "Service Name",
    "Services": "Services",
    "Share": "Share",
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.",
    "Show directions": "Show directions",
    "Specify time as HH:mm": "Specify time as HH:mm",
    "Staff members:": "Staff members:",
    "Statistics &amp; Reports": "Statistics & Reports",
    "Subtitle": "Subtitle",
    "SuperAdmin: Assign Company": "Join the company (admin)",
    "Switzerland": "Switzerland",
    "Bookee Tagline": "Your appointments - free & easy",
    "terms and conditions": "terms and conditions",
    "Terms and conditions": "Terms and conditions",
    "Terms of Use": "Terms of Use",
    "THANK YOU": "THANK YOU",
    "There are no offers that satisfy your criteria. We need to remove your filtering.": "There are no offers that satisfy your criteria. We need to remove your filtering.",
    "This company has no portoflio": "This company has no portoflio",
    "This company has no reviews yet": "This company has no reviews yet",
    "Time after [minutes]": "Time after [minutes]",
    "Time before [minutes]": "Time before [minutes]",
    "Title": "Title",
    "Under construction": "Under construction",
    "United Arab Emirates": "United Arab Emirates",
    "Unique clients:": "Unique clients:",
    "Upcoming": "Upcoming",
    "Update map": "Update map",
    "Upfront payment": "Upfront payment",
    "Upfront payment - price": "Upfront payment (quote)",
    "Upfront payment - you need to pay upfront for this visit": "Upfront payment - you need to pay upfront for this visit",
    "Upfront payment - the client has to pay upfront online while booking a visit": "Coming soon - Upfront payment - the client has to pay upfront online while booking a visit",
    "View as client": "Preview",
    "Visit ID": "Visit ID",
    "Visit Start": "Visit",
    "Visit Start for Client": "Visit (for client)",
    "Visit statistics": "Visit statistics",
    "Visit Status": "Visit Status",
    "VisitSummaryCard:When your request is accepted(1/2)": "When your request is accepted",
    "VisitSummaryCard:When your request is accepted(2/2)": "we will send you an SMS.",
    "VisitSummaryCard:Your request will be answered shortly.": "Your request will be answered shortly.",
    "Waiting for your rating and review:": "Waiting for your rating and review:",
    "We need the following information before publishing:": "We need the following information before publishing:",
    "Week": "Week",
    "Working Hours:": "Working Hours:",
    "Working hours": "Working hours",
    "Write here...": "Write here...",
    "Write review": "Write review",
    "Yearly payments:": "Yearly payments:",
    "Yearly payments:...Clients": "Clients",
    "Yes, please cancel": "Yes, please cancel",
    "You can select your favourite staff for the service and select your preferred date and time": "You can select your favourite staff for the service and select your preferred date and time",
    "You have no completed visits": "You have no completed visits",
    "You have no favorites yet": "You have no favorites yet",
    "You have no upcoming visits": "You have no upcoming visits",
    "You're done!": "You're done!",
    "We'll send you a reminder": "We'll send you a reminder",
    "You've earned:": "You've earned:",
    "You've almost earned additional:": "You've almost earned additional:",
    "before your appointment": "before your appointment",
    "Your company calendar...": "Your company calendar...",
    "Your consent is required": "Your consent is required",
    "Your referrals:": "Your referrals:",
    "Your referrals:...Clients": "Clients",
    "Your reviews:": "Your reviews:",
    "Your session has expired.": "Your session has expired.",
    "Zoom": "Zoom",
    'Besucher': 'Visitors',
    'Dienstleister': 'Service provider',
    'Für Besucher': 'For visitor',
    'Für Dienstleister': 'For service provider',
    'Email': 'Email',
    'Nachricht': 'Message',
    'Name': 'Name',
    '100% kostenlos für Besucher': '100% free for visitors',
    '100% kostenlos': '100% free of charge',
    '100% online': '100% online',
    '12 Monate zum Preis von 10': '12 months for the price of 10',
    '24 / 7 / 365 Termine': '24 / 7 / 365 dates',
    '24h Buchungsassistent': '24h booking assistant',
    '30 Tage kostenfrei testen': '30 days free trial',
    '49€': 'AED 199',
    '499€': 'AED 1990',
    'ABONNIEREN': 'SUBSCRIBE',
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': 'As an example: A receptionist spends about 50% of her working time on the phone, taking appointment requests, looking in the calendar and also browsing around until the right appointment is found for both of them. This results in longer waiting times, and more important tasks fall by the wayside.',
    'Am Sandfeld 18, 76149 Karlsruhe': 'Am Sandfeld 18, 76149 Karlsruhe, Germany',
    'Angebote für Dienstleister': 'Offers for service providers',
    'Buchen': 'Book',
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': 'Then just write to us via email, or contact form and we will get back to you as soon as possible.',
    'Datenschutz &amp; AGB': 'Privacy & GTC (General terms and conditions)',
    'Direkt und zeitsparend': 'Direct and time saving',
    'Empfehlung': 'Recommendation',
    'Erinnerungs-SMS mit Hinweise an Kunden': 'SMS reminder with notices to customers',
    'FEATURES': 'FEATURES',
    'Features': 'Features',
    'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': 'This is exactly where we come in with "Bookee". We have come up with a solution that provides you as a customer, but also as a business owner, with a suitable answer.',
    'HOME': 'HOME',
    'Hier sind wir': 'Here we are',
    'Impressum': 'Imprint',
    'Jahres-Abo': 'Annual subscription',
    'KATEGORIEN': 'CATEGORIES',
    'KONTAKT': 'Contact',
    'Kategorien': 'Categories',
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': 'Do you know this, you call the hairdresser, it goes back and forth for a few minutes, just to get a free appointment? Or when you would like to make an appointment for Monday at the weekend, but nobody answers the phone?',
    'Kontaktiere uns': 'Contact us',
    'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': 'With Bookee you can always look for a suitable appointment online, book it and if necessary change or even cancel it. If you run a business and you`re tired of being on the phone to coordinate appointments, Bookee is for you.With Bookee as your personal round- the - clock appointment assistant, you have more time for yourself and for the more important things in your private or professional life.',
    'Monats-Abo': 'Monthly subscription',
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': 'Would you like to use our service as a service provider?',
    'Nachricht erfolgreich gesendet': 'Message sent successfully',
    'Nutze unser Kontaktformular': 'Use our contact form',
    'PROBIER ES AUS': 'TRY IT OUT',
    'Probier es aus': 'Try it out',
    'SCREENSHOTS': 'SCREENSHOTS',
    'SMS-Erinnerung': 'SMS reminder',
    'Screenshots': 'Screenshots',
    'Senden': 'Send',
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': 'You will be redirected to our partner to complete the payment',
    'TEAM': 'TEAM',
    'Unabhängige Flexibilität': 'connect with social media',
    'Vorzugspreis': 'Preferential price',
    'WARUM?': 'WHY?',
    'Warum Bookee?': 'Why Bookee?',
    'Was ist Bookee?': 'What is Bookee?',
    'bitte füllen Sie das Formular aus': 'please fill out the form',
    'inkl. Werbe-Material': 'incl. advertising material',
    'jederzeit buchbar': 'bookable at any time',
    'komplette Übersicht': 'complete overview',
    'monatlich kündbar': 'can be cancelled monthly',
    'weniger Zeit am Telefon': 'less time on the phone',
    'ÜBER UNS': 'About us',
    'MIT BOOKEE KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': 'DITCH THE WAIT AND BOOK YOUR SERVICE LIKE A PRO WITH BOOKEE',
};
export const L10N_en: L10N_resx = {
    ...L10N_keys_en,
    ...L10N_date_en,
    ...L10N_duration_en,
    ...L10N_category_en,
    ...L10N_hint_en,
    ...L10N_editStep_en,
    ...L10N_status_en,
    ...L10N_price_en,
    ...L10N_signal_en,
};
export const L10N_pl: L10N_resx = {
    "resx_locale": "pl",
    "_defaultCurrency": "EUR",
    "_dateAsOn": (value?: string) => moment(value).locale("pl").format("DD. MMM"),
    "_dateAsTimeAgo": (value?: string) => moment(value).locale("pl").fromNow(),
    "_dateAsDD": (value?: string) => moment(value).locale("pl").format("DD"),
    "_dateAsddd": (value?: string) => moment(value).locale("pl").format("ddd"),
    "_dateAsdddd": (value?: string) => moment(value).locale("pl").format("dddd"),
    "_dateAsMM": (value?: string) => moment(value).locale("pl").format("MM"),
    "_dateAsMMM": (value?: string) => moment(value).locale("pl").format("MMM"),
    "_dateAsMMMM": (value?: string) => moment(value).locale("pl").format("MMMM"),
    "_dateAsMMMMYYYY": (value?: string) => moment(value).locale("pl").format("MMMM, YYYY"),
    "_dateAsHHmm": (value?: string) => moment(value).locale("pl").format("HH:mm"),
    "_dateAsDDMMMHHmm": (value?: string) => moment(value).locale("pl").format("DD MMM HH:mm"),
    "_dateAsDayOrTomorrow": (value?: string) => moment(value).locale("pl").calendar({
        sameDay: '[dzisiaj]',
        nextDay: '[jutro]',
        nextWeek: 'ddd',
        lastDay: '[wczoraj]',
        lastWeek: '[poprzedni] ddd',
        sameElse: 'ddd'
    }),
    "_duration": (value?: number) => {
        if (!value) {
            return "-";
        } else if (value < 60) {
            return `${value}min`;
        } else if (value % 60 === 0) {
            return `${value / 60}h`;
        } else {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        }
    },
    "_category": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "wszystko";
            case "barber": return "barber";
            case "beauty-salon": return "salon piękności";
            case "book": return "edukacja";
            case "car-shops": return "salony samochodowe";
            case "coach": return "coach";
            case "doctor": return "lekarz";
            case "ear": return "słuch";
            case "exercise": return "ćwiczenia";
            case "flat-tire": return "wulkanizator";
            case "glasses": return "okulary";
            case "hairdresser": return "fryzjer";
            case "hairstyle": return "fryzjer dla zwierząt";
            case "lips": return "kosmetyczka";
            case "massage": return "masaż";
            case "mortar": return "medycyna naturalna";
            case "nail-polish": return "paznokcie";
            case "nutritionist": return "odżywianie";
            case "physical-therapy": return "fizykoterapia";
            case "piercing": return "piercing";
            case "psychology": return "psychologia";
            case "tattoo": return "tatuaż";
            case "tennis": return "sport";
            case "tooth": return "dentysta";
            case "veterinarian": return "weterynarz";
            case "_YAVU_Trainer": return "YAVU Trainer";
            case "_YAVU_Beauty": return "YAVU Beauty";
            default: return value;
        }
    },
    "_hint": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anytime": return "dowolny czas";
            case "today": return "dzisiaj";
            case "tomorrow": return "jutro";
            default: return value;
        }
    },
    "_editStep": (value?: EditStep) => {
        switch (value) {
            case "about": return "Dane podstawowe";
            case "address": return "Adres";
            case "for clients": return "O Twojej firmie";
            case "services": return "Usługi";
            case "resources": return "Pracownicy";
            case "publish": return "Publikuj";
            default: return value ?? "";
        }
    },
    "_status": (value?: VisitStatusEnum) => {
        switch (value) {
            case undefined: return "";
            case "Draft": return "W trakcie";
            case "Requested": return "Zamówiona";
            case "Confirmed": return "Potwierdzona";
            case "Done": return "Zakończona";
            case "CanceledByClient": return "Anulowana przez klienta";
            case "CanceledByCompany": return "Anulowana przez firmę";
            case "RescheduledByClient": return "Przesunięta przez klienta";
            default: return value;
        }
    },
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        if ((value?.price ?? 0) === 0) return "gratis";
        const currency = (!value?.currency) ? "€"
            : value?.currency?.toUpperCase() === "EUR" ? "€"
                : value?.currency?.toUpperCase() === "USD" ? "$"
                    : value?.currency;
        return `${value?.price} ${currency}`;
    },
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        const currency = (!value?.currency) ? "€"
            : value?.currency?.toUpperCase() === "EUR" ? "€"
                : value?.currency?.toUpperCase() === "USD" ? "$"
                    : value?.currency;
        return `${currency}`;
    },
    "_signalMessageType": (value?: SignalMessageType) => {
        switch (value) {
            case undefined: return "";
            case "Custom": return "Ktoś zainteresował się Twoją ofertą";
            case "CompanyViewed": return "Ktoś ogląda Twoją ofertę";
            case "InviteCreated": return "Ktoś szuka terminu wizyty";
            case "VisitCancelled": return "Ktoś anulował wizytę";
            case "VisitConfirmed": return "Ktoś potwierdził wizytę";
            case "VisitRescheduled": return "Ktoś zmienił termin wizyty";
            default: return value;
        }
    },
    "_Go to marketing website": "Info",
    "Arabic": "arabski",
    "Russian": "ruski",
    "Ukrainian": "ukraiński",
    "About us": "O nas",
    "Account details": "Konto",
    "Account Details": "Konto",
    "Add": "Dodaj",
    "Add some resources to configure your offer": "Dodaj pracowników, żeby dokończyć ofertę",
    "Add some services to configure your offer": "Dodaj usługi, żeby dokończyć ofertę",
    "Add to calendar": "Zapisz w kalendarzu",
    "Add to Calendar": "Zapisz w kalendarzu",
    "Additional info:": "Informacje:",
    "Address": "Adres",
    "All clients": "Wszyscy Klienci",
    "All services": "Wszystkie usługi",
    "Apple or Microsoft": "Apple lub Microsoft",
    "Appointment confirmed": "Potwierdzono spotkanie",
    "Appointment requested": "Spotkanie zgłoszone",
    "appointments": "Spotkania",
    "Are you sure?": "Czy na pewno?",
    "Are you sure that you want to cancel your visit?": "Czy jesteś pewien, żeby anulować wizytę?",
    "at server": "na serwerze",
    "Austria": "Austria",
    "Back": "Wróć",
    "Banned clients": "Zablokowani",
    "Belgium": "Belgia",
    "Block - the client will not be allowed to book a visit with your company": "Zablokuj - Klient nie będzie mógł rezerwować wizyt w Twojej firmie",
    "book and pay": "zarezerwuj i zapłać",
    "book": "zarezerwuj",
    "Book": "Zarezerwuj",
    "Book again": "Zarezerwuj ponownie",
    "Book an Appointment": "Zarezerwuj spotkanie",
    "Booked Service:": "Umówiona usługa:",
    "Bookee Category": "Kategoria bookee",
    "Cancel": "Anuluj",
    "Cancel Visit": "Anuluj spotkanie",
    "Cancel visit?": "Czy anulować spotkanie?",
    "cannot be empty": "wartość wymagana",
    "Cannot login?": "Nie możesz się zalogować?",
    "Change": "Zmień",
    "Click OK to un-block this client.": "Naciśnij OK, żeby ODBLOKOWAĆ klienta.",
    "Click OK to block this client from your company.": "Naciśnij OK, żeby ZABLOKOWAĆ klienta.",
    "Client Name": "Klient",
    "Client Phone": "Telefon Klienta",
    "Clients": "Klienci",
    "Closed": "Zamknięte",
    "Company Calendar": "Kalendarz firmowy",
    "Company Email": "Email firmowy",
    "Company ID": "ID firmy",
    "Company description:": "Opis firmy",
    "Company Name": "Nazwa firmy",
    "Company Website": "Strona firmowa www",
    "Completed": "Zakończone",
    "Completed visits:": "Wizyty zakończone:",
    "Confirm the visit to client": "Potwierdzam wizytę klienta",
    "Contact Details for Clients": "Dane kontaktowe dla klientów",
    "Contact Details": "Dane kontaktowe",
    "Contact details:": "Dane kontaktowe:",
    "Contact Email for Bookee": "Email kontaktowy dla Bookee",
    "Contact Phone for Bookee": "Telefon kontaktowy dla Bookee",
    "contact us and help us resolving the issue.": "Skontaktuj się z nami i pomóż rowiązać ten problem.",
    "Create Company": "Stwórz ofertę",
    "Create Company - save": "Zapisz",
    "Czech Republic": "Czechy",
    "India": "Indie",
    "Day": "Dzień",
    "Delete": "Usuń",
    "Duration [minutes]": "Czas trwania [minuty]",
    "Default Price": "Cena",
    "Demo": "Demo",
    "Description": "Opis",
    "Description:": "Opis:",
    "Details": "Szczegóły",
    "Display Name": "Nazwa",
    "Deutschland": "Niemcy",
    "Do you have any special notes? Leave note here.": "Napisz jeżeli masz dodatkowe uwagi.",
    "Download": "Pobierz",
    "Edit": "Edytuj",
    "Edit Company": "Edytuj firmę",
    "Edit Profile": "Edytuj profil",
    "Edit review": "Edytuj komentarz",
    "emailsubject:Invite to join bookee": "Zaproszenie do Bookee",
    "emailbody1:Check out this new bookee: ": "Użyj tego linku, żeby się zarejestrować do Bookee: ",
    "emailbody2:Remember to use my referral code": " (to część mojego programu parnerskiego)",
    "English": "angielski",
    "Enter a valid email": "Wpisz poprawny email",
    "Enter a valid number": "Wpisz poprawną liczbę",
    "Error occurred:": "Wystąpił błąd:",
    "explore": "odkryj",
    "Explore offers around you": "Odkryj oferty w Twojej okolicy",
    "Favorites": "Ulubione",
    "favourites": "Ulubione",
    "Featured": "Premium",
    "Finish a visit to review it": "Zakończ wizytę, żeby móc ją ocenić",
    "First Name": "Imię",
    "From the total of:": "z następujących:",
    "From the total of:...visits": "wizyt",
    "German": "niemiecki",
    "Google": "Google",
    "Here is your special code.": "Twój specjalny kod.",
    "home": "home",
    "https://letsbookee.com": "https://letsbookee.com",
    "I agree to ": "Zgadzam się na ",
    "I don't have referral code": "Nie mam kodu partnerskiego",
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": "Przekonaj conajmniej 10 osób i zdobądź nagrodę specjalną. Podziel się z przyjaciółmi! Pomoż nam!!!",
    "Are you satisfied with Bookee and want to find even more offers?": "Jesteś zadowolony z Bookee i chciałbyś mieć nawet więcej ofert?",
    "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.": "Poleć nas swoim przyjaciołom, znajomym, fryzjerom, lekarzom i innym, aby otrzymać bonus za każdą subskrypcję.",
    "Get started now, support us and secure your reward!": "Zacznij już teraz, pomóż nam i zapewnij sobie nagrodę!",
    "Invalid data provided.": "Nieprawidłowa wartość.",
    "Invalid number": "Nieprawidłowa wartość",
    "Invite to join bookee": "Zaproś do bookee",
    "Klarna API url": "Klarna API URL",
    "Klarna Connection Not Working": "Połączenie z Klarna nie działa",
    "Klarna Connection Successful": "Połączenie z Klarna działa",
    "Klarna Connection Test": "Sprawdź połączenie z Klarna",
    "Klarna Intro Text": "Jeśli korzystasz już z Klarna w swojej firmie, tutaj możesz połączyć swoje konto",
    "Klarna Payments": "System płatności z góry - wkrótce dostępny",
    "Klarna username": "Klarna login",
    "Klarna password": "Klarna hasło",
    "Language": "Język",
    "Last Name": "Nazwisko",
    "Latitude": "Szerokość geograficzna",
    "Learn more": "Czytaj więcej",
    "loading...": "wczytywanie...",
    "Loading...": "Wczytywanie...",
    "Log in": "Zaloguj",
    "Log into your account": "Zaloguj się",
    "Login": "Zaloguj",
    "Login to finalize": "Zaloguj aby kontynuować",
    "Logout": "Wyloguj",
    "Longitude": "Długość geograficzna",
    "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ": "mailto:contact@letsbookee.com?subject=Rejestracja w bookee&body=Potrzebuję pomocy z logowaniem na etapie: ",
    "Map cannot be loaded": "Nie można wczytać mapy",
    "Mark for deletion": "Zaznacz do usunięcia",
    "Mark for publishing": "Pokaż ofertę w serwisie",
    "Month": "Miesiąc",
    "Monthly payments:": "Płatności miesięczne:",
    "Monthly payments:...Clients": "klientów",
    "Must be later": "Musi być później",
    "My Appointments": "Moje spotkania",
    "My Calendar": "Mój kalendarz",
    "Next": "Dalej",
    "No results found": "Brak wyników",
    "No visit selected": "Wybierz wizytę",
    "No, I decided to come": "Nie, jednak przyjdę.",
    "Not getting SMS?": "Nie dostałeś SMS?",
    "Notes:": "Notatki:",
    "OK": "OK",
    "OK, got it": "OK, rozumiem",
    "Online payment impossible": "Płatność online niemożliwa",
    "OR": "LUB",
    "Paid": "Zapłacono",
    "Pay": "Zapłać",
    "PaymentPage - Title": "Opłać wizytę",
    "PaymentPage - Description": "Nasze płatności są obsługiwane przez zewnętrznego partnera. Możesz sfinalizować transakcję za pomocą przycisku, lub kodu QR.",
    "PaymentPage - Finalize Payment": "Zapłać",
    "Photo": "Zdjęcie",
    "Phone number": "Numer telefonu",
    "PIN": "PIN",
    "Please contact us to change your phone number": "Skontaktuj się z nami, żeby zmienić numer telefonu",
    "Please enter partner code below or check that you don't have it": "Podaj kod partnera lub zaznacz jego brak.",
    "Please define your bookee": "Proszę, wybierz datę i czas wizyty",
    "Poland": "Polska",
    "Polish": "polski",
    "Portfolio": "Portfolio",
    "Prepare": "Przygotuj",
    "profile": "profil",
    "Publish": "Publikuj",
    "Rate and review your experience": "Oceń i opisz swoją wizytę",
    "Ready to publish": "Gotowe do publikacji",
    "Recommend Bookee": "Rekomenduj bookee",
    "Recommend Bookee and gain instant benefits": "Wypromuj Bookee i zyskaj natychmiastowe korzyści",
    "Referral code": "Kod partnera",
    "Referral statistics": "Program partnerski",
    "refresh reminders:": "odśwież przypomnienia:",
    "Replace": "Zamień",
    "Require manual confirmation of the booking": "Wymagaj ręcznego potwierdzenia wizyty",
    "Resource ID": "ID pracownika",
    "Resource Name": "Pracownik",
    "Resource edit: Phone number": "Numer telefonu",
    "Resources": "Pracownicy",
    "Return": "Powrót",
    "Review": "Komentarz",
    "Reviews": "Komentarze",
    "Save": "Zapisz",
    "Save account": "Zapisz konto",
    "Search": "Szukaj",
    "Search Keywords": "Słowa kluczowe",
    "Send to contacts": "Wyślij do znajomych",
    "Service Category": "Kategoria usługi",
    "Service ID": "ID usługi",
    "Service Name": "Nazwa usługi",
    "Services": "Usługi",
    "Share": "Podziel się",
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": "Przekaż Twój unikalny kod przyjaciołom i dostawcom usług. Gdy zalogują się do bookee, skontaktujemy się z Tobą i wręczymy nagrodę",
    "Show directions": "Wskazówki dojazdu",
    "Specify time as HH:mm": "Wprowadź czas jako HH:mm",
    "Staff members:": "Pracownicy:",
    "Statistics &amp; Reports": "Statystyki i Raporty",
    "Subtitle": "Podtytuł",
    "SuperAdmin: Assign Company": "Dołącz do firmy (admin)",
    "Switzerland": "Szwajcaria",
    "Bookee Tagline": "Darmowy i wygodny system rezerwacji",
    "terms and conditions": "warunki umowy",
    "Terms and conditions": "Warunki umowy",
    "Terms of Use": "Warunki używania",
    "THANK YOU": "DZIĘKUJEMY",
    "There are no offers that satisfy your criteria. We need to remove your filtering.": "Nie ma ofert, które spełniają Twoje kryteria. Sprobujemy usunąć dodatkowe filtry.",
    "This company has no portoflio": "Ta firma nie ma portoflio",
    "This company has no reviews yet": "Ta firma nie ma jeszcze komentarzy",
    "Time after [minutes]": "Czas po [minuty]",
    "Time before [minutes]": "Czas przed [minuty]",
    "Title": "Tytuł",
    "Under construction": "W przygotowaniu",
    "United Arab Emirates": "Zjednoczone Emiraty Arabskie",
    "Unique clients:": "Unikalnych klientow:",
    "Upcoming": "Nadchodzące",
    "Update map": "Aktualizuj mapę",
    "Upfront payment": "Płatność online",
    "Upfront payment - price": "Płatność online (kwota)",
    "Upfront payment - you need to pay upfront for this visit": "Płatność online - możesz zapłacić online za tę usługę",
    "Upfront payment - the client has to pay upfront online while booking a visit": "Dostępne wkrótce - Płatność online - Klient może dokonać płatności online",
    "View as client": "Podgląd",
    "Visit ID": "ID wizyty",
    "Visit Start": "Wizyta",
    "Visit Start for Client": "Wizyta (dla klienta)",
    "Visit statistics": "Statystki wizyt",
    "Visit Status": "Status",
    "VisitSummaryCard:When your request is accepted(1/2)": "Gdy Twoje zgłoszenie zostanie zaakceptowane",
    "VisitSummaryCard:When your request is accepted(2/2)": "wyślemy Ci SMS.",
    "VisitSummaryCard:Your request will be answered shortly.": "Wkrótce odpowiedmy na Twoje zgłoszenie.",
    "Waiting for your rating and review:": "Czekamy na Twój komentarz i ocenę:",
    "We need the following information before publishing:": "Potrzebujemy poniższych informacji, żeby opublikować Twoją ofertę:",
    "Week": "Tydzień",
    "Working Hours:": "Godziny pracy:",
    "Working hours": "Godziny pracy",
    "Write here...": "Napisz tutaj...",
    "Write review": "Napisz ocenę",
    "Yearly payments:": "Płatność roczna:",
    "Yearly payments:...Clients": "klientów",
    "Yes, please cancel": "Tak, anuluj",
    "You can select your favourite staff for the service and select your preferred date and time": "Możesz wybrać pracownika oraz datę i czas wizyty",
    "You have no completed visits": "Nie mamy informacji o Twojej zakończonej wizycie",
    "You have no favorites yet": "Nie mamy informacji o Twoich ulubionych firmach",
    "You have no upcoming visits": "Nie mamy informacji o Twoich nadchodzących wizytach",
    "You've earned:": "Zarobiłeś dotychczas:",
    "You've almost earned additional:": "Wkrótce możesz zarobić:",
    "You're done!": "Wizyta umówiona!",
    "We'll send you a reminder": "Wyślemi Ci przypomnienie",
    "Your referrals:": "Twoje polecenia:",
    "Your referrals:...Clients": "klientów",
    "before your appointment": "przed terminem spotkania",
    "Your company calendar...": "Kalendarz Twojej firmy...",
    "Your consent is required": "Twoja zgoda jest wymagana",
    "Your reviews:": "Twoje komentarze:",
    "Your session has expired.": "Twoja sesja wygasła.",
    "Zoom": "Zoom",
    'Besucher': 'Besucher',
    'Dienstleister': 'Dienstleister',
    'Für Besucher': 'Für Besucher',
    'Für Dienstleister': 'Für Dienstleister',
    'Email': 'Email',
    'Nachricht': 'Nachricht',
    'Name': 'Name',
    '100% kostenlos für Besucher': '100% kostenlos für Besucher',
    '100% kostenlos': '100% kostenlos',
    '100% online': '100% online',
    '12 Monate zum Preis von 10': '12 Monate zum Preis von 10',
    '24 / 7 / 365 Termine': '24 / 7 / 365 Termine',
    '24h Buchungsassistent': '24h Buchungsassistent',
    '30 Tage kostenfrei testen': '30 Tage kostenfrei testen',
    '49€': '49€',
    '499€': '499€',
    'ABONNIEREN': 'ABONNIEREN',
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': 'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.',
    'Am Sandfeld 18, 76149 Karlsruhe': 'Am Sandfeld 18, 76149 Karlsruhe',
    'Angebote für Dienstleister': 'Angebote für Dienstleister',
    'Buchen': 'Buchen',
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': 'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.',
    'Datenschutz &amp; AGB': 'Datenschutz &amp; AGB',
    'Direkt und zeitsparend': 'Direkt und zeitsparend',
    'Empfehlung': 'Empfehlung',
    'Erinnerungs-SMS mit Hinweise an Kunden': 'Erinnerungs-SMS mit Hinweise an Kunden',
    'FEATURES': 'FEATURES',
    'Features': 'Features',
    'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': 'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.',
    'HOME': 'HOME',
    'Hier sind wir': 'Hier sind wir',
    'Impressum': 'Impressum',
    'Jahres-Abo': 'Jahres-Abo',
    'KATEGORIEN': 'KATEGORIEN',
    'KONTAKT': 'KONTAKT',
    'Kategorien': 'Kategorien',
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': 'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?',
    'Kontaktiere uns': 'Kontaktiere uns',
    'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': 'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.',
    'Monats-Abo': 'Monats-Abo',
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': 'Möchtest du gerne als Dienstleister unseren Service nutzen?',
    'Nachricht erfolgreich gesendet': 'Nachricht erfolgreich gesendet',
    'Nutze unser Kontaktformular': 'Nutze unser Kontaktformular',
    'PROBIER ES AUS': 'WYPRÓBUJ',
    'Probier es aus': 'Wypróbuj',
    'SCREENSHOTS': 'SCREENSHOTS',
    'SMS-Erinnerung': 'SMS-Erinnerung',
    'Screenshots': 'Screenshots',
    'Senden': 'Senden',
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': 'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen',
    'TEAM': 'TEAM',
    'Unabhängige Flexibilität': 'Unabhängige Flexibilität',
    'Vorzugspreis': 'Vorzugspreis',
    'WARUM?': 'WARUM?',
    'Warum Bookee?': 'Warum Bookee?',
    'Was ist Bookee?': 'PL_Was ist Bookee?',
    'bitte füllen Sie das Formular aus': 'bitte füllen Sie das Formular aus',
    'inkl. Werbe-Material': 'inkl. Werbe-Material',
    'jederzeit buchbar': 'jederzeit buchbar',
    'komplette Übersicht': 'komplette Übersicht',
    'monatlich kündbar': 'monatlich kündbar',
    'weniger Zeit am Telefon': 'weniger Zeit am Telefon',
    'ÜBER UNS': 'ÜBER UNS',
    'MIT BOOKEE KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': 'ERSPARE DIR DIE WARTEZEIT UND BUCHE DEINEN SERVICE WIE EIN PROFI MIT BOOKEE',
};

export const L10N_ru: L10N_resx = {
    "resx_locale": "ru",
    "_defaultCurrency": "AED",
    "_dateAsOn": (value?: string) => moment(value).locale("ru").format("DD. MMM"),
    "_dateAsTimeAgo": (value?: string) => moment(value).locale("ru").fromNow(),
    "_dateAsDD": (value?: string) => moment(value).locale("ru").format("DD"),
    "_dateAsddd": (value?: string) => moment(value).locale("ru").format("ddd"),
    "_dateAsdddd": (value?: string) => moment(value).locale("ru").format("dddd"),
    "_dateAsMM": (value?: string) => moment(value).locale("ru").format("MM"),
    "_dateAsMMM": (value?: string) => moment(value).locale("ru").format("MMM"),
    "_dateAsMMMM": (value?: string) => moment(value).locale("ru").format("MMMM"),
    "_dateAsMMMMYYYY": (value?: string) => moment(value).locale("ru").format("MMMM, YYYY"),
    "_dateAsHHmm": (value?: string) => moment(value).locale("ru").format("HH:mm"),
    "_dateAsDDMMMHHmm": (value?: string) => moment(value).locale("ru").format("DD MMM HH:mm"),
    "_dateAsDayOrTomorrow": (value?: string) => moment(value).locale("en-gb").calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] ddd',
        sameElse: 'ddd'
    }),
    "_duration": (value?: number) => {
        if (!value) {
            return "-";
        } else if (value < 60) {
            return `${value}min`;
        } else if (value % 60 === 0) {
            return `${value / 60}h`;
        } else {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        }
    },
    "_category": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "Все";
            case "barber": return "Барбер";
            case "beauty-salon": return "Салон красоты";
            case "book": return "Повышение квалификации";
            case "car-shops": return "IGNORE";
            case "coach": return "Персональный тренер";
            case "doctor": return "IGNORE";
            case "ear": return "IGNORE";
            case "exercise": return "IGNORE";
            case "flat-tire": return "IGNORE";
            case "glasses": return "IGNORE";
            case "hairdresser": return "парикмахер";
            case "hairstyle": return "IGNORE";
            case "lips": return "Косметолог";
            case "massage": return "Массажист";
            case "mortar": return "IGNORE";
            case "nail-polish": return "Маникюрный салон";
            case "nutritionist": return "Питание";
            case "physical-therapy": return "Физиотерапия";
            case "piercing": return "Пирсинг";
            case "psychology": return "IGNORE";
            case "tattoo": return "Татуировка";
            case "tennis": return "IGNORE";
            case "tooth": return "IGNORE";
            case "veterinarian": return "IGNORE";
            case "_YAVU_Trainer": return "IGNORE";
            case "_YAVU_Beauty": return "IGNORE";
            default: return value;
        }
    },
    "_hint": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anytime": return "anytime";
            case "today": return "today";
            case "tomorrow": return "tomorrow";
            default: return value;
        }
    },
    "_editStep": (value?: EditStep) => {
        switch (value) {
            case "about": return "About you";
            case "address": return "Your address";
            case "for clients": return "For clients";
            case "services": return "Services";
            case "resources": return "Resources";
            case "publish": return "Publish";
            default: return value ?? "";
        }
    },
    "_status": (value?: VisitStatusEnum) => {
        switch (value) {
            case undefined: return "";
            case "Draft": return "Draft";
            case "Requested": return "Requested";
            case "Confirmed": return "Confirmed";
            case "Done": return "Done";
            case "CanceledByClient": return "Canceled by Client";
            case "CanceledByCompany": return "Canceled by Company";
            case "RescheduledByClient": return "Rescheduled by Client";
            default: return value;
        }
    },
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        if ((value?.price ?? 0) === 0) return "gratis";
        const currency = (!value?.currency) ? "AED"
            : value?.currency?.toUpperCase() === "EUR" ? "AED"
                : value?.currency?.toUpperCase() === "USD" ? "AED"
                    : value?.currency;
        return `${value?.price} ${currency}`;
    },
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        const currency = (!value?.currency) ? "AED"
            : value?.currency?.toUpperCase() === "EUR" ? "AED"
                : value?.currency?.toUpperCase() === "USD" ? "AED"
                    : value?.currency;
        return `${currency}`;
    },
    "_signalMessageType": (value?: SignalMessageType) => {
        switch (value) {
            case undefined: return "";
            case "Custom": return "Someone is interested in your company";
            case "CompanyViewed": return "Someone has just viewed your company";
            case "InviteCreated": return "Someone is looking for a visit";
            case "VisitCancelled": return "Someone has cancelled a visit";
            case "VisitConfirmed": return "Someone has confirmed a visit";
            case "VisitRescheduled": return "Someone has rescheduled a visit";
            default: return value;
        }
    },
    "_Go to marketing website": "Info",
    "Arabic": "arabic",
    "Russian": "русский",
    "Ukrainian": "украинец",
    "About us": "About us",
    "Account details": "Account details",
    "Account Details": "Account Details",
    "Add": "Add",
    "Add some resources to configure your offer": "Add some resources to configure your offer",
    "Add some services to configure your offer": "Add some services to configure your offer",
    "Add to calendar": "Add to calendar",
    "Add to Calendar": "Add to Calendar",
    "Additional info:": "Additional info:",
    "Address": "Address",
    "All clients": "All clients",
    "All services": "All services",
    "Apple or Microsoft": "Apple or Microsoft",
    "Appointment confirmed": "Appointment confirmed",
    "Appointment requested": "Appointment requested",
    "appointments": "appointments",
    "Are you sure?": "Are you sure?",
    "Are you sure that you want to cancel your visit?": "Are you sure that you want to cancel your visit?",
    "at server": "at server",
    "Austria": "Austria",
    "Back": "Back",
    "Banned clients": "Banned clients",
    "Belgium": "Belgium",
    "Block - the client will not be allowed to book a visit with your company": "Block - the client will not be allowed to book a visit with your company",
    "book": "book",
    "book and pay": "book and pay",
    "Book": "Book",
    "Book again": "Book again",
    "Book an Appointment": "Book an Appointment",
    "Booked Service:": "Booked Service:",
    "Bookee Category": "Bookee Category",
    "Cancel": "Cancel",
    "Cancel Visit": "Cancel Visit",
    "Cancel visit?": "Cancel visit?",
    "cannot be empty": "cannot be empty",
    "Cannot login?": "Cannot login?",
    "Change": "Change",
    "Click OK to un-block this client.": "Click OK to un-block this client.",
    "Click OK to block this client from your company.": "Click OK to block this client from your company.",
    "Client Name": "Client Name",
    "Client Phone": "Client Phone",
    "Clients": "Clients",
    "Closed": "Closed",
    "Company Calendar": "Company Calendar",
    "Company Email": "Company Email",
    "Company ID": "Company ID",
    "Company description:": "Company description",
    "Company Name": "Company Name",
    "Company Website": "Company Website",
    "Completed": "Completed",
    "Completed visits:": "Completed visits:",
    "Confirm the visit to client": "Confirm the visit to client",
    "Contact Details for Clients": "Contact Details for Clients",
    "Contact Details": "Contact Details",
    "Contact details:": "Contact details:",
    "Contact Email for Bookee": "Contact Email for Bookee",
    "Contact Phone for Bookee": "Contact Phone for Bookee",
    "contact us and help us resolving the issue.": "contact us and help us resolving the issue.",
    "Create Company": "Add your offer",
    "Create Company - save": "Create",
    "Czech Republic": "Czech Republic",
    "India": "India",
    "Day": "Day",
    "Delete": "Delete",
    "Duration [minutes]": "Default Duration [minutes]",
    "Default Price": "Default Price",
    "Demo": "Demo",
    "Description": "Description",
    "Description:": "Description:",
    "Details": "Details",
    "Display Name": "Display Name",
    "Deutschland": "Deutschland",
    "Do you have any special notes? Leave note here.": "Do you have any special notes? Leave note here.",
    "Download": "Download",
    "Edit": "Edit",
    "Edit Company": "Edit Company",
    "Edit Profile": "Edit Profile",
    "Edit review": "Edit review",
    "emailsubject:Invite to join bookee": "Invite to join bookee",
    "emailbody1:Check out this new bookee: ": "You can follow this link to register to Bookee: ",
    "emailbody2:Remember to use my referral code": " (this is from my partnership program)",
    "English": "English",
    "Enter a valid email": "Enter a valid email",
    "Enter a valid number": "Enter a valid number",
    "Error occurred:": "Error occurred:",
    "explore": "explore",
    "Explore offers around you": "Explore offers around you",
    "Favorites": "Favorites",
    "favourites": "favourites",
    "Featured": "Featured",
    "Finish a visit to review it": "Finish a visit to review it",
    "First Name": "First Name",
    "From the total of:": "From the total of:",
    "From the total of:...visits": "visits",
    "German": "German",
    "Google": "Google",
    "Here is your special code.": "Here is your referral link.",
    "home": "home",
    "https://letsbookee.com": "https://letsbookee.com",
    "I agree to ": "I agree to ",
    "I don't have referral code": "I don't have referral code",
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!",
    "Are you satisfied with Bookee and want to find even more offers?": "Are you satisfied with Bookee and want to find even more offers?",
    "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.": "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.",
    "Get started now, support us and secure your reward!": "Get started now, support us and secure your reward!",
    "Invalid number": "Invalid number",
    "Invalid data provided.": "Invalid data provided.",
    "Invite to join bookee": "Invite to join bookee",
    "Klarna API url": "Klarna API URL",
    "Klarna Connection Not Working": "Klarna Connection Not Working",
    "Klarna Connection Successful": "Klarna Connection Successful",
    "Klarna Connection Test": "Test Klarna Connection",
    "Klarna Intro Text": "If you already use Klarna in the shop, you can connect your Klarna account here",
    "Klarna Payments": "Upfront payments system - soon available",
    "Klarna username": "Klarna login",
    "Klarna password": "Klarna password",
    "Language": "Language",
    "Last Name": "Last Name",
    "Latitude": "Latitude",
    "Learn more": "Learn more",
    "loading...": "loading...",
    "Loading...": "Loading...",
    "Log in": "Log in",
    "Log into your account": "Log into your account",
    "Login": "Login",
    "Login to finalize": "Login to finalize",
    "Logout": "Logout",
    "Longitude": "Longitude",
    "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ": "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ",
    "Map cannot be loaded": "Map cannot be loaded",
    "Mark for deletion": "Mark for deletion",
    "Mark for publishing": "Show the offer to clients",
    "Month": "Month",
    "Monthly payments:": "Monthly payments:",
    "Monthly payments:...Clients": "Clients",
    "Must be later": "Must be later",
    "My Appointments": "My Appointments",
    "My Calendar": "My Calendar",
    "Next": "Next",
    "No results found": "No results found",
    "No visit selected": "No visit selected",
    "No, I decided to come": "No, I decided to come",
    "Not getting SMS?": "Not getting SMS?",
    "Notes:": "Notes:",
    "OK": "OK",
    "OK, got it": "OK, got it",
    "Online payment impossible": "Online payment impossible",
    "OR": "OR",
    "Paid": "Paid",
    "Pay": "Pay",
    "PaymentPage - Title": "Pay for the visit",
    "PaymentPage - Description": "Our payments are supported with external provider. You can finalize the payment using the button below, or scanning the QR code.",
    "PaymentPage - Finalize Payment": "Finalize Payment",
    "Phone number": "Phone number",
    "Photo": "Photo",
    "PIN": "PIN",
    "Please enter partner code below or check that you don't have it": "Please enter partner code below or check that you don't have it",
    "Please contact us to change your phone number": "Please contact us to change your phone number",
    "Please define your bookee": "Please select date and time of your visit",
    "Poland": "Poland",
    "Polish": "Polish",
    "Portfolio": "Portfolio",
    "Prepare": "Prepare",
    "profile": "profile",
    "Publish": "Publish",
    "Rate and review your experience": "Rate and review your experience",
    "Ready to publish": "Ready to publish",
    "Recommend Bookee": "Recommend Bookee",
    "Recommend Bookee and gain instant benefits": "Recommend Bookee and gain instant benefits",
    "Referral code": "Referral code",
    "Referral statistics": "Referral statistics",
    "refresh reminders:": "refresh reminders:",
    "Replace": "Replace",
    "Require manual confirmation of the booking": "Require manual confirmation of the booking",
    "Resource ID": "Resource ID",
    "Resource Name": "Resource Name",
    "Resource edit: Phone number": "Phone number",
    "Resources": "Resources",
    "Return": "Return",
    "Review": "Review",
    "Reviews": "Reviews",
    "Save": "Save",
    "Save account": "Save account",
    "Search": "Search",
    "Search Keywords": "Search Keywords",
    "Send to contacts": "Send to contacts",
    "Service Category": "Service Category",
    "Service ID": "Service ID",
    "Service Name": "Service Name",
    "Services": "Services",
    "Share": "Share",
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.",
    "Show directions": "Show directions",
    "Specify time as HH:mm": "Specify time as HH:mm",
    "Staff members:": "Staff members:",
    "Statistics &amp; Reports": "Statistics & Reports",
    "Subtitle": "Subtitle",
    "SuperAdmin: Assign Company": "Join the company (admin)",
    "Switzerland": "Switzerland",
    "Bookee Tagline": "Your appointments - free & easy",
    "terms and conditions": "terms and conditions",
    "Terms and conditions": "Terms and conditions",
    "Terms of Use": "Terms of Use",
    "THANK YOU": "THANK YOU",
    "There are no offers that satisfy your criteria. We need to remove your filtering.": "There are no offers that satisfy your criteria. We need to remove your filtering.",
    "This company has no portoflio": "This company has no portoflio",
    "This company has no reviews yet": "This company has no reviews yet",
    "Time after [minutes]": "Time after [minutes]",
    "Time before [minutes]": "Time before [minutes]",
    "Title": "Title",
    "Under construction": "Under construction",
    "United Arab Emirates": "United Arab Emirates",
    "Unique clients:": "Unique clients:",
    "Upcoming": "Upcoming",
    "Update map": "Update map",
    "Upfront payment": "Upfront payment",
    "Upfront payment - price": "Upfront payment (quote)",
    "Upfront payment - you need to pay upfront for this visit": "Upfront payment - you need to pay upfront for this visit",
    "Upfront payment - the client has to pay upfront online while booking a visit": "Upfront payment - the client has to pay upfront online while booking a visit",
    "View as client": "Preview",
    "Visit ID": "Visit ID",
    "Visit Start": "Visit",
    "Visit Start for Client": "Visit (for client)",
    "Visit statistics": "Visit statistics",
    "Visit Status": "Visit Status",
    "VisitSummaryCard:When your request is accepted(1/2)": "When your request is accepted",
    "VisitSummaryCard:When your request is accepted(2/2)": "we will send you an SMS.",
    "VisitSummaryCard:Your request will be answered shortly.": "Your request will be answered shortly.",
    "Waiting for your rating and review:": "Waiting for your rating and review:",
    "We need the following information before publishing:": "We need the following information before publishing:",
    "Week": "Week",
    "Working Hours:": "Working Hours:",
    "Working hours": "Working hours",
    "Write here...": "Write here...",
    "Write review": "Write review",
    "Yearly payments:": "Yearly payments:",
    "Yearly payments:...Clients": "Clients",
    "Yes, please cancel": "Yes, please cancel",
    "You can select your favourite staff for the service and select your preferred date and time": "You can select your favourite staff for the service and select your preferred date and time",
    "You have no completed visits": "You have no completed visits",
    "You have no favorites yet": "You have no favorites yet",
    "You have no upcoming visits": "You have no upcoming visits",
    "You're done!": "You're done!",
    "We'll send you a reminder": "We'll send you a reminder",
    "You've earned:": "You've earned:",
    "You've almost earned additional:": "You've almost earned additional:",
    "before your appointment": "before your appointment",
    "Your company calendar...": "Your company calendar...",
    "Your consent is required": "Your consent is required",
    "Your referrals:": "Your referrals:",
    "Your referrals:...Clients": "Clients",
    "Your reviews:": "Your reviews:",
    "Your session has expired.": "Your session has expired.",
    "Zoom": "Zoom",
    'Besucher': 'Посетители',
    'Dienstleister': 'Поставщик услуг',
    'Für Besucher': 'Для посетителя',
    'Für Dienstleister': 'Для поставщика услуг',
    'Email': 'Электронная почта',
    'Nachricht': 'Сообщение',
    'Name': 'Имя',
    '100% kostenlos für Besucher': '100% бесплатно для посетителей',
    '100% kostenlos': '100% бесплатно',
    '100% online': '100% онлайн',
    '12 Monate zum Preis von 10': '12 месяцев по цене 10',
    '24 / 7 / 365 Termine': '24 / 7 / 365 Дней',
    '24h Buchungsassistent': 'круглосуточный ассистент по бронированию',
    '30 Tage kostenfrei testen': '30 дней бесплатной пробной версии',
    '49€': 'AED 199',
    '499€': 'AED 1990',
    'ABONNIEREN': 'ПОДПИСАТЬСЯ',
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': 'В качестве примера: администратор тратит около 50% своего рабочего времени на разговоры по телефону, принимая заказы на прием, заглядывая в календарь, а также просматривая его, пока не будет найдено свободное время для процедуры. Это приводит к тому, что время ожидания увеличивается, а более важные задачи уходят в сторону.',
    'Am Sandfeld 18, 76149 Karlsruhe': 'Am Sandfeld 18, 76149 Karlsruhe, Germany',
    'Angebote für Dienstleister': 'Предложения для поставщиков услуг',
    'Buchen': 'Заказать',
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': 'Тогда просто напишите нам по электронной почте или через контактную форму, и мы свяжемся с вами как можно скорее',
    'Datenschutz &amp; AGB': 'Конфиденциальность и ОПУ (Общие положения и условия)',
    'Direkt und zeitsparend': 'Запись на прямую, что сэкономит время',
    'Empfehlung': 'Рекомендация',
    'Erinnerungs-SMS mit Hinweise an Kunden': 'SMS-напоминание с уведомлениями для клиентов',
    'FEATURES': 'ОСОБЕННОСТИ',
    'Features': 'Особенности',
    'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': 'Именно для этого мы и создали "Bookee". Мы разработали решение, которое дает вам, как клиенту, так и владельцу бизнеса, подходящий выход.',
    'HOME': 'ГЛАВНАЯ',
    'Hier sind wir': 'Мы здесь',
    'Impressum': 'Отпечаток',
    'Jahres-Abo': 'Годовая подписка',
    'KATEGORIEN': 'КАТЕГОРИИ',
    'KONTAKT': 'Связаться с',
    'Kategorien': 'Категории',
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': 'Знакомо ли вам такое: вы звоните парикмахеру, у вас уходит несколько минут на то, чтобы для вас нашли окно. Или когда вы хотите в выходные записаться на понедельник, но никто не берет трубку?',
    'Kontaktiere uns': 'Свяжитесь с нами',
    'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': 'С помощью Bookee вы всегда можете найти свободное окно в Интернете, забронировать его, а при необходимости изменить или даже отменить. Если вы занимаетесь бизнесом и устали от телефонных переговоров, чтобы договориться о встрече, Bookee - для вас. С Bookee в качестве вашего личного круглосуточного помощника по назначению встреч у вас появится больше времени для себя и для более важных вещей в вашей личной или профессиональной жизни.',
    'Monats-Abo': 'Ежемесячная подписка',
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': 'Вы хотели бы воспользоваться нашими услугами в качестве поставщика услуг?',
    'Nachricht erfolgreich gesendet': 'Сообщение успешно отправлено',
    'Nutze unser Kontaktformular': 'Используйте нашу контактную форму',
    'PROBIER ES AUS': 'ПОПРОБУЙТЕ',
    'Probier es aus': 'Попробуйте',
    'SCREENSHOTS': 'СКРИНШОТЫ',
    'SMS-Erinnerung': 'SMS-напоминание',
    'Screenshots': 'скриншоты',
    'Senden': 'послать',
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': 'Вы будете перенаправлены к нашему партнеру для завершения платежа',
    'TEAM': 'КОМАНДА',
    'Unabhängige Flexibilität': 'подключайтесь через социальные сети',
    'Vorzugspreis': 'Льготная цена',
    'WARUM?': 'ПОЧЕМУ?',
    'Warum Bookee?': 'Почему именно Bookee?',
    'Was ist Bookee?': 'Что такое Bookee?',
    'bitte füllen Sie das Formular aus': 'пожалуйста, заполните форму',
    'inkl. Werbe-Material': 'включая рекламные материалы',
    'jederzeit buchbar': 'возможность бронирования в любое время',
    'komplette Übersicht': 'полный обзор',
    'monatlich kündbar': 'может быть отменена ежемесячно',
    'weniger Zeit am Telefon': 'меньше времени на телефоне',
    'ÜBER UNS': 'О нас',
    'MIT BOOKEE KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': 'С ПОМОЩЬЮ BOOKEE ВЫ МОЖЕТЕ ЗАПИСАТЬСЯ НА ПРИЕМ БЕСПЛАТНО И КРУГЛОСУТОЧНО',
};

export const L10N_de: L10N_resx = {
    "resx_locale": "de",
    "_defaultCurrency": "EUR",
    "_dateAsOn": (value?: string) => moment(value).locale("de").format("DD. MMM"),
    "_dateAsTimeAgo": (value?: string) => moment(value).locale("de").fromNow(),
    "_dateAsDD": (value?: string) => moment(value).locale("de").format("DD"),
    "_dateAsddd": (value?: string) => moment(value).locale("de").format("ddd"),
    "_dateAsdddd": (value?: string) => moment(value).locale("de").format("dddd"),
    "_dateAsMM": (value?: string) => moment(value).locale("de").format("MM"),
    "_dateAsMMM": (value?: string) => moment(value).locale("de").format("MMM"),
    "_dateAsMMMM": (value?: string) => moment(value).locale("de").format("MMMM"),
    "_dateAsMMMMYYYY": (value?: string) => moment(value).locale("de").format("MMMM, YYYY"),
    "_dateAsHHmm": (value?: string) => moment(value).locale("de").format("HH:mm"),
    "_dateAsDDMMMHHmm": (value?: string) => moment(value).locale("de").format("DD MMM HH:mm"),
    "_dateAsDayOrTomorrow": (value?: string) => moment(value).locale("de").calendar({
        sameDay: '[Heute]',
        nextDay: '[Morgen]',
        nextWeek: 'ddd',
        lastDay: '[Gestern]',
        lastWeek: '[Letzte] ddd',
        sameElse: 'ddd'
    }),
    "_duration": (value?: number) => {
        if (!value) {
            return "-";
        } else if (value < 60) {
            return `${value}min`;
        } else if (value % 60 === 0) {
            return `${value / 60}h`;
        } else {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        }
    },
    "_category": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "Alles";
            case "barber": return "Barber";
            case "beauty-salon": return "Beauty-Salon";
            case "book": return "Weiterbildung";
            case "car-shops": return "Autohaus";
            case "coach": return "Personal Trainer";
            case "doctor": return "Arzt";
            case "ear": return "Hörgeräte";
            case "exercise": return "Yoga";
            case "flat-tire": return "Reifenservice";
            case "glasses": return "Optiker";
            case "hairdresser": return "Friseurstudio";
            case "hairstyle": return "Tiersalon";
            case "lips": return "Beauty-Doc";
            case "massage": return "Massage";
            case "mortar": return "Naturheilkunde";
            case "nail-polish": return "Nagelstudio";
            case "nutritionist": return "Ernährung";
            case "physical-therapy": return "Physiotherapie";
            case "piercing": return "Piercing";
            case "psychology": return "Psycholgie";
            case "tattoo": return "Tattoo";
            case "tennis": return "Sport";
            case "tooth": return "Zahnarzt";
            case "veterinarian": return "Tierarzt";
            case "_YAVU_Trainer": return "YAVU Trainer";
            case "_YAVU_Beauty": return "YAVU Beauty";
            default: return value;
        }
    },
    "_hint": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anytime": return "Jederzeit";
            case "today": return "Heute";
            case "tomorrow": return "Morgen";
            default: return value;
        }
    },
    "_editStep": (value?: EditStep) => {
        switch (value) {
            case "about": return "Ihre Firma";
            case "for clients": return "Über deine Firma";
            case "address": return "Deine Adresse";
            case "services": return "Dienstleistungen";
            case "resources": return "Mitarbeiter";
            case "publish": return "Veröffentlichen";
            default: return value ?? "";
        }
    },
    "_status": (value?: VisitStatusEnum) => {
        switch (value) {
            case undefined: return "";
            case "Draft": return "Entwurf";
            case "Requested": return "Angefragt";
            case "Confirmed": return "Bestätigt";
            case "Done": return "Abgeschlossen";
            case "CanceledByClient": return "Storniert durch Kunden";
            case "CanceledByCompany": return "Storniert durch Anbieter";
            case "RescheduledByClient": return "Verschoben durch Kunden";
            default: return value;
        }
    },
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        if ((value?.price ?? 0) === 0) return "kostenlos";
        const currency = (!value?.currency) ? "EUR"
            : value?.currency?.toUpperCase() === "EUR" ? "€"
                : value?.currency?.toUpperCase() === "USD" ? "$"
                    : value?.currency;
        return `${value?.price}${currency}`;
    },
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        const currency = (!value?.currency) ? "€"
            : value?.currency?.toUpperCase() === "EUR" ? "€"
                : value?.currency?.toUpperCase() === "USD" ? "$"
                    : value?.currency;
        return `${currency}`;
    },
    "_signalMessageType": (value?: SignalMessageType) => {
        switch (value) {
            case undefined: return "";
            case "Custom": return "Jemand hat sich für Ihr Angebot interessiert";
            case "CompanyViewed": return "Jemand beobachtet Ihr Angebot";
            case "InviteCreated": return "Jemand sucht einen Termin";
            case "VisitCancelled": return "Jemand hat den Besuch abgesagt";
            case "VisitConfirmed": return "Jemand bestätigte den Besuch";
            case "VisitRescheduled": return "Jemand hat den Besuch verschoben";
            default: return value;
        }
    },
    "_Go to marketing website": "info",
    "Arabic": "Arabisch",
    "Russian": "russisch",
    "Ukrainian": "ukrainisch",
    "About us": "Über uns",
    "Account details": "Nutzerdetails",
    "Account Details": "Nutzerdetails",
    "Add": "Hinzufügen",
    "Add some resources to configure your offer": "Fügen Sie einige Ressourcen hinzu, um Ihr Angebot zu konfigurieren",
    "Add some services to configure your offer": "Fügen Sie einen Service hinzu, um Ihr Angebot zu konfigurieren",
    "Add to calendar": "Im Kalender buchen",
    "Add to Calendar": "Im Kalender buchen",
    "Additional info:": "Zusätzliche Infos:",
    "Address": "Adresse",
    "All clients": "Alle Kunden",
    "All services": "Alle Dienstleistungen",
    "Apple or Microsoft": "Apple oder Microsoft",
    "Appointment confirmed": "Termin bestätigt",
    "Appointment requested": "Terminanfrage gesendet für",
    "appointments": "Termine",
    "Are you sure?": "Bist du sicher?",
    "Are you sure that you want to cancel your visit?": "Sicher das du deinen Termin stornieren willst?",
    "at server": "am Server",
    "Austria": "Österreich",
    "Back": "Zurück",
    "Banned clients": "Gesperrte Kunden",
    "Belgium": "Belgien",
    "Block - the client will not be allowed to book a visit with your company": "Blockieren - der Besucher kann keine Termine mehr bei Ihnen buchen",
    "book and pay": "buchen und bezahlen",
    "book": "buchen",
    "Book": "Buchen",
    "Book again": "Buch nochmal",
    "Book an Appointment": "Buche einen Termin",
    "Booked Service:": "Gebuchte Dienstleistungen:",
    "Bookee Category": "Bookee Kategorie",
    "Cancel": "Abbrechen",
    "Cancel Visit": "Termin stornieren",
    "Cancel visit?": "Termin stornieren?",
    "cannot be empty": "Kann nicht leer sein",
    "Cannot login?": "Kann nicht einloggen?",
    "Change": "Ändern",
    "Click OK to un-block this client.": "Klicken Sie auf OK, um diesen Client zu entsperren.",
    "Click OK to block this client from your company.": "Klicken Sie auf OK, um diesen Kunden von Ihrem Unternehmen zu blockieren.",
    "Client Name": "der Kunde",
    "Client Phone": "Telefon des Kunden",
    "Clients": "Kunden",
    "Closed": "geschlossen",
    "Company Calendar": "Geschäftskalender",
    "Company Email": "Firmen-E-Mail",
    "Company ID": "Firmen-ID",
    "Company description:": "Über uns",
    "Company Name": "Firmenname",
    "Company Website": "Firmenwebseite",
    "Completed": "Abgeschlossen",
    "Completed visits:": "Abgeschlossene Besuche:",
    "Confirm the visit to client": "Bestätigen Sie die Terminanfrage",
    "Contact Details for Clients": "Kontaktdaten für Kunden",
    "Contact Details": "Kontaktdaten",
    "Contact details:": "Telefon",
    "Contact Email for Bookee": "Kontakt-E-Mail für Bookee",
    "Contact Phone for Bookee": "Kontakttelefon für Bookee",
    "contact us and help us resolving the issue.": "Kontaktiere und hilf uns das Problem zu lösen.",
    "Create Company": "Fügen Sie Ihr Angebot hinzu",
    "Create Company - save": "Weiter",
    "Czech Republic": "Tschechien",
    "India": "India",
    "Day": "Tag",
    "Delete": "Löschen",
    "Duration [minutes]": "Hauptakteur [Minuten]",
    "Default Price": "Preis",
    "Description": "Beschreibung",
    "Description:": "Beschreibung:",
    "Details": "Details",
    "Display Name": "Anzeigename",
    "Demo": "Demo",
    "Deutschland": "Deutschland",
    "Do you have any special notes? Leave note here.": "Hast du eine besondere Anmerkung? Schreib sie hier rein.",
    "Download": "Download",
    "Edit": "Bearbeiten",
    "Edit Company": "Firma bearbeiten",
    "Edit Profile": "Profil bearbeiten",
    "Edit review": "Bewertung bearbeiten",
    "emailsubject:Invite to join bookee": "Einladen bookee beitreten",
    "emailbody1:Check out this new bookee: ": "Hallo, %0D%0A%0D%0Ahast du schon was von Bookee gehört? Ich nutze es schon und buche bereits meine Termine damit. %0D%0A%0D%0AMit Bookee können deine Besucher ganz einfach und bequem auch außerhalb deiner Arbeitszeit bei dir Termine buchen. %0D%0ATeste es jetzt 30 Tage lang völlig kostenlos. Falls dein Interesse geweckt ist, klicke einfach auf den Link und schau es dir an.: %0D%0A%0D%0A",
    "emailbody2:Remember to use my referral code": "%0D%0A%0D%0AWir wünschen dir viel Spaß, Freude und bei Fragen stehen wir dir gerne jederzeit zur Verfügung. %0D%0A%0D%0ADein Bookee-Team",
    "English": "English",
    "Enter a valid email": "Geben Sie eine gültige E-Mail ein",
    "Enter a valid number": "Geben Sie eine gültige Nummer ein",
    "Error occurred:": "Fehler aufgetreten:",
    "explore": "Suchen",
    "Explore offers around you": "Entdecke Angebote in deiner Nähe",
    "Favorites": "Favoriten",
    "favourites": "Favoriten",
    "Featured": "Premium",
    "Finish a visit to review it": "Beende einen Termin um eine Bewertung abzugeben",
    "First Name": "Vorname",
    "From the total of:": "Aus der Summe:",
    "From the total of:...visits": "Besuche",
    "German": "Deutsche",
    "Google": "Google",
    "Here is your special code.": "Hier ist dein Bookee-Link",
    "home": "Home",
    "https://letsbookee.com": "https://letsbookee.com",
    "I agree to ": "Ich stimme zu ",
    "I don't have referral code": "Ich habe keinen Empfehlungscode",
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": "Wenn du mehr als 10 Personen einlädst, erhälst du einen Sonderpreis. Leg jetzt los und unterstütz uns!!!",
    "Are you satisfied with Bookee and want to find even more offers?": "Bist du zufrieden mit Bookee und möchtest noch mehr Angebote finden?",
    "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.": "Dann empfehle uns doch deinen Freunden, Friseur, Arzt, oder anderen Dienstleister und erhalte bei jedem abgeschlossenen Abonnement eine Prämie.",
    "Get started now, support us and secure your reward!": "Leg jetzt los, unterstütze uns und sichere dir deine Prämie!",
    "Invalid data provided.": "Ungültige Daten eingegben.",
    "Invalid number": "Ungültige Daten eingegben",
    "Invite to join bookee": "Lade zu Bookee ein",
    "Klarna API url": "Klarna API-URL",
    "Klarna Connection Not Working": "Klarna-Verbindung funktioniert nicht",
    "Klarna Connection Successful": "Klarna-Verbindung erfolgreich",
    "Klarna Connection Test": "Klarna-Verbindung testen",
    "Klarna Intro Text": "Falls Sie bereits Klarna im Geschäft nutzen, können Sie Ihr Klarna-Konto hier verbinden",
    "Klarna Payments": "Vorauszahlungssystem – bald verfügbar",
    "Klarna username": "Klarna-Login",
    "Klarna password": "Klarna-Passwort",
    "Language": "Sprache",
    "Last Name": "Nachname",
    "Latitude": "Breitengrad",
    "Learn more": "Mehr dazu",
    "loading...": "Lädt...",
    "Loading...": "Lädt...",
    "Log in": "Log in",
    "Log into your account": "Melde dich an",
    "Login": "Anmelden",
    "Login to finalize": "ZUR BUCHUNG BITTE ANMELDEN",
    "Logout": "Abmelden",
    "Longitude": "Längengrad",
    "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ": "mailto:contact@letsbookee.com?subject=Login to bookee&body=Ich benötige Hilfe beim Login in der Phase: ",
    "Map cannot be loaded": "Karte kann nicht geladen werden",
    "Mark for deletion": "Markiere es zum Löschen",
    "Mark for publishing": "Hier klicken um dein Profil sichtbar zu schalten",
    "Month": "Monat",
    "Monthly payments:": "Monatliche Zahlungen:",
    "Monthly payments:...Clients": "Monats-Abo Kunden",
    "Must be later": "Muss später sein",
    "My Appointments": "Meine Termine",
    "My Calendar": "Mein Kalender",
    "Next": "Weiter",
    "No results found": "Kein Ergebnis gefunden",
    "No visit selected": "Kein Besuch ausgewählt",
    "No, I decided to come": "Nein, ich komme doch",
    "Not getting SMS?": "Keine SMS bekommen?",
    "Notes:": "Vermerk:",
    "OK": "Ok",
    "OK, got it": "Ok, verstanden",
    "Online payment impossible": "Online-Zahlung unmöglich",
    "OR": "Oder",
    "Paid": "Bezahlt",
    "Pay": "Zahlen",
    "PaymentPage - Title": "Bezahlen Sie den Besuch",
    "PaymentPage - Description": "Unsere Zahlungen werden mit externen Anbietern unterstützt. Sie können die Zahlung über die Schaltfläche unten abschließen oder den QR-Code scannen. ",
    "PaymentPage - Finalize Payment": "Zahlung abschließen",
    "Phone number": "Telefonnummer",
    "Photo": "Foto",
    "PIN": "PIN",
    "Please contact us to change your phone number": "Bitte kontaktiere uns um deine Telefonnummer zu ändern",
    "Please enter partner code below or check that you don't have it": "Falls Sie einen Code haben, geben Sie unten den Code unten ein",
    "Please define your bookee": "Bitte wähle Datum & Uhrzeit aus",
    "Poland": "Polen",
    "Polish": "Polnisch",
    "Portfolio": "Galerie",
    "Prepare": "Bereite vor",
    "profile": "Profil",
    "Publish": "Veröffentlichen",
    "Rate and review your experience": "Bewerte und überprüfe deine Erfahrung",
    "Ready to publish": "Bereit zur Veröffentlichung",
    "Recommend Bookee": "Bookee empfehlen",
    "Recommend Bookee and gain instant benefits": "Empfehle Bookee weiter",
    "Referral code": "Partnercode",
    "Referral statistics": "Partner",
    "refresh reminders:": "Erinnerungen aktualisieren:",
    "Replace": "Ersetzen",
    "Require manual confirmation of the booking": "Manuelle Bestätigung der Buchung erforderlich",
    "Resource ID": "Mitarbeiter/in-ID",
    "Resource Name": "Mitarbeiter/in",
    "Resource edit: Phone number": "Telefonnummer",
    "Resources": "Wer macht das?",
    "Return": "Zurück",
    "Review": "Bewerten",
    "Reviews": "Bewertungen",
    "Save": "Speichern",
    "Save account": "Konto speichern",
    "Search": "Suche",
    "Search Keywords": "Suchbegriffe",
    "Send to contacts": "Via Email teilen",
    "Service Category": "Servicekategorie",
    "Service ID": "Service-ID",
    "Service Name": "Titel der Dienstleistung",
    "Services": "Services",
    "Share": "Teilen",
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": "Teilen Sie Ihren einzigartigen Code mit Freunden und Geschäftsinhabern. Wenn sie unseren Service abonnieren, werden wir Sie mit Vorteilen kontaktieren.",
    "Show directions": "Zeige den Weg",
    "Specify time as HH:mm": "Geben Sie die Zeit an HH:mm",
    "Staff members:": "Angestellte:",
    "Statistics &amp; Reports": "Statistik & Berichte",
    "Subtitle": "Untertitel",
    "SuperAdmin: Assign Company": "Der Firma beitreten (Admin)",
    "Switzerland": "Schweiz",
    "Bookee Tagline": "deine Termine einfach & kostenlos ",
    "terms and conditions": "Geschäftsbedingungen",
    "Terms and conditions": "Geschäftsbedingungen",
    "Terms of Use": "Nutzungsbedingungen",
    "THANK YOU": "VIELEN DANK",
    "There are no offers that satisfy your criteria. We need to remove your filtering.": "Es gibt keine Angebote, die Ihren Kriterien entsprechen.Wir müssen Ihre Filterung entfernen.",
    "This company has no portoflio": "Dieser Nutzer hat keine Galerie",
    "This company has no reviews yet": "Dieser Nutzer hat noch keine Bewertungen",
    "Time after [minutes]": "Assistent/in [Minuten]",
    "Time before [minutes]": "Assistent/in [Minuten]",
    "Title": "Titel",
    "Under construction": "im Bau",
    "United Arab Emirates": "Vereinigte Arabische Emirate",
    "Unique clients:": "Einzigartige Kunden:",
    "Upcoming": "Demnächst",
    "Update map": "Karte aktualisieren",
    "Upfront payment": "Vorabzahlung",
    "Upfront payment - price": "Vorabzahlung (die Quote)",
    "Upfront payment - you need to pay upfront for this visit": "Vorabzahlung - Sie müssen für diesen Besuch im Voraus bezahlen",
    "Upfront payment - the client has to pay upfront online while booking a visit": "Kommt demnächst - Vorabzahlung - der Besucher muss beim Buchen im Voraus zahlen",
    "View as client": "Vorschau",
    "Visit ID": "Termin ID",
    "Visit Start": "Termin",
    "Visit Start for Client": "Termin (für den Kunden)",
    "Visit statistics": "Besucher",
    "Visit Status": "Status",
    "VisitSummaryCard:When your request is accepted(1/2)": "Bei der Annahme deiner Anfrage erhälst du von uns",
    "VisitSummaryCard:When your request is accepted(2/2)": "eine SMS mit der Terminbestätigung.",
    "VisitSummaryCard:Your request will be answered shortly.": "In Kürze wird Ihre Anfrage beantwortet.",
    "Waiting for your rating and review:": "Wartet auf deine Bewertung:",
    "We need the following information before publishing:": "Zur Veröffentlichung Ihres Angebots benötigen wir folgende Angaben:",
    "Week": "Woche",
    "Working Hours:": "Öffnungszeiten:",
    "Working hours": "Öffnungszeiten",
    "Write here...": "Schreib hier...",
    "Write review": "Schreib die Bewertung",
    "Yearly payments:": "Jährliche Zahlungen:",
    "Yearly payments:...Clients": "Jahres-Abo Kunden",
    "Yes, please cancel": "Ja, bitte stornieren",
    "You can select your favourite staff for the service and select your preferred date and time": "Du kannst deinen Lieblingsansprechpartner für den Service auswählen und dein bevorzugten Zeitpunkt auswählen",
    "You have no completed visits": "Du hast bis jetzt keine Termine abgeschlossen",
    "You have no favorites yet": "Du hast noch keine Favoriten",
    "You have no upcoming visits": "Du hast keine anstehende Termine",
    "You're done!": "Du bist fertig!",
    "We'll send you a reminder": "Wir werden dir eine Erinnerung",
    "You've earned:": "Du hast verdient:",
    "You've almost earned additional:": "Das hast du fast zusätzlich verdient:",
    "before your appointment": "zum Termin schicken",
    "Your company calendar...": "Dein Geschäftskalender...",
    "Your consent is required": "Deine Zustimmung ist erforderlich",
    "Your referrals:": "Deine Empfehlungen:",
    "Your referrals:...Clients": "Deine beworbenen Kunden",
    "Your reviews:": "Deine Bewertungen:",
    "Your session has expired.": "Deine Sitzung ist abgelaufen.",
    "Zoom": "Zoom",
    'Besucher': 'Besucher',
    'Dienstleister': 'Dienstleister',
    'Für Besucher': 'Für Besucher',
    'Für Dienstleister': 'Für Dienstleister',
    'Email': 'Email',
    'Nachricht': 'Nachricht',
    'Name': 'Name',
    '100% kostenlos für Besucher': '100% kostenlos für Besucher',
    '100% kostenlos': '100% kostenlos',
    '100% online': '100% online',
    '12 Monate zum Preis von 10': '12 Monate zum Preis von 10',
    '24 / 7 / 365 Termine': '24 / 7 / 365 Termine',
    '24h Buchungsassistent': '24h Buchungsassistent',
    '30 Tage kostenfrei testen': '30 Tage kostenfrei testen',
    '49€': '49€',
    '499€': '499€',
    'ABONNIEREN': 'ABONNIEREN',
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': 'Als Beispiel: Eine Empfangsdame verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten, und wichtigere Aufgaben bleiben dabei auf der Strecke.',
    'Am Sandfeld 18, 76149 Karlsruhe': 'Am Sandfeld 18, 76149 Karlsruhe, Germany',
    'Angebote für Dienstleister': 'Angebote für Dienstleister',
    'Buchen': 'Buchen',
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': 'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.',
    'Datenschutz &amp; AGB': 'Datenschutz & AGB',
    'Direkt und zeitsparend': 'Direkt und zeitsparend',
    'Empfehlung': 'Empfehlung',
    'Erinnerungs-SMS mit Hinweise an Kunden': 'Erinnerungs-SMS mit Hinweise an Kunden',
    'FEATURES': 'FEATURES',
    'Features': 'Features',
    'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': 'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.',
    'HOME': 'HOME',
    'Hier sind wir': 'Hier sind wir',
    'Impressum': 'Impressum',
    'Jahres-Abo': 'Jahres-Abo',
    'KATEGORIEN': 'KATEGORIEN',
    'KONTAKT': 'KONTAKT',
    'Kategorien': 'Kategorien',
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': 'Kennst du das, du rufst beim Friseur an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag vereinbaren möchtest, aber niemand geht ans Telefon?',
    'Kontaktiere uns': 'Kontaktiere uns',
    'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': 'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.',
    'Monats-Abo': 'Monats-Abo',
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': 'Möchtest du gerne als Dienstleister unseren Service nutzen?',
    'Nachricht erfolgreich gesendet': 'Nachricht erfolgreich gesendet',
    'Nutze unser Kontaktformular': 'Nutze unser Kontaktformular',
    'PROBIER ES AUS': 'PROBIER ES AUS',
    'Probier es aus': 'Probier es aus',
    'SCREENSHOTS': 'SCREENSHOTS',
    'SMS-Erinnerung': 'SMS-Erinnerung',
    'Screenshots': 'Screenshots',
    'Senden': 'Senden',
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': 'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen',
    'TEAM': 'TEAM',
    'Unabhängige Flexibilität': 'mit Social Media verbinden',
    'Vorzugspreis': 'Vorzugspreis',
    'WARUM?': 'WARUM?',
    'Warum Bookee?': 'Warum Bookee?',
    'Was ist Bookee?': 'Was ist Bookee?',
    'bitte füllen Sie das Formular aus': 'bitte füllen Sie das Formular aus',
    'inkl. Werbe-Material': 'inkl. Werbematerial',
    'jederzeit buchbar': 'jederzeit buchbar',
    'komplette Übersicht': 'komplette Übersicht',
    'monatlich kündbar': 'monatlich kündbar',
    'weniger Zeit am Telefon': 'weniger Zeit am Telefon',
    'ÜBER UNS': 'Über uns',
    'MIT BOOKEE KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': 'MIT BOOKEE KANNST DU KOSTENLOS & BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN',
};

export const L10N_ae: L10N_resx = {
    "resx_locale": "ae",
    "_defaultCurrency": "AED",
    "_dateAsOn": (value?: string) => moment(value).locale("ae").format("DD. MMM"),
    "_dateAsTimeAgo": (value?: string) => moment(value).locale("ae").fromNow(),
    "_dateAsDD": (value?: string) => moment(value).locale("ae").format("DD"),
    "_dateAsddd": (value?: string) => moment(value).locale("ae").format("ddd"),
    "_dateAsdddd": (value?: string) => moment(value).locale("ae").format("dddd"),
    "_dateAsMM": (value?: string) => moment(value).locale("ae").format("MM"),
    "_dateAsMMM": (value?: string) => moment(value).locale("ae").format("MMM"),
    "_dateAsMMMM": (value?: string) => moment(value).locale("ae").format("MMMM"),
    "_dateAsMMMMYYYY": (value?: string) => moment(value).locale("ae").format("MMMM, YYYY"),
    "_dateAsHHmm": (value?: string) => moment(value).locale("ae").format("HH:mm"),
    "_dateAsDDMMMHHmm": (value?: string) => moment(value).locale("ae").format("DD MMM HH:mm"),
    "_dateAsDayOrTomorrow": (value?: string) => moment(value).locale("ae").calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] ddd',
        sameElse: 'ddd'
    }),
    "_duration": (value?: number) => {
        if (!value) {
            return "-";
        } else if (value < 60) {
            return `${value}min`;
        } else if (value % 60 === 0) {
            return `${value / 60}h`;
        } else {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`;
        }
    },
    "_category": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anything": return "الكل";
            case "barber": return "حلاق";
            case "beauty-salon": return "صالون تجميل";
            case "book": return "مواصلة التعليم";
            case "car-shops": return "IGNORE";
            case "coach": return "مدرب شخصي";
            case "doctor": return "IGNORE";
            case "ear": return "IGNORE";
            case "exercise": return "IGNORE";
            case "flat-tire": return "IGNORE";
            case "glasses": return "IGNORE";
            case "hairdresser": return "صالون تصفيف الشعر";
            case "hairstyle": return "IGNORE";
            case "lips": return "دكتور تجميل";
            case "massage": return "تدليك";
            case "mortar": return "IGNORE";
            case "nail-polish": return "صالون العناية بالأظافر";
            case "nutritionist": return "تغذية";
            case "physical-therapy": return "علاج طبيعي";
            case "piercing": return "بيرسينج";
            case "psychology": return "IGNORE";
            case "tattoo": return "تاتو";
            case "tennis": return "IGNORE";
            case "tooth": return "IGNORE";
            case "veterinarian": return "IGNORE";
            case "_YAVU_Trainer": return "IGNORE";
            case "_YAVU_Beauty": return "IGNORE";
            default: return value;
        }
    },
    "_hint": (value?: string) => {
        switch (value) {
            case undefined: return "";
            case "anytime": return "anytime";
            case "today": return "today";
            case "tomorrow": return "tomorrow";
            default: return value;
        }
    },
    "_editStep": (value?: EditStep) => {
        switch (value) {
            case "about": return "About you";
            case "address": return "Your address";
            case "for clients": return "For clients";
            case "services": return "Services";
            case "resources": return "Resources";
            case "publish": return "Publish";
            default: return value ?? "";
        }
    },
    "_status": (value?: VisitStatusEnum) => {
        switch (value) {
            case undefined: return "";
            case "Draft": return "Draft";
            case "Requested": return "Requested";
            case "Confirmed": return "Confirmed";
            case "Done": return "Done";
            case "CanceledByClient": return "Canceled by Client";
            case "CanceledByCompany": return "Canceled by Company";
            case "RescheduledByClient": return "Rescheduled by Client";
            default: return value;
        }
    },
    "_priceAndCurrency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        if ((value?.price ?? 0) === 0) return "gratis";
        const currency = (!value?.currency) ? "AED"
            : value?.currency?.toUpperCase() === "EUR" ? "AED"
                : value?.currency?.toUpperCase() === "USD" ? "AED"
                    : value?.currency;
        return `${value?.price}${currency}`;
    },
    "_currency": (value?: PriceAndCurrency | PriceAndCurrencyEditModel) => {
        const currency = (!value?.currency) ? "AED"
            : value?.currency?.toUpperCase() === "EUR" ? "AED"
                : value?.currency?.toUpperCase() === "USD" ? "AED"
                    : value?.currency;
        return `${currency}`;
    },
    "_signalMessageType": (value?: SignalMessageType) => {
        switch (value) {
            case undefined: return "";
            case "Custom": return "Someone is interested in your company";
            case "CompanyViewed": return "Someone has just viewed your company";
            case "InviteCreated": return "Someone is looking for a visit";
            case "VisitCancelled": return "Someone has cancelled a visit";
            case "VisitConfirmed": return "Someone has confirmed a visit";
            case "VisitRescheduled": return "Someone has rescheduled a visit";
            default: return value;
        }
    },
    "_Go to marketing website": "info",
    "Arabic": "عربى",
    "Russian": "الروسية",
    "Ukrainian": "اوكراني",
    "About us": "About us",
    "Account details": "Account details",
    "Account Details": "Account Details",
    "Add": "Add",
    "Add some resources to configure your offer": "Add some resources to configure your offer",
    "Add some services to configure your offer": "Add some services to configure your offer",
    "Add to calendar": "Add to calendar",
    "Add to Calendar": "Add to Calendar",
    "Additional info:": "Additional info:",
    "Address": "Address",
    "All clients": "All clients",
    "All services": "All services",
    "Apple or Microsoft": "Apple or Microsoft",
    "Appointment confirmed": "Appointment confirmed",
    "Appointment requested": "Appointment requested",
    "appointments": "appointments",
    "Are you sure?": "Are you sure?",
    "Are you sure that you want to cancel your visit?": "Are you sure that you want to cancel your visit?",
    "at server": "at server",
    "Austria": "Austria",
    "Back": "Back",
    "Banned clients": "Banned clients",
    "Belgium": "Belgium",
    "Block - the client will not be allowed to book a visit with your company": "Block - the client will not be allowed to book a visit with your company",
    "book": "book",
    "book and pay": "book and pay",
    "Book": "Book",
    "Book again": "Book again",
    "Book an Appointment": "Book an Appointment",
    "Booked Service:": "Booked Service:",
    "Bookee Category": "Bookee Category",
    "Cancel": "Cancel",
    "Cancel Visit": "Cancel Visit",
    "Cancel visit?": "Cancel visit?",
    "cannot be empty": "cannot be empty",
    "Cannot login?": "Cannot login?",
    "Change": "Change",
    "Click OK to un-block this client.": "Click OK to un-block this client.",
    "Click OK to block this client from your company.": "Click OK to block this client from your company.",
    "Client Name": "Client Name",
    "Client Phone": "Client Phone",
    "Clients": "Clients",
    "Closed": "Closed",
    "Company Calendar": "Company Calendar",
    "Company Email": "Company Email",
    "Company ID": "Company ID",
    "Company description:": "Company description",
    "Company Name": "Company Name",
    "Company Website": "Company Website",
    "Completed": "Completed",
    "Completed visits:": "Completed visits:",
    "Confirm the visit to client": "Confirm the visit to client",
    "Contact Details for Clients": "Contact Details for Clients",
    "Contact Details": "Contact Details",
    "Contact details:": "Contact details:",
    "Contact Email for Bookee": "Contact Email for Bookee",
    "Contact Phone for Bookee": "Contact Phone for Bookee",
    "contact us and help us resolving the issue.": "contact us and help us resolving the issue.",
    "Create Company": "Add your offer",
    "Create Company - save": "Create",
    "Czech Republic": "Czech Republic",
    "India": "India",
    "Day": "Day",
    "Delete": "Delete",
    "Duration [minutes]": "Default Duration [minutes]",
    "Default Price": "Default Price",
    "Demo": "Demo",
    "Description": "Description",
    "Description:": "Description:",
    "Details": "Details",
    "Display Name": "Display Name",
    "Deutschland": "Deutschland",
    "Do you have any special notes? Leave note here.": "Do you have any special notes? Leave note here.",
    "Download": "Download",
    "Edit": "Edit",
    "Edit Company": "Edit Company",
    "Edit Profile": "Edit Profile",
    "Edit review": "Edit review",
    "emailsubject:Invite to join bookee": "Invite to join bookee",
    "emailbody1:Check out this new bookee: ": "You can follow this link to register to Bookee: ",
    "emailbody2:Remember to use my referral code": " (this is from my partnership program)",
    "English": "English",
    "Enter a valid email": "Enter a valid email",
    "Enter a valid number": "Enter a valid number",
    "Error occurred:": "Error occurred:",
    "explore": "explore",
    "Explore offers around you": "Explore offers around you",
    "Favorites": "Favorites",
    "favourites": "favourites",
    "Featured": "Featured",
    "Finish a visit to review it": "Finish a visit to review it",
    "First Name": "First Name",
    "From the total of:": "From the total of:",
    "From the total of:...visits": "visits",
    "German": "German",
    "Google": "Google",
    "Here is your special code.": "Here is your referral link.",
    "home": "home",
    "https://letsbookee.com": "https://letsbookee.com",
    "I agree to ": "I agree to ",
    "I don't have referral code": "I don't have referral code",
    "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!": "If you convince more than 10 people, you can expect a special prize. Go there! Help us!!!",
    "Are you satisfied with Bookee and want to find even more offers?": "Are you satisfied with Bookee and want to find even more offers?",
    "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.": "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out.",
    "Get started now, support us and secure your reward!": "Get started now, support us and secure your reward!",
    "Invalid number": "Invalid number",
    "Invalid data provided.": "Invalid data provided.",
    "Invite to join bookee": "Invite to join bookee",
    "Klarna API url": "Klarna API URL",
    "Klarna Connection Not Working": "Klarna Connection Not Working",
    "Klarna Connection Successful": "Klarna Connection Successful",
    "Klarna Connection Test": "Test Klarna Connection",
    "Klarna Intro Text": "If you already use Klarna in the shop, you can connect your Klarna account here",
    "Klarna Payments": "Upfront payments system - soon available",
    "Klarna username": "Klarna login",
    "Klarna password": "Klarna password",
    "Language": "Language",
    "Last Name": "Last Name",
    "Latitude": "Latitude",
    "Learn more": "Learn more",
    "loading...": "loading...",
    "Loading...": "Loading...",
    "Log in": "Log in",
    "Log into your account": "Log into your account",
    "Login": "Login",
    "Login to finalize": "Login to finalize",
    "Logout": "Logout",
    "Longitude": "Longitude",
    "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ": "mailto:contact@letsbookee.com?subject=Login to bookee&body=I need assistance with login at stage: ",
    "Map cannot be loaded": "Map cannot be loaded",
    "Mark for deletion": "Mark for deletion",
    "Mark for publishing": "Show the offer to clients",
    "Month": "Month",
    "Monthly payments:": "Monthly payments:",
    "Monthly payments:...Clients": "Clients",
    "Must be later": "Must be later",
    "My Appointments": "My Appointments",
    "My Calendar": "My Calendar",
    "Next": "Next",
    "No results found": "No results found",
    "No visit selected": "No visit selected",
    "No, I decided to come": "No, I decided to come",
    "Not getting SMS?": "Not getting SMS?",
    "Notes:": "Notes:",
    "OK": "OK",
    "OK, got it": "OK, got it",
    "Online payment impossible": "Online payment impossible",
    "OR": "OR",
    "Paid": "Paid",
    "Pay": "Pay",
    "PaymentPage - Title": "Pay for the visit",
    "PaymentPage - Description": "Our payments are supported with external provider. You can finalize the payment using the button below, or scanning the QR code.",
    "PaymentPage - Finalize Payment": "Finalize Payment",
    "Phone number": "Phone number",
    "Photo": "Photo",
    "PIN": "PIN",
    "Please enter partner code below or check that you don't have it": "Please enter partner code below or check that you don't have it",
    "Please contact us to change your phone number": "Please contact us to change your phone number",
    "Please define your bookee": "Please select date and time of your visit",
    "Poland": "Poland",
    "Polish": "Polish",
    "Portfolio": "Portfolio",
    "Prepare": "Prepare",
    "profile": "profile",
    "Publish": "Publish",
    "Rate and review your experience": "Rate and review your experience",
    "Ready to publish": "Ready to publish",
    "Recommend Bookee": "Recommend Bookee",
    "Recommend Bookee and gain instant benefits": "Recommend Bookee and gain instant benefits",
    "Referral code": "Referral code",
    "Referral statistics": "Referral statistics",
    "refresh reminders:": "refresh reminders:",
    "Replace": "Replace",
    "Require manual confirmation of the booking": "Require manual confirmation of the booking",
    "Resource ID": "Resource ID",
    "Resource Name": "Resource Name",
    "Resource edit: Phone number": "Phone number",
    "Resources": "Resources",
    "Return": "Return",
    "Review": "Review",
    "Reviews": "Reviews",
    "Save": "Save",
    "Save account": "Save account",
    "Search": "Search",
    "Search Keywords": "Search Keywords",
    "Send to contacts": "Send to contacts",
    "Service Category": "Service Category",
    "Service ID": "Service ID",
    "Service Name": "Service Name",
    "Services": "Services",
    "Share": "Share",
    "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.": "Share your unique code with friends and business owners. When they subscribe to our service, we'll contact you with benefits.",
    "Show directions": "Show directions",
    "Specify time as HH:mm": "Specify time as HH:mm",
    "Staff members:": "Staff members:",
    "Statistics &amp; Reports": "Statistics & Reports",
    "Subtitle": "Subtitle",
    "SuperAdmin: Assign Company": "Join the company (admin)",
    "Switzerland": "Switzerland",
    "Bookee Tagline": "Your appointments - free & easy",
    "terms and conditions": "terms and conditions",
    "Terms and conditions": "Terms and conditions",
    "Terms of Use": "Terms of Use",
    "THANK YOU": "THANK YOU",
    "There are no offers that satisfy your criteria. We need to remove your filtering.": "There are no offers that satisfy your criteria. We need to remove your filtering.",
    "This company has no portoflio": "This company has no portoflio",
    "This company has no reviews yet": "This company has no reviews yet",
    "Time after [minutes]": "Time after [minutes]",
    "Time before [minutes]": "Time before [minutes]",
    "Title": "Title",
    "Under construction": "Under construction",
    "United Arab Emirates": "United Arab Emirates",
    "Unique clients:": "Unique clients:",
    "Upcoming": "Upcoming",
    "Update map": "Update map",
    "Upfront payment": "Upfront payment",
    "Upfront payment - price": "Upfront payment (quote)",
    "Upfront payment - you need to pay upfront for this visit": "Upfront payment - you need to pay upfront for this visit",
    "Upfront payment - the client has to pay upfront online while booking a visit": "Coming soon - Upfront payment - the client has to pay upfront online while booking a visit",
    "View as client": "Preview",
    "Visit ID": "Visit ID",
    "Visit Start": "Visit",
    "Visit Start for Client": "Visit (for client)",
    "Visit statistics": "Visit statistics",
    "Visit Status": "Visit Status",
    "VisitSummaryCard:When your request is accepted(1/2)": "When your request is accepted",
    "VisitSummaryCard:When your request is accepted(2/2)": "we will send you an SMS.",
    "VisitSummaryCard:Your request will be answered shortly.": "Your request will be answered shortly.",
    "Waiting for your rating and review:": "Waiting for your rating and review:",
    "We need the following information before publishing:": "We need the following information before publishing:",
    "Week": "Week",
    "Working Hours:": "Working Hours:",
    "Working hours": "Working hours",
    "Write here...": "Write here...",
    "Write review": "Write review",
    "Yearly payments:": "Yearly payments:",
    "Yearly payments:...Clients": "Clients",
    "Yes, please cancel": "Yes, please cancel",
    "You can select your favourite staff for the service and select your preferred date and time": "You can select your favourite staff for the service and select your preferred date and time",
    "You have no completed visits": "You have no completed visits",
    "You have no favorites yet": "You have no favorites yet",
    "You have no upcoming visits": "You have no upcoming visits",
    "You're done!": "You're done!",
    "We'll send you a reminder": "We'll send you a reminder",
    "You've earned:": "You've earned:",
    "You've almost earned additional:": "You've almost earned additional:",
    "before your appointment": "before your appointment",
    "Your company calendar...": "Your company calendar...",
    "Your consent is required": "Your consent is required",
    "Your referrals:": "Your referrals:",
    "Your referrals:...Clients": "Clients",
    "Your reviews:": "Your reviews:",
    "Your session has expired.": "Your session has expired.",
    "Zoom": "Zoom",
    'Besucher': 'الزواز',
    'Dienstleister': 'مزود الخدمة',
    'Für Besucher': 'للزائر',
    'Für Dienstleister': 'لمزود الخدمة',
    'Email': 'البريد الإلكتروني',
    'Nachricht': 'الرسالة',
    'Name': 'الاسم',
    '100% kostenlos für Besucher': 'مجاني ١٠٠% للزوار',
    '100% kostenlos': '١٠٠% مجانا',
    '100% online': 'عبر الإنترنت ١٠٠%',
    '12 Monate zum Preis von 10': 'احصل على اشتراك ١٢ شهر بسعر ١٠ أشهر فقط',
    '24 / 7 / 365 Termine': 'متاح على مدار الساعة',
    '24h Buchungsassistent': 'مساعد حجز متوفر على مدار الساعة',
    '30 Tage kostenfrei testen': 'تجربة مجانية لمدة ٣٠ يوم',
    '49€': '١٩٩ درهم',
    '499€': '١٩٩٠ درهم',
    'ABONNIEREN': 'اشتراك',
    'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.': 'فمثلًا موظفة الاستقبال تقضي نصف وقت عملها في الرد على الهاتف وتلقى طلبات حجز المواعيد ومحاولة العثور على موعد يتناسب مع العميل ومع العمل. مما بالتأكيد يطيل من فترات الانتظار للعملاء الآخرين ويضيع الكثير من الوقت الذي من الممكن استثماره للقيام بأعمال أخرى أهم.',
    'Am Sandfeld 18, 76149 Karlsruhe': 'Am Sandfeld 18, 76149 Karlsruhe, Germany',
    'Angebote für Dienstleister': 'عروض لمزودي الخدمات',
    'Buchen': 'احجز',
    'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.': 'قم بكتابة عنوان بريدك الإلكتروني أو بيانات الاتصال الخاصة بك وسوف نعاود الاتصال بك في أقرب وقت',
    'Datenschutz &amp; AGB': 'الخصوصية و الشروط و الأحكام',
    'Direkt und zeitsparend': 'سهل وبسيط وموفر للوقت',
    'Empfehlung': 'التوصيات',
    'Erinnerungs-SMS mit Hinweise an Kunden': 'تذكير عبر الرسائل القصيرة بإشعارات العملاء',
    'FEATURES': 'الميزات',
    'Features': 'الميزات',
    'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.': 'هذا بالظبط مادفعنا لإطلاق بوكي، فهو ببساطة الحل الأمثل لهذه المعاناة، فائدة بوكي لا تقتصر على العميل فقط بل على صاحب العمل أيضًا.',
    'HOME': 'الصفحة الرئيسية',
    'Hier sind wir': 'نحن هنا',
    'Impressum': 'بصمة',
    'Jahres-Abo': 'اشتراك سنوي',
    'KATEGORIEN': 'الفئات',
    'KONTAKT': 'اتصال',
    'Kategorien': 'الفئات',
    'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?': 'هل تعاني من كثرة الانتظار وتضييع الوقت وأنت تحاول حجز موعد في صالون تجميل أو أي مكان آخر؟ هل مللت من جرس الهاتف الذي يرن دون أي رد وأنت تحجز موعد في عطلة نهاية الأسبوع ليوم الاثنين التالي؟',
    'Kontaktiere uns': 'تواصل معنا',
    'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.': 'أزال بوكي كل تلك العوائق التي يعاني منها العميل وصاحب العمل معًا، حيث أصبح بإمكان العميل بكل بساطة الدخول عبر الإنترنت وحجز الموعد المناسب بسهولة، وأصبح متاح له إلغاء الموعد أو تغييره بكل بساطة. فإذا كنت ممن يديرون نشاطًا تجاريًا وتعاني من تنسيق المواعيد وإضاعة الوقت على الهاتف، فإن بوكي سينجز لك كل تلك المهام بدقة وسهولة. سيوفر لك مساعدك الشخصي بوكي الكثير من الوقت الذي من الأفضل لك استثماره في مهام أخرى أهم.',
    'Monats-Abo': 'اشتراك شهري',
    'Möchtest du gerne als Dienstleister unseren Service nutzen?': 'هل تريد الاستفادة من خدماتنا بصفتك مزود خدمة؟',
    'Nachricht erfolgreich gesendet': 'تم إرسال الرسالة بنجاح',
    'Nutze unser Kontaktformular': 'استخدم نموذج الاتصال الخاص بنا',
    'PROBIER ES AUS': 'جربه',
    'Probier es aus': 'جربه',
    'SCREENSHOTS': 'لقطات الشاشة',
    'SMS-Erinnerung': 'تذكير عبر الرسائل القصيرة',
    'Screenshots': 'تذكير عبر الرسائل القصيرة',
    'Senden': 'إرسال',
    'Sie werden zu unserem Partner weitergeleitet, um die Zahlung abzuschließen': 'سيتم إعادة توجيهك إلى مقدم الخدمة لإتمام الدفع',
    'TEAM': 'الفريق',
    'Unabhängige Flexibilität': 'متصل بمواقع التواصل الاجتماعي',
    'Vorzugspreis': 'أسعار مميزة',
    'WARUM?': 'لماذا؟',
    'Warum Bookee?': 'لماذا عليك تجربة بوكي؟',
    'Was ist Bookee?': 'ما هو بوكي؟',
    'bitte füllen Sie das Formular aus': 'رجاءًا قم بملء النموذج',
    'inkl. Werbe-Material': 'يحتوي على مواد إعلانية',
    'jederzeit buchbar': 'بإمكانك الحجز في أي وقت',
    'komplette Übersicht': 'استعراض شامل',
    'monatlich kündbar': 'من الممكن إلغاء الاشتراك شهريًا',
    'weniger Zeit am Telefon': 'وقت أقل على الهاتف',
    'ÜBER UNS': 'نبذة عنّا',
    'MIT BOOKEE KANNST DU KOSTENLOS &amp; BEQUEM RUND UM DIE UHR DEINEN TERMIN BUCHEN': 'مع بوكي ستحجز موعدك في أي وقت وبدون أي رسوم',

};

export class L10N_current {
    public static resx: L10N_resx = L10N_en;
    public static browserLanguage = navigator.language;

    public static _initialize() {
        console.log("Initializing to browser language:" + this.browserLanguage);
        this.changeLocale(this.browserLanguage);
    }
    public static changeLocale(lang?: string) {
        console.log("Changing language to:" + lang);
        const effectiveLang = lang ?? this.browserLanguage;
        switch (effectiveLang?.toLowerCase()) {
            case "de": this.resx = L10N_de; break;
            case "pl": this.resx = L10N_pl; break;
            case "en": this.resx = L10N_en; break;
            case "ae": this.resx = L10N_ae; break;
            case "ru": this.resx = L10N_ru; break;
            default: this.resx = L10N_en; break;
        }
    }
    public static getLocale() {
        return this.resx.resx_locale;
    }
}
L10N_current._initialize();

export const L10N = (props: {
    key: keyof (L10N_resx),
    date?: string,
    price?: PriceAndCurrency | PriceAndCurrencyEditModel,
    duration?: number,

    cat?: string,
    hint?: string,
    editStep?: EditStep,
    status?: VisitStatusEnum,
    signalMessageType?: SignalMessageType,
}) => {
    function translate(): string {
        if (!props.key) return "?0?";
        if (props.key in L10N_date_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_date)];
            return mapped(props.date);
        } else if (props.key in L10N_duration_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_duration)];
            return mapped(props.duration);
        } else if (props.key in L10N_category_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_category)];
            return mapped(props.cat);
        } else if (props.key in L10N_hint_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_hint)];
            return mapped(props.hint);
        } else if (props.key in L10N_editStep_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_editStep)];
            return mapped(props.editStep);
        } else if (props.key in L10N_status_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_status)];
            return mapped(props.status);
        } else if (props.key in L10N_price_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_price)];
            return mapped(props.price);
        } else if (props.key in L10N_signal_en) {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_signal)];
            return mapped(props.signalMessageType);
        } else {
            const mapped = L10N_current.resx[props.key as keyof (L10N_resx_keys)];
            return mapped;
        }
    }
    const showDebug = false;
    function wrap(): string {
        if (showDebug) return "*" + translate();
        else return translate();
    }
    return wrap();
}
