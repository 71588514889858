import { Action } from 'redux';
import { EditState } from './edit.reducer';

export const CHANGE_STATE = '[EDIT] CHANGE_STATE';
interface ChangeStateAction extends Action {
    type: typeof CHANGE_STATE,
    payload: EditState
}
export type EditActionTypes = ChangeStateAction
    ;
