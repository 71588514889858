import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import * as React from 'react';
import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { berlinMapCoords, CompanyDetails, MapCoords } from '../../api/models/Details';
import { L10N } from '../pages/L10N';
import { CategoryIconURL } from '../ui/CategoryIcon';
export const CompanyMapView = (props: {
    company: CompanyDetails,
    height?: string,
    onMapClick?: (coords: MapCoords) => void,
}) => {
    const mapContainerStyle = {
        width: '100%',
        height: props.height ?? '400px',
    };
    const options = {
        disableDefaultUI: true,
        mapId: "3b47b43fdae52c1f",
        zoomControlOptions: {
        }
    }
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAAB-pgbzDAcfFU2Ej9W3kWjT8dcPpvbIE",
        mapIds: ["3b47b43fdae52c1f"],
    })
    const onLoad = useCallback(function onLoad(mapInstance) {
    }, [])
    function iconBg(): any {
        const url = '/img/map-marker-bg.svg';
        return {
            url: url,
            scaledSize: {
                height: 80,
                width: 80,
            },
            anchor: {
                x: 47.5,
                y: 80,
            },
        };
    }
    function icon(category?: string): any {
        const url = category
            ? CategoryIconURL(category, true)
            : '/img/categories/anything.svg';
        return {
            url: url,
            scaledSize: {
                height: 40,
                width: 40,
            },
            anchor: {
                x: 28,
                y: 65,
            },
        };
    }
    function markerClick() {
        const coords = props.company.mapCoords ?? berlinMapCoords;
        const label = encodeURIComponent(props.company.title);
        const latLng = `${coords.lat},${coords.lng}`;
        const url = `https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=${latLng}&destination_place_id=${label}`;
        if (isMobile) {
            window.location.href = url;
        } else {
            window.open(url, '_blank')?.focus();
        }
    }
    function mapClick(e: any) {
        const coords = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        console.log(JSON.stringify(coords));
        if (props.onMapClick) {
            props.onMapClick(coords);
        }
    }
    const renderMap = () => {
        return <GoogleMap
            options={options}
            onLoad={onLoad}
            onClick={mapClick}
            center={props.company.mapCoords ?? berlinMapCoords}
            zoom={props.company.mapCoords ? 16 : 6}
            mapContainerStyle={mapContainerStyle}
        >
            {props.company.mapCoords && <Marker
                key={"bg-" + props.company.itemID}
                icon={iconBg()}
                position={props.company.mapCoords ?? berlinMapCoords}
            />}
            {props.company.mapCoords && <Marker
                onClick={markerClick}
                key={props.company.itemID}
                icon={icon(props.company.category)}
                title={props.company.title}
                position={props.company.mapCoords ?? berlinMapCoords}
            />}
        </GoogleMap>
    }

    if (loadError) {
        return <div>{L10N({ key: "Map cannot be loaded" })}</div>
    }

    return isLoaded ? renderMap() : <>{L10N({ key: "loading..." })}</>
};
