import moment from 'moment';
import * as yup from 'yup';
import { SchemaOf } from "yup";
import { EditStep } from '../../components/pages/EditPage';
import { L10N } from '../../components/pages/L10N';
import { MapCoords } from "./Details";
import { AccessTokenBearer, BeePhoto, PriceAndCurrency } from "./Global";

export interface UpdatePhotoMessage extends AccessTokenBearer, BeePhoto {
    base64?: string | null,
}
export interface EditModel extends AccessTokenBearer {

}
export interface RequestCompanyCreateModel extends AccessTokenBearer {
    referralCode: string,
}
export interface RequestCompanyEditModel extends AccessTokenBearer {
    companyID: string,
}
export interface AssignCompanyMessage extends AccessTokenBearer {
    companyID: string,
}
export interface ToggleDemoCompanyMessage extends AccessTokenBearer {
    companyID: string,
}
export interface KlarnaApiCredentials {
    username: string | null,
    password: string | null,
    url: string | null,
}
export interface CompanyEditModel extends EditModel {
    companyID: string,
    name: string | null,
    category: string | null,
    bookeePhone: string | null,
    bookeeEmail: string | null,
    address: string | null,
    mapCoords: MapCoords,
    description: string | null,
    keyWords: string | null,
    contactDetails: string | null,
    website: string | null,
    email: string | null,
    photos: PhotoEditModel[],
    klarnaApiCredentials?: KlarnaApiCredentials,
    isNotPublished: boolean,
}
export function CreateNewCompany(): CompanyEditModel {
    return {
        accessToken: "",
        companyID: "123-564",
        name: "",
        category: "",
        bookeePhone: "",
        bookeeEmail: "",
        address: "",
        mapCoords: {
            lat: 1,
            lng: 1,
        },
        description: "",
        keyWords: "",
        contactDetails: "",
        website: "",
        email: "",
        photos: [],
        klarnaApiCredentials: undefined,
        isNotPublished: true,
    };
}
export const AccessTokenValidationSchema = yup.string().default("");
export const PhotoPositionEnumValidationSchema = yup.mixed().oneOf(["Default", "Top", "Center", "Bottom"]).defined();
export const BeePhotoValidationSchema: SchemaOf<BeePhoto> = yup.object({
    objectID: yup.string().defined(),
    photoIndex: yup.number().defined(),
    versionStamp: yup.string().defined(),
    position: PhotoPositionEnumValidationSchema,
});
export interface PhotoEditModel extends BeePhoto {
    base64?: string | null,
    isDeleted: boolean,
}
export const BeePhotoArrayValidationSchema: SchemaOf<BeePhoto[]> = yup.array().of(BeePhotoValidationSchema);
export const PhotoEditModelValidationSchema: SchemaOf<PhotoEditModel> = yup.object({
    objectID: yup.string().defined().default(""),
    photoIndex: yup.number().defined(),
    versionStamp: yup.string().defined(),
    position: PhotoPositionEnumValidationSchema,
    base64: yup.string().optional().nullable(),
    isDeleted: yup.boolean().defined().default(false),
});
export const PhotoEditModelArrayValidationSchema: SchemaOf<PhotoEditModel[]> = yup.array().of(PhotoEditModelValidationSchema);

export interface PriceAndCurrencyEditModel {
    price?: string,
    currency: string,
}
export const isNumberTest: yup.TestConfig<string | undefined, Record<string, any>> = {
    name: "isNumberTest",
    test: (value) => {
        if (!value) return true;
        const val = value.replace(/,/, '.');
        return !isNaN(+value)
            || !isNaN(+val);
    },
    message: L10N({ key: "Invalid number" }),
}
const isTimeTest: yup.TestConfig<string | undefined, Record<string, any>> = {
    name: "isTimeTest",
    test: (value) => {
        if (!value) return true;
        const parsed = moment(value, "HH:mm");
        const result = parsed.isValid();
        return result;
    },
    message: L10N({ key: "Specify time as HH:mm" }),
}
export interface DateTimeRangeEditModel {
    fromHHmm: string,
    toHHmm: string,
}
export const DateTimeRangeEditModelValidationSchema: SchemaOf<DateTimeRangeEditModel> = yup.object({
    fromHHmm: yup.string()
        .test(isTimeTest)
        .optional()
        .default("9:00"),
    toHHmm: yup.string()
        .test(isTimeTest)
        .test({
            name: "isTimeGreaterThan",
            test: (value, context) => {
                const parent: DateTimeRangeEditModel = context.parent;
                const fromValue = parent.fromHHmm;
                const fromMoment = moment(fromValue)
                if (!fromMoment.isValid()) return true;
                const parsed = moment(value);
                if (!parsed.isValid()) return false;

                const diff = parsed.hours() * 60 + parsed.minutes()
                    - (fromMoment.hours() * 60 + fromMoment.minutes());

                const result = diff > 0;
                return result;
            },
            message: L10N({ key: "Must be later" }),
        })
        .optional()
        .default("17:00"),
});
export function CreateNextDateTimeRange(model: WorkingHoursEditModel): DateTimeRangeEditModel {
    const last = model.dayWorkingHours[model.dayWorkingHours.length - 1];

    if (!last) {
        return {
            fromHHmm: "09:00",
            toHHmm: "17:00",
        };
    }

    const from = moment(last.toHHmm).add("hour", 1).format("HH:mm");
    const to = moment(last.toHHmm).add("hour", 2).format("HH:mm");
    return {
        fromHHmm: from ?? "09:00",
        toHHmm: to ?? "17:00",
    };
}
export interface WorkingHoursEditModel {
    dayOfWeek: string,
    dayWorkingHours: DateTimeRangeEditModel[],
    dayOfWeek_asDate: string,
}
export const WorkingHoursEditModelValidationSchema: SchemaOf<WorkingHoursEditModel> = yup.object({
    dayOfWeek: yup.string().oneOf([
        "Sunday"
        , "Monday"
        , "Tuesday"
        , "Wednesday"
        , "Thursday"
        , "Friday"
        , "Saturday"])
        .required()
        .defined(),
    dayOfWeek_asDate: yup.string().defined().default(""),
    dayWorkingHours: yup.array().of(DateTimeRangeEditModelValidationSchema).optional(),
});
export const PriceAndCurrencyEditModelValidationSchema: SchemaOf<PriceAndCurrencyEditModel> = yup.object({
    price: yup.string()
        .test(isNumberTest)
        .optional()
        .default("0"),
    currency: yup.string().default("EUR"),
});
export const PriceAndCurrencyValidationSchema: SchemaOf<PriceAndCurrency> = yup.object({
    price: yup.number().required(),
    currency: yup.string().required(),
});
export const MapCoordsValidationSchema: SchemaOf<MapCoords> = yup.object({
    lat: yup.number().required(),
    lng: yup.number().required(),
});
export const KlarnaApiCredentialsValidationSchema: SchemaOf<KlarnaApiCredentials> = yup.object({
    username: yup.string().defined().nullable(),
    password: yup.string().defined().nullable(),
    url: yup.string().defined().nullable(),
});
export const EmailValidationSchema = yup
    .string()
    .trim()
    .email(L10N({ key: 'Enter a valid email' }));

export const CompanyEditModelValidationSchema: SchemaOf<CompanyEditModel> = yup.object({
    accessToken: AccessTokenValidationSchema,
    companyID: yup.string().defined(),
    name: yup.string().defined(),
    category: yup.string().defined().nullable(),
    bookeePhone: yup.string().defined().nullable(),
    bookeeEmail: EmailValidationSchema.defined().nullable(),
    address: yup.string().defined().default("").nullable(),
    mapCoords: MapCoordsValidationSchema,
    description: yup.string().default("").nullable(),
    keyWords: yup.string().default("").nullable(),
    contactDetails: yup.string().default("").nullable(),
    website: yup.string().default("").nullable(),
    email: EmailValidationSchema.default("").nullable(),
    photos: PhotoEditModelArrayValidationSchema.optional(),
    klarnaApiCredentials: KlarnaApiCredentialsValidationSchema.optional().nullable(),
    isNotPublished: yup.boolean().defined().default(true),
});

export interface ServiceEditModel extends EditModel {
    serviceID?: string,
    companyID: string,
    serviceName: string,
    serviceGroupName: string,
    category: string | null,
    readMore: string,
    description: string,
    defaultPrice?: PriceAndCurrencyEditModel | null,
    durationInMinutes?: string | null,
    timeBeforeInMinutes?: string | null,
    timeAfterInMinutes?: string | null,
    photos: PhotoEditModel[],
    offers: OfferEditModel[],
    paymentUpfront: boolean,
    paymentUpfrontPrice?: string | null,
    manualConfirmationRequired: boolean,
    isDeleted: boolean,
}
export function CreateNewService(companyID: string): ServiceEditModel {
    return {
        accessToken: "",
        serviceID: undefined,
        companyID: companyID,
        serviceName: "",
        serviceGroupName: "",
        category: "",
        readMore: "",
        description: "",
        defaultPrice: {
            currency: "",
            price: "0",
        },
        durationInMinutes: "0",
        timeBeforeInMinutes: "0",
        timeAfterInMinutes: "0",
        photos: [],
        offers: [],
        paymentUpfront: false,
        paymentUpfrontPrice: "0",
        manualConfirmationRequired: false,
        isDeleted: false,
    };
}
export const ServiceEditModelValidationSchema: SchemaOf<ServiceEditModel> = yup.object({
    accessToken: AccessTokenValidationSchema,
    serviceID: yup.string().optional(),
    companyID: yup.string().defined(),
    serviceName: yup.string().defined(),
    serviceGroupName: yup.string().defined().default("").nullable(),
    category: yup.string().defined().nullable(),
    readMore: yup.string().defined().default("").nullable(),
    description: yup.string().defined().default(""),
    defaultPrice: PriceAndCurrencyEditModelValidationSchema.optional().nullable(),
    durationInMinutes: yup.string()
        .test(isNumberTest)
        .optional().nullable(),
    timeBeforeInMinutes: yup.string()
        .test(isNumberTest)
        .optional().nullable(),
    timeAfterInMinutes: yup.string()
        .test(isNumberTest)
        .optional().nullable(),
    photos: PhotoEditModelArrayValidationSchema,
    offers: yup.array(),
    paymentUpfront: yup.boolean().defined().default(false),
    paymentUpfrontPrice: yup.string()
        .test(isNumberTest)
        .optional().nullable(),
    manualConfirmationRequired: yup.boolean().defined().default(false),
    isDeleted: yup.boolean().defined().default(false),
});

export interface ResourceEditModel extends EditModel {
    resourceID?: string,
    companyID: string,
    firstName: string,
    lastName: string,
    displayName: string,
    title: string,
    subtitle: string,
    contactDetails: string,
    description?: string | null,
    photos: PhotoEditModel[],
    workingHours: WorkingHoursEditModel[],
    offers: OfferEditModel[],
    isDeleted: boolean,
}
export function CreateNewResource(companyID: string): ResourceEditModel {
    return {
        accessToken: "",
        resourceID: undefined,
        companyID: companyID,
        firstName: "",
        lastName: "",
        displayName: "",
        title: "",
        subtitle: "",
        contactDetails: "",
        description: "",
        photos: [],
        workingHours: [
            { dayOfWeek_asDate: "2000-01-03T12:00", dayWorkingHours: [], dayOfWeek: 'Monday', },
            { dayOfWeek_asDate: "2000-01-04T12:00", dayWorkingHours: [], dayOfWeek: 'Tuesday', },
            { dayOfWeek_asDate: "2000-01-05T12:00", dayWorkingHours: [], dayOfWeek: 'Wednesday', },
            { dayOfWeek_asDate: "2000-01-06T12:00", dayWorkingHours: [], dayOfWeek: 'Thursday', },
            { dayOfWeek_asDate: "2000-01-07T12:00", dayWorkingHours: [], dayOfWeek: 'Friday', },
            { dayOfWeek_asDate: "2000-01-08T12:00", dayWorkingHours: [], dayOfWeek: 'Saturday', },
            { dayOfWeek_asDate: "2000-01-09T12:00", dayWorkingHours: [], dayOfWeek: 'Sunday', },
        ],
        offers: [],
        isDeleted: false,
    };
}
export const ResourceEditModelValidationSchema: SchemaOf<ResourceEditModel> = yup.object({
    accessToken: AccessTokenValidationSchema,
    resourceID: yup.string().optional(),
    companyID: yup.string().defined(),
    firstName: yup.string().defined().default(""),
    lastName: yup.string().defined().default(""),
    displayName: yup.string().defined(),
    title: yup.string().defined().default("").nullable(),
    subtitle: yup.string().defined().default(""),
    contactDetails: yup.string().defined().default(""),
    description: yup.string().defined().default("").optional().nullable(),
    photos: PhotoEditModelArrayValidationSchema,
    workingHours: yup.array().of(WorkingHoursEditModelValidationSchema).optional(),
    offers: yup.array(),
    isDeleted: yup.boolean().defined().default(false),
});

export interface OfferEditModel {
    isAvailable: boolean,
    resourceID: string,
    resourceDisplayName: string,
    serviceID: string,
    serviceDisplayName: string,
}
export interface PublishRule {
    editStep: EditStep,
    rule: string,
    isOK: boolean,
}
export class PublishRules {
    public static AnalyzeCompany(company?: CompanyEditModel, services?: ServiceEditModel[], resources?: ResourceEditModel[]): PublishRule[] {
        if (!company) return [];
        if (!services) return [];
        if (!resources) return [];

        const rules: PublishRule[] = [
            //company
            { editStep: "about", rule: L10N({ key: "Company Name" }), isOK: Boolean(company.name && company.name !== "") },
            { editStep: "about", rule: L10N({ key: "Bookee Category" }), isOK: Boolean(company.category && company.category !== "") },
            { editStep: "address", rule: L10N({ key: "Address" }), isOK: Boolean(company.address && company.address !== "") },
            { editStep: "for clients", rule: L10N({ key: "Description" }), isOK: Boolean(company.description && company.description !== "") },
            { editStep: "for clients", rule: L10N({ key: "Photo" }), isOK: Boolean(company.photos && company.photos.length > 0) },
            //services
            { editStep: "services", rule: L10N({ key: "Add" }), isOK: Boolean(services && services.length > 0) },
            { editStep: "services", rule: L10N({ key: "Photo" }), isOK: Boolean(services && services.every(m => m.photos && m.photos.length > 0)) },
            { editStep: "services", rule: L10N({ key: "Duration [minutes]" }), isOK: Boolean(services && services.every(m => +(m.durationInMinutes || 0) > 0)) },
            //resources
            { editStep: "resources", rule: L10N({ key: "Add" }), isOK: Boolean(resources && resources.length > 0) },
            { editStep: "resources", rule: L10N({ key: "Photo" }), isOK: Boolean(resources && resources.every(m => m.photos && m.photos.length > 0)) },
        ];
        return rules;
    }
}
export interface ClientEditModel extends EditModel {
    userID: string,
    companyID: string,
    phone: string | null,
    email: string | null,
    firstName: string | null,
    lastName: string | null,
    photos: PhotoEditModel[],
    isBanned: boolean | null,
}