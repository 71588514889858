import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { L10N } from "../pages/L10N";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circle1: {
            position: 'absolute',
            top: '-20px',
            left: '-20px',
        },
        circle2: {
            position: 'absolute',
            top: '-120px',
            left: '220px',
        },
    }),
);
export const Marketing07_AboutUs = () => {
    const classes = useStyles();

    return (
        <>
            <div className="bg-gradient-bee-diagonal">
                <Container style={{
                    color: 'white',
                    fontWeight: 'bold',
                    overflow: 'hidden',
                }}>
                    <Row className="mb-3">
                        <Col />
                        <Col className="col-auto">
                            <Typography variant="h4" align="center"
                                className=""
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginTop: '32px',
                                    marginLeft: '15px',
                                }}>
                                {L10N({ key: 'ÜBER UNS' })}
                            </Typography>
                        </Col>
                        <Col />
                    </Row>
                    <Row className="mt-3">
                        <Col xs={{ span: 12, order: 2 }} lg={{ span: 7, order: 1 }} className="my-5">
                            <Typography variant="subtitle1" align="justify" >
                                {L10N({ key: 'Kennst du das, du rufst beim Arzt an, es einige Minuten hin und her geht, nur um einen freien Termin zu bekommen? Oder wenn Du am Wochenende gerne einen Termin für Montag beim Frisör vereinbaren möchtest, aber niemand geht ans Telefon?' })}
                                <br />
                                <br />
                                {L10N({ key: 'Genau jetzt kommen wir mit „Bookee“ ins Spiel. Wir haben uns eine Lösung ausgedacht, die Dir als Kunden, aber auch als Geschäftsbetreiber eine passende Antwort liefert.' })}
                                <br />
                                <br />
                                {L10N({ key: 'Als Beispiel: Eine Arzthelferin verbringt um die 50% der Arbeitszeit am Telefon, um aufwendig die Terminanfragen anzunehmen, in den Kalender zu schauen und auch rumzublättern, bis der passende Termin für beide gefunden ist. Dadurch entstehen längere Wartezeiten in der Praxis, und wichtigere Aufgaben bleiben dabei auf der Strecke.' })}
                                <br />
                                <br />
                                {L10N({ key: 'Mit Bookee kannst du jederzeit nach einem passenden Termin online schauen, buchen und ggfs. auch ändern oder sogar stornieren. Falls Du ein Geschäft betreibst und du es satt hast, ständig am Telefon zu sitzen um die Termine zu koordinieren, ist Bookee genau das Richtige für dich. Mit Bookee, als deinen persönlichen‚ rund um die Uhr Termin-Assistenten, hast du mehr Zeit für dich und für die wichtigeren Dinge im privaten oder beruflichen Alltag.' })}
                            </Typography>
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} lg={{ span: 5, order: 2 }} className=""
                            style={{
                                minHeight: '500px',
                            }}
                        >
                            <img src="/img/marketing/about-us1.svg" alt="bee1" className={classes.circle1} />
                            <img src="/img/marketing/about-us2.svg" alt="bee1" className={classes.circle2} />
                        </Col>
                    </Row>
                </Container >
            </div>
        </>
    );
};
