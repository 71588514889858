import { LinearProgress, Typography } from "@material-ui/core";
import moment from "moment";
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { ConfirmedVisit } from "../../api/models/Visit";
import { RootState } from '../../store/store';
import { loadAllVisits } from '../../store/visit/visit.actions';
import { TabsSwitch } from '../ui/TabsSwitch';
import { VisitListItemCard } from '../visit/VisitListItemCard';
import { L10N } from "./L10N";
import { Routes } from "./NavMenu";
import { PageHeader } from './PageHeader';

export const VisitPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { inProgress, completedVisits, notCompletedVisits } = useSelector((state: RootState) => state.visit);
    const [selectedTab, setSelectedTab] = useState(0);
    const { user } = useSelector((state: RootState) => state.user);
    const urlParams = useParams<{ tab: "upcoming" | "completed" }>();

    useMemo(() => {
        if (!user) return;
        dispatch(loadAllVisits(user));
    }, [dispatch, user]);

    useEffect(() => {
        if (!urlParams.tab || urlParams.tab === "upcoming") {
            setSelectedTab(0);
        } else {
            setSelectedTab(1);
        }
    }, [urlParams]);
    async function visitClick(event: React.MouseEvent<any, MouseEvent>, item: ConfirmedVisit) {
        const route = Routes.detailsTab('visit', item.visitID);
        history.push(route);
        event.stopPropagation();
        event.preventDefault();
    }
    function onTabChanged(value: number) {
        if (value === 0) {
            history.push(Routes.visitTab("upcoming"));
        } else {
            history.push(Routes.visitTab("completed"));
        }
    }
    return (
        <>
            {!(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.visitTab())} />}

            <Container>
                <Row className="my-5">
                    <Col />
                    <Col className="col-auto">
                        <Typography variant="subtitle1" color="primary">
                            {L10N({ key: "My Appointments" })}
                        </Typography >
                    </Col>
                    <Col />
                </Row>
                <Row className="mb-5">
                    <Col className="p-0" />
                    <Col className="col-auto">
                        <TabsSwitch selectedTab={selectedTab} onTabChanged={onTabChanged}
                            tabs={[
                                L10N({ key: "Upcoming" }),
                                L10N({ key: "Completed" })
                            ]}
                        />
                    </Col>
                    <Col className="p-0" />
                </Row>

                {inProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                {selectedTab === 0 && <>
                    {notCompletedVisits && notCompletedVisits?.length === 0 && <>
                        <Row className="mb-3">
                            <Col />
                            <Col className="col-auto">
                                <img src={`/img/envelope.svg`} alt="heart" width="150" />
                            </Col>
                            <Col />
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Typography align="center">
                                    {L10N({ key: "You have no upcoming visits" })}
                                </Typography>
                            </Col>
                        </Row>
                        <PageHeader showLogo logoNavigatesToHome />
                    </>}
                    <Row className="mb-3">
                        {notCompletedVisits?.map && notCompletedVisits?.map((row, i) =>
                            <Col key={i} className="mb-3"
                                xs={12} md={6} >
                                <VisitListItemCard data={row} onCardClick={visitClick} />
                            </Col>
                        )}
                    </Row>
                </>}
                {selectedTab === 1 && <>
                    {completedVisits && completedVisits?.length === 0 && <>
                        <Row className="mb-3">
                            <Col />
                            <Col className="col-auto">
                                <img src={`/img/envelope.svg`} alt="heart" width="150" />
                            </Col>
                            <Col />
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Typography align="center">
                                    {L10N({ key: "You have no completed visits" })}
                                </Typography>
                            </Col>
                        </Row>
                        <PageHeader showLogo logoNavigatesToHome />
                    </>}
                    <Row className="mb-3">
                        {completedVisits?.map &&
                            completedVisits
                                ?.sort((a, b) => moment(b.visitStartForClient).diff(moment(a.visitStartForClient)))
                                ?.map((row, i) =>
                                    <Col key={i} className="mb-3"
                                        xs={12} md={6} >
                                        <VisitListItemCard data={row} onCardClick={visitClick} />
                                    </Col>
                                )}
                    </Row>
                </>}
            </Container >
        </>
    );
};
