import { Button, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { SearchResult } from '../../api/models/Search';
import { changeInvite } from '../../store/invite/invite.actions';
import { refreshFavorites } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { SearchCard } from '../search/SearchCard';
import { Animations } from '../ui/Animations';
import { ButtonShadow } from '../ui/ButtonShadow';
import { L10N } from './L10N';
import { Routes } from './NavMenu';
import { PageHeader } from './PageHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '8px',
        },
    })
);
export const FavouritesPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { favoriteResultsInProgress, favoriteResults } = useSelector((state: RootState) => state.search);
    const { user, inProgress } = useSelector((state: RootState) => state.user);
    const classes = useStyles();

    useMemo(() => {
        if (!user) return;
        dispatch(refreshFavorites(user));
    }, [dispatch, user]);

    async function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>, item: SearchResult) {
        await dispatch(changeInvite({
            query: "",
            offeredResourceID: item.offeredResourceID,
        }));
        history.push(Routes.invite);
        event.stopPropagation();
    }
    async function handleClickExplore() {
        history.push(Routes.explore);
    }
    function renderItem(m: SearchResult, i: number, isCurrent: boolean): JSX.Element {
        return <Col key={i}
            className="mb-3"
            xs={12} sm={6} md={4} lg={3} xl={3}>
            <SearchCard className={
                isCurrent
                    ? Animations.swing_in_top_fwd()
                    : Animations.slide_out_bck_center()
                    + " " + Animations.delay(i)}
                data={m}
                showImage
                index={i}
                onCardClick={handleClick} />
        </Col>
    }
    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.favourites)} />}
        <Container>
            <Row className="my-5">
                <Col />
                <Col className="col-auto">
                    <Typography variant="subtitle1" color="primary">
                        {L10N({ key: "Favorites" })}
                    </Typography >
                </Col>
                <Col />
            </Row>
            {favoriteResultsInProgress && <>
                <Row>
                    <Col xs={12}>
                        <LinearProgress className="w-100 mt-n1" color="secondary" />
                    </Col>
                </Row>
            </>}
            {!favoriteResultsInProgress && (favoriteResults?.results?.length ?? 0) === 0 && <>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <img src={`/img/icons/icon_heart_dark.svg`} alt="heart" width="100" />
                    </Col>
                    <Col />
                </Row>
                <Row className="mb-3">
                    <Col />
                    <Col className="col-auto">
                        <Typography align="center">{L10N({ key: "You have no favorites yet" })}</Typography>
                    </Col>
                    <Col />
                </Row>
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto">
                        <Button onClick={handleClickExplore} disableElevation
                            className={classes.btn}
                            variant="contained" color="primary"
                            disabled={inProgress}>
                            {L10N({ key: "Explore offers around you" })}
                            <ButtonShadow />
                        </Button>
                    </Col>
                    <Col />
                </Row>
                <PageHeader showLogo logoNavigatesToHome />
            </>}
            <Row className="mb-3">
                {favoriteResults?.results?.map && favoriteResults.results?.map((m, i) => (renderItem(m, i, true)))}
            </Row>
        </Container>
    </>);
}
