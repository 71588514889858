import { Button, CircularProgress, createStyles, LinearProgress, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { api } from '../../api/api';
import { RootState } from '../../store/store';
import { selectReviewOrCreate, updateReview } from '../../store/visit/visit.actions';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { Animations } from '../ui/Animations';
import { ButtonShadow } from '../ui/ButtonShadow';
import { Rating } from '../ui/Rating';
import { L10N } from './L10N';
import { Routes } from './NavMenu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        roundedOverlay: {
            top: '-20px',
            borderTop: '1px solid',
            borderTopColor: theme.palette.primary.light,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            height: '20px',
            background: theme.palette.background.paper,
            zIndex: 10,
            position: 'relative',
            cursor: 'pointer',
        },
        roundedOverlayBar: {
            height: '5px',
            width: '180px',
            background: theme.palette.primary.light,
            marginTop: '11px',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '2px',
        },
        roundedButton: {
            borderRadius: '12px',
            textTransform: 'none',
        },
    }),
);
export interface VisitReviewPageState {
    ratingScore?: number,
    reviewTitle?: string,
    reviewDetails?: string,
    showValidation: boolean,
}
export const VisitReviewEditPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { inProgress, currentReview } = useSelector((state: RootState) => state.visit);
    const urlParams = useParams<{ visitID: string, visitReviewID: string }>();
    const { user } = useSelector((state: RootState) => state.user);
    const classes = useStyles();
    const [state, setState] = useState<VisitReviewPageState>({
        ratingScore: 0,
        reviewTitle: "",
        reviewDetails: "",
        showValidation: false,
    });
    useMemo(() => {
        if (!user) return;
        const visitID = urlParams.visitID && urlParams.visitID !== "default"
            ? urlParams.visitID
            : undefined;
        const visitReviewID = urlParams.visitReviewID && urlParams.visitReviewID !== "default"
            ? urlParams.visitReviewID
            : undefined;
        dispatch(selectReviewOrCreate(user, visitID, visitReviewID));
    }, [dispatch, user, urlParams]);

    useEffect(() => {
        setState((state) => {
            return {
                ...state,
                ratingScore: currentReview?.ratingScore ?? state.ratingScore,
                reviewTitle: currentReview?.reviewTitle ?? state.reviewTitle,
                reviewDetails: currentReview?.reviewDetails ?? state.reviewDetails,
            }
        });
    }, [currentReview]);

    function cancelClick() {
        history.goBack();
    }
    async function saveClick() {
        if (!user) return;
        if (!currentReview) return;
        setState({ ...state, showValidation: true });

        currentReview.ratingScore = state.ratingScore ?? currentReview.ratingScore;
        currentReview.reviewTitle = state.reviewTitle ?? currentReview.reviewTitle;
        currentReview.reviewDetails = state.reviewDetails ?? currentReview.reviewDetails;

        await dispatch(updateReview({
            accessToken: user?.accessToken,
            ...currentReview
        }));
        setState({ ...state, showValidation: false });
        history.goBack();
    }
    return (
        <>
            {!(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.visitReviewEditPage(urlParams.visitID, urlParams.visitReviewID))} />}
            <Container className="px-5">
                {inProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                {currentReview && <>
                    <BackArrowHeader title="Review" />
                    <Row className="">
                        <Col>
                            <img src={api.Search.getFirstPhotoUrl(currentReview.photos)} alt={currentReview.reviewTitle} width="100%" />
                        </Col>
                    </Row>
                    <div className={Animations.slide_in_bottom()}>
                        <Row >
                            <Col>
                                <div className={classes.roundedOverlay} onClick={() => cancelClick()}>
                                    <div className={"  " + classes.roundedOverlayBar} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Typography variant="subtitle2" className="my-2">
                                    {L10N({ key: "Write review" })}
                                </Typography>
                                <Typography variant="body1">
                                    {L10N({ key: "Rate and review your experience" })}
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="my-5">
                            <Col className="col-auto">
                                <Rating big value={state.ratingScore} onValueChange={value => setState({ ...state, ratingScore: value })} />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <TextField fullWidth variant="outlined"
                                    placeholder={L10N({ key: "Write here..." })}
                                    label={L10N({ key: "Title" })}
                                    color="primary"
                                    value={state.reviewTitle}
                                    onChange={event => setState({ ...state, reviewTitle: event.target.value })}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <TextField fullWidth variant="outlined"
                                    multiline
                                    rows={3}
                                    placeholder={L10N({ key: "Write here..." })}
                                    label={L10N({ key: "Review" })}
                                    color="primary"
                                    value={state.reviewDetails}
                                    onChange={event => setState({ ...state, reviewDetails: event.target.value })}
                                />
                            </Col>
                        </Row>
                        <Row className="my-5">
                            <Col className="">
                                <Button onClick={() => saveClick()} variant="contained" color="primary"
                                    size='large'
                                    className={classes.roundedButton}
                                    disableElevation
                                    fullWidth>
                                    {inProgress && <CircularProgress color="secondary" />}
                                    {!inProgress && L10N({ key: "Save" })}
                                    <ButtonShadow />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </>}
            </Container >
        </>
    );
};

