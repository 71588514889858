import { Action } from 'redux';
import { SignalMessage } from '../../api/models/Global';

export const CHANGE_CALENDAR = '[COMPANY] CHANGE_CALENDAR';
export const CHANGE_SIGNAL_MESSAGES = '[COMPANY] CHANGE_SIGNAL_MESSAGES';

interface ChangeCalendarAction extends Action {
    type: typeof CHANGE_CALENDAR,
    payload: {
        inProgress: boolean,
        calendar: undefined,
    }
}
interface ChangeSignalMessagesAction extends Action {
    type: typeof CHANGE_SIGNAL_MESSAGES,
    payload: {
        signalMessages?: SignalMessage[],
    }
}
export type CompanyActionTypes = ChangeCalendarAction
    | ChangeSignalMessagesAction;
