import { env } from '../env';
import { apiErrorFromResponse } from './ApiError';
import { ApiRequest, ApiResponse } from './ApiResponse';
import { CompanyEndpoint } from './endpoints/Company';
import { DetailsEndpoint } from './endpoints/Details';
import { EditEndpoint } from './endpoints/Edit';
import { FeaturedEndpoint } from './endpoints/Featured';
import { GeocodingEndpoint } from './endpoints/Geocoding';
import { InviteEndpoint } from './endpoints/Invite';
import { MarketingEndpoint } from './endpoints/Marketing';
import { PurchaseEndpoint } from './endpoints/Purchase';
import { SearchEndpoint } from './endpoints/Search';
import { UsersEndpoint } from './endpoints/User';
import { VisitEndpoint } from './endpoints/Visit';

export class api {
    static Company = new CompanyEndpoint();
    static Featured = new FeaturedEndpoint();
    static Invite = new InviteEndpoint();
    static Search = new SearchEndpoint();
    static Users = new UsersEndpoint();
    static Details = new DetailsEndpoint();
    static Visit = new VisitEndpoint();
    static Marketing = new MarketingEndpoint();
    static Edit = new EditEndpoint();
    static Geocoding = new GeocodingEndpoint();
    static Purchase = new PurchaseEndpoint();

    static url = env.current.PUBLIC_URL + "/";
    static signalrUrl = env.current.PUBLIC_URL + "/hubs/signal";

    static async createHeaders<T>(apiRequest: ApiRequest<T>): Promise<Headers | undefined> {
        const headers = new Headers({
        });
        if (apiRequest.json) {
            headers.append("Content-Type", 'application/json');
        }
        return headers;
    }
    static async consumeResults<T>(apiResponse: ApiResponse<T>): Promise<void> {
        if (apiResponse.apiRequest.resultJsonTransformer && apiResponse.rawResponse) {
            const data = await apiResponse.rawResponse.json().then(
                result => {
                    return result;
                },
                reason => {
                    console.error(reason);
                    apiResponse.error = {
                        type: "error",
                    };
                });
            if (data) {
                apiResponse.result = apiResponse.apiRequest.resultJsonTransformer(data);
            }
        } else if (apiResponse.apiRequest.resultTransformer && apiResponse.rawResponse) {
            apiResponse.result = await apiResponse.apiRequest.resultTransformer(apiResponse.rawResponse);
        }
    }

    static async createRequest<T>(apiRequest: ApiRequest<T>): Promise<ApiResponse<T>> {
        if (apiRequest.endpoint && apiRequest.endpoint.indexOf("/") === 0) throw new Error("Api cannot start with /. Just fix the code.");
        const apiResponse: ApiResponse<T> = {
            apiRequest: apiRequest,
        };
        const headers = await api.createHeaders(apiRequest);
        if (!headers) {
            apiResponse.error = {
                type: "unauthorized",
            };
            return apiResponse;
        }
        const url = apiRequest.endpoint
            ? api.url + apiRequest.endpoint
            : apiRequest.externalUrl ?? "";

        apiResponse.rawResponse = await fetch(url,
            {
                method: apiRequest.method,
                headers: headers,
                body: apiRequest.json ?? apiRequest.form,
            }).then(result => result,
                reason => {
                    console.error(reason);
                    apiResponse.error = {
                        type: "error",
                    };
                    return undefined;
                });
        if (apiResponse.rawResponse && !apiResponse.rawResponse.ok) {
            console.warn(apiResponse.rawResponse);
            apiResponse.error = await apiErrorFromResponse(apiResponse.rawResponse);
            return apiResponse;
        }
        await api.consumeResults(apiResponse);
        return apiResponse;
    }
}
