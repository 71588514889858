import { CircularProgress, createStyles, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyEditModel } from '../../api/models/Edit';
import { SearchCategory_Anything } from '../../api/models/Search';
import { refreshHints } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { L10N } from '../pages/L10N';
import { CategoryIcon } from '../ui/CategoryIcon';
import { useFormikResult } from '../ui/FormikAPI';
import { FieldSetKlarnaApiCredentials } from './FieldSetKlarnaApiCredentials';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectItemImg: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
            width: '20px',
            height: '20px',
        },
        menuText: {
            marginLeft: '20px',
        },
    })
);
export const FieldSetCompanyBasics = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {
    const dispatch = useDispatch();
    const { searchCategories, searchHintsInProgress } = useSelector((state: RootState) => state.search);
    const classes = useStyles();

    useEffect(() => {
        if ((searchCategories?.results?.length ?? 0) === 0) {
            dispatch(refreshHints());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <>
        <Container>
            <TextField className="mb-3" fullWidth
                disabled
                id="companyID" name="companyID" label={L10N({ key: "Company ID" })}
                value={props.formik.values.companyID ?? ""}
                onChange={props.formik.handleChange}
                error={props.formik.touched.companyID && Boolean(props.formik.errors.companyID)}
                helperText={props.formik.touched.companyID && props.formik.errors.companyID}
            /><br />
            <TextField variant="outlined" className="mb-3 text-black" fullWidth
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                id="name" name="name" label={L10N({ key: "Company Name" })}
                value={props.formik.values.name ?? ""}
                onChange={props.formik.handleChange}
                error={props.formik.touched.name && Boolean(props.formik.errors.name)}
                helperText={props.formik.touched.name && props.formik.errors.name}
            /><br />
            <TextField variant="outlined" className="mb-3" fullWidth
                select
                id="category" name="category" label={L10N({ key: "Bookee Category" })}
                value={props.formik.values.category ?? ""}
                onChange={props.formik.handleChange}
                error={props.formik.touched.category && Boolean(props.formik.errors.category)}
                helperText={props.formik.touched.category && props.formik.errors.category}
            >
                {searchHintsInProgress && <CircularProgress />}
                {searchCategories?.results?.map &&
                    searchCategories.results
                        .filter((m, i, a) => m.text !== SearchCategory_Anything)
                        .map((m, i) => (
                            <MenuItem key={m.encodedText} value={m.text}>
                                <CategoryIcon name={m.text} className={classes.selectItemImg} />
                                <Typography variant="body1" color={m.hasResults ? "textPrimary" : "textSecondary"} className={classes.menuText}>
                                    {L10N({ key: "_category", cat: m.text })}
                                </Typography>
                            </MenuItem>
                        ))}
            </TextField>
            <TextField variant="outlined" className="mb-3 text-black" fullWidth
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                id="keyWords" name="keyWords" label={L10N({ key: "Search Keywords" })}
                value={props.formik.values.keyWords ?? ""}
                onChange={props.formik.handleChange}
                error={props.formik.touched.keyWords && Boolean(props.formik.errors.keyWords)}
                helperText={props.formik.touched.keyWords && props.formik.errors.keyWords}
            /><br />
            <Row>
                <Col xs={12} md={6}>
                    <TextField variant="outlined" className="mb-3" fullWidth
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        id="bookeePhone" name="bookeePhone" label={L10N({ key: "Contact Phone for Bookee" })}
                        value={props.formik.values.bookeePhone ?? ""}
                        onChange={props.formik.handleChange}
                        error={props.formik.touched.bookeePhone && Boolean(props.formik.errors.bookeePhone)}
                        helperText={props.formik.touched.bookeePhone && props.formik.errors.bookeePhone}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <TextField variant="outlined" className="mb-3" fullWidth
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        id="bookeeEmail" name="bookeeEmail" label={L10N({ key: "Contact Email for Bookee" })}
                        value={props.formik.values.bookeeEmail?.trim() ?? ""}
                        onChange={props.formik.handleChange}
                        error={props.formik.touched.bookeeEmail && Boolean(props.formik.errors.bookeeEmail)}
                        helperText={props.formik.touched.bookeeEmail && props.formik.errors.bookeeEmail}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldSetKlarnaApiCredentials formik={props.formik} />
                </Col>
            </Row>
        </Container>
    </>;
}