import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeSearchString } from '../../store/search/search.actions';
import { RootState } from '../../store/store';
import { SearchChip } from './SearchChip';

export const SearchChips = () => {
    const dispatch = useDispatch();
    const { searchString, searchHints } = useSelector((state: RootState) => state.search);

    function handleChange(isChecked: boolean, chip: string) {
        let newSearchString = searchString;
        if (!isChecked) {
            const regEx = new RegExp(chip, "ig");
            newSearchString = newSearchString.replace(regEx, "");
        } else {
            newSearchString = newSearchString + " " + chip;
        }
        dispatch(changeSearchString(newSearchString));
    }
    return (
        <>
            <Col>
                {searchHints?.results?.map &&
                    <div className="noScroll"
                        style={{
                        display: "flex",
                        overflow: "scroll",
                        scrollbarWidth: "none",
                    }}>
                        {searchHints.results?.map((m, i) =>
                            <div key={i} className="float-left" >
                                <SearchChip key={i}
                                    onChange={(isChecked) => handleChange(isChecked, m.encodedText)}
                                    isChecked={searchString.includes(m.encodedText)}
                                    data={m} />
                            </div>
                        )}
                    </div>
                }
            </Col>
        </>
    );
}
