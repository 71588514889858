import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, Typography } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { CompanyEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { useFormikResult } from '../ui/FormikAPI';
import { TestKlarnaButton } from '../ui/KlarnaPayments';

export const FieldSetKlarnaApiCredentials = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {
    function onGoToKlarna() {
        const url = "https://www.klarna.com";
        if (isMobile) {
            window.location.href = url;
        } else {
            window.open(url, '_blank')?.focus();
        }
    }
    return <>
        <Accordion defaultExpanded={false} disabled>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                className="text-black text-weight-bold2">
                {L10N({ key: "Klarna Payments" })}
                <Button onClick={onGoToKlarna} variant="contained" color="primary"
                    className="ml-auto">
                    <ExitToApp />
                </Button>
            </AccordionSummary>
            <AccordionDetails className="d-block">
                <Typography variant="body1">
                    {L10N({ key: "Klarna Intro Text" })}
                </Typography>
                <br />
                <TextField variant="outlined" className="mb-3 text-black" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="klarnaApiCredentials.url" name="klarnaApiCredentials.url" label={L10N({ key: "Klarna API url" })}
                    value={props.formik.values.klarnaApiCredentials?.url ?? ""}
                    onChange={props.formik.handleChange}
                /><br />
                <TextField variant="outlined" className="mb-3 text-black" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="klarnaApiCredentials.username" name="klarnaApiCredentials.username" label={L10N({ key: "Klarna username" })}
                    value={props.formik.values.klarnaApiCredentials?.username ?? ""}
                    onChange={props.formik.handleChange}
                /><br />
                <TextField variant="outlined" className="mb-3 text-black" fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    id="klarnaApiCredentials.password" name="klarnaApiCredentials.password" label={L10N({ key: "Klarna password" })}
                    value={props.formik.values.klarnaApiCredentials?.password ?? ""}
                    onChange={props.formik.handleChange}
                /><br />
                <TestKlarnaButton klarnaApiCredentials={props.formik.values.klarnaApiCredentials} />
            </AccordionDetails>
        </Accordion>

    </>;
}