import {
    API_ACTION_HAS_FINISHED, API_ACTION_HAS_STARTED,
    CHANGE_MODAL_ACTION,
    ErrorDetails,
    UIActionTypes
} from './ui.types';

export interface UIState {
    progressVisible: boolean,
    error?: ErrorDetails,
    cancelVisitDialogVisible?: boolean,
    serviceDetailsDialogVisible?: boolean,
}

const initialUIState: UIState = {
    progressVisible: false,
    error: undefined,
    cancelVisitDialogVisible: false,
    serviceDetailsDialogVisible: false,
}
export function uiReducer(
    state = initialUIState,
    action: UIActionTypes
): UIState {
    switch (action.type) {
        case API_ACTION_HAS_STARTED: return { ...state, progressVisible: true };
        case API_ACTION_HAS_FINISHED: return { ...state, progressVisible: false, error: action.payload.error };
        case CHANGE_MODAL_ACTION: return { ...state, ...action.payload };
        default:
            return state
    }
}

