import { Button, CircularProgress, Divider, TextField, Typography } from "@material-ui/core";
import * as React from 'react';
import { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { api } from "../../api/api";
import { MarketingQuestionMessage } from "../../api/models/Marketing";
import { CompanyMapView } from "../details/CompanyMapView";
import { L10N } from "../pages/L10N";
import { ButtonShadow } from "../ui/ButtonShadow";
import { MarketingGradientShadow } from "./MarketingGradientShadow";

export const Marketing08_ContactUs = () => {
    const [state, setState] = useState<MarketingQuestionMessage & {
        inProgress: boolean,
        hasSent: boolean,
        hasAttempted: boolean,
    }>({
        email: "",
        message: "",
        name: "",
        inProgress: false,
        hasSent: false,
        hasAttempted: false,
    });
    async function sendClick() {
        if (state.inProgress) return;
        if (!state.hasAttempted) {
            setState({
                ...state,
                hasAttempted: true,
            });
        }
        if (state.hasSent) {
            setState({
                ...state,
                hasSent: false,
            });
        }
        if (!isValid()) return;

        setState({
            ...state,
            inProgress: true,
        });
        const result = await api.Marketing.postQuestion(state);
        if (result) {
            setState({
                ...state,
                email: "",
                message: "",
                name: "",
                inProgress: false,
                hasSent: true,
                hasAttempted: false,
            });
        } else {
            setState({
                ...state,
                inProgress: false,
                hasSent: false,
                hasAttempted: false,
            });
        }
    }
    function isValid(): boolean {
        if (!state.email || !state.message || !state.name) {
            return false;
        } else {
            return true;
        }
    }
    return (
        <>
            <MarketingGradientShadow />
            <Container >
                <Row className="my-3">
                    <Col xs={12} lg={true} className="my-3">
                        <Typography variant="h4" align="center" className="text-weight-bold2">
                            {L10N({ key: 'Kontaktiere uns' })}
                        </Typography>
                        <Typography variant="subtitle2" align="justify" color="secondary" >
                            <br />
                            {L10N({ key: 'Möchtest du gerne als Dienstleister unseren Service nutzen?' })}
                            <br />
                            <br />
                            {L10N({ key: 'Dann schreib uns einfach via Email, oder Kontaktformular an und wir melden uns dann schnellstmöglich bei dir.' })}
                        </Typography>
                        <Typography variant="subtitle2" className="text-weight-bold2">
                            <br />
                            <a href="mailto:info@letsbookee.com?subject=Anfrage">info@letsbookee.com</a>
                        </Typography>
                        <Divider className="my-5" />
                        <Typography variant="subtitle1" >
                            {L10N({ key: 'Hier sind wir' })}
                        </Typography>
                        <Typography variant="subtitle2" color="secondary" >
                            {L10N({ key: 'Am Sandfeld 18, 76149 Karlsruhe' })}
                        </Typography>
                        <CompanyMapView company={{
                            ratingScore: 5,
                            ratingVotes: 999,
                            address: '',
                            category: 'book',
                            mapCoords: { "lat": 49.04407274408796, "lng": 8.39069144294367 },
                            serviceGroups: [],
                            resources: [],
                            portfolio: [],
                            reviews: [],
                            itemID: '',
                            itemType: '',
                            title: '',
                            subtitle: '',
                            description: '',
                            htmlDescription: '',
                            learnMoreURL: '',
                            photos: [],
                            isDemo: false,
                        }} />
                    </Col>
                    <Col xs={1} />
                    <Col xs={12} lg={true} className="my-3">
                        <Typography variant="h4" align="center" className="text-weight-bold2">
                            {L10N({ key: 'Nutze unser Kontaktformular' })}
                        </Typography>
                        <Typography variant="subtitle2" align="justify" color="secondary" >
                            <br />
                        </Typography>
                        <div style={{
                            backgroundColor: '#EBEAED',
                            padding: '20px',
                        }}>
                            <TextField fullWidth variant="outlined"
                                className="my-3"
                                placeholder={L10N({ key: "Email" })}
                                label={L10N({ key: "Email" })}
                                type="email"
                                autoComplete="email"
                                value={state.email}
                                onChange={(event) => setState({
                                    ...state,
                                    email: event.target.value,
                                })}
                            />
                            <TextField fullWidth variant="outlined"
                                className="my-3"
                                multiline
                                rows={6}
                                placeholder={L10N({ key: "Nachricht" })}
                                label={L10N({ key: "Nachricht" })}
                                value={state.message}
                                onChange={(event) => setState({
                                    ...state,
                                    message: event.target.value,
                                })}
                            />
                            <TextField fullWidth variant="outlined"
                                className="my-3"
                                placeholder={L10N({ key: "Name" })}
                                label={L10N({ key: "Name" })}
                                autoComplete="name"
                                value={state.name}
                                onChange={(event) => setState({
                                    ...state,
                                    name: event.target.value,
                                })}
                            />
                            {state.hasAttempted && !isValid() &&
                                <Typography>
                                    {L10N({ key: 'bitte füllen Sie das Formular aus' })}
                                </Typography>
                            }
                            <Button onClick={sendClick}
                                variant="contained" color="primary"
                                disableElevation>
                                {state.inProgress
                                    ? <CircularProgress size="25px" />
                                    : L10N({ key: 'Senden' })
                                }
                                <ButtonShadow />
                            </Button>
                            {state.hasSent &&
                                <Typography>
                                    {L10N({ key: 'Nachricht erfolgreich gesendet' })}
                                </Typography>
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="my-5" />
            </Container>
        </>
    );
};
