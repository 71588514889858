import 'bootstrap/dist/css/bootstrap.css';
import * as ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { IntlProvider } from "react-intl";
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';
import { persistor, store } from './store/store';

const rootElement = document.getElementById('root');
ReactGA.initialize('UA-198855106-1', {
    debug: false,
    gaOptions: {
        siteSpeedSampleRate: 100,
        sampleRate: 100,
    },
});

//persistor.purge();
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <IntlProvider locale='en'>
                    <App />
                </IntlProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    rootElement);

registerServiceWorker();