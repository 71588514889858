import { Avatar, Badge, Box, Button, CardActionArea, CardHeader, CardProps, createStyles, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../api/api";
import { CalendarSlot } from "../../api/models/Company";
import { loadVisitForEdit } from "../../store/edit/edit.actions";
import { RootState } from "../../store/store";
import { L10N } from '../pages/L10N';
import { Animations } from "../ui/Animations";
import { CustomStyle } from "../ui/CustomStyle";
import { EmiCard } from "../ui/EmiCard";
import { EditVistDialog } from "./EditVistDialog";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            position: 'absolute',
            right: '10px',
            top: '10px',
        },
        collapseIcon: {
            position: 'absolute',
            right: '5px',
            bottom: '-5px',
        },
        btnEdit: {
            position: 'absolute',
            left: '0px',
            top: '0px',
            zIndex: 2,
        },
    })
);

export const CalendarVisitListItemCard = (props: CardProps & {
    data: CalendarSlot,
    onCardClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
    onCardDeleteClick?: () => Promise<void>,
    canCollapse?: boolean,
    minHeight?: number,
    onReloadRequired: () => Promise<void>,
}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    const [editDialog, setEditDialog] = useState(false);
    const classes = useStyles();
    const { data,
        className,
        onCardClick,
        onCardDeleteClick,
        canCollapse,
        minHeight,
        onReloadRequired,
        ...cardProps } = props;

    async function onDelete() {
        if (!props.onCardDeleteClick) return;

        await props.onCardDeleteClick();
    }
    async function editDetailsClick(event: React.MouseEvent<any, MouseEvent>) {
        if (!user) return;
        if (!props.data.visitID) return;
        if (event.defaultPrevented) return;

        event.stopPropagation();
        event.preventDefault();
        setEditDialog(true);
        await dispatch(loadVisitForEdit(user, props.data.visitID));

    }
    async function editClose() {
        setEditDialog(false);
    }
    return (<>
        <EmiCard className={Animations.swing_in_top_fwd() + " " + className}
            {...cardProps}
            elevation={1}
            onClick={!editDialog && props.onCardClick ? props.onCardClick : undefined}
            style={{
                minHeight: props.minHeight,
                borderBottom: '1px solid gray',
            }}
        >
            {editDialog &&
                <EditVistDialog
                    onReloadRequired={props.onReloadRequired}
                    open={editDialog}
                    onClose={editClose}
                />
            }
            <IconButton className={classes.btnEdit} onClick={editDetailsClick}>
                <Badge badgeContent={props.data.visitStatus === 'Requested' ? 1 : undefined} color="primary"
                    max={9}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                    <EditIcon color="primary" />
                </Badge>
            </IconButton >
            <Container>
                <Row className="">
                    <Col className="p-0 align-self-center" xs={3}>
                        <Box borderRadius={6} borderColor={"secondary.light"} className="px-1 m-0 py-1"
                        >
                            {props.data.startForClient !== props.data.start &&
                                <Typography variant="body1" align="center" color="textPrimary" >
                                    {L10N({ key: "_dateAsHHmm", date: props.data.startForClient })}<br />
                                </Typography>
                            }
                            <Typography variant="body1" align="center" color="textSecondary" className="ml-3" >
                                {L10N({ key: "_dateAsHHmm", date: props.data.start })}<br />
                            </Typography>
                            <Typography variant="body1" align="center" color="textPrimary" style={{ lineHeight: "0.5" }} className="ml-3" >
                                -
                            </Typography>
                            <Typography variant="body1" align="center" color="textSecondary" className="ml-3" >
                                {L10N({ key: "_dateAsHHmm", date: props.data.end })}<br />
                            </Typography>
                            {props.data.endForClient !== props.data.end &&
                                <Typography variant="body1" align="center" color="textPrimary"  >
                                    {L10N({ key: "_dateAsHHmm", date: props.data.endForClient })}<br />
                                </Typography>
                            }
                        </Box>
                    </Col>
                    <Col className="p-0 col-auto" xs={9}>
                        <CardActionArea disabled={!props.onCardClick}>
                            <CardHeader className="py-1"
                                title={<>
                                    <Typography variant="body1" align="left" color="textPrimary">
                                        {props.data.clientName}
                                    </Typography>
                                    <Typography variant="body2" align="left" color="textPrimary">
                                        {props.data.clientPhone}<br />
                                        {props.data.serviceName}<br />
                                        {props.data.notes}<br />
                                    </Typography>
                                    <Typography variant="body1" align="left" className={CustomStyle.visitStatusClassName(props.data?.visitStatus)}>
                                        {L10N({ key: "_status", status: props.data?.visitStatus })}
                                    </Typography>
                                </>}
                            />
                            <Avatar alt={props.data.clientName}
                                className={classes.avatar}
                                variant="rounded"
                                src={api.Search.getFirstPhotoUrl(props.data?.photos)} />
                            {props.canCollapse &&
                                <img src="/img/icons/icon_up_dark.svg" alt="up" className={classes.collapseIcon} />
                            }
                        </CardActionArea>
                        {props.data?.visitStatus === "CanceledByClient" && props.onCardDeleteClick &&
                            <Button onClick={onDelete} variant="outlined" style={{ float: 'right' }} className="m-1">
                                {L10N({ key: "Delete" })}
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </EmiCard>
    </>);
}