import { ValidationResult } from "./models/Global";

export interface ApiError {
    type?: "nullResponse" | "unauthorized" | "error" | "validation",
    message?: string,
    validation?: ValidationResult,
}
export async function apiErrorFromResponse(response: Response): Promise<ApiError | undefined> {
    try {
        var json = await response.json();
    } catch {
        json = undefined;
    }
    switch (response.status) {
        case 401:
            return {
                type: "unauthorized",
                message: json,
            };
        case 422:
            return {
                type: "validation",
                validation: (json as ValidationResult),
            };
        case 404:
        default:
            return {
                type: "error",
                message: json,
            };
    }
}

function isResponse(value: any): boolean {
    return (typeof value === "object")
        && ("headers" in value)
        && ("ok" in value)
        && ("status" in value)
        && ("url" in value);
}
export function isApiError(value: any): boolean {
    if (isResponse(value)) {
        var http = value as Response;

        switch (http.status) {
            case 401:
                return true;
            default:
                return !http.ok;
        }
    } else if (
        value === "nullResponse"
        || value === "unauthorized"
        || value === "error"
    ) {
        return true;
    } else {
        return false;
    }
}

