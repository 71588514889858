import { Avatar, Button, LinearProgress, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { api } from '../../api/api';
import { CompanyDetails } from '../../api/models/Details';
import { AssignCompanyMessage, ToggleDemoCompanyMessage } from '../../api/models/Edit';
import { UserListItem } from '../../api/models/User';
import { RootState } from '../../store/store';
import { assignCompany } from '../../store/user/user.actions';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { Routes } from './NavMenu';

type SuperAdminPageState = {
    inProgress: boolean,
    userList?: UserListItem[],
    companyList?: CompanyDetails[],
};
export const SuperAdminPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user, inProgress } = useSelector((state: RootState) => state.user);
    const [state, setState] = useState<SuperAdminPageState>({
        inProgress: false,
        userList: undefined,
        companyList: undefined,
    });
    async function reloadStore(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({ inProgress: true });
        await api.Users.reloadStore(user);
        setState({ inProgress: false });
    }
    async function reloadUserList(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({
            inProgress: true,
        });
        const result = await api.Users.getUserList(user);
        setState({
            inProgress: false,
            userList: result.result,
        });
    }
    async function reloadCompanyList(): Promise<void> {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;

        setState({
            inProgress: true,
        });
        const result = await api.Details.getCompanyList(user);
        setState({
            inProgress: false,
            companyList: result.result,
        });
    }
    function editCompanyClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        history.push(Routes.editPage(companyID));
    }
    function viewCompanyClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        history.push(Routes.detailsTab("company", companyID));
    }
    function onAssignCompanyClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        const assignCompanyMessage: AssignCompanyMessage = {
            accessToken: user.accessToken,
            companyID: companyID,
        };
        dispatch(assignCompany(assignCompanyMessage));
    }
    async function onToggleDemoClick(companyID?: string) {
        if (!user) return;
        if (!user?.isSuperAdmin) return;
        if (state.inProgress) return;
        if (!companyID) return;

        const toggleDemoMessage: ToggleDemoCompanyMessage = {
            accessToken: user.accessToken,
            companyID: companyID,
        };
        await api.Edit.toggleDemoCompany(toggleDemoMessage);
        await reloadCompanyList();
    }

    return (
        <>
            {!inProgress && user?.isSuperAdmin !== true && <Redirect to={Routes.loginAndReturn(Routes.superAdminPage())} />}

            <Container className="w-100" style={{ maxWidth: '100%', }}>
                <BackArrowHeader title="Super Admin" />
                {(inProgress || state.inProgress) && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row className="mb-3">
                    <Col md={3} />
                    <Col className="">
                        <Typography variant="body1" className="my-3" align="center">
                            The tools below are only meant to be used by Super Admins. Please use with care.
                        </Typography>
                    </Col>
                    <Col md={3} />
                </Row>
                <Row className="my-3">
                    <Col md={3} />
                    <Col className="">
                        <Button variant="contained"
                            size="small"
                            disabled={state.inProgress}
                            onClick={reloadStore}>
                            Reload Store
                        </Button>
                    </Col>
                    <Col md={3} />
                </Row>
                <Row className="my-3">
                    <Col md={3} />
                    <Col className="">
                        <Button variant="contained"
                            size="small"
                            disabled={state.inProgress}
                            onClick={reloadUserList}>
                            Load users
                        </Button>
                    </Col>
                    <Col md={3} />
                </Row>
                <Row className="my-3">
                    <Col md={3} />
                    <Col className="">
                        <Button variant="contained"
                            size="small"
                            disabled={state.inProgress}
                            onClick={reloadCompanyList}>
                            Load companies
                        </Button>
                    </Col>
                    <Col md={3} />
                </Row>
                {state.userList && state.userList.length > 0 &&
                    <Row className="my-3">
                        <Col >
                            <table className="table table-striped table-bordered">
                                <caption>List of users</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Photo</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Language</th>
                                        <th scope="col">Created On</th>
                                        <th scope="col">CompanyID</th>
                                        <th scope="col">ResourceID</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.userList.map((m, i) => <tr key={i}>
                                        <td>{i + 1}.</td>
                                        <td>
                                            <Avatar
                                                src={api.Search.getFirstPhotoUrl(m?.photos)}
                                                className={"m-1 "}
                                                alt={m?.firstName} />
                                        </td>
                                        <td>{m.firstName}</td>
                                        <td>{m.lastName}</td>
                                        <td>{m.phone}</td>
                                        <td>{m.email}</td>
                                        <td>{m.language}</td>
                                        <td>{m.createdOn}</td>
                                        <td>{m.companyID}</td>
                                        <td>{m.resourceID}</td>
                                        <td>
                                            {m.companyID && <Button onClick={() => editCompanyClick(m.companyID)}
                                                className="m-1"
                                                variant="outlined" color="primary" size="small">
                                                Edit company
                                            </Button>}
                                            {m.companyID && <Button onClick={() => viewCompanyClick(m.companyID)}
                                                className="m-1"
                                                variant="outlined" color="primary" size="small">
                                                View company
                                            </Button>}
                                            {m.companyID && <Button onClick={() => onAssignCompanyClick(m.companyID)}
                                                className="m-1"
                                                variant="outlined" color="primary" size="small">
                                                Assign company
                                            </Button>}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                }
                {state.companyList && state.companyList.length > 0 &&
                    <Row className="my-3">
                        <Col >
                            <table className="table table-striped table-bordered">
                                <caption>List of companies</caption>
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Photo</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.companyList
                                        .reverse()
                                        .map((m, i) => <tr key={i}>
                                            <td>{i + 1}.</td>
                                            <td>
                                                <Avatar
                                                    src={api.Search.getFirstPhotoUrl(m?.photos)}
                                                    className={"m-1 "}
                                                    alt={m?.title} />
                                            </td>
                                            <td>{m.title}</td>
                                            <td>{m.category}</td>
                                            <td>
                                                {m.itemID && <Button onClick={() => editCompanyClick(m.itemID)}
                                                    className="m-1"
                                                    variant="outlined" color="primary" size="small">
                                                    Edit company
                                                </Button>}
                                                {m.itemID && <Button onClick={() => viewCompanyClick(m.itemID)}
                                                    className="m-1"
                                                    variant="outlined" color="primary" size="small">
                                                    View company
                                                </Button>}
                                                {m.itemID && <Button onClick={() => onAssignCompanyClick(m.itemID)}
                                                    className="m-1"
                                                    variant="outlined" color="primary" size="small">
                                                    Assign company
                                                </Button>}
                                                {m.itemID && <Button onClick={() => onToggleDemoClick(m.itemID)}
                                                    className="m-1"
                                                    variant="outlined" color="primary" size="small">
                                                    {m.isDemo ? "Unset demo" : "Set demo"}
                                                </Button>}
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                }
            </Container>
        </>
    );
};
