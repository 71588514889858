import { Button, createStyles, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { InviteCompanyService, InviteDuration, InvitePrice } from '../../api/models/Invite';
import { loadAllDetails } from '../../store/details/details.actions';
import { changeSelectedDimension } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { Routes } from '../pages/NavMenu';
import { EmiPaper } from '../ui/EmiPaper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            display: 'block',
            textTransform: 'none',
            outline: 'none !important',
            borderRadius: '20px',
        },
        horizontalList: {
            display: "flex",
            overflow: "scroll",
            scrollbarWidth: "none",
            paddingBottom: "6px",
        },
    })
);
export const ServiceSelector = (props: {
    hideIfOne?: boolean,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentInvite, serverInvite } = useSelector((state: RootState) => state.invite);
    const classes = useStyles();

    function isSelected(m: InviteCompanyService | undefined): boolean {
        if (!m) return false;
        return m.dimensionValue === currentInvite?.companyServiceDimensionSelectedValue;
    }
    function onServiceClicked(m: InviteCompanyService) {
        dispatch(changeSelectedDimension(m, "service"));
    }
    function findServiceDetails(serviceID: string): undefined | {
        service?: InviteCompanyService,
        price?: InvitePrice,
        duration?: InviteDuration
    } {
        if (!serverInvite) return undefined;
        const offer = serverInvite
            ?.offers
            ?.find((v, i, a) => v.s === serviceID);

        const price = serverInvite
            ?.priceDimension
            ?.find((v, i, a) => v.dimensionValue === offer?.p);
        const duration = serverInvite
            ?.durationDimension
            ?.find((v, i, a) => v.dimensionValue === offer?.du);
        const service = serverInvite
            ?.companyServiceDimension
            ?.find((v, i, a) => v.dimensionValue === offer?.s);

        return { price, duration, service };
    }
    async function infoClick(event: React.MouseEvent<any, MouseEvent>, serviceID: string) {
        await dispatch(loadAllDetails(
            true,
            undefined,
            serviceID,
            undefined
        ));
        const route = Routes.detailsTab('service', serviceID);
        history.push(route);
        event.stopPropagation();
        event.preventDefault();
    }
    function renderService(m: InviteCompanyService): JSX.Element {
        const details = findServiceDetails(m.serviceID);
        return (<>
            <Col className={"col-auto p-2"}>
                <Typography display="inline">
                    {details?.service?.displayName ?? m.displayName}
                </Typography>
                <IconButton onClick={async (event) => await infoClick(event, m.serviceID)} >
                    <img src="/img/icons/icon_info.svg" alt="info" />
                </IconButton>
                <br />
                <Typography variant="body2" display="inline" color="textPrimary">
                    {details?.price?.dimensionValue}
                    &nbsp;
                    &nbsp;
                    &nbsp;
                </Typography>
                {details?.duration?.duration &&
                    <Typography variant="body2" display="inline">
                        {moment.duration(details?.duration?.duration ?? 0, "minutes").humanize()}
                    </Typography>
                }
            </Col>
            <Col className="p-0" lg={false} xl={false}>
            </Col>
            <Col className="col-auto p-2">
                <Button className={classes.btn + " px-4 align-middle"}
                    variant={isSelected(m) ? "contained" : "outlined"}
                    color={isSelected(m) ? "primary" : "default"}
                    size="small"
                    onClick={() => onServiceClicked(m)}>
                    Book
                </Button>
            </Col>
        </>);
    }
    return (<>
        <Col className="mt-3" >
            <Paper>
                {currentInvite?.companyServiceDimension?.map
                    && currentInvite?.companyServiceDimension
                    && (!props.hideIfOne || currentInvite?.companyServiceDimension?.length > 1)
                    && currentInvite?.companyServiceDimension?.map
                    && currentInvite?.companyServiceDimension?.map((m, i) =>
                        <EmiPaper key={i}
                            elevationOnNoMouse={0} elevationOnMouseOver={0}
                            classOnMouseOver="border border-primary"
                            classOnNoMouse="border border-white"
                        >
                            <Container>
                                <Row >
                                    {renderService(m)}
                                </Row>
                            </Container>
                        </EmiPaper>
                    )}
            </Paper>
        </Col>
    </>);
};
