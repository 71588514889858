import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'bootstrap/dist/css/bootstrap.min.css';
//import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router';
import { AccountDetailsPage } from './components/pages/AccountDetailsPage';
import { CalendarPage } from './components/pages/CalendarPage';
import { ClientsPage } from './components/pages/ClientsPage';
import { CreateCompanyPage } from './components/pages/CreateCompanyPage';
import { DetailsPage } from './components/pages/DetailsPage';
import { EditPage } from './components/pages/EditPage';
import { ExplorePage } from './components/pages/ExplorePage';
import { FavouritesPage } from './components/pages/FavouritesPage';
import { InvitePage } from './components/pages/InvitePage';
import { Layout } from './components/pages/Layout';
import { LegalNoticePage } from './components/pages/LegalNoticePage';
import { LoginPage } from './components/pages/LoginPage';
import { MarketingPage } from './components/pages/MarketingPage';
import { MobilePage } from './components/pages/MobilePage';
import { Routes } from './components/pages/NavMenu';
import { PaymentPage } from './components/pages/PaymentPage';
import { ProfilePage } from './components/pages/ProfilePage';
import { RecommendPage } from './components/pages/RecommendPage';
import { SearchPage } from './components/pages/SearchPage';
import { StatisticsPage } from './components/pages/StatisticsPage';
import { SuperAdminPage } from './components/pages/SuperAdminPage';
import { TermsOfUsePage } from './components/pages/TermsOfUsePage';
import { VisitConfirmedPage } from './components/pages/VisitConfirmedPage';
import { VisitPage } from './components/pages/VisitPage';
import { VisitReviewEditPage } from './components/pages/VisitReviewEditPage';
import { VisitReviewPage } from './components/pages/VisitReviewPage';
import { ErrorHandler } from './components/users/ErrorHandler';
import './scss/custom.scss';
import './scss/gradients.scss';
import { RootState } from './store/store';
import { getCurrentUser } from './store/user/user.actions';


export const App = (props: {
}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.user);
    useEffect(() => {
        if (user) {
            dispatch(getCurrentUser(user));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const palette = createPalette({
        primary: {
            main: '#E8385A',
            light: '#AFAFAF',
            dark: '#1B1B1B',
        },
        secondary: {
            main: '#BFBFBF',
            light: '#EEEEEE',
        },
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
        },
    });
    const theme = createMuiTheme({
        palette: {
            primary: palette.primary,
            secondary: palette.secondary,
            background: palette.background,
        },
        typography: {
            fontFamily: ['"Montserrat"', 'Open Sans'].join(','),
            body1: {
                fontSize: "small",
                color: palette.primary.dark,
            },
            body2: {
                fontSize: "x-small",
                color: palette.primary.light,
            },
            subtitle1: {
                fontWeight: 600,
            },
            subtitle2: {
                fontWeight: 600,
            },
            h6: {
                fontWeight: 600,
            },
        },
        overrides: {
            MuiAccordion: {
                root: {
                    borderRadius: '20px',
                    marginBottom: '20px',
                },
                rounded: {
                    borderRadius: '20px',
                    '&:first-child': {
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                    },
                    '&:last-child': {
                        borderBottomLeftRadius: '20px',
                        borderBottomRightRadius: '20px',
                    }
                },
                expanded: {
                },
            },
            MuiAccordionDetails: {
                root: {
                },
            },
            MuiAccordionSummary: {
                content: {
                    '&.Mui-expanded': {
                    },
                },
                root: {
                    color: palette.primary.dark,
                    borderRadius: '20px',
                    "&$expanded": {
                        color: palette.primary.main,
                    }
                },
            },
            MuiAvatar: {
                colorDefault: {
                    backgroundColor: 'transparent',
                    color: "inherit",
                }
            },
            MuiChip: {
                root: {
                    '& > .MuiChip-avatarColorPrimary': {
                        backgroundColor: 'transparent',
                        color: "inherit",
                    },
                },
                avatarColorPrimary: {
                },
            },
            MuiIconButton: {
                colorPrimary: {
                    color: 'white',
                },
            },
            MuiButton: {
                containedPrimary: {
                    color: 'white',
                    background: 'linear-gradient(60.95deg, #ed242c 0%, #d2218b 100%)',
                    fontWeight: 900,
                },
            },
            MuiFab: {
                primary: {
                    color: 'white',
                    background: 'linear-gradient(60.95deg, #ed242c 0%, #d2218b 100%)',
                },
                secondary: {
                    color: '#E02359',
                    backgroundColor: 'white',
                }
            },
            MuiInputBase: {
                root: {
                    backgroundColor: palette.background.paper,
                }
            },
            MuiOutlinedInput: {
                root: {
                    borderRadius: '8px',
                }
            },
            MuiTextField: {
                root: {

                }
            },
            MuiButtonBase: {
                root: {
                }
            },
            MuiTableCell: {
                root: {
                    borderBottomColor: palette.primary.main
                }
            },
            MuiPaper: {
                rounded: {
                    borderRadius: '8px',
                }
            },
            MuiCardMedia: {
                media: {
                    margin: '8px auto 0px auto ',
                    width: '94%',
                    borderRadius: '8px',
                },
                root: {
                }
            },
            MuiTabs: {
                root: {
                },
                indicator: {
                    borderTop: '3px solid',
                    borderTopColor: palette.primary.main,
                }
            },
            MuiTab: {
                root: {
                    textTransform: 'none',
                    borderBottom: '3px solid',
                    color: palette.primary.light,
                    borderBottomColor: palette.secondary.light,
                    maxWidth: '',
                    "&$selected": {
                        color: palette.primary.main,
                    }
                },
            },
        }
    });

    function navigateToView(node: React.ReactNode, props: RouteComponentProps) {
        ReactGA.pageview(props.location.pathname);
        return node;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
            <MuiThemeProvider theme={theme}>
                <ErrorHandler />
                <Layout>
                    <Route exact path={Routes.home} render={props => { return navigateToView(<SearchPage />, props); }} />
                    <Route path={Routes.mobileHome} render={props => { return navigateToView(<MobilePage />, props); }} />
                    <Route path={Routes.login} render={props => { return navigateToView(<LoginPage />, props); }} />
                    <Route path={Routes.explore} render={props => { return navigateToView(<ExplorePage />, props); }} />
                    <Route path={Routes.favourites} render={props => { return navigateToView(<FavouritesPage />, props); }} />
                    <Route path={Routes.profile} render={props => { return navigateToView(<ProfilePage />, props); }} />
                    <Route path={Routes.calendar} render={props => { return navigateToView(<CalendarPage />, props); }} />
                    <Route path={Routes.invite} render={props => { return navigateToView(<InvitePage />, props); }} />
                    <Route path={Routes.visit} render={props => { return navigateToView(<VisitPage />, props); }} />
                    <Route path={Routes.visitConfirmed} render={props => { return navigateToView(<VisitConfirmedPage />, props); }} />
                    <Route path={Routes.marketing} render={props => { return navigateToView(<MarketingPage />, props); }} />
                    <Route path={Routes.visitReview} render={props => { return navigateToView(<VisitReviewPage />, props); }} />
                    <Route path={Routes.visitReviewEdit} render={props => { return navigateToView(<VisitReviewEditPage />, props); }} />
                    <Route path={Routes.details} render={props => { return navigateToView(<DetailsPage />, props); }} />
                    <Route path={Routes.accountDetails} render={props => { return navigateToView(<AccountDetailsPage />, props); }} />
                    <Route path={Routes.terms} render={props => { return navigateToView(<TermsOfUsePage />, props); }} />
                    <Route path={Routes.legal} render={props => { return navigateToView(<LegalNoticePage />, props); }} />
                    <Route path={Routes.recommend} render={props => { return navigateToView(<RecommendPage />, props); }} />
                    <Route path={Routes.statistics} render={props => { return navigateToView(<StatisticsPage />, props); }} />
                    <Route path={Routes.edit} render={props => { return navigateToView(<EditPage />, props); }} />
                    <Route path={Routes.clients} render={props => { return navigateToView(<ClientsPage />, props); }} />
                    <Route path={Routes.createCompany} render={props => { return navigateToView(<CreateCompanyPage />, props); }} />
                    <Route path={Routes.payment} render={props => { return navigateToView(<PaymentPage />, props); }} />
                    <Route path={Routes.superAdmin} render={props => { return navigateToView(<SuperAdminPage />, props); }} />
                </Layout>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
}
