import { createStyles, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { L10N } from '../pages/L10N';

export const extractCountry = (phone: string): string | undefined => {
    if (!phone) return undefined;
    if (phone.startsWith("+43")
        || phone.startsWith("0043")) {
        return "AT";
    } else if (phone.startsWith("+32")
        || phone.startsWith("0032")) {
        return "BE";
    } else if (phone.startsWith("+41")
        || phone.startsWith("0041")) {
        return "CH";
    } else if (phone.startsWith("+420")
        || phone.startsWith("00420")) {
        return "CZ";
    } else if (phone.startsWith("+49")
        || phone.startsWith("0049")) {
        return "DE";
    } else if (phone.startsWith("+48")
        || phone.startsWith("0048")) {
        return "PL";
    } else if (phone.startsWith("+971")
        || phone.startsWith("00971")) {
        return "AE";
    } else if (phone.startsWith("+91")
        || phone.startsWith("0091")) {
        return "IN";
    } else {
        return undefined;
    }
}
export const extractPrefix = (country: string): string | undefined => {
    if (!country) return undefined;
    switch (country) {
        case "AT": return "+43";
        case "BE": return "+32";
        case "CH": return "+41";
        case "CZ": return "+420";
        case "DE": return "+49";
        case "PL": return "+48";
        case "AE": return "+971";
        case "IN": return "+91";
        default: return undefined;
    }
}
export const extractInCountryPhone = (phone?: string): string | undefined => {
    if (!phone) return undefined;

    const country = extractCountry(phone);
    if (!country) return phone;
    const prefix = extractPrefix(country);
    if (!prefix) return phone;

    let inCountryPhone = phone;
    if (inCountryPhone.startsWith("00")) {
        inCountryPhone = "+" + inCountryPhone.substr(2);
    }
    inCountryPhone = inCountryPhone.replace(prefix, "");
    return inCountryPhone;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '10px',
            bottom: '0',
        },
        select: {
            '& .MuiSelect-root': {
                padding: '17px 32.5px 17px 6px',
            },
        }
    })
);
export const PhoneCountrySelect = (props: {
    phoneCountry: string,
    onChange: (value: string) => void,
    disabled: boolean,
}) => {
    const classes = useStyles();
    function flagMenuItem(code: string, name: string): JSX.Element {
        return (<MenuItem value={code} className="" >
            <img src={`/img/flags/${code}.png`} height={30} alt={name}
                className={classes.img + " my-auto p-0"}
            />
            <Typography className="ml-5"
                color={props.disabled ? "textSecondary" : "inherit"}
                align="right">
                {extractPrefix(code)}
            </Typography>
        </MenuItem>);
    }
    return (
        <TextField variant="outlined" color="primary"
            select
            value={props.phoneCountry}
            disabled={props.disabled}
            onChange={(event) => props.onChange(event.target.value)}
            className={classes.select}
        >
            {flagMenuItem("AT", L10N({ key: "Austria" }))}
            {flagMenuItem("BE", L10N({ key: "Belgium" }))}
            {flagMenuItem("CH", L10N({ key: "Switzerland" }))}
            {flagMenuItem("CZ", L10N({ key: "Czech Republic" }))}
            {flagMenuItem("DE", L10N({ key: "Deutschland" }))}
            {flagMenuItem("PL", L10N({ key: "Poland" }))}
            {flagMenuItem("AE", L10N({ key: "United Arab Emirates" }))}
            {flagMenuItem("IN", L10N({ key: "India" }))}
        </TextField>
    );
};
