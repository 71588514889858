import { Typography } from "@material-ui/core";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadVisitAndAllDetails } from '../../store/details/details.actions';
import { RootState } from '../../store/store';
import { getCurrentUser } from "../../store/user/user.actions";
import { VisitSummaryCard } from "../visit/VisitSummaryCard";
import { L10N } from "./L10N";

export const VisitConfirmedPage = () => {
    const dispatch = useDispatch();
    const { visit, inProgress } = useSelector((state: RootState) => state.details);
    const { user } = useSelector((state: RootState) => state.user);
    const urlParams = useParams<{ visitID: string, visitAccessToken?: string }>();

    useEffect(() => {
        if (inProgress) return;
        if (!urlParams) return;
        if (!urlParams.visitID) return;
        if (urlParams.visitID === "undefined") return;
        if (!user && !urlParams.visitAccessToken) return;

        if (!user && urlParams.visitAccessToken) {
            dispatch(getCurrentUser({ accessToken: urlParams.visitAccessToken }));
            return;
        }
        if (!user) return;

        if (urlParams.visitID !== visit?.visitID) {
            dispatch(loadVisitAndAllDetails(user, urlParams.visitID));
        }
    }, [visit, urlParams, inProgress, dispatch, user]);

    return (
        <>
            {inProgress && <Typography>{L10N({ key: "Loading..." })}</Typography>}
            {!inProgress && visit && <VisitSummaryCard data={visit} />}
            {!inProgress && !visit && <Typography>{L10N({ key: "No visit selected" })}</Typography>}
        </>
    );
};
