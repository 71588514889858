import { Accordion, AccordionDetails, AccordionSummary, Button, CardActionArea, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CompanyOfferService } from '../../api/models/Details';
import { loadServiceDetails } from '../../store/details/details.actions';
import { changeInvite } from '../../store/invite/invite.actions';
import { RootState } from '../../store/store';
import { ServiceDetailsDialogVisible } from '../../store/ui/ui.actions';
import { L10N } from '../pages/L10N';
import { Routes } from '../pages/NavMenu';
import { EmiPaper } from '../ui/EmiPaper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            display: 'block',
            textTransform: 'none',
            outline: 'none !important',
            borderRadius: '20px',
        },
    }),
);
export const CompanyDetailsViewTabServices = (props: {
    onBookClick: () => void,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { company } = useSelector((state: RootState) => state.details);
    const { searchResults } = useSelector((state: RootState) => state.search);
    const classes = useStyles();

    async function infoClick(event: React.MouseEvent<any, MouseEvent>, serviceID: string) {
        event.stopPropagation();
        event.preventDefault();

        await dispatch(loadServiceDetails(serviceID));
        await dispatch(ServiceDetailsDialogVisible(true));
    }
    async function bookClick(event: React.MouseEvent<HTMLElement, MouseEvent>, item: CompanyOfferService) {
        props.onBookClick();

        await dispatch(changeInvite({
            query: searchResults?.query ?? "",
            offeredResourceID: item.offeredResourceID,
        }));
        history.push(Routes.invite);
        event.stopPropagation();
    }
    function renderService(details: CompanyOfferService): JSX.Element {
        return (<>
            <Col className={"p-1"}
                style={{
                    lineHeight: '15px'
                }}>
                <CardActionArea onClick={async (event) => await infoClick(event, details.serviceID)}>
                    <Typography display="inline">
                        {details?.serviceName}
                    </Typography>
                    <br />
                    <Typography variant="body1" display="inline" color="textPrimary">
                        {L10N({ key: "_priceAndCurrency", price: details?.price })}
                        &nbsp;
                        &nbsp;
                        &nbsp;
                    </Typography>
                    <Typography variant="body1" display="inline">
                        {L10N({
                            key: "_duration",
                            duration: (details?.durationInMinutes ?? 0)
                                + (details?.timeBeforeInMinutes ?? 0)
                                + (details?.timeAfterInMinutes ?? 0),
                        })}
                    </Typography>
                    <img src="/img/icons/icon_info.svg" alt="info" className="ml-2" />
                </CardActionArea>
            </Col>
            <Col className="p-0 col-auto " >
            </Col>
            <Col className="col-auto p-1 ml-auto">
                <Button className={classes.btn + " px-4 align-middle"}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={async (event) => bookClick(event, details)}>
                    {L10N({ key: "Book" })}
                </Button>
            </Col>
        </>);
    }
    return (<>
        <Container className="my-3">
            <Row className="mx-n5">
                {company?.serviceGroups?.map && company?.serviceGroups?.map((group, iGroup) =>
                    <React.Fragment key={"Ac" + iGroup + group?.serviceGroupName ?? "All services"}>
                        <Col xl={6} md={12} className={"mb-3 " + ((iGroup % 2) ? "" : "")}>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                    className="text-black text-weight-bold2"
                                >{group?.serviceGroupName ?? L10N({ key: "All services" })}</AccordionSummary>
                                <AccordionDetails className="d-block">
                                    {group.services.map && group.services.map((service, iService) =>
                                        <EmiPaper key={"sp" + iService + service?.offeredResourceID.join() ?? iService}
                                            elevationOnNoMouse={0} elevationOnMouseOver={0}
                                            classOnMouseOver="border border-primary"
                                            classOnNoMouse="border border-white"
                                        >
                                            <Container>
                                                <Row >
                                                    {renderService(service)}
                                                </Row>
                                            </Container>
                                        </EmiPaper>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </React.Fragment>)}
            </Row>
        </Container>
    </>);
};
