import { Avatar, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import React from "react";
import { useSelector } from 'react-redux';
import { api } from "../../api/api";
import { RootState } from '../../store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        large: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            cursor: 'pointer',
        },
        largeSelected: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            borderColor: theme.palette.primary.main,
            borderWidth: '3px',
            cursor: 'pointer',
        },
        divCenterParent: {
            float: 'left',
            position: 'relative',
            left: '50%',
        },
        divCenterChild: {
            float: 'left',
            position: 'relative',
            left: '-50%',
        },
        avatarText: {
            maxWidth: theme.spacing(12),
        },
    }),
);
export const CalendarResourcePicker = (props: {
    toggleSelectResource: (resourceID: string | undefined) => void,
    selectedResourceDimension?: string,
}) => {
    const classes = useStyles();
    const { calendar } = useSelector((state: RootState) => state.company);

    return (<>
        {calendar?.resourceDimension?.map &&
            <AvatarGroup>
                {calendar?.resourceDimension?.map((m, i) =>
                    <div className={"float-left mx-2"} key={m.resourceID}
                        onClick={() => props.toggleSelectResource(m.resourceID)}
                    >
                        <div className={classes.divCenterParent}>
                            <div className={classes.divCenterChild}>
                                <Avatar
                                    alt={m.displayName}
                                    className={m.resourceID === props.selectedResourceDimension
                                        ? `${classes.largeSelected} `
                                        : `${classes.large} `}
                                    src={api.Search.getPhotoUrl(m.photo)} />
                            </div>
                        </div>
                        <div className="w-100"></div>
                        <div className={"float-left w-100 mt-1 " + classes.avatarText} >
                            <Typography variant="body2" align="center"
                                color={m.resourceID === props.selectedResourceDimension
                                    ? "textPrimary"
                                    : "textSecondary"}                                >
                                {m.displayName}
                            </Typography>
                        </div>
                    </div>
                )}
            </AvatarGroup>
        }
    </>);
};
