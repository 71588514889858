import { Button, createStyles, IconButton, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../store/store';
import { ButtonShadow } from '../ui/ButtonShadow';
import { BackArrowHeader } from './BackArrowHeader';
import { L10N } from './L10N';
import { Routes } from './NavMenu';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            textTransform: 'none',
            borderRadius: '8px',
            height: theme.spacing(8),
            "&.MuiButton-containedPrimary:hover": {
                color: 'white',
            },
        },
        code: {
            textTransform: 'none',
            borderRadius: '8px',
            height: theme.spacing(8),
        },
    }),
);
export const RecommendPage = (props: {}) => {
    const classes = useStyles();
    const { user, inProgress } = useSelector((state: RootState) => state.user);
    const sendBtnRef = useRef<HTMLAnchorElement>(null);
    const createAccountUrl = Routes.createCompanyPage(user?.userID);
    const fullUrl = "https://letsbookee.com" + createAccountUrl;
    function buildHref(): string {
        const emailSubject = L10N({ key: "emailsubject:Invite to join bookee" });
        const emailBody = L10N({ key: `emailbody1:Check out this new bookee: ` })
            + fullUrl
            + L10N({ key: `emailbody2:Remember to use my referral code` });
        const result = `mailto:?subject=${emailSubject}&body=${emailBody}`;
        return result;
    }
    async function shareClick() {
        if (navigator.share) {
            try {
                await navigator.share({ title: L10N({ key: "Invite to join bookee" }), url: fullUrl });
            } catch { }
        } else {
            sendBtnRef?.current?.click();
        }
    }
    function onLinkClick() {
        copy(fullUrl);
    }
    return (<>
        {!inProgress && !(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(Routes.recommend)} />}
        <Container>
            <Row className="my-3" />
            <BackArrowHeader title={L10N({ key: "Recommend Bookee" })} />
            <Row className="mb-3">
                <Col />
                <Col className="col-auto">
                    <img src={`/img/envelope.svg`} alt="heart" width="150" />
                </Col>
                <Col />
            </Row>
            <Row className="mb-3">
                <Col md={3} />
                <Col className="">
                    <Typography variant="body1" className="my-3" align="center">
                        {L10N({ key: "Are you satisfied with Bookee and want to find even more offers?" })}
                    </Typography>
                    <Typography variant="body1" className="my-3" align="center">
                        {L10N({ key: "Then recommend us to your friends, hairdresser, doctor or other service provider and receive a bonus for every subscription you take out." })}
                    </Typography>
                    <Typography variant="body1" className="my-3" align="center">
                        {L10N({ key: "Get started now, support us and secure your reward!" })}
                    </Typography>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="mb-3">
                <Col md={3} />
                <Col className="px-5">
                    <Typography variant="subtitle2" align="center" className="mb-3">
                        {L10N({ key: "Here is your special code." })}
                    </Typography>
                    <TextField
                        value={fullUrl}
                        variant="outlined" color="primary"
                        fullWidth className={classes.code}
                        inputProps={
                            { readOnly: true, }
                        }
                        InputProps={{
                            endAdornment: (<>
                                <IconButton onClick={onLinkClick} style={{ order: 1 }} >
                                    <FileCopyIcon color="primary" />
                                </IconButton>
                            </>)
                        }}
                    />
                </Col>
                <Col md={3} />
            </Row>
            <Row className="my-5">
                <Col md={3} />
                <Col className="px-5">
                    <Button onClick={shareClick}
                        className={classes.btn}
                        variant="outlined" color="primary" fullWidth>
                        {L10N({ key: "Share" })}
                    </Button>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="">
                <Col md={3} />
                <Col className="px-5">
                    <Typography variant="subtitle2" align="center" color="secondary">
                        {L10N({ key: "OR" })}
                    </Typography>
                </Col>
                <Col md={3} />
            </Row>
            <Row className="my-5">
                <Col md={3} />
                <Col className="px-5">
                    <Button ref={sendBtnRef}
                        href={buildHref()}
                        className={classes.btn}
                        variant="contained" color="primary"
                        disableElevation
                        fullWidth>
                        {L10N({ key: "Send to contacts" })}
                        <ButtonShadow />
                    </Button>
                </Col>
                <Col md={3} />
            </Row>
        </Container>
    </>
    );
};
