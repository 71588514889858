import { api } from "../api";
import { ApiResponse } from "../ApiResponse";
import { CreateInviteMessage, Invite } from "../models/Invite";

export class InviteEndpoint {
    async createInvite(message: CreateInviteMessage): Promise<ApiResponse<Invite>> {
        const apiResponse = await api.createRequest({
            endpoint: `search/create-invite`,
            method: "POST",
            json: JSON.stringify(message),
            resultJsonTransformer: (json) => json as Invite,
        });
        return apiResponse;
    }
}