import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from '../../api/api';
import { AssignCompanyMessage } from '../../api/models/Edit';
import { AccessTokenBearer } from "../../api/models/Global";
import { UserProfile, UserRefreshAccessTokenMessage, UserRefreshPINMessage } from "../../api/models/User";
import { RootState } from '../store';
import { hideProgress, resetRedux } from '../ui/ui.actions';
import {
    CHANGE_USER
} from './user.types';

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
export const refreshPIN = (
    message: UserRefreshPINMessage
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: true,
            user: undefined,
            apiError: undefined,
        }
    });
    const apiResponse = await api.Users.refreshPIN(message);

    dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            user: apiResponse.result,
            apiError: apiResponse.error,
        }
    });
};
export const refreshAccessToken = (
    message: UserRefreshAccessTokenMessage
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: true,
            user: undefined,
            apiError: undefined,
        }
    });
    const apiResponse = await api.Users.refreshAccessToken(message);

    dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            user: apiResponse.result,
            apiError: apiResponse.error,
        }
    });
};
export const getCurrentUser = (
    message: AccessTokenBearer
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: true,
            user: undefined,
        }
    });
    const apiResponse = await api.Users.getCurrentUser(message);
    dispatch(hideProgress(apiResponse.error));

    dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            user: apiResponse.result
        }
    });
};
export const refreshCurrentUser = (
    message: AccessTokenBearer
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: true,
        }
    });
    const apiResponse = await api.Users.getCurrentUser(message);
    dispatch(hideProgress(apiResponse.error));

    dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            user: apiResponse.result
        }
    });
};
export const toggleFavorites = (
    user: UserProfile,
    companyID: string,
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: true,
            inProgressFavoriteCompanyID: companyID,
        }
    });
    const index = user.favoriteCompanyIDs?.findIndex((v, i, a) => v === companyID);
    if (index >= 0) {
        user.favoriteCompanyIDs.splice(index, 1);
    } else {
        if (!user.favoriteCompanyIDs) {
            user.favoriteCompanyIDs = [companyID];
        } else {
            user.favoriteCompanyIDs.push(companyID);
        }
    }

    const apiResponse = await api.Users.updateUserProfile(user);
    dispatch(hideProgress(apiResponse.error));

    dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            inProgressFavoriteCompanyID: undefined,
            user: apiResponse.result
        }
    });
};
export const updateUserProfile = (
    message: UserProfile
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: true,
            user: undefined,
        }
    });
    const apiResponse = await api.Users.updateUserProfile(message);
    dispatch(hideProgress(apiResponse.error));

    dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            user: apiResponse.result
        }
    });
};
export const changeLanguage = (
    lang: string
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            lang: lang,
        }
    });
};
export const assignCompany = (
    message: AssignCompanyMessage
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: true,
            user: undefined,
        }
    });
    const apiResponse = await api.Edit.assignCompany(message);
    dispatch(hideProgress(apiResponse.error));

    dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            user: apiResponse.result
        }
    });
};
export const logout = (
    message: AccessTokenBearer
): AppThunk => async (dispatch, getState) => {
    const state = getState().user;
    await dispatch({
        type: CHANGE_USER,
        payload: {
            ...state,
            inProgress: true,
        }
    });
    const apiResponse = await api.Users.logout(message);
    await dispatch(hideProgress(apiResponse.error));
    await dispatch({
        type: CHANGE_USER,
        payload: {
            inProgress: false,
            user: null,
        }
    });
    await dispatch(resetRedux());
};
export const refreshReminders = (
): AppThunk => async (dispatch, getState) => {
    const state = getState().user;
    await dispatch({
        type: CHANGE_USER,
        payload: {
            ...state,
            inProgress: true,
            remindersLog: undefined,
        }
    });
    const apiResponse = await api.Users.refreshReminders();

    dispatch({
        type: CHANGE_USER,
        payload: {
            ...state,
            inProgress: false,
            remindersLog: apiResponse.result,
        }
    });
};
export const clearReminders = (
): AppThunk => async (dispatch, getState) => {
    const state = getState().user;
    await dispatch({
        type: CHANGE_USER,
        payload: {
            ...state,
            remindersLog: undefined,
        }
    });
};
export const setMobileClient = (
    mobileClient?: string,
    mobileVersion?: string
): AppThunk => async dispatch => {
    await dispatch({
        type: CHANGE_USER,
        payload: {
            mobileClient: mobileClient,
            mobileVersion: mobileVersion,
        }
    });
};