import { MapCoords } from "./Details";
import { DimensionTypes, BeePhoto } from "./Global";

export interface SearchResults {
    query: string,
    timeStamp: string,
    results: SearchResult[],
}
export interface SearchResult {
    title: string,
    subtitle: string,
    addressLine: string[],
    details: string,
    photos: BeePhoto[],
    avatars: SearchAvatar[],
    companyID: string,
    isDemo: boolean,
    ratingScore?: number,
    ratingVotes?: number,
    mapCoords: MapCoords,
    offeredResourceID: string[],
}
export interface SearchAvatar {
    photo: BeePhoto,
    displayName: string,
}
export interface SearchHints {
    query: string,
    timeStamp: string,
    results: SearchHint[],
}
export interface SearchHint {
    hintText: string,
    encodedText: string,
    hintType: DimensionTypes,
}
export interface SearchCategories {
    query: string,
    timeStamp: string,
    results: SearchCategory[],
}
export const SearchCategory_Anything = "anything";

export interface SearchCategory {
    text: string,
    encodedText: string,
    hasResults: boolean,
}