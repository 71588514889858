import { InputAdornment, TextField } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { api } from '../../api/api';
import { CompanyEditModel, PhotoEditModel } from '../../api/models/Edit';
import { L10N } from '../pages/L10N';
import { AvatarUpload } from '../ui/AvatarUpload';
import { EmiPaper } from '../ui/EmiPaper';
import { useFormikResult } from '../ui/FormikAPI';

export const FieldSetCompanyForCustomers = (props: {
    formik: useFormikResult<CompanyEditModel>,
}) => {

    return <>
        <Container>
            <EmiPaper className="p-3 mb-3">
                <Row>
                    {props.formik.values.photos?.map && props.formik.values.photos.map((m, i) =>
                        <Col key={m.photoIndex} className="col-auto">
                            <AvatarUpload
                                variant="img"
                                src={props.formik.values.photos?.[i]?.base64 ?? api.Search.getPhotoUrl(m) ?? ""}
                                editMode
                                deleteMode
                                positionMode
                                position={props.formik.values.photos?.[i]?.position ?? "Default"}
                                onDelete={() => {
                                    props.formik.setFieldValue(`photos[${i}].base64`, "");
                                    props.formik.setFieldValue(`photos[${i}].isDeleted`, true);
                                }}
                                onChange={(base64, position) => {
                                    props.formik.setFieldValue(`photos[${i}].base64`, base64);
                                    props.formik.setFieldValue(`photos[${i}].position`, position ?? "Default");
                                }}
                                fileId={"" + i}
                                alt={props.formik.values.description ?? ""} />
                        </Col>
                    )}
                    <Col className="col-auto">
                        <AvatarUpload
                            variant="square"
                            src=""
                            addMode
                            onChange={(base64) => props.formik.setFieldValue(`photos[${props.formik.values.photos.length ?? 0}]`, {
                                base64: base64,
                                accessToken: "",
                                objectID: props.formik.values.companyID,
                                photoIndex: props.formik.values.photos.length,
                                versionStamp: moment().toISOString(),
                                isDeleted: false,
                                position: "Default",
                            } as PhotoEditModel)}
                            fileId={"new"}
                            alt={props.formik.values.description ?? ""} />
                    </Col>
                </Row>
            </EmiPaper>
            <TextField variant="outlined" className="mb-3" fullWidth
                multiline rows={5}
                id="description" name="description" label={L10N({ key: "About us" })}
                value={props.formik.values.description}
                onChange={props.formik.handleChange}
                error={props.formik.touched.description && Boolean(props.formik.errors.description)}
                helperText={props.formik.touched.description && props.formik.errors.description}
            />
            <TextField variant="outlined" className="mb-3" fullWidth
                InputProps={{
                    startAdornment: <InputAdornment position="start" className="mr-1">https://</InputAdornment>,
                }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                id="website" name="website" label={L10N({ key: "Company Website" })}
                onChange={(e) => props.formik.setFieldValue(`website`, "https://" + e.target.value)}
                value={props.formik.values.website?.replace("https://", "")}
                error={props.formik.touched.website && Boolean(props.formik.errors.website)}
                helperText={props.formik.touched.website && props.formik.errors.website}
            />
            <TextField variant="outlined" className="mb-3" fullWidth
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                id="email" name="email" label={L10N({ key: "Company Email" })}
                value={props.formik.values.email?.trim() ?? ""}
                onChange={props.formik.handleChange}
                error={props.formik.touched.email && Boolean(props.formik.errors.email)}
                helperText={props.formik.touched.email && props.formik.errors.email}
            />
            <TextField variant="outlined" className="mb-3" fullWidth
                id="contactDetails" name="contactDetails" label={L10N({ key: "Contact Details for Clients" })}
                multiline rows={2}
                value={props.formik.values.contactDetails}
                onChange={props.formik.handleChange}
                error={props.formik.touched.contactDetails && Boolean(props.formik.errors.contactDetails)}
                helperText={props.formik.touched.contactDetails && props.formik.errors.contactDetails}
            /><br />
        </Container>
    </>;
}