import { Button, ButtonGroup, createStyles, LinearProgress, makeStyles, StepButton, Theme, Typography } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { CompanyEditModelValidationSchema, CreateNewCompany, CreateNewResource, CreateNewService, ResourceEditModel, ResourceEditModelValidationSchema, ServiceEditModel, ServiceEditModelValidationSchema } from '../../api/models/Edit';
import { loadAllCompanyForEdit, UpdateCompanyAndReload, UpdateCompanyResourceAndReload, UpdateCompanyServiceAndReload } from '../../store/edit/edit.actions';
import { RootState } from '../../store/store';
import { FieldSetResourceList } from '../edit/FieldSetResourceList';
import { FieldSetServiceList } from '../edit/FieldSetServiceList';
import { CompanyEditStep, FormCompanyDetails } from '../edit/FormCompanyDetails';
import { FormResourceDetails } from '../edit/FormResourceDetails';
import { FormServiceDetails } from '../edit/FormServiceDetails';
import { BackArrowHeader } from '../pages/BackArrowHeader';
import { ScreenSizeListener } from '../ui/ScreenSizeListener';
import { L10N } from './L10N';
import { Routes } from './NavMenu';

export type EditStep = CompanyEditStep
    | 'resources'
    | 'services'
    | 'publish'
    ;
function getAllSteps(): EditStep[] {
    return [
        'about',
        'for clients',
        'address',
        'resources',
        'services',
        'publish',
    ];
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            "& :first-child": {
                "border-top-left-radius": '20px !important',
                "border-bottom-left-radius": '20px !important',
            },
            "& :last-child": {
                "border-top-right-radius": '20px !important',
                "border-bottom-right-radius": '20px !important',
            },
            textTransform: 'none',
        },
    }),
);
export const EditPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user, inProgress } = useSelector((state: RootState) => state.user);
    const { editInProgress, company, resources, services } = useSelector((state: RootState) => state.edit);
    const urlParams = useParams<{ step: EditStep, companyID: string, selectedServiceID?: string, selectedResourceID?: string }>();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getAllSteps();
    const selectedResource = resources?.find((m, i) => m.resourceID === urlParams.selectedResourceID);
    const selectedService = services?.find((m, i) => m.serviceID === urlParams.selectedServiceID);
    const classes = useStyles();

    useEffect(() => {
        if (inProgress) return;
        if (!urlParams.companyID) return;

        if (urlParams.companyID !== company?.companyID) {
            if (!user) return;
            dispatch(loadAllCompanyForEdit(user, urlParams.companyID));
        }
    }, [company, urlParams, inProgress, user, dispatch]);

    const formik = useFormik({
        initialValues: company ?? CreateNewCompany(),
        enableReinitialize: true,
        validationSchema: CompanyEditModelValidationSchema,
        onSubmit: (values) => {
            values.accessToken = user?.accessToken ?? "";
            dispatch(UpdateCompanyAndReload(values));
        },
    });
    const formikResource = useFormik({
        initialValues: selectedResource ?? CreateNewResource(company?.companyID ?? ""),
        enableReinitialize: true,
        validationSchema: ResourceEditModelValidationSchema,
        onSubmit: (values) => {
            values.accessToken = user?.accessToken ?? "";
            dispatch(UpdateCompanyResourceAndReload(values));
            const url = Routes.editPage(urlParams.companyID, "resources", undefined, undefined);
            history.push(url);
        },
    });
    const formikService = useFormik({
        initialValues: selectedService ?? CreateNewService(company?.companyID ?? ""),
        enableReinitialize: true,
        validationSchema: ServiceEditModelValidationSchema,
        onSubmit: (values) => {
            values.accessToken = user?.accessToken ?? "";
            dispatch(UpdateCompanyServiceAndReload(values));
            const url = Routes.editPage(urlParams.companyID, "services", undefined, undefined);
            history.push(url);
        },
    });
    useEffect(() => {
        if (activeStep < 0 || activeStep >= steps.length) return;
        const stepValue = steps[activeStep];
        if (stepValue === urlParams.step) return;

        const url = getStepUrl(activeStep);
        if (!url) return;

        history.push(url);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep]);

    function getStepUrl(stepIndex: number): string {
        const stepValue = getStepByIndex(stepIndex);
        const url = Routes.editPage(urlParams.companyID, stepValue, undefined, undefined);
        return url;
    }
    function getStepByIndex(step: number): EditStep | undefined {
        if (activeStep < 0 || activeStep >= steps.length) return;

        const stepValue = steps[step];
        return stepValue;
    }
    const handleNext = () => {
        const stepValue = getStepByIndex(activeStep);
        const shouldReturnFromResources = stepValue === "resources"
            && urlParams.selectedResourceID
            && urlParams.selectedResourceID !== "-";
        const shouldReturnFromServices = stepValue === "services"
            && urlParams.selectedServiceID
            && urlParams.selectedServiceID !== "-";
        if (shouldReturnFromResources || shouldReturnFromServices) {
            const url = getStepUrl(activeStep);
            if (!url) return;
            history.push(url);
            return;
        } else {
            setActiveStep((prevActiveStep) => (prevActiveStep + 1) % steps.length);
        }
    };
    const handleBack = () => {
        const stepValue = getStepByIndex(activeStep);
        const shouldReturnFromResources = stepValue === "resources"
            && urlParams.selectedResourceID
            && urlParams.selectedResourceID !== "-";
        const shouldReturnFromServices = stepValue === "services"
            && urlParams.selectedServiceID
            && urlParams.selectedServiceID !== "-";
        if (shouldReturnFromResources || shouldReturnFromServices) {
            const url = getStepUrl(activeStep);
            if (!url) return;
            history.push(url);
            return;
        } else {
            setActiveStep((prevActiveStep) => (prevActiveStep - 1) % steps.length);
        }
    };
    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    function onNewServiceClick() {
        formikService.resetForm();
        const url = Routes.editPage(urlParams.companyID, "services", "new", urlParams.selectedResourceID);
        history.push(url);
    }
    function onServiceClick(item: ServiceEditModel) {
        const url = Routes.editPage(urlParams.companyID, "services", item.serviceID, urlParams.selectedResourceID);
        history.push(url);
    }
    function onNewResourceClick() {
        formikResource.resetForm();
        const url = Routes.editPage(urlParams.companyID, "resources", urlParams.selectedServiceID, "new");
        history.push(url);
    }
    function onResourceClick(item: ResourceEditModel) {
        const url = Routes.editPage(urlParams.companyID, "resources", urlParams.selectedServiceID, item.resourceID);
        history.push(url);
    }
    function onSeeAsClientClick() {
        const url = Routes.detailsTab("company", urlParams.companyID);
        if (isMobile) {
            const fullUrl = "https://letsbookee.com" + url;
            window.location.href = fullUrl;
        } else {
            window.open(url, '_blank')?.focus();
        }
    }
    const showTitleThreshold = "xs";
    const activeStepValue = getStepByIndex(activeStep);

    return (
        <>
            {!inProgress && !(user?.clientAccessGranted ?? false) && <Redirect to={Routes.loginAndReturn(getStepUrl(activeStep))} />}
            <Container>
                <BackArrowHeader title={L10N({ key: 'Edit Company' })} />
                {editInProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                <Row>
                    <Col>
                        <Stepper alternativeLabel nonLinear
                            activeStep={activeStep} >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepButton onClick={handleStep(index)}
                                        completed={false}
                                    >
                                        <ScreenSizeListener threshold={showTitleThreshold}
                                            whenAboveThreshold={L10N({ key: '_editStep', editStep: getStepByIndex(index) })}
                                            whenThresholdOrBelow={""}
                                        />
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ScreenSizeListener threshold={showTitleThreshold}
                            whenAboveThreshold={""}
                            whenThresholdOrBelow={<Typography align="center" >{L10N({ key: '_editStep', editStep: getStepByIndex(activeStep) })}
                            </Typography>}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="my-5">
                            {(activeStepValue === 'about'
                                || activeStepValue === 'for clients'
                                || activeStepValue === 'address'
                                || activeStepValue === 'publish'
                            )
                                &&
                                <FormCompanyDetails formik={formik} activeStepValue={activeStepValue} />
                            }
                            {activeStepValue === 'resources' && <>
                                {urlParams.selectedResourceID && urlParams.selectedResourceID !== "-"
                                    ? <FormResourceDetails formik={formikResource} />
                                    : <FieldSetResourceList onNew={onNewResourceClick} onSelected={onResourceClick} />
                                }
                            </>}
                            {activeStepValue === 'services' && <>
                                {urlParams.selectedServiceID && urlParams.selectedServiceID !== "-"
                                    ? <FormServiceDetails formik={formikService} />
                                    : <FieldSetServiceList onNew={onNewServiceClick} onSelected={onServiceClick} />
                                }
                            </>}
                        </div>
                    </Col>
                </Row>
                {(!formikService.dirty && !formikResource.dirty && !formik.dirty)
                    ?
                    <Row className="mb-5">
                        <Col />
                        <Col className="col-auto">
                            <ButtonGroup size="small" className={classes.btn}>
                                <Button disabled={activeStep === 0} onClick={handleBack} >
                                    {L10N({ key: 'Back' })}
                                </Button>
                                <Button onClick={onSeeAsClientClick}>
                                    {L10N({ key: 'View as client' })}
                                </Button>
                                <Button variant="contained"
                                    disabled={activeStep === steps.length - 1}
                                    color="primary"
                                    onClick={handleNext}
                                >
                                    {L10N({ key: 'Next' })}
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col />
                    </Row>
                    :
                    <Row className="mb-5">
                        <Col />
                        <Col className="col-auto">
                            <ButtonGroup size="small" className={classes.btn}>
                                <Button onClick={onSeeAsClientClick}>
                                    {L10N({ key: 'View as client' })}
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col />
                    </Row>
                }
            </Container>
        </>
    );
};
