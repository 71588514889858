import { createStyles, LinearProgress, makeStyles, Theme, Typography } from "@material-ui/core";
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SearchCategory, SearchCategory_Anything } from "../../api/models/Search";
import { refreshHints } from "../../store/search/search.actions";
import { RootState } from '../../store/store';
import { L10N } from "../pages/L10N";
import { Animations } from "../ui/Animations";
import { CategoryIcon } from "../ui/CategoryIcon";
import { MarketingGradientShadow } from "./MarketingGradientShadow";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryRow: {
            backgroundColor: '#FBFBFB',
        },
        category: {
            display: 'flex',
            justifyContent: 'center',
            width: '120px',
            height: '120px',
            "&:hover": {
                "& img": {
                    display: 'none',
                },
                "& .MuiTypography-root": {
                    display: 'block',
                },
            },
            "& img": {
                display: 'block',
                margin: '10px',
                padding: '10px',
                verticalAlign: 'bottom',
                backgroundColor: '#fff',
                borderRadius: '16px',
                width: '100px',
                height: '100px',
            },
            "& .MuiTypography-root": {
                display: 'none',
                verticalAlign: 'bottom',
                width: '100%',
                height: '120px',
                marginTop: '50px',
            },
        },
    }),
);
export const Category = (props: {
    data: SearchCategory
}) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);

    function onMouseOver() {
        if (hover) return;
        setHover(true);
    }
    function onMouseLeave() {
        if (!hover) return;
        setHover(false);
    }

    return (<div className={classes.category}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseLeave}
    >
        <Typography variant="subtitle1" align="center" > {L10N({ key: "_category", cat: props.data.text })}</Typography>
        <CategoryIcon name={props.data.text} noSizeLimit
            className={Animations.roll_in_blurred_left()} />
    </div>
    );
}
export const Marketing04_Categories = () => {
    const dispatch = useDispatch();
    const { searchHintsInProgress, searchCategories } = useSelector((state: RootState) => state.search);
    const classes = useStyles();

    useEffect(() => {
        if (!searchHintsInProgress && (searchCategories?.results?.length ?? 0) === 0) {
            dispatch(refreshHints());
        }
    });
    return (
        <>
            <MarketingGradientShadow />
            <Container >
                <Row className="my-3">
                    <Col />
                    <Col className="col-auto"
                        style={{
                            backgroundImage: 'url(/img/marketing/app-circles.svg)',
                            backgroundRepeat: 'no-repeat',
                            minHeight: '150px',
                        }}>
                        <Typography variant="h4" align="center"
                            className=""
                            style={{
                                fontWeight: 'bold',
                                marginTop: '32px',
                                marginLeft: '15px',
                            }}>
                            {L10N({ key: 'Kategorien' })}
                        </Typography>
                    </Col>
                    <Col />
                </Row>
                {searchHintsInProgress && <>
                    <Row>
                        <Col xs={12}>
                            <LinearProgress className="w-100 mt-n1" color="secondary" />
                        </Col>
                    </Row>
                </>}
                {(searchCategories?.results?.length ?? 0) > 0 && <Row className={"my-3 " + classes.categoryRow}>
                    {searchCategories?.results
                        .filter((m, i, a) => m.text !== SearchCategory_Anything)
                        .filter((m, i, a) => L10N({ key: "_category", cat: m.text }) !== "IGNORE")
                        .map((m, i) => <Col xs={4} md={3} lg={2}
                            key={m.encodedText}>
                            <Category data={m} />
                        </Col>)}
                </Row>}
            </Container>
        </>
    );
};
